import React from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Button, Card, Col, DatePicker, Form, message, Row, Select, Typography } from 'antd';
import moment from 'moment';
import { Subscription, SubscriptionType } from '../../model/subscription';
import { gtinValidator } from './EditSubscriptionPage';
import { useHistory } from 'react-router-dom';
import { buildPath } from '../../utils/build-path';
import { ROUTER_PAGES } from '../../routes';
import ProductCategoryTree from '../../components/ProductCategoryTree';
import { useSaveSubscription } from '../../api/subscription';

const AddNewSubscriptionPage: React.FC = () => {
    const history = useHistory();
    const [saveSubscriptionMutation] = useSaveSubscription();

    const [form] = Form.useForm();

    const onFormSubmit = (formData: any): void => {
        const subscription: Subscription = {
            type: SubscriptionType.PULL,
            body: {
                gpcCategoryCode: formData.gpcCategoryCode,
                gtins: formData.gtins.map((gtin: any) => gtin.trim()),
                lastChangeDateTimeFrom: (formData.lastChangeDateTimeFrom
                    ? (formData.lastChangeDateTimeFrom as moment.Moment).startOf('day').toISOString()
                    : null) as any, // FIXME: check if typescript is correct here and null is not valid value
            },
        };

        saveSubscriptionMutation({
            variables: { subscription: subscription },
        })
            .then((result) => {
                message.success('Subscription created successfully');
                history.replace(
                    buildPath(ROUTER_PAGES.editSubscription.path, {
                        id: String(result.data?.subscription.id),
                    })
                );
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    const onFormReset = () => {
        form.resetFields();
    };

    return (
        <div>
            <Breadcrumb />
            <Typography.Title level={1}>Add new subscription</Typography.Title>

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Card>
                        <Form
                            form={form}
                            labelCol={{ span: 3 }}
                            name="subscription-form"
                            onFinish={onFormSubmit}
                            initialValues={{ gtins: [] }}
                        >
                            <ProductCategoryTree formItemName="gpcCategoryCode" formItemLabel="Choose categories" />
                            <Form.Item name="gtins" label="GTINs" rules={[{ validator: gtinValidator }]}>
                                <Select
                                    mode="tags"
                                    placeholder="Enter or paste in a comma separated gtin list"
                                    tokenSeparators={[',']}
                                    maxTagCount={5}
                                    allowClear={true}
                                ></Select>
                            </Form.Item>
                            <Form.Item name="lastChangeDateTimeFrom" label="From date">
                                <DatePicker />
                            </Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={onFormReset} style={{ marginRight: '10px' }}>
                                    Reset
                                </Button>
                                <Button htmlType={'submit'} type={'primary'}>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AddNewSubscriptionPage;
