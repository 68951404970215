import React, { FC, useCallback, useMemo, useState } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Alert, Button, Modal, notification, Popconfirm, Space, Table, TablePaginationConfig, Typography } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { ExpandedRowRender } from 'rc-table/lib/interface';
import { ColumnsType } from 'antd/lib/table';
import GroupPermissions from './GroupPermissions';
import { UserGroup } from './types';
import { useUserGroups } from './hooks';
import AdminPermission from './AdminPermission';
import styles from './styles.module.scss';
import AddNewGroup from './AddNewGroup';
import { EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const PAGE_SIZE = 10;

const DELETE_GROUP_MUTATION = gql`
    mutation DeleteGroupMutation($id: ID!) {
        deleteUserGroup(id: $id)
    }
`;

const Actions: FC<{ record: UserGroup; refetch: () => void }> = ({ record, refetch }) => {
    const [deleteGroup, { loading: deleteInProgress }] = useMutation<unknown, { id: string }>(DELETE_GROUP_MUTATION);
    return (
        <Space>
            <Popconfirm
                title={`Are you sure to delete group "${record.name}"?`}
                onConfirm={() => {
                    deleteGroup({
                        variables: {
                            id: record.id,
                        },
                    })
                        .then(() => {
                            notification.success({
                                message: `Group "${record.name}" deleted`,
                            });
                            return refetch();
                        })
                        .catch((err) => {
                            notification.error({
                                message: err instanceof Error ? err.message : String(err),
                            });
                        });
                }}
                okText="Yes"
                cancelText="No"
            >
                <Button type={'default'} danger loading={deleteInProgress}>
                    Delete group
                </Button>
            </Popconfirm>
            <Link to={`/acl/users?group=${record.id}`} className={'ant-btn ant-btn-link'}>
                <EyeOutlined /> Manage users
            </Link>
        </Space>
    );
};
const ACLPage: FC = () => {
    const { data, loading, error, refetch } = useUserGroups();

    const columns: ColumnsType<UserGroup> = useMemo(() => {
        return [
            {
                key: 'id',
                dataIndex: 'id',
                title: 'ID',
            },
            {
                key: 'name',
                dataIndex: 'name',
                title: 'Name',
            },
            {
                key: 'admin',
                dataIndex: 'admin',
                title: 'Is Admin',
                render: (value, record) => {
                    return <AdminPermission group={record} />;
                },
            },
            {
                key: 'actions',
                title: 'Actions',
                render: (value, record) => {
                    return <Actions record={record} refetch={refetch} />;
                },
            },
        ];
    }, [refetch]);
    const expandedRowRender: ExpandedRowRender<UserGroup> = useCallback((record) => {
        return <GroupPermissions group={record} />;
    }, []);
    const expandableConfig = useMemo(
        () => ({
            expandedRowRender,
        }),
        [expandedRowRender]
    );
    const title = useCallback(() => (error ? <Alert type={'error'} message={error.message} /> : undefined), [error]);

    const [addNewGroupModalVisible, setAddNewGroupModalVisible] = useState(false);
    const [page, setPage] = useState(1);
    const pagination: TablePaginationConfig = useMemo(
        () => ({ pageSize: PAGE_SIZE, current: page, onChange: (page1) => setPage(page1) }),
        [page]
    );

    const handleAddNewGroupSuccess = useCallback(() => {
        setAddNewGroupModalVisible(false);
        refetch()
            .then((res) => {
                setPage(Math.ceil(res.data.userGroups.length / PAGE_SIZE));
            })
            .catch((err) => {
                notification.error({
                    message: err instanceof Error ? err.message : String(err),
                });
            });
    }, [refetch]);
    return (
        <div>
            <Breadcrumb />
            <Typography.Title>User groups</Typography.Title>
            <div className={styles.operations}>
                <Button type={'primary'} onClick={() => setAddNewGroupModalVisible(true)}>
                    Create group
                </Button>
            </div>
            <Table
                rowKey={'id'}
                loading={loading}
                columns={columns}
                dataSource={data?.userGroups}
                expandable={expandableConfig}
                title={title}
                pagination={pagination}
            />
            <Modal
                title={'Add new group'}
                footer={null}
                visible={addNewGroupModalVisible}
                destroyOnClose={true}
                onCancel={() => setAddNewGroupModalVisible(false)}
            >
                <AddNewGroup onSuccess={handleAddNewGroupSuccess} />
            </Modal>
        </div>
    );
};

export default ACLPage;
