import React, { FC, useCallback, useMemo, useState } from 'react';
import { Tree, Table, Button, Drawer, Form, message, Spin, Space } from 'antd';
import { InterpretedIngredient } from '../../../model/ingredients';
import { Ingredient } from './Ingredient';
import { buildTreeData, dropToPayload } from './data';
import { NodeDragEventParams } from 'rc-tree/lib/contextTypes';
import { EventDataNode, Key } from 'rc-tree/lib/interface';
import { buildParentsMap, buildParentsMapKeyed, findNode } from '../../../utils/tree';
import { ColumnsType } from 'antd/lib/table/interface';
import { ExpandAltOutlined, ShrinkOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import { GetComponentProps, TableComponents } from 'rc-table/lib/interface';
import { Operations } from './Operations';
import { FoundNameCell } from './cells/FoundNameCell';
import {
    EditItemIdContext,
    EditRowForm,
    ItemsOnDeleteContext,
    ItemUpdateInProgressContext,
    OnEditIngredientRow,
    OnMoveIngredientDown,
    OnMoveIngredientUp,
    OnMoveIngredientUpper,
} from './context';
import { Row } from './Row';
import { IExtraRowProps } from './types';
import { EcoCell } from './cells/EcoCell';
import { useDeleteIngredientHit, useMoveIngredientHit, useUpdateIngredientHit } from './hooks';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IngredientCell } from './cells/IngredientCell';
import { DescriptionsCell } from './cells/DescriptionsCell';
import { AmountCell } from './cells/AmountCell';
import { OriginCell } from './cells/OriginCell';
import TableFooter from './TableFooter';
import { useSet } from 'react-use';
import TableComponent from './TableComponent';
import { LifeStyleTags } from './cells/LifeStyleTags';

/// http://localhost:3000/products/180350
interface IProps {
    disabled: boolean;
    productId: number | string;
    ingredients: InterpretedIngredient[];
    onIngClicked?: (ing: InterpretedIngredient) => void;
    originalIngredients: string;
    isExpandable?: boolean;
}

export const InterpretedIngredientList: FC<IProps> = (props: IProps) => {
    const { ingredients, isExpandable = true, onIngClicked, productId, disabled, originalIngredients } = props;

    const [jaccardCutoff] = useState(Number.MIN_SAFE_INTEGER);

    const treeData = useMemo(
        () =>
            buildTreeData({
                items: ingredients,
                jaccardCutoff,
                onClickItem: onIngClicked,
                ItemComponent: Ingredient,
                isEditable: !disabled,
            }),
        [disabled, ingredients, jaccardCutoff, onIngClicked]
    );

    const parentsMap = useMemo(() => {
        return buildParentsMapKeyed(treeData, (node) => Number(node.key));
    }, [treeData]);

    const [moveIngredientHit] = useMoveIngredientHit();
    const [dropInProgress, setDropInProgress] = useState(false);
    const onMove = useCallback(
        (hitId: number, afterId: number | null, parentId: number | null): void => {
            setDropInProgress(true);
            moveIngredientHit({
                variables: {
                    hitId,
                    target: {
                        afterId,
                        parentId,
                    },
                },
            })
                .catch((err) => {
                    message.error(String(err));
                })
                .finally(() => setDropInProgress(false));
        },
        [moveIngredientHit]
    );
    const onDropHandler = useCallback(
        (
            info: NodeDragEventParams & {
                dragNode: EventDataNode<any>;
                dragNodesKeys: Key[];
                dropPosition: number;
                dropToGap: boolean;
            }
        ): void => {
            const payload = dropToPayload(ingredients, parentsMap, treeData, info);
            onMove(payload.item.id, payload.target.afterId, payload.target.id);
        },
        [ingredients, onMove, parentsMap, treeData]
    );
    const [tableExpanded, setTableExpanded] = useState(false);
    const onExpandTable = useCallback((): void => {
        setTableExpanded(true);
    }, []);
    const onShrinkTable = useCallback((): void => {
        setTableExpanded(false);
    }, []);
    const onMoveUp = useCallback(
        (item: InterpretedIngredient) => {
            const parentsMap = buildParentsMap(ingredients);
            const parent = parentsMap.get(item);
            const listToSearch = parent ? parent.children : ingredients;
            const itemPos = listToSearch.findIndex((_) => _.id === item.id);
            const prevItemId = itemPos >= 0 ? listToSearch[itemPos - 2]?.id || null : null;

            onMove(item.id, prevItemId, parent?.id || null);
        },
        [ingredients, onMove]
    );
    const onMoveDown = useCallback(
        (item: InterpretedIngredient) => {
            const parentsMap = buildParentsMap(ingredients);
            const parent = parentsMap.get(item);
            const listToSearch = parent ? parent.children : ingredients;
            const itemPos = listToSearch.findIndex((_) => _.id === item.id);
            const nextItemId = itemPos >= 0 ? listToSearch[itemPos + 1]?.id || null : null;
            onMove(item.id, nextItemId, parent?.id || null);
        },
        [ingredients, onMove]
    );
    const onMoveUpper = useCallback(
        (item: InterpretedIngredient) => {
            const parentsMap = buildParentsMap(ingredients);
            const parent = parentsMap.get(item);
            const grandpa = parent ? parentsMap.get(parent) : null;

            onMove(item.id, parent?.id || null, grandpa?.id || null);
        },
        [ingredients, onMove]
    );
    return (
        <>
            {isExpandable && (
                <div className={styles.controls}>
                    {tableExpanded ? (
                        <Button icon={<ShrinkOutlined />} onClick={onShrinkTable} />
                    ) : (
                        <Button icon={<ExpandAltOutlined />} onClick={onExpandTable} />
                    )}
                </div>
            )}
            <Drawer
                visible={tableExpanded}
                footer={null}
                placement="top"
                onClose={onShrinkTable}
                title={'Interpreted Ingredient'}
                height={'100vh'}
                className={styles.modal}
                destroyOnClose={true}
            >
                <InterpretedIngredientsTable
                    originalIngredients={originalIngredients}
                    ingredients={ingredients}
                    productId={productId}
                    disabled={disabled}
                />
            </Drawer>
            <Spin spinning={dropInProgress}>
                <OnMoveIngredientUp.Provider value={onMoveUp}>
                    <OnMoveIngredientDown.Provider value={onMoveDown}>
                        <OnMoveIngredientUpper.Provider value={onMoveUpper}>
                            <Tree
                                defaultExpandAll
                                treeData={treeData}
                                draggable
                                blockNode
                                onDrop={onDropHandler}
                                disabled={dropInProgress || disabled}
                            />
                        </OnMoveIngredientUpper.Provider>
                    </OnMoveIngredientDown.Provider>
                </OnMoveIngredientUp.Provider>
            </Spin>
        </>
    );
};

export interface IIngredientsTableProps {
    ingredients: InterpretedIngredient[];
    productId: number | string;
    disabled: boolean;
    originalIngredients: string;
}

export const InterpretedIngredientsTable: FC<IIngredientsTableProps> = (props) => {
    const { ingredients, productId, disabled, originalIngredients } = props;

    const dataSource = useMemo(() => ingredients, [ingredients]);

    const [form] = Form.useForm();
    const [editItemId, setEditItemId] = useState<InterpretedIngredient['id'] | null>(null);

    const onEdit = useCallback(
        (itemId: InterpretedIngredient['id']): void => {
            setEditItemId(itemId);

            const parentsMap = buildParentsMap(ingredients);
            const ingredientToEdit = findNode(ingredients, (ing) => ing.id === itemId);
            const parentId = ingredientToEdit ? parentsMap.get(ingredientToEdit)?.id : undefined;
            if (ingredientToEdit) {
                // filter empty values to not set it accidentally
                const fieldsToUpdate = Object.fromEntries(
                    Object.entries({
                        amount: ingredientToEdit.amount,
                        descriptions: ingredientToEdit.descriptions || [],
                        eco: ingredientToEdit.eco,
                        foundName: ingredientToEdit.foundName,
                        ingredient: ingredientToEdit.ingredient?.id,
                        origin: ingredientToEdit.origin,
                        parentId,
                    })
                );

                form.setFieldsValue(fieldsToUpdate);
            }
        },
        [form, ingredients]
    );
    const onCancelEdit = useCallback((): void => {
        setEditItemId(null);
        form.resetFields();
    }, [form]);

    const [updateHit] = useUpdateIngredientHit();
    const [deleteHit] = useDeleteIngredientHit();

    const [itemsOnDelete, { add: addItemOnDelete, remove: removeItemOnDelete }] = useSet<number>();
    const handleDelete = useCallback(
        (hitId: number) => {
            addItemOnDelete(hitId);
            return deleteHit({
                variables: {
                    hitId,
                },
            })
                .catch((err) => message.error(String(err)))
                .finally(() => {
                    removeItemOnDelete(hitId);
                });
        },
        [addItemOnDelete, deleteHit, removeItemOnDelete]
    );

    const [itemUpdateInProgress, setItemUpdateInProgress] = useState(false);
    const handleOnSave = useCallback(() => {
        if (!editItemId) {
            console.error('Trying to update not specified item');
            return;
        }
        setItemUpdateInProgress(true);

        updateHit({
            variables: {
                hitId: editItemId,
                values: {
                    amount: form.getFieldValue('amount'),
                    descriptions: form.getFieldValue('descriptions'),
                    eco: form.getFieldValue('eco'),
                    foundName: form.getFieldValue('foundName'),
                    ingredientId: form.getFieldValue('ingredient'),
                    origin: form.getFieldValue('origin'),
                    parentId: form.getFieldValue('parentId'),
                },
            },
        })
            .then(() => {
                setEditItemId(null);
            })
            .catch((err) => {
                message.error(String(err));
            })
            .finally(() => {
                setItemUpdateInProgress(false);
            });
    }, [editItemId, form, updateHit]);

    const columns = useMemo((): ColumnsType<InterpretedIngredient> => {
        const columns: ColumnsType<InterpretedIngredient> = [
            {
                title: 'id',
                dataIndex: 'id',
            },
            {
                title: 'Found name',
                dataIndex: 'foundName',
                render: (value, record) => <FoundNameCell record={record} />,
            },
            {
                title: 'Ingredient',
                dataIndex: 'ingredient.id',
                width: 200,
                render: (value, record) => <IngredientCell record={record} />,
            },
            {
                title: 'Jaccard',
                dataIndex: 'jaccard',
                render: (value) => {
                    if (value < 1) {
                        return <span className="unsure-jaccard">{value}</span>;
                    } else {
                        return <span>{value}</span>;
                    }
                },
            },
            {
                title: 'Descriptions',
                dataIndex: 'descriptions',
                shouldCellUpdate(record, prevRecord) {
                    return record.descriptions !== prevRecord.descriptions;
                },
                render: (value, record) => <DescriptionsCell record={record} />,
            },
            {
                title: 'Amount',
                dataIndex: 'amount',
                width: 80,
                shouldCellUpdate(record, prevRecord) {
                    return record.amount !== prevRecord.amount;
                },
                render: (value, record) => <AmountCell record={record} />,
            },
            {
                title: 'Eco',
                dataIndex: 'eco',
                width: 120,
                shouldCellUpdate(record, prevRecord) {
                    return record.eco !== prevRecord.eco;
                },
                render: (value: InterpretedIngredient['eco'], record) => <EcoCell record={record} />,
            },
            {
                title: 'Lifetags',
                dataIndex: 'undefined',
                render: (value: unknown, record) => {
                    const { ingredient } = record;
                    if (!ingredient) {
                        return null;
                    } else {
                        return (
                            <LifeStyleTags
                                isLactoVegetarian={ingredient.isLactoVegetarian}
                                isOvoVegetarian={ingredient.isOvoVegetarian}
                                isPescetarian={ingredient.isPescetarian}
                                isVegan={ingredient.isVegan}
                                isVegetarian={ingredient.isVegetarian}
                            />
                        );
                    }
                },
            },
            {
                title: 'Origin',
                dataIndex: 'origin',
                width: 120,
                shouldCellUpdate(record, prevRecord) {
                    return record.origin !== prevRecord.origin;
                },
                render: (value: InterpretedIngredient['origin'], record) => <OriginCell record={record} />,
            },
        ];
        if (!disabled) {
            columns.push({
                title: 'Operations',
                render: (value, record) => (
                    <Operations
                        record={record}
                        onEdit={onEdit}
                        onCancel={onCancelEdit}
                        onSave={handleOnSave}
                        onDelete={handleDelete}
                    />
                ),
            });
        }
        return columns;
    }, [disabled, handleDelete, handleOnSave, onCancelEdit, onEdit]);

    const components = useMemo((): TableComponents<InterpretedIngredient> => {
        return {
            table: TableComponent,
            body: {
                row: Row,
            },
        };
    }, []);

    const onRow: GetComponentProps<InterpretedIngredient> = useCallback((record, index) => {
        const extraProps: IExtraRowProps = {
            index: index || 0,
            record,
        };
        return {
            ...({
                ...extraProps,
            } as any),
        };
    }, []);

    const renderFooter = useCallback(() => {
        return disabled ? null : <TableFooter productId={productId} />;
    }, [disabled, productId]);

    return (
        <DndProvider backend={HTML5Backend}>
            <EditItemIdContext.Provider value={editItemId}>
                <ItemUpdateInProgressContext.Provider value={itemUpdateInProgress}>
                    <ItemsOnDeleteContext.Provider value={itemsOnDelete}>
                        <EditRowForm.Provider value={form}>
                            <OnEditIngredientRow.Provider value={onEdit}>
                                <Space direction={'vertical'}>
                                    <div>{originalIngredients}</div>
                                    <Table
                                        expandable={{ defaultExpandAllRows: true }}
                                        className={styles.table}
                                        dataSource={dataSource}
                                        columns={columns}
                                        rowKey={'id'}
                                        components={components}
                                        onRow={onRow}
                                        footer={renderFooter}
                                        pagination={false}
                                    />
                                </Space>
                            </OnEditIngredientRow.Provider>
                        </EditRowForm.Provider>
                    </ItemsOnDeleteContext.Provider>
                </ItemUpdateInProgressContext.Provider>
            </EditItemIdContext.Provider>
        </DndProvider>
    );
};
