import { IngredientCategory } from '../../../../model/ingredients';
import React, { FC, useEffect, useState } from 'react';
import {
    useDeleteIngredientsGroup,
    useGetIngredientsCategory,
    useUpdateIngredientsGroup,
} from '../../../../api/ingredients';
import { Alert, Button, Card, Col, Drawer, Form, Input, message, Modal, Row, Select, Spin, Typography } from 'antd';
import { debounce } from 'lodash';
import { DeleteFilled } from '@ant-design/icons';
import SubGroupsTree from './SubGroupsTree';
import { useIngredientGroup } from './hooks';
import SubIngredients from './SubIngredients';

export interface UpdateIngredientGroupDrawerProps {
    groupId: string;
    onClose: (refetch: boolean) => void;
}
const UpdateIngredientGroupDrawer: FC<UpdateIngredientGroupDrawerProps> = ({ groupId, onClose }) => {
    const { data: result, loading, error } = useIngredientGroup(groupId);
    const group = result?.group;
    const [update] = useUpdateIngredientsGroup();
    const [_delete] = useDeleteIngredientsGroup();
    const [groupSearch, setGroupSearch] = useState<string>();
    const { data: groupResult, loading: groupsLoading } = useGetIngredientsCategory({
        variables: {
            q: (groupSearch && groupSearch.length > 0 ? groupSearch : undefined)!, // FIXME: check variables!
            offset: 0,
            limit: 30,
        },
        skip: groupSearch === undefined,
    });

    useEffect(() => {
        if (!group) {
            setGroupSearch(undefined);
        } else if (group?.parentGroup?.name && groupSearch === undefined) {
            setGroupSearch(group?.parentGroup?.name);
        }
    }, [group, groupSearch]);

    const onSave = (form: IngredientCategory) => {
        update({
            variables: {
                id: groupId,
                values: { ...form, parentGroup: undefined, parentId: form.parentGroup?.id } as any,
            },
        })
            .then((res) => {
                message.success('Updated ingredient ' + res.data?.category.name);
            })
            .catch((error) => {
                message.error(error.message);
            });
    };

    return (
        <Drawer
            width="40vw"
            title={<>Editing {group ? group.name : <Spin />}</>}
            placement="right"
            onClose={() => onClose(false)}
            visible={true}
        >
            <Card loading={loading}>
                {error && <Alert message={error.message} type={'error'} />}
                {group && <SubGroupsTree ingredientGroup={group} />}
                {group && (
                    <Form
                        initialValues={group}
                        onFinish={onSave}
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                        layout="vertical"
                    >
                        <Row gutter={[20, 0]}>
                            <Col span={12}>
                                <Form.Item label="Name" name="name">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label="Parent category" name={['parentGroup', 'id']}>
                                    <Select
                                        style={{ width: '100%' }}
                                        loading={groupsLoading}
                                        onSearch={debounce((e) => {
                                            setGroupSearch(e);
                                        }, 500)}
                                        value={groupSearch}
                                        placeholder="Enter a ingredient category"
                                        allowClear={true}
                                        filterOption={false}
                                        showSearch
                                    >
                                        {groupResult?.category.data.map((v) => {
                                            return <Select.Option value={v.id}>{v.name}</Select.Option>;
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[10, 0]} justify="end">
                            <Col>
                                <Button
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure?',
                                            content: (
                                                <Typography.Text>
                                                    Are you sure you want to remove {group.name}?
                                                </Typography.Text>
                                            ),
                                            onOk: () => {
                                                _delete({ variables: { id: group.id } }).then(() => {
                                                    onClose(true);
                                                });
                                            },
                                        });
                                    }}
                                    icon={<DeleteFilled />}
                                    shape="circle"
                                />
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Card>
            <SubIngredients groupId={groupId} />
        </Drawer>
    );
};

export default UpdateIngredientGroupDrawer;
