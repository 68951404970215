import React, { FC, useMemo } from 'react';
import { useIngredientRelationshipsMap } from '../UpdateIngredient/hooks';
import { Alert, Card, Tree } from 'antd';
import { DataNode } from 'rc-tree/lib/interface';
import { ProductIngredient } from '../../../model/ingredients';
import { Link, useRouteMatch } from 'react-router-dom';
import { ROUTER_PAGES } from '../../../routes';
import { useLocation } from 'react-router';
import { setQueryParams } from '../../../utils/url-search-params';

export interface SubIngredientsTreeProps {
    ingredient: ProductIngredient;
}

const SubIngredientsTree: FC<SubIngredientsTreeProps> = (props) => {
    const { ingredient } = props;
    const [{ loading, error }, ingredientsMap] = useIngredientRelationshipsMap();
    const match = useRouteMatch(ROUTER_PAGES.ingredientsEdit.path);
    const location = useLocation();
    const treeData: DataNode[] = useMemo(() => {
        const ingredientNode = ingredientsMap.get(ingredient.id.toString());
        if (ingredientNode) {
            return ingredientNode.children.map((child): DataNode => {
                let to: string;
                if (match) {
                    // match, preserve page and other params
                    to = setQueryParams(location, {
                        selected: child.id.toString(),
                    });
                } else {
                    // not match, make just a link (to the first page)
                    to = `${ROUTER_PAGES.ingredientsEdit.path}?selected=${child.id}`;
                }
                return {
                    key: child.id,
                    title: <Link to={to}>{child.name}</Link>,
                };
            });
        } else {
            return [];
        }
    }, [ingredient.id, ingredientsMap, location, match]);
    return (
        <Card loading={loading}>
            {error && <Alert message={'Can not load relationships'} description={error.message} type={'error'} />}
            {!error && treeData.length === 0 && <Alert message={'There is no sub-ingredients'} type={'info'} />}
            {!error && treeData.length > 0 && (
                <Tree treeData={treeData} selectable={false} showLine={{ showLeafIcon: false }} />
            )}
        </Card>
    );
};

export default SubIngredientsTree;
