import React, { useCallback, useState } from 'react';
import { Button, Col, Divider, Form, Input, Row, Space, Tabs } from 'antd';
import SearchFilter from './SearchFilter';
import { OrStatement } from 'src/model/productQuery';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';

interface AdvancedSearchProps {
    query?: OrStatement;
    onQueryChange: (query: OrStatement) => void;
}

const defaultQuery: OrStatement = {
    or: [
        {
            and: [],
        },
    ],
};
export const AdvancedSearch = (props: AdvancedSearchProps) => {
    const { query = defaultQuery, onQueryChange } = props;

    return (
        <>
            <SearchFilter query={query} onChange={onQueryChange} />
        </>
    );
};

interface ISearchBar {
    onSubmit?: (query: OrStatement) => void;
    defaultValues?: OrStatement;
    singleRow?: boolean;
    loading?: boolean;

    extraActions?: JSX.Element | JSX.Element[];
}

export const SearchBar = (props: ISearchBar) => {
    const { onSubmit, defaultValues, loading, extraActions } = props;
    const [query, setQuery] = useState<OrStatement | undefined>(defaultValues);

    const searchMenu = (
        <Space>
            {extraActions}
            <Button
                type="default"
                onClick={() => {
                    setQuery({});
                }}
                icon={<ClearOutlined />}
                block={true}
                loading={loading}
            >
                Clear
            </Button>
            <Button
                icon={<SearchOutlined />}
                type="primary"
                onClick={() => {
                    if (query) onSubmit?.(query);
                }}
                htmlType="submit"
                block={true}
                loading={loading}
            >
                Search
            </Button>
        </Space>
    );

    const handleInputChange = useCallback((e) => {
        setQuery({
            or: [
                {
                    and: [
                        {
                            name: {
                                includes: e.target.value,
                            },
                        },
                    ],
                },
                {
                    and: [
                        {
                            gtin: {
                                endsWith: e.target.value,
                            },
                        },
                    ],
                },
            ],
        });
    }, []);

    return (
        <Form layout={'vertical'} initialValues={{ limit: 10, offset: 10 }}>
            <Tabs tabBarExtraContent={searchMenu}>
                <Tabs.TabPane tab="Default search" key="default">
                    <Row gutter={[10, 10]}>
                        <Col span={16}>
                            <Input
                                onChange={handleInputChange}
                                placeholder="Search by GTIN or part of the product name"
                                allowClear={true}
                            />
                        </Col>
                    </Row>
                </Tabs.TabPane>

                <Tabs.TabPane tab="Advanced search" key="advanced">
                    <Row gutter={[10, 10]}>
                        <Col span={24}>
                            <AdvancedSearch key="1" query={query} onQueryChange={setQuery} />
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>

            <Divider />
        </Form>
    );
};
