import { gql, useLazyQuery } from '@apollo/client';
import { Auth } from 'aws-amplify';
import { createContext, ReactNode, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTER_PAGES } from 'src/routes';

interface GroupPermission {
    read: boolean;
    write: boolean;
}

export interface SiteUser {
    userName: string;
    userGroup: {
        admin: boolean;
        name: string;
        permissions: {
            ingredientService: GroupPermission;
            productService: GroupPermission;
            volumeEstimateService: GroupPermission;
            imageFetcherService: GroupPermission;
            usersService: GroupPermission;
            searchService: GroupPermission;
            sustainabilityService: GroupPermission;
            translationService: GroupPermission;
            carbonService: GroupPermission;
        };
    };
}

const Query = gql`
    fragment groupPermission on GroupPermission {
        read
        write
    }

    query get_user {
        currentSiteUser {
            userName
            userGroup {
                admin
                name
                permissions {
                    ingredientService {
                        ...groupPermission
                    }
                    productService {
                        ...groupPermission
                    }
                    volumeEstimateService {
                        ...groupPermission
                    }
                    imageFetcherService {
                        ...groupPermission
                    }
                    usersService {
                        ...groupPermission
                    }
                    searchService {
                        ...groupPermission
                    }
                    sustainabilityService {
                        ...groupPermission
                    }
                    translationService {
                        ...groupPermission
                    }
                    carbonService {
                        ...groupPermission
                    }
                }
            }
        }
    }
`;

export const AuthContext = createContext<SiteUser | undefined>(undefined);

interface AuthProviderProps {
    children: ReactNode;
}
export function AuthProvider(props: AuthProviderProps) {
    const [reqSiteUser, { data }] = useLazyQuery<{ currentSiteUser: SiteUser }>(Query);
    const [userInfo, setUserInfo] = useState<any>(null);
    const location = useLocation();
    const history = useHistory();

    const currentSiteUser = data?.currentSiteUser;

    useEffect(() => {
        if (location.pathname.startsWith('/public/')) return;
        if (!userInfo && location.pathname !== ROUTER_PAGES.signIn.path) {
            Auth.currentUserInfo().then((info) => {
                setUserInfo(info);
            });
        }
    }, [location, history, userInfo]);

    useEffect(() => {
        if (userInfo && !currentSiteUser) {
            reqSiteUser();
        }
    }, [currentSiteUser, reqSiteUser, userInfo]);

    return <AuthContext.Provider value={currentSiteUser}>{props.children}</AuthContext.Provider>;
}
