import React, { FC } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Alert, Button, Space, Typography } from 'antd';
import { ReviewsList } from '../../components/ReviewsList';
import { DownloadOutlined } from '@ant-design/icons';
import { useUserSettingsCSV } from 'src/api/users';
import { downloadData } from '../../utils/downloadFile';

export interface ReviewsProps {}

export const ReviewsPage: FC<ReviewsProps> = (props) => {
    const { data, loading, error } = useUserSettingsCSV();
    const downloadCSV = () => {
        const csvData = data?.userSettingsCSV.CSV;
        if (csvData !== undefined) {
            downloadData('userSettings.csv', new Blob([csvData]));
        }
    };
    return (
        <div>
            <Breadcrumb />
            <Typography.Title>Reviews</Typography.Title>
            <Space style={{ float: 'right', marginBottom: 16 }}>
                <Button loading={loading} onClick={downloadCSV} icon={<DownloadOutlined></DownloadOutlined>}>
                    Download user settings
                </Button>
                {error && <Alert message={error.message} type={'error'} />}
            </Space>
            <ReviewsList />
        </div>
    );
};
