import { FC, useCallback, useMemo } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { Button, Drawer, message, Modal, Space, Table, Typography } from 'antd';
import { useQueryParam, StringParam } from 'use-query-params';
import { ColumnsType } from 'antd/lib/table/interface';
import { useCreateTag, useDeleteTag, useTags, useUpdateTag } from './hooks/useTags';
import { TagForm } from './components/TagForm';
import { TagProducts } from './components/TagProducts';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { v4 } from 'uuid';

const TableWithData: FC<{
    data: any[];
    setSelected: (id: string) => void;
    setViewing: (value: string) => void;
    refetch: () => void;
}> = ({ data, setSelected, setViewing, refetch }) => {
    const [duplicate] = useCreateTag();
    const columns: ColumnsType<any> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'User Group',
            dataIndex: ['userGroup', 'name'],
            key: 'userGroup.name',
        },
        {
            dataIndex: 'id',
            key: 'id',
            render(value) {
                return (
                    <>
                        <Button
                            style={{ float: 'right' }}
                            onClick={(e) => {
                                setViewing(value);
                                e.stopPropagation();
                            }}
                        >
                            View products
                        </Button>
                        <Button
                            style={{ float: 'right', marginRight: '10px' }}
                            onClick={(e) => {
                                Modal.confirm({
                                    title: 'Are you sure you want to remove the tag?',
                                    icon: <ExclamationCircleOutlined />,
                                    okText: 'Continue',
                                    cancelText: 'Cancel',
                                    onOk: () => {
                                        _delete({ variables: { id: value } }).then(() => {
                                            refetch();
                                        });
                                    },
                                });

                                e.stopPropagation();
                            }}
                        >
                            Delete
                        </Button>
                        <Button
                            style={{ float: 'right', marginRight: '10px' }}
                            onClick={(e) => {
                                e.stopPropagation();
                                duplicate({
                                    variables: {
                                        values: {
                                            name: `NEW_TAG-${v4()}`,
                                            query: data.find((tag) => tag.id === value).query,
                                        },
                                    },
                                }).then(() => {
                                    message.success('Successfully duplicated tag');
                                    return refetch();
                                });
                            }}
                        >
                            Duplicate tag
                        </Button>
                    </>
                );
            },
        },
    ];
    const [_delete] = useDeleteTag();

    const onRow = useCallback(
        (data) => {
            return {
                onClick: () => {
                    setSelected(data.id);
                },
            };
        },
        [setSelected]
    );

    return <Table size={'middle'} dataSource={data} columns={columns} rowKey={'id'} onRow={onRow} />;
};

const TagListPage = () => {
    const [selected, setSelected] = useQueryParam('id', StringParam);
    const [viewing, setViewing] = useQueryParam('view', StringParam);
    const { data: tagsResult, refetch } = useTags({});
    const [update, { loading: updateInProgress }] = useUpdateTag();
    const [create] = useCreateTag();

    const selectedTag = tagsResult?.tags.find((tag) => tag.id === selected);
    const viewingTag = tagsResult?.tags.find((tag) => tag.id === viewing);

    const existingTags = useMemo(() => tagsResult?.tags.map((tag) => tag.name) || [], [tagsResult?.tags]);
    return (
        <div>
            <Breadcrumb />
            <Typography.Title level={1}>Tags</Typography.Title>
            <Space style={{ marginBottom: 16 }}>
                <Button
                    onClick={() => {
                        create({ variables: { values: { name: `NEW_TAG-${v4()}`, query: '{}' } } }).then(() => {
                            message.success('Successfully created new');
                            return refetch();
                        });
                    }}
                >
                    Create new
                </Button>
            </Space>
            {tagsResult && (
                <TableWithData
                    data={tagsResult.tags}
                    setViewing={setViewing}
                    setSelected={setSelected}
                    refetch={refetch}
                />
            )}
            {selectedTag && (
                <Drawer
                    title={<Typography.Text>Editing {selectedTag.name}</Typography.Text>}
                    placement={'right'}
                    closable={true}
                    visible={true}
                    width={'100vw'}
                    onClose={() => setSelected(undefined)}
                >
                    <TagForm
                        existingNames={existingTags}
                        key={selectedTag.id}
                        onSave={(tag) => {
                            update({
                                variables: {
                                    id: tag.id,
                                    values: {
                                        name: tag.name,
                                        query: tag.query,
                                        isPublic: tag.isPublic,
                                        description: tag.description,
                                        publicName: tag.publicName,
                                    },
                                },
                            }).then(() => {
                                message.success('Updated tag!');
                            });
                        }}
                        loading={updateInProgress}
                        tag={selectedTag}
                    />
                </Drawer>
            )}
            {viewingTag && (
                <Drawer
                    title={<Typography.Text>Viewing products for {viewingTag.name} </Typography.Text>}
                    placement={'right'}
                    closable={true}
                    visible={true}
                    width={'100vw'}
                    onClose={() => setViewing(undefined)}
                >
                    <TagProducts defaultValues={viewingTag} />
                </Drawer>
            )}
        </div>
    );
};

export default TagListPage;
