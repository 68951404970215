import React from 'react';
import { useHistory, useLocation } from 'react-router';
import Authenticator from 'aws-amplify-react/lib/Auth/Authenticator';

const SignInPage = () => {
    const history = useHistory();
    const location = useLocation();

    const signInChange: any = async function (state: string, cognitoState: any) {
        if (state === 'signedIn') {
            history.replace(new URLSearchParams(location.search).get('from') || '/');
        }
    };

    return <Authenticator onStateChange={signInChange} />;
};

export default SignInPage;
