import { gql, useMutation, useQuery } from '@apollo/client';
import { PermissionsType, UserGroup } from './types';

export const PERMISSIONS_FRAGMENT = gql`
    fragment Permissions on GroupPermissions {
        imageFetcherService {
            read
            write
        }
        ingredientService {
            read
            write
        }
        productService {
            read
            write
        }
        searchService {
            read
            write
        }
        sustainabilityService {
            read
            write
        }
        translationService {
            read
            write
        }
        usersService {
            read
            write
        }
        volumeEstimateService {
            read
            write
        }
        carbonService {
            read
            write
        }
    }
`;

export const USER_GROUP_ROW_FRAGMENT = gql`
    fragment UserGroupRow on UserGroup {
        name
        admin
        permissions {
            ...Permissions
        }
    }

    ${PERMISSIONS_FRAGMENT}
`;

const UPDATE_PERMISSIONS = gql`
    mutation GroupPermissions_Mutation($id: ID!, $values: UserGroupInput!) {
        updateUserGroup(values: $values, id: $id) {
            id
            permissions {
                ...Permissions
            }
        }
    }
    ${PERMISSIONS_FRAGMENT}
`;

export function useUpdateGroup() {
    return useMutation<
        unknown,
        {
            id: string;
            values: {
                name: string;
                admin: boolean;
                permissions: PermissionsType;
            };
        }
    >(UPDATE_PERMISSIONS);
}

const UserGroupsQuery = gql`
    query ACLPageQuery {
        userGroups(limit: 10000000, offset: 0) {
            id
            ...UserGroupRow
        }
    }

    ${USER_GROUP_ROW_FRAGMENT}
`;
export function useUserGroups() {
    return useQuery<{ userGroups: UserGroup[] }>(UserGroupsQuery);
}
