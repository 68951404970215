import React, { FC } from 'react';
import { YesNoMaybe } from '../../../../../model/ingredients';
import { Space, Tooltip, Typography } from 'antd';

export interface ILifeStyleTagsProps {
    isLactoVegetarian: YesNoMaybe;
    isOvoVegetarian: YesNoMaybe;
    isPescetarian: YesNoMaybe;
    isVegan: YesNoMaybe;
    isVegetarian: YesNoMaybe;
    bordered?: boolean;
}

export const LifeStyleTags: FC<ILifeStyleTagsProps> = (props) => {
    const { isLactoVegetarian, isOvoVegetarian, isPescetarian, isVegan, isVegetarian, bordered = false } = props;
    return (
        <Space direction={'horizontal'}>
            {isVegan && <YesNoMaybeLetter bordered={bordered} code={'V'} yesNoMaybe={isVegan} title={'Vegan'} />}
            {isLactoVegetarian && (
                <YesNoMaybeLetter
                    bordered={bordered}
                    code={'L'}
                    yesNoMaybe={isLactoVegetarian}
                    title={'Lacto vegetarian'}
                />
            )}
            {isOvoVegetarian && (
                <YesNoMaybeLetter
                    bordered={bordered}
                    code={'O'}
                    yesNoMaybe={isOvoVegetarian}
                    title={'Ovo vegetarian'}
                />
            )}
            {isVegetarian && (
                <YesNoMaybeLetter bordered={bordered} code={'Vt'} yesNoMaybe={isVegetarian} title={'Vegetarian'} />
            )}
            {isPescetarian && (
                <YesNoMaybeLetter bordered={bordered} code={'P'} yesNoMaybe={isPescetarian} title={'Pescetarian'} />
            )}
        </Space>
    );
};

export interface YesNoMaybeLetterProps {
    code: string;
    yesNoMaybe: YesNoMaybe;
    bordered: boolean;
    title: string;
}

export const YesNoMaybeLetter: FC<YesNoMaybeLetterProps> = (props) => {
    const { code, yesNoMaybe, title, bordered } = props;
    return (
        <Tooltip title={title}>
            <Typography.Text
                code={bordered}
                type={yesNoMaybe === YesNoMaybe.yes ? 'success' : yesNoMaybe === YesNoMaybe.no ? 'danger' : 'warning'}
            >
                {code}
            </Typography.Text>
        </Tooltip>
    );
};
