import React, { FC, useCallback, useState } from 'react';
import { Button, Col, Row, Typography, Spin, Modal, Input, message } from 'antd';
import { ProductViewer } from '../../components/ProductViewer/ProductViewer';
import { Route, useHistory, useParams } from 'react-router-dom';
import { useProduct, useProductByGtin, useDeleteProduct } from '../../api/product';
import { Product } from '../../model/product';
import { useStartReindexJob } from '../../api/job';
import { FileSyncOutlined, DeleteOutlined, TagsOutlined, FileSearchOutlined, BugFilled } from '@ant-design/icons';
import { buildPath } from '../../utils/build-path';
import { ROUTER_PAGES } from '../../routes';
import { TableOutlined } from '@ant-design/icons/lib';
import EditProductPage from './EditProductPage';
import { useInterpretProductIngredients } from '../../api/ingredients';
import { useChangeApproveStatus } from 'src/components/ProductViewer/ApproveBadge/ApproveForm/hooks';
import { useDocumentKeyboardEvent } from 'src/utils/useKeyboard';
import { useDocumentMouseEvent } from 'src/utils/useMouse';

export const parse = (product: Product): Product => {
    if (!product) {
        return null as any; // FIXME: come on! it accepts not nullable value and returns not nullable. Why the code is so perfect?
    }
    const allergens = product.allergenInformation
        ? JSON.parse(JSON.stringify(product.allergenInformation.allergens))
        : [];
    const preDefinedOrder = ['CONTAINS', 'MAY_CONTAIN', 'UNDECLARED', 'FREE_FROM'];

    /* FIXME: in place sort?! */
    allergens.sort((a: any, b: any /* FIXME: any */) => {
        if (preDefinedOrder.indexOf(a.containmentCode.code) - preDefinedOrder.indexOf(b.containmentCode.code) > 0) {
            return 1;
        } else {
            return -1;
        }
    });

    return {
        ...product,
        allergenInformation: {
            allergens: allergens,
            statement: product.allergenInformation?.statement,
        },
    };
};

const ProductPage: FC = () => {
    const params = useParams<{ id: string }>();
    const history = useHistory();

    const { data, error, loading } = useProduct({ variables: { id: Number(params.id) } });

    const { data: products } = useProductByGtin({
        variables: {
            gtins: (data?.product.productIdentifier.externalId
                ? [data?.product.productIdentifier.externalId]
                : null)! /* FIXME: undefined */,
        },
        skip: !data?.product.productIdentifier.externalId,
    });
    const [changeApprovalStatus] = useChangeApproveStatus();
    const [startReIndexJob, { loading: reIndexing }] = useStartReindexJob();
    const [deleteProduct, { loading: deleting }] = useDeleteProduct();

    const [interpretIngredients, { loading: interpreting }] = useInterpretProductIngredients();
    const [canDelete, setCanDelete] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);

    const product = parse(data?.product! /* FIXME: undefined */);

    useDocumentMouseEvent((ev) => {
        // middle-mousebutton to quickly approve a product. && !ev.shiftKey to not trigger both shortcuts.
        if (ev.button === 1 && !ev.shiftKey) {
            changeApprovalStatus({
                variables: {
                    productId: Number.parseInt(params.id),
                    approval: {
                        message: '',
                        ok: true,
                    },
                },
            }).then(() => {
                message.success('Successfully approved product');
            });
        }
        //This mostly exists to stay consistent, not expected to be used much. Doing mouse-only with 2 buttons is too much
        if (ev.shiftKey && ev.button === 1) {
            changeApprovalStatus({
                variables: {
                    productId: Number.parseInt(params.id),
                    approval: {
                        message: '',
                        ok: false,
                    },
                },
            }).then(() => {
                message.success('Successfully disapproved product');
            });
        }
    });

    useDocumentKeyboardEvent((ev) => {
        // ctrl + < to quickly approve a product. && !ev.shiftKey to not trigger both shortcuts.
        if (ev.ctrlKey && ev.key === '<' && !ev.shiftKey) {
            changeApprovalStatus({
                variables: {
                    productId: Number.parseInt(params.id),
                    approval: {
                        message: '',
                        ok: true,
                    },
                },
            }).then(() => {
                message.success('Successfully approved product');
            });
        }
        // ctrl + shift + < to quickly disapprove a product.
        if (ev.ctrlKey && ev.shiftKey && ev.key === '<') {
            changeApprovalStatus({
                variables: {
                    productId: Number.parseInt(params.id),
                    approval: {
                        message: '',
                        ok: false,
                    },
                },
            }).then(() => {
                message.success('Successfully disapproved product');
            });
        }
    });

    const onReindexProduct = useCallback(() => {
        const product = data?.product;
        if (!product) {
            console.error('You can not re-index if the product data is not loaded yet');
            return;
        }
        const productsToReindex: Array<{ gln: string; gtin: string; targetMarket?: string }> = [
            {
                gtin: product.productIdentifier.externalId,
                gln: product.informationProvider.gln,
            },
        ];
        startReIndexJob({
            variables: {
                products: productsToReindex,
            },
        }).then(() => {
            return message.warn(
                <>
                    <Typography>
                        <b>Fetching product from validoo</b>
                        <br />
                        This process can take up to a few minutes before it is propagated threw the entire system. The
                        changes should be visible soon!
                    </Typography>
                </>
            );
        });
    }, [data?.product, startReIndexJob]);

    const onCompareProducts = useCallback(() => {
        history.push({
            pathname: buildPath(ROUTER_PAGES.productsCompare.path, {
                gtin: data!.product.productIdentifier.externalId /* FIXME: undefined */,
            }),
        });
    }, [data, history]);

    const onDelete = useCallback(() => {
        deleteProduct({ variables: { id: params.id } })
            .then(() => {
                setDeleteModalVisible(false);
            })
            .catch((e) => {
                if (
                    e.message.includes('foreign key constraint fails ') &&
                    e.message.includes('products_identifiers_group')
                ) {
                    message.error('You can not remove product which exists in group');
                    setDeleteModalVisible(false);
                } else {
                    message.error(e.message);
                }
            });
    }, [deleteProduct, params.id]);

    const onInterpretIngredients = useCallback(() => {
        interpretIngredients({
            variables: {
                id: params.id,
                ingredientStatement: data?.product.interpretationIngredientStatement || undefined,
            },
        })
            .then(() => {
                return message.success('Successfully interpreted ingredients');
            })
            .catch((error) => {
                return message.error(error.message);
            });
    }, [data?.product.interpretationIngredientStatement, interpretIngredients, params.id]);
    const handleCloseModal = useCallback(() => {
        setDeleteModalVisible(false);
    }, []);
    if (error) {
        return (
            <div>
                <Typography.Title level={1}>Unexpected error occurred</Typography.Title>
                <pre>
                    {error.graphQLErrors.map(({ message }, i) => (
                        <span key={i}>{message}</span>
                    ))}
                </pre>
            </div>
        );
    }

    return (
        <div>
            <Modal
                confirmLoading={deleting}
                onCancel={handleCloseModal}
                visible={deleteModalVisible}
                onOk={onDelete}
                okText={
                    <>
                        Delete <DeleteOutlined />
                    </>
                }
                okButtonProps={{
                    disabled: !canDelete,
                }}
            >
                <Typography.Title level={4} type="danger">
                    Are you sure you want to delete product?
                </Typography.Title>
                <Input
                    onChange={(value) => {
                        setCanDelete(value.target.value === 'delete');
                    }}
                />
                <Typography.Text>Type "delete" in field to remove product</Typography.Text>
            </Modal>

            <Row gutter={[20, 20]} justify="end">
                <Col>
                    <Button
                        type="ghost"
                        disabled={interpreting || !data || data.product.interpretedIngredients?.approval?.ok === true}
                        onClick={onInterpretIngredients}
                    >
                        Interpret <TagsOutlined spin={interpreting} />
                    </Button>
                </Col>
                <Col>
                    <Button type="ghost" disabled={reIndexing} onClick={onReindexProduct}>
                        Reindex <FileSyncOutlined spin={reIndexing} />
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="ghost"
                        href={`https://productsearch.gs1.se/details/${data?.product.productIdentifier.externalId}-${data?.product.informationProvider.gln}`}
                        target="_blank"
                    >
                        GS1 Product Search <FileSearchOutlined />
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="ghost"
                        href={
                            'https://services.validoo.se/products/recipient?query=' +
                            btoa(
                                JSON.stringify({
                                    gtins: [data?.product.productIdentifier.externalId],
                                    targetMarketCountryCodes: ['752'],
                                    limit: 25,
                                })
                            )
                        }
                        target="_blank"
                    >
                        Validoo Search <FileSearchOutlined />
                    </Button>
                </Col>
                <Col>
                    <Button
                        type="ghost"
                        href={'https://hub.foodfacts.se/co2/byProductId/' + data?.product.id}
                        target="_blank"
                    >
                        Hub CO2 debugger <BugFilled />
                    </Button>
                </Col>

                {/* <Col>
                    <Button type="ghost" onClick={() => setDeleteModalVisible(true)}>
                        Delete <DeleteOutlined />
                    </Button>
                </Col> */}
                <Col>
                    <Button
                        type="primary"
                        hidden={!products?.productsByGTINs || products.productsByGTINs.length === 1}
                        onClick={onCompareProducts}
                        icon={<TableOutlined />}
                    >
                        Compare
                    </Button>
                </Col>
            </Row>
            {loading && <Spin />}
            {params.id && product && <ProductViewer data={product} />}
            <Route
                key={ROUTER_PAGES.editProduct.path}
                path={ROUTER_PAGES.editProduct.path}
                component={EditProductPage}
            />
        </div>
    );
};

export default ProductPage;
