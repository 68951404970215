import { ID } from '../utils/type';
import { IngredientInformation } from './ingredientInfromation';

export enum YesNoMaybe {
    'yes' = 'yes',
    'no' = 'no',
    'maybe' = 'maybe',
}

export interface ProductAllergen {
    allergen: string;
    reason: string;
    allergenLevel: string;
}

export interface ProductAllergenSearch {
    name: string;
}
export interface ProductIngredientNutrient {
    id: ID;
    nutrientId: ID;
    nutrientAmount: number;
    nutrientType: string;
    nutrientUnit: string;
    nutrientSource: null | {
        id: null | ID;
        name: null | string;
    };
}
export interface ProductIngredient {
    isVegan: YesNoMaybe;
    isVegetarian: YesNoMaybe;
    isLactoVegetarian: YesNoMaybe;
    isOvoVegetarian: YesNoMaybe;
    isPescetarian: YesNoMaybe;
    isAddedSugar: boolean;
    scientificName: string;
    name: string;
    eNumber: string;
    synonyms?: string[];
    parentIngredient?: ProductIngredient;
    ingredientCategory?: IngredientCategory;
    id: ID;
    nutrients: Array<ProductIngredientNutrient | null>;

    ingredientInformation: IngredientInformation[];
}
export interface InterpretedIngredient {
    id: number;
    foundName: string;
    jaccard: number;
    groceryNumber?: string;
    descriptions?: (string | null)[] | null;
    amount?: string | null;
    eco?: string | null;
    origin?: string | null;
    productId?: string;
    product?: ProductIngredient;
    ingredient?: ProductIngredient;
    children: InterpretedIngredient[];
}

export interface IngredientStatistics {
    globalCount: number;
    globalAverage: number;
    globalParsedProductsCount: number;
    missingIngredients: InterpretedIngredient[];
}

export interface IngredientDescriptor {
    id: string;
    name: string;
    isJoinable: boolean;
    isPreparationMethod: boolean;
    isColor: boolean;
    isOrigin: boolean;
    isShape: boolean;
    isPiecePart: boolean;
}

export interface IngredientCategory {
    id: ID;
    name: string;
    parentGroup?: IngredientCategory | null;
}
