export enum SubscriptionType {
    PULL = 'PULL',
    PUSH = 'PUSH',
    BOTH = 'BOTH',
}

export interface Subscription {
    id?: string; //because of ID in graphql
    body: ValidooSearchModel;
    type: SubscriptionType;
    createdAt?: Date;
    modifiedAt?: Date;
}

export interface ValidooSearchModel {
    gtins?: string[];
    informationProviderGln?: string[];
    gpcCategoryCode?: string[];
    targetMarketCountryCode?: string;
    lastChangeDateTimeFrom?: string;
    limit?: number;
    skip?: number;
    dataType?: string;
}
