import React, { FC, useCallback } from 'react';
import { Button, message } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
import { useInterpretProductIngredients } from '../../../api/ingredients';

interface InterpretButtonProps {
    productId: number;
    interpretationIngredientStatement: string | null | undefined;
    disabled: boolean;
}

export const InterpretButton: FC<InterpretButtonProps> = (props) => {
    const { productId, interpretationIngredientStatement, disabled } = props;
    const [interpretIngredients, { loading: interpreting }] = useInterpretProductIngredients();
    const onInterpretIngredients: React.MouseEventHandler<HTMLElement> = useCallback(
        (event) => {
            event.stopPropagation();
            interpretIngredients({
                variables: {
                    id: productId,
                    ingredientStatement: interpretationIngredientStatement || undefined,
                },
            })
                .then(() => {
                    return message.success('Successfully interpreted ingredients');
                })
                .catch((error: unknown) => {
                    return message.error(String(error));
                });
        },
        [interpretIngredients, interpretationIngredientStatement, productId]
    );
    return (
        <Button type="ghost" loading={interpreting} disabled={disabled} onClick={onInterpretIngredients}>
            {interpretationIngredientStatement ? 'Re-interpret' : 'Interpret'} <TagsOutlined spin={interpreting} />
        </Button>
    );
};
