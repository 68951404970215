import React, { useState } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Popconfirm,
    Radio,
    Row,
    Table,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useSubscriptions, useDeleteSubscription, useRerunSubscription } from '../../api/subscription';
import moment from 'moment';
import { DeleteOutlined, RedoOutlined } from '@ant-design/icons/lib';
import { useHistory } from 'react-router-dom';
import { buildPath } from '../../utils/build-path';
import { Subscription } from '../../model/subscription';
import { ROUTER_PAGES } from '../../routes';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { ColumnsType } from 'antd/lib/table/interface';

const SubscriptionListPage = () => {
    const { data: subscriptions, error } = useSubscriptions();
    const history = useHistory();
    const [deleteSubscription] = useDeleteSubscription();
    const [reRunSubscription] = useRerunSubscription();
    const [showReRunModal, setShowReRunModal] = useState<boolean>(false);

    const [form] = Form.useForm();

    if (error) {
        message.error(error.graphQLErrors.map(({ message }, i) => <span key={i}>{message}</span>));
    }

    const confirmDelete = (subscription: Subscription) => {
        deleteSubscription({
            variables: {
                id: subscription.id! /* FIXME: undefined */,
            },
        })
            .then(() => message.success('Subscription was deleted successfully'))
            .catch((err) => message.error(err.message));
    };

    const columns: ColumnsType<Subscription> = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: 'Based on',
            key: 'body',
            render: (body, subscription) => {
                const tags: JSX.Element[] = [];

                let index = 0;
                for (const key in subscription.body) {
                    if (
                        key !== '__typename' &&
                        (subscription.body as any)[key] /* FIXME: type */ &&
                        (subscription.body as any)[key].length > 0 /* FIXME: type */
                    ) {
                        tags.push(
                            <Tooltip
                                title={(subscription.body as any)[key].length + ' st'} /* FIXME: type */
                                placement={'right'}
                                key={'tooltip_' + index}
                            >
                                <Tag key={index}>{key.toUpperCase()}</Tag>
                            </Tooltip>
                        );
                    }
                    index++;
                }
                return tags;
            },
        },
        {
            title: 'Last changed',
            key: 'lastChangedAt',
            render: (createdAt, subscription) => (
                <span style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    {(subscription.modifiedAt
                        ? moment(subscription.modifiedAt)
                        : moment(subscription.createdAt)
                    ).format('YYYY-MM-DD HH:mm')}
                </span>
            ),
        },
        {
            title: '',
            key: 'actions',
            render: (actions, subscription) => {
                return (
                    <Row gutter={[20, 0]} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Col>
                            <Button
                                icon={<RedoOutlined />}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    form.setFieldsValue({ id: subscription.id });
                                    setShowReRunModal(true);
                                }}
                            />
                        </Col>
                        <Col>
                            <Popconfirm
                                placement={'left'}
                                title="Are you sure to delete this subscription?"
                                onConfirm={(event) => {
                                    event?.stopPropagation();
                                    confirmDelete(subscription);
                                }}
                                onCancel={(event) => {
                                    event?.stopPropagation();
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    shape={'circle'}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                ></Button>
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            },
        },
    ];
    return (
        <div>
            <Breadcrumb />
            <Typography.Title level={1}>Subscriptions</Typography.Title>

            <Table
                size={'middle'}
                dataSource={subscriptions?.subscriptions}
                columns={columns}
                rowKey={'id'}
                title={() => (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type={'primary'} onClick={() => history.push(ROUTER_PAGES.addNewSubscription.path)}>
                            Add new
                        </Button>
                    </div>
                )}
                pagination={{ position: ['topCenter', 'bottomCenter'] }}
                onRow={(record, index) => {
                    return {
                        onClick: (event) => {
                            history.push(
                                buildPath(ROUTER_PAGES.editSubscription.path, {
                                    id: record.id + '',
                                })
                            );
                        },
                    };
                }}
            />

            <Modal
                title="Rerun subscription"
                visible={showReRunModal}
                onOk={() => {
                    form.submit();
                }}
                onCancel={() => {
                    form.resetFields();
                    setShowReRunModal(false);
                }}
            >
                <Form
                    layout={'vertical'}
                    form={form}
                    onFinish={(values) => {
                        reRunSubscription({
                            variables: {
                                id: values.id,
                                onlyNew: values.onlyNew,
                                lastChangeDateTimeFrom: values.lastChangeDateTimeFrom
                                    ? values.lastChangeDateTimeFrom.toDate()
                                    : null,
                            },
                        })
                            .then((response) => {
                                message.info('Initiated a rerun successfully on subscription ' + values.id);
                            })
                            .catch((err) => {
                                message.error(err.message);
                            })
                            .finally(() => {
                                setShowReRunModal(false);
                            });
                    }}
                    initialValues={{
                        onlyNew: true,
                        lastChangeDateTimeFrom: moment().startOf('day'),
                    }}
                >
                    <Form.Item name="id">
                        <Input hidden={true} />
                    </Form.Item>
                    <Form.Item label="Download only non-existing products from Validoo?" name="onlyNew">
                        <Radio.Group>
                            <Radio.Button value={true}>Yes</Radio.Button>
                            <Radio.Button value={false}>No</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item name="lastChangeDateTimeFrom" label="Download products that has been changed after">
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default SubscriptionListPage;
