import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import SubMenu from 'antd/es/menu/SubMenu';
import { Layout, Menu } from 'antd';
import React from 'react';
import {
    CloudDownloadOutlined,
    HomeOutlined,
    SearchOutlined,
    TagsOutlined,
    BuildOutlined,
    QuestionCircleOutlined,
    AlignLeftOutlined,
    PieChartOutlined,
    GroupOutlined,
    ForkOutlined,
    InfoCircleOutlined,
    CommentOutlined,
    FlagOutlined,
    UserOutlined,
    TeamOutlined,
    FormOutlined,
} from '@ant-design/icons';
import { ROUTER_PAGES } from '../routes';

const { Sider } = Layout;

interface MenuItem {
    path?: string;
    title: string;
    icon?: JSX.Element;
    subMenuItems?: MenuItem[];
}

const menuItems: MenuItem[] = [
    {
        path: ROUTER_PAGES.home.path,
        title: 'Home',
        icon: <HomeOutlined />,
    },
    {
        path: ROUTER_PAGES.search.path,
        title: 'Products',
        icon: <SearchOutlined />,
    },
    {
        path: ROUTER_PAGES.subscriptions.path,
        title: 'Subscriptions',
        icon: <CloudDownloadOutlined />,
    },
    {
        path: ROUTER_PAGES.tags.path,
        title: 'Tags',
        icon: <TagsOutlined />,
    },
    {
        title: 'Groups',
        icon: <BuildOutlined />,
        subMenuItems: [
            {
                path: ROUTER_PAGES.groups.path,
                title: 'Edit groups',
                icon: <BuildOutlined />,
            },
            {
                path: ROUTER_PAGES.groupHierachy.path,
                title: 'Edit hierarchy',
                icon: <ForkOutlined />,
            },
        ],
    },
    {
        title: 'Ingredients',
        icon: <QuestionCircleOutlined />,
        subMenuItems: [
            {
                path: ROUTER_PAGES.ingredientsStatistics.path,
                title: 'Ingredients stats',
                icon: <PieChartOutlined />,
            },
            {
                path: ROUTER_PAGES.ingredientsEdit.path,
                title: 'Edit Ingredients',
                icon: <TagsOutlined />,
            },
            {
                path: ROUTER_PAGES.ingredientsDescriptorsEdit.path,
                title: 'Edit Descriptions',
                icon: <AlignLeftOutlined />,
            },
            {
                path: ROUTER_PAGES.ingredientsGroups.path,
                title: 'Edit Groups',
                icon: <GroupOutlined />,
            },
            {
                path: ROUTER_PAGES.ingredientSourceList.path,
                title: 'Edit Sources',
                icon: <InfoCircleOutlined />,
            },
            {
                path: ROUTER_PAGES.ingredientsOrigins.path,
                title: 'Edit Origins',
                icon: <FlagOutlined />,
            },
        ],
    },
    {
        title: 'Reviews',
        icon: <CommentOutlined />,
        subMenuItems: [
            {
                path: ROUTER_PAGES.reviews.path,
                title: 'Product reviews',
                icon: <CommentOutlined />,
            },
            {
                path: ROUTER_PAGES.reports.path,
                title: 'Product reports',
                icon: <FormOutlined />,
            },
        ],
    },
    {
        title: 'Manage users',
        icon: <UserOutlined />,
        subMenuItems: [
            {
                path: ROUTER_PAGES.acl.path,
                title: 'Groups',
                icon: <TeamOutlined />,
            },
            {
                path: ROUTER_PAGES.aclUsers.path,
                title: 'Users',
                icon: <UserOutlined />,
            },
        ],
    },
];

const SideMenu = withRouter(({ location }: RouteComponentProps) => {
    const renderMenuItems = (items: MenuItem[]) => {
        return items.map((item) => {
            if (item.subMenuItems && item.subMenuItems.length > 0) {
                return (
                    <SubMenu key={item.title} title={item.title} icon={item.icon}>
                        {renderMenuItems(item.subMenuItems)}
                    </SubMenu>
                );
            } else {
                return (
                    <Menu.Item key={item.path} icon={item.icon}>
                        <Link to={item.path! /* FIXME: undefined */}>{item.title}</Link>
                    </Menu.Item>
                );
            }
        });
    };

    return (
        <>
            <Sider
                theme="light"
                breakpoint="sm"
                hidden={
                    [ROUTER_PAGES.signIn.path].includes(location.pathname) || location.pathname.startsWith('/public')
                }
                collapsible={true}
            >
                <Menu
                    className="sidebar-menu"
                    theme="light"
                    defaultSelectedKeys={[ROUTER_PAGES.home.path]}
                    mode="vertical"
                    selectedKeys={[location.pathname]}
                >
                    {renderMenuItems(menuItems)}
                </Menu>
            </Sider>
        </>
    );
});

export default SideMenu;
