import React, { useState } from 'react';
import { Card, Row, Col, Statistic, List, Typography, Slider, Divider, Spin, Button } from 'antd';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useIngredientStats } from '../../api/ingredients';
import { useHistory } from 'react-router-dom';
import { ROUTER_PAGES } from '../../routes';
import InfiniteScroll from 'react-infinite-scroller';
import { withDefault, NumberParam, useQueryParam } from 'use-query-params';

const IngredientsStatistics = () => {
    const [maxJaccard, setMaxJaccard] = useQueryParam<number>('maxJaccard', withDefault(NumberParam, 0));
    const [page, setPage] = useState(0);
    const pageSize = 10;
    const { data, loading, fetchMore, refetch } = useIngredientStats({
        variables: {
            maxJaccard,
            limit: pageSize,
            offset: pageSize * page,
        },
    });
    const [hasMore, setHasMore] = useState(true);
    const history = useHistory();

    const loadMore = () => {
        fetchMore({
            variables: {
                maxJaccard,
                offset: pageSize * (page + 1),
                limit: pageSize,
            },
        }).then((res) => {
            // FIXME: undefined
            if (res.data!.stats.missingIngredients.length < pageSize) {
                setHasMore(false);
            }
            setPage(page + 1);
        });
    };

    return (
        <div>
            <Breadcrumb />
            <Card title="Statistics">
                <Row gutter={[20, 20]}>
                    <Col lg={6} md={24}>
                        <Statistic
                            title="Number of products with intrepret ingredients"
                            value={data?.stats.globalCount}
                        />
                    </Col>
                    <Col lg={6} md={24}>
                        <Statistic
                            title="Jaccard average of all of products"
                            value={Math.floor((data?.stats.globalAverage || 0) * 100) / 100} // FIXME: undefined
                        />
                    </Col>
                    <Col lg={6} md={24}>
                        <Button
                            onClick={() => {
                                refetch();
                            }}
                        >
                            reload{' '}
                        </Button>
                    </Col>
                </Row>
                <Divider></Divider>
                {data?.stats && (
                    <>
                        <Typography.Title>Product with jaccard less than {maxJaccard}</Typography.Title>
                        <Slider
                            defaultValue={maxJaccard}
                            onAfterChange={setMaxJaccard}
                            step={0.05}
                            min={0}
                            max={1}
                        ></Slider>
                        <InfiniteScroll
                            loader={
                                <div style={{ width: '100%', display: 'flex' }}>
                                    <Spin style={{ margin: 'auto' }}></Spin>
                                </div>
                            }
                            initialLoad={false}
                            pageStart={0}
                            loadMore={loadMore}
                            hasMore={hasMore}
                        >
                            <List
                                loading={loading}
                                dataSource={data.stats.missingIngredients}
                                renderItem={(item) => (
                                    <List.Item
                                        style={{ cursor: 'pointer' }}
                                        key={item.id}
                                        onClick={() =>
                                            history.push(
                                                ROUTER_PAGES.products.path.replace(':id', String(item.productId))
                                            )
                                        }
                                    >
                                        <List.Item.Meta
                                            title={item.foundName}
                                            description={'Jaccard ' + item.jaccard}
                                        />
                                    </List.Item>
                                )}
                            ></List>
                        </InfiniteScroll>
                    </>
                )}
            </Card>
        </div>
    );
};

export default IngredientsStatistics;
