import React, { FC } from 'react';
import { Form, TreeSelect } from 'antd';
import { Segment } from '../model/segment';
import { useSegment } from '../api/segment';

interface TreeData {
    title: string;
    value: string;
    key: string;
    children?: TreeData[];
}

const transformSegmentToTree = (segment: Segment): TreeData[] => {
    if (!segment) {
        return [];
    }

    return segment.families.map<TreeData>((family) => {
        return {
            title: family.text! /* FIXME: undefined */,
            value: family.code,
            key: family.code,
            children: family.classes.map((cl) => {
                return {
                    title: cl.text! /* FIXME: undefined */,
                    value: cl.code,
                    key: cl.code,
                    children: cl.bricks.map((brick) => {
                        return {
                            title: brick.text! /* FIXME: undefined */,
                            value: brick.code,
                            key: brick.code,
                            children: [],
                        };
                    }),
                };
            }),
        };
    });
};

export interface ProductCategoryTreeProps {
    formItemName?: string;
    formItemLabel?: string;
    onChange?: (ids: string[]) => void;
    value?: string[];
    style?: any;
}

const LIVSMEDEL_DRYCKER_TOBAKSPRODUKTER = '50000000';

const ProductCategoryTree: FC<ProductCategoryTreeProps> = ({ formItemName, onChange, value, formItemLabel, style }) => {
    const segmentData = useSegment({ variables: { segmentCode: LIVSMEDEL_DRYCKER_TOBAKSPRODUKTER } });

    if (!formItemName) {
        return (
            <>
                <TreeSelect
                    value={value}
                    onChange={onChange}
                    style={style}
                    treeData={transformSegmentToTree(segmentData?.data?.segment! /* FIXME: undefined */)}
                    treeCheckable={true}
                    showCheckedStrategy={'SHOW_CHILD'}
                    placeholder={'Please choose one or more nodes'}
                    maxTagCount={6}
                />
            </>
        );
    }

    return (
        <Form.Item name={formItemName} label={formItemLabel}>
            <TreeSelect
                treeData={transformSegmentToTree(segmentData?.data?.segment! /* FIXME: undefined */)}
                treeCheckable={true}
                showCheckedStrategy={'SHOW_CHILD'}
                placeholder={'Please choose one or more nodes'}
                maxTagCount={6}
            />
        </Form.Item>
    );
};

export default ProductCategoryTree;
