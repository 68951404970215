import React, { FC } from 'react';
import { InterpretedIngredient } from '../../../../model/ingredients';
import { EditableField } from '../EditableField';

export interface IAmountCellProps {
    record: InterpretedIngredient;
}

export const AmountCell: FC<IAmountCellProps> = (props) => {
    const { record } = props;
    return <EditableField record={record} fieldName={'amount'} value={record.amount || '-'} />;
};
