import { useEffect, useRef } from 'react';

type KeyboardEventCallback = (ev: KeyboardEvent) => void;

export const useDocumentKeyboardEvent = (onChange: KeyboardEventCallback) => {
    const r = useRef<KeyboardEventCallback>();

    useEffect(() => {
        r.current = onChange;
    }, [onChange]);

    useEffect(() => {
        const eventListener = (ev: KeyboardEvent) => r?.current?.(ev);
        document.addEventListener('keydown', eventListener);

        return () => {
            document.removeEventListener('keydown', eventListener);
        };
    }, [r]);
};
