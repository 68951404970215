import { gql, useMutation } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';

export type Payload = { values: { name: string; synonyms: string[] | null } };
export function useAddOrigin(): MutationTuple<unknown, Payload> {
    return useMutation(
        gql`
            mutation AddNewOriginForm($values: UpdateIngredientOriginInput!) {
                createIngredientOrigin(values: $values) {
                    id
                    name
                    synonyms
                }
            }
        `
    );
}
