import React, { FC } from 'react';
import { Alert, Button, Form, Input, notification, Switch } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { USER_GROUP_ROW_FRAGMENT } from '../hooks';
import { PERMISSION_KEYS, PermissionsType } from '../types';

const MUTATION = gql`
    mutation AddNewGroup($values: UserGroupInput!) {
        insertUserGroup(values: $values) {
            id
            ...UserGroupRow
        }
    }
    ${USER_GROUP_ROW_FRAGMENT}
`;
const AddNewGroup: FC<{ onSuccess: () => void }> = ({ onSuccess }) => {
    const [addGroup, { loading, error }] = useMutation<
        unknown,
        {
            values: {
                name: string;
                admin: boolean;
                permissions: PermissionsType;
            };
        }
    >(MUTATION);
    return (
        <Form
            initialValues={{
                name: '',
                admin: false,
            }}
            onFinish={(values: { name: string; admin: boolean }) => {
                const permissions = {} as PermissionsType;
                for (const key of PERMISSION_KEYS) {
                    permissions[key] = {
                        read: false,
                        write: false,
                    };
                }
                addGroup({
                    variables: {
                        values: {
                            name: values.name,
                            admin: values.admin,
                            permissions,
                        },
                    },
                })
                    .then(() => {
                        notification.success({
                            message: 'A new group created',
                        });
                        onSuccess();
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }}
        >
            {error && <Alert type={'error'} message={error.message} />}
            <Form.Item name={'name'} label="Name" required={true}>
                <Input />
            </Form.Item>
            <Form.Item name={'admin'} label={'Admin'} valuePropName={'checked'}>
                <Switch />
            </Form.Item>
            <Form.Item>
                <Alert type={'info'} message={'You can set permissions later in the table'} />
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                    Add group
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddNewGroup;
