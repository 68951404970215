import React, { FC, ReactElement } from 'react';
import { Descriptions } from 'antd';
import { YesNoMaybe } from '../../../model/ingredients';
import { IInterpretedLifeTags } from '../../../model/product';
import { DescriptionsItemProps } from 'antd/lib/descriptions/Item';
import { CheckCircleTwoTone, CloseCircleTwoTone, QuestionCircleTwoTone } from '@ant-design/icons';

export interface ILifeStyleTagsInfoProps {
    interpretedLifestyles?: IInterpretedLifeTags | null;
}
const hasLifeTagsInformation = (lifeTags?: IInterpretedLifeTags | null): lifeTags is IInterpretedLifeTags =>
    !!lifeTags &&
    [
        lifeTags.isLactoVegetarian,
        lifeTags.isOvoVegetarian,
        lifeTags.isPescetarian,
        lifeTags.isVegan,
        lifeTags.isVegetarian,
    ].filter((prop) => prop !== null).length > 0;

interface IDescriptionItemProps extends DescriptionsItemProps {}
const DescriptionItem: FC<IDescriptionItemProps> = (props) => {
    const { children, ...restProps } = props;
    return <Descriptions.Item {...restProps}>{children}</Descriptions.Item>;
};
export interface IDescriptionValueProps {
    value: YesNoMaybe;
}
const DescriptionValue: FC<IDescriptionValueProps> = (props) => {
    const { value } = props;
    let node: ReactElement;
    if (value === YesNoMaybe.yes) {
        node = (
            <>
                <CheckCircleTwoTone twoToneColor="#52c41a" /> Yes
            </>
        );
    } else if (value === YesNoMaybe.no) {
        node = (
            <>
                <CloseCircleTwoTone twoToneColor="#f81d22" /> No
            </>
        );
    } else {
        node = (
            <>
                <QuestionCircleTwoTone twoToneColor="#1890ff" /> Maybe
            </>
        );
    }
    return <span>{node}</span>;
};
export const LifeStyleTagsInfo: FC<ILifeStyleTagsInfoProps> = (props) => {
    const { interpretedLifestyles } = props;
    return (
        <>
            {hasLifeTagsInformation(interpretedLifestyles) && (
                <>
                    <Descriptions title={<h3 style={{ fontSize: '14px' }}>Lifestyle tags</h3>}>
                        {interpretedLifestyles.isLactoVegetarian && (
                            <DescriptionItem label={'Lacto vegetarian'}>
                                <DescriptionValue value={interpretedLifestyles.isLactoVegetarian} />
                            </DescriptionItem>
                        )}
                        {interpretedLifestyles.isOvoVegetarian && (
                            <DescriptionItem label={'Ovo vegetarian'}>
                                <DescriptionValue value={interpretedLifestyles.isOvoVegetarian} />
                            </DescriptionItem>
                        )}
                        {interpretedLifestyles.isPescetarian && (
                            <DescriptionItem label={'Pescetarian'}>
                                <DescriptionValue value={interpretedLifestyles.isPescetarian} />
                            </DescriptionItem>
                        )}
                        {interpretedLifestyles?.isVegan && (
                            <DescriptionItem label={'Vegan'}>
                                <DescriptionValue value={interpretedLifestyles.isVegan} />
                            </DescriptionItem>
                        )}
                        {interpretedLifestyles?.isVegetarian && (
                            <DescriptionItem label={'Vegetarian'}>
                                <DescriptionValue value={interpretedLifestyles.isVegetarian} />
                            </DescriptionItem>
                        )}
                    </Descriptions>
                </>
            )}
        </>
    );
};
