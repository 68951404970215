import { ApolloCache, gql, QueryResult, useMutation, useQuery } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';
import produce from 'immer';

export const RATING_FIELDS = gql`
    fragment RatingFields on ProductRating {
        id
        isDeleted
        creationDateAndTime
        comment
        rating
        product {
            id
            gtin
            name
            productImage @include(if: $showImages) {
                url
            }
        }
        user {
            publicName
        }
    }
`;

const GET_PRODUCT_RATING = gql`
    query getProductRatings($showImages: Boolean! = false) {
        getProductRatings {
            ...RatingFields
        }
    }
    ${RATING_FIELDS}
`;

export interface ProductRating {
    id: string;
    rating: number;
    isDeleted: boolean;
    creationDateAndTime: number;
    gtin: string;
    comment: string | null;
    product: {
        id: string;
        gtin: string | null;
        name: string | null;
        productImage: null | {
            url: string;
        };
    };
    user: null | {
        publicName: null | string;
    };
}
export function useReviews(props: { showImages: boolean }): QueryResult<
    {
        getProductRatings: ProductRating[];
    },
    {
        showImages: boolean;
    }
> {
    return useQuery(GET_PRODUCT_RATING, {
        variables: {
            showImages: props.showImages,
        },
    });
}

const DELETE_PRODUCT_RATING = gql`
    mutation deleteProductRating($id: ID!, $showImages: Boolean! = false) {
        deleteProductRatingAsAdmin(id: $id) {
            data {
                ...RatingFields
            }
        }
    }
    ${RATING_FIELDS}
`;
export function useDeleteProductRating(): MutationTuple<unknown, { id: string }> {
    return useMutation(DELETE_PRODUCT_RATING);
}

export function removeDeletedReviewFromCache(cache: ApolloCache<unknown>, deletedId: string) {
    const existingRecords = cache.readQuery<{
        getProductRatings: ProductRating[];
    }>({
        query: GET_PRODUCT_RATING,
    });
    if (!existingRecords) {
        console.error('Did not found existingRecords (GET_PRODUCT_RATING)');
        return;
    }

    const newRecords = produce(existingRecords, (draftState) => {
        draftState.getProductRatings = draftState.getProductRatings.filter((r) => r.id !== deletedId);
    });

    cache.writeQuery({
        query: GET_PRODUCT_RATING,
        data: newRecords,
    });
}
