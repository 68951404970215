import React, { FC, useCallback, useMemo, useState } from 'react';
import { ProductRating, removeDeletedReviewFromCache, useDeleteProductRating, useReviews } from './hooks';
import {
    Alert,
    Button,
    Comment,
    message,
    Popconfirm,
    Popover,
    Rate,
    Space,
    Switch,
    Table,
    Tooltip,
    Typography,
} from 'antd';
import { ColumnGroupType, ColumnsType, ColumnType } from 'antd/lib/table/interface';
import { Image } from '../Image';
import { notNullGuard } from '../../utils/typescript';
import Barcode from 'react-barcode';
import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';

export interface ReviewsListProps {}

export const ReviewsList: FC<ReviewsListProps> = () => {
    const [showImages, setShowImages] = useState(false);
    const { data, loading, error } = useReviews({
        showImages,
    });
    const reviews = useMemo(() => data?.getProductRatings || [], [data?.getProductRatings]);
    const columns = useMemo((): ColumnsType<ProductRating> => {
        const dirtyColumns: (ColumnGroupType<ProductRating> | ColumnType<ProductRating> | null)[] = [
            {
                dataIndex: 'id',
                title: '#',
                defaultSortOrder: 'descend',
                sorter: {
                    compare: (a: ProductRating, b: ProductRating) => Number(a.id) - Number(b.id),
                    multiple: 1,
                },
            },
            {
                dataIndex: 'creationDateAndTime',
                title: 'Datetime',
                render(creationDateAndTime: ProductRating['creationDateAndTime']) {
                    return (
                        <Typography.Text type={'secondary'} style={{ fontSize: '0.7em' }}>
                            {new Date(creationDateAndTime).toLocaleString()}
                        </Typography.Text>
                    );
                },
            },
            {
                dataIndex: 'rating',
                title: 'Rating',
                width: 200,
                sorter: {
                    compare: (a: ProductRating, b: ProductRating) => a.rating - b.rating,
                    multiple: 2,
                },
                render(rating: number) {
                    return <Rate value={rating} disabled={true} />;
                },
            },
            {
                dataIndex: 'comment',
                title: 'Comment',
                render(comment: ProductRating['comment'], record: ProductRating) {
                    return <Comment content={comment} author={record.user?.publicName} />;
                },
            },
            {
                dataIndex: ['product', 'gtin'],
                title: 'GTIN',
                render(gtin: ProductRating['gtin']) {
                    return (
                        <Popover placement={'left'} content={<Barcode value={gtin} />}>
                            <span>{gtin}</span>
                        </Popover>
                    );
                },
            },
            {
                dataIndex: ['product', 'name'],
                title: 'Product name',
            },
            showImages
                ? {
                      dataIndex: ['product', 'productImage', 'url'],
                      title: 'Product image',
                      render(url: string | null) {
                          return (
                              <Image
                                  style={{ maxWidth: '60px', maxHeight: '60px', margin: 'auto' }}
                                  src={url || undefined}
                              />
                          );
                      },
                  }
                : null,
            {
                title: 'Actions',
                render(_: unknown, productRating: ProductRating) {
                    return (
                        <Space direction={'vertical'}>
                            {productRating.isDeleted ? <DeletedButton /> : <DeleteButton id={productRating.id} />}
                            <Button
                                type={'link'}
                                href={`/products/${productRating.product.id}`}
                                target={'_blank'}
                                icon={<EyeOutlined />}
                            >
                                View product
                            </Button>
                        </Space>
                    );
                },
            },
        ];
        return dirtyColumns.filter(notNullGuard);
    }, [showImages]);

    return (
        <>
            <Switch
                checked={showImages}
                onChange={setShowImages}
                checkedChildren="Show images"
                unCheckedChildren="Do not show images"
            />
            {error && <Alert message={String(error)} type={'error'} />}
            <Table dataSource={reviews} columns={columns} loading={loading} rowKey={'id'} />
        </>
    );
};

const DeletedButton: FC = () => {
    return (
        <Tooltip title="The comment already deleted">
            <Button icon={<DeleteOutlined />} danger={true}>
                Deleted
            </Button>
        </Tooltip>
    );
};
const DeleteButton: FC<{ id: string }> = ({ id }) => {
    const [deleteProductRating, { loading }] = useDeleteProductRating();

    const handleDelete = useCallback(() => {
        deleteProductRating({
            variables: {
                id,
            },
            update(cache) {
                removeDeletedReviewFromCache(cache, id);
            },
        }).catch((err) => {
            return message.error(String(err));
        });
    }, [deleteProductRating, id]);
    return (
        <Popconfirm title="Sure to delete?" onConfirm={handleDelete}>
            <Button icon={<DeleteOutlined />} danger={true} type={'link'} loading={loading}>
                Delete
            </Button>
        </Popconfirm>
    );
};
