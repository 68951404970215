import MainPage from './pages/MainPage/MainPage';
import QueryPage from './pages/QueryPage/QueryPage';
import ProductPage from './pages/ProductPage/ProductPage';
import SignInPage from './pages/SignInPage/SignInPage';
import SubscriptionListPage from './pages/SubscriptionPages/SubscriptionListPage';
import AddNewSubscriptionPage from './pages/SubscriptionPages/AddNewSubscriptionPage';
import EditSubscriptionPage from './pages/SubscriptionPages/EditSubscriptionPage';
import TagListPage from './pages/TagPages/TagListPage';
import GroupListPage from './pages/GroupPage/GroupListPage';
import AddNewGroupPage from './pages/GroupPage/AddNewGroupPage';
import EditGroupPage from './pages/GroupPage/EditGroupPage';
import ProductsComparePage from './pages/ProductPage/ProductsComparePage';
import EditProductPage from './pages/ProductPage/EditProductPage';
import AddTagPage from './pages/TagPages/AddNewAllergenTagPage';
import FindProductPage from './pages/GroupPage/FindProductsPage';
import IngredientListPage from './pages/IngredientsPage/IngredientListPage';
import IngredientsStatistics from './pages/IngredientsPage/IngredientsStatistics';
import IngredientDescriptorListPage from './pages/IngredientsPage/IngredientDescriptorListPage';
import IngredientGroupPage from './pages/IngredientsPage/IngredientGroupPage';
import GroupHierarchy from './pages/GroupPage/GroupHierarchy';
import { IngredientSourceList } from './pages/IngredientsPage/IngredientSourceList/IngredientSourceList';
import ProductIngredientSearch from './pages/IngredientsPage/ProductIngredientSearch/ProductIngredientSearch';
import { ReviewsPage } from './pages/ReviewsPage';
import PreviewProductPage from './pages/PreviewPage/PreviePage';
import OriginsPage from './pages/OriginsPage';
import ACLPage from './pages/ACLPage';
import ACLUsersPage from './pages/ACLUsersPage';
import { ProductReportsPage } from './pages/ProductReportsPage';

export const ROUTER_PAGES = {
    home: {
        path: '/',
        component: MainPage,
        exact: true,
    },
    search: {
        path: '/products/',
        component: QueryPage,
        exact: true,
    },
    productsCompare: {
        path: '/products/compare/:gtin',
        component: ProductsComparePage,
        exact: true,
    },
    editProduct: {
        path: '/products/:id/edit',
        component: EditProductPage,
        skip: true,
    },
    products: {
        path: '/products/:id',
        component: ProductPage,
    },
    signIn: {
        path: '/signin',
        component: SignInPage,
    },
    subscriptions: {
        path: '/subscriptions',
        component: SubscriptionListPage,
        exact: true,
    },
    addNewSubscription: {
        path: '/subscriptions/new',
        component: AddNewSubscriptionPage,
        exact: true,
    },
    editSubscription: {
        path: '/subscriptions/:id',
        component: EditSubscriptionPage,
    },
    tags: {
        path: '/tags',
        component: TagListPage,
    },
    addNewTag: {
        path: '/tags/new',
        component: AddTagPage,
        skip: true,
    },
    productPreviewPage: {
        path: '/public/products/:id',
        component: PreviewProductPage,
    },
    addNewGroup: {
        path: '/groups/new',
        component: AddNewGroupPage,
        exact: true,
    },
    editTag: {
        path: '/tags/:tag',
        component: null,
        skip: true,
    },
    groups: {
        path: '/groups',
        component: GroupListPage,
        exact: true,
    },
    editGroups: {
        path: '/groups/:id',
        component: EditGroupPage,
        exact: true,
    },
    groupHierachy: {
        path: '/groups-hierarchy',
        component: GroupHierarchy,
        exact: true,
    },
    findProduct: {
        path: '/groups/:id/find',
        component: FindProductPage,
        exact: true,
    },
    ingredientsGroups: {
        path: '/ingredients/groups',
        component: IngredientGroupPage,
        exact: true,
    },
    ingredientsOrigins: {
        path: '/ingredients/origins',
        component: OriginsPage,
        exact: true,
    },
    ingredientsStatistics: {
        path: '/ingredients/statistics',
        component: IngredientsStatistics,
        exact: true,
    },

    ingredientsEdit: {
        path: '/ingredients/edit',
        component: IngredientListPage,
        exact: true,
    },
    ingredientsDescriptorsEdit: {
        path: '/ingredients-descriptors/edit',
        component: IngredientDescriptorListPage,
        exact: true,
    },
    ingredientSourceList: {
        path: '/ingredients-sources',
        component: IngredientSourceList,
        exact: true,
    },
    productIngredientSearch: {
        path: '/ingredients/:id/products',
        component: ProductIngredientSearch,
        exact: true,
    },
    reviews: {
        path: '/reviews',
        component: ReviewsPage,
        exact: true,
    },
    reports: {
        path: '/product-reports',
        component: ProductReportsPage,
        exact: true,
    },
    acl: {
        path: '/acl',
        component: ACLPage,
        exact: true,
    },
    aclUsers: {
        path: '/acl/users',
        component: ACLUsersPage,
    },
};
