import { FC, useContext, useEffect, useState } from 'react';
import { useCarbonEstimate, useRecalculateCarbonEstimate } from 'src/api/carbonEstimate';
import { Bar, Gauge } from '@ant-design/plots';
import { InfoCircleFilled, ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Space, Tooltip, message } from 'antd';
import { CarbonEstimate } from 'src/model/carbonEstimate';
import styles from './styles.module.scss';
import Title from 'antd/lib/typography/Title';
import { AuthContext } from 'src/components/AuthProvider';

const RecalculateButton: FC<{ productId: number }> = (props) => {
    const { productId } = props;
    const [isClicked, setIsClicked] = useState(false);
    const [recalculateCarbonEstimate, { loading, data }] = useRecalculateCarbonEstimate();

    useEffect(() => {
        if (!isClicked || loading) return;

        if (data?.recalculateCarbonEstimate) message.success('Successfully recalculated carbon footprint');
        else if (!data?.recalculateCarbonEstimate) message.error('Failed to recalculate carbon footprint');

        setIsClicked(false);
        setTimeout(() => {
            window.location.reload();
        }, 1500); // Force reload the page so that the new carbon estimate is displayed. TODO: find a better way to do this
    }, [loading, data, isClicked]);

    return (
        <Button
            onClick={() => {
                recalculateCarbonEstimate({ variables: { productId } });
                setIsClicked(true);
            }}
            disabled={loading}
        >
            Recalculate <ReloadOutlined spin={loading} />
        </Button>
    );
};

const MAX_CARBON_FOOTPRINT = 30;

interface CarbonEstimateProps {
    productId: number;
}

const removeTypeName = (data: any): CarbonEstimate => {
    delete data.__typename;
    return data;
};

const CarbonEstimateCard: FC<CarbonEstimateProps> = (props) => {
    const { productId } = props;

    const user = useContext(AuthContext);

    const { data, loading } = useCarbonEstimate({ variables: { productId: Number(productId) } });
    const [color, setColor] = useState<string>(styles.green);
    const [annotations, setAnnotations] = useState<any[]>([]);

    useEffect(() => {
        if (!data?.product?.carbonEstimate) return;
        const carbonEstimate = removeTypeName(data?.product.carbonEstimate);
        const total = carbonEstimate?.total;

        if (total <= 0.5) {
            setColor(styles.green);
        } else if (total <= 3) {
            setColor(styles.greenLight);
        } else if (total <= 10) {
            setColor(styles.yellow);
        } else {
            setColor(styles.red);
        }

        const withoutTotal = Object.keys(carbonEstimate).filter((key) => key !== 'total');
        const typeLabels = withoutTotal.map((key, index) => ({
            type: 'html',
            html: `
                    <div style="display:flex; gap:8px;">
                        <img src="${process.env.PUBLIC_URL + '/carbon-' + key + '.svg'}" alt="${key}-icon" />
                        <h5 class="${styles.heading}">${key.charAt(0).toUpperCase() + key.slice(1)}</h5>
                        <p class="${styles.annotationText}" style="margin:0;">${carbonEstimate[
                key as keyof CarbonEstimate
            ]
                .toFixed(3)
                .toString()
                .replace('.', ',')} kg</p>
                    </div>
                `,
            position: [index, 0],
            offsetY: -40,
        }));

        const percentageLabels = withoutTotal.map((key) => {
            const value = carbonEstimate[key as keyof CarbonEstimate];
            const percentage = (value / total) * 100;
            const PERCENTAGE_THRESHOLD = 6;
            const percentageIsSmall = percentage < PERCENTAGE_THRESHOLD;

            const percentageString = percentage.toFixed(0).toString();
            // hacky way to adjust the offset depending on the length of the string
            let offset = percentageIsSmall ? 3 : -(percentageString.length + 1) * 10;

            return {
                type: 'text',
                content: percentageString + '%',
                style: { fill: percentageIsSmall ? styles.purple : styles.white },
                position: [key, value],
                offsetX: offset,
            };
        });
        setAnnotations([...typeLabels, ...percentageLabels]);
    }, [data]);

    return (
        <>
            {(loading || data?.product?.carbonEstimate) && (
                <Card
                    loading={loading}
                    className={styles.carbonEstimateCard}
                    title={<Title level={5}>Carbon footprint</Title>}
                    extra={
                        user?.userGroup.permissions.carbonService.write ? (
                            <RecalculateButton productId={productId} />
                        ) : undefined
                    }
                    // TODO: enable information tooltip when we have a multiple types estimates
                    // extra={
                    //     <Tooltip
                    //         placement="bottomRight"
                    //         color={styles.white}
                    //         trigger="click"
                    //         arrowPointAtCenter={false}
                    //         overlay={
                    //             <div
                    //                 className={styles.carbonEstimateCard}
                    //                 style={{
                    //                     padding: '24px',
                    //                     display: 'flex',
                    //                     flexDirection: 'column',
                    //                 }}
                    //             >
                    //                 <Title level={5}>FoodFacts estimate</Title>
                    //                 <p style={{ color: styles.black }}>
                    //                     Etiam porta sem malesuada magna mollis euismod. Etiam porta sem malesuada magna
                    //                     mollis euismod. Nulla vitae elit libero, a pharetra augue. Nullam id dolor id
                    //                     nibh ultricies vehicula ut id elit.
                    //                 </p>
                    //             </div>
                    //         }
                    //         overlayInnerStyle={{ color: styles.greyDark, width: '300px', borderRadius: '8px' }}
                    //     >
                    //         <Button type="text" style={{ borderRadius: '8px' }}>
                    //             <Space>
                    //                 <img src={process.env.PUBLIC_URL + '/FF_logo_purple.svg'} alt="logo"></img>
                    //                 FoodFacts estimate
                    //                 <InfoCircleFilled className={styles.infoButtonIcon} alt="" />
                    //             </Space>
                    //         </Button>
                    //     </Tooltip>
                    // }
                >
                    {data?.product.carbonEstimate && !loading && (
                        <>
                            <Gauge
                                {...{
                                    style: {
                                        maxWidth: '400px',
                                        margin: 'auto',
                                    },
                                    percent: data.product.carbonEstimate.total / MAX_CARBON_FOOTPRINT,
                                    range: {
                                        color: color,
                                        width: 18,
                                    },
                                    height: 200,
                                    animation: true,
                                    autoFit: true,
                                    startAngle: Math.PI,
                                    endAngle: 2 * Math.PI,
                                    indicator: false,
                                    radius: 0.8,
                                    statistic: {
                                        title: {
                                            offsetY: -195,
                                            style: {
                                                fontWeight: 600,
                                            },
                                            customHtml: () => `
                                    <div class="${styles.iconText}">
                                        <img src=${process.env.PUBLIC_URL + '/cloud.svg'} alt="cloud-icon">
                                        <h5 class="${styles.heading}">Total carbon footprint</h5>
                                    </div>
                                    `,
                                        },
                                        content: {
                                            offsetY: -45,
                                            style: {
                                                font: 'Inter',
                                                lineHeight: '150%',
                                                color: styles.black,
                                                width: '100%',
                                            },
                                            customHtml: () => `
                                        <div style="display:flex; flex-direction:column; min-width:100%;">
                                            <span
                                                class="${styles.heading}"
                                                style="font-size:48px;letter-spacing: -0.04em;"
                                            >
                                                ${data.product.carbonEstimate.total
                                                    .toFixed(3)
                                                    .toString()
                                                    .replace('.', ',')}
                                            </span>
                                            <span class="${styles.annotationText}">kg CO₂e per kg</span>
                                            <div class="${styles.annotationText}">
                                                <span style="display:inline-block; float:left; margin-left:8%;">
                                                    low
                                                </span>
                                                <span style="display:inline-block; float:right; margin-right:8%;">
                                                    high
                                                </span>
                                            </div>
                                        </div>
                                    `,
                                        },
                                    },
                                }}
                            />
                            <Bar
                                {...{
                                    style: { marginTop: 50 },
                                    data: Object.keys(removeTypeName(data?.product.carbonEstimate))
                                        .filter((key) => key !== 'total')
                                        .map((key) => ({
                                            type: key,
                                            value: (data?.product.carbonEstimate)[key as keyof CarbonEstimate],
                                        })),

                                    xField: 'value',
                                    yField: 'type',
                                    annotations: annotations,
                                    yAxis: {
                                        grid: null,
                                        line: null,
                                        label: null,
                                    },
                                    xAxis: { grid: null, line: null, label: null },
                                    interactions: undefined,
                                    animation: true,
                                    tooltip: false,
                                    autoFit: true,
                                    legend: false,
                                    seriesField: 'type',
                                    color: [
                                        styles.purple,
                                        styles.purpleLight1,
                                        styles.purpleLight2,
                                        styles.purpleLight3,
                                    ],
                                    minBarWidth: 24,
                                    maxBarWidth: 24,
                                    height: 300,
                                    barStyle: { radius: [4, 4, 2, 2] },
                                    barBackground: {
                                        style: {
                                            fill: styles.greyLight,
                                            fillOpacity: 1,
                                            radius: 2,
                                        },
                                    },
                                }}
                            />
                        </>
                    )}
                </Card>
            )}
        </>
    );
};

export default CarbonEstimateCard;
