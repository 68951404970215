import {
    Alert,
    Button,
    Modal,
    notification,
    Popconfirm,
    Select,
    Space,
    Table,
    TablePaginationConfig,
    Typography,
} from 'antd';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { gql, useMutation, useQuery } from '@apollo/client';
import { SiteUser } from './types';
import { ColumnsType } from 'antd/lib/table';
import { SITE_USER_ROW_FRAGMENT } from './hooks';
import { useUserGroups } from '../ACLPage/hooks';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import { createPortal } from 'react-dom';
import styles from './styles.module.scss';
import AddNewSiteUser from './AddNewSiteUser';
import { StringParam, useQueryParam } from 'use-query-params';

const Query = gql`
    query ACLUsersPageQuery {
        siteUsers {
            userName
            ...SiteUserRow
        }
    }

    ${SITE_USER_ROW_FRAGMENT}
`;
const PAGE_SIZE = 10;

const DELETE_SITE_USER_MUTATION = gql`
    mutation DeleteSiteUser($userName: String!) {
        deleteSiteUser(userName: $userName)
    }
`;
const CHANGE_USER_GROUP_MUTATION = gql`
    mutation ChangeUserGroup($userName: String!, $userGroupId: String!) {
        updateSiteUserGroup(userGroupID: $userGroupId, userName: $userName) {
            userName
            ...SiteUserRow
        }
    }

    ${SITE_USER_ROW_FRAGMENT}
`;

const Actions: FC<{ record: SiteUser; refetch: () => void }> = ({ record, refetch }) => {
    const [deleteUser, { loading: deleteInProgress }] = useMutation<unknown, { userName: string }>(
        DELETE_SITE_USER_MUTATION
    );
    return (
        <Space>
            <Popconfirm
                title={`Are you sure to delete user "${record.userName}"?`}
                onConfirm={() => {
                    deleteUser({
                        variables: {
                            userName: record.userName,
                        },
                    })
                        .then(() => {
                            notification.success({
                                message: `User "${record.userName}" deleted`,
                            });
                            return refetch();
                        })
                        .catch((err) => {
                            notification.error({
                                message: err instanceof Error ? err.message : String(err),
                            });
                        });
                }}
                okText="Yes"
                cancelText="No"
            >
                <Button type={'default'} danger loading={deleteInProgress}>
                    Delete user
                </Button>
            </Popconfirm>
        </Space>
    );
};

const UserGroupSelector: FC<{ record: SiteUser }> = ({ record }) => {
    const { data: groups, loading } = useUserGroups();

    const [updateUserGroup, { loading: updateInProgress }] = useMutation<
        unknown,
        { userName: string; userGroupId: string }
    >(CHANGE_USER_GROUP_MUTATION);
    const options = useMemo(() => {
        if (!groups) {
            return [];
        } else {
            return groups.userGroups.map((group) => ({
                value: group.id,
                label: group.name,
            }));
        }
    }, [groups]);
    const [selectedValue, setSelectedValue] = useState(record.userGroup?.id);
    useEffect(() => {
        setSelectedValue(record.userGroup?.id);
    }, [record.userGroup?.id, selectedValue]);
    return (
        <Select
            loading={loading || updateInProgress}
            options={options}
            value={selectedValue}
            onChange={(changeValue) => {
                updateUserGroup({
                    variables: {
                        userGroupId: changeValue,
                        userName: record.userName,
                    },
                })
                    .then(() => {
                        notification.success({
                            message: 'User group updated',
                        });
                    })
                    .catch((err) => {
                        notification.error({
                            message: err instanceof Error ? err.message : String(err),
                        });
                    });
            }}
        />
    );
};

const ACLUsersPage: FC = () => {
    const { data, loading, error, refetch } = useQuery<{ siteUsers: SiteUser[] }>(Query);
    const [groupFilter, setGroupFilter] = useQueryParam<string | undefined | null>('group', StringParam);

    const { data: groups, loading: groupsLoading } = useUserGroups();
    const groupFilters = useMemo(() => {
        if (!groups) {
            return [];
        } else {
            return groups.userGroups.map((group) => ({
                label: group.name,
                value: group.id,
            }));
        }
    }, [groups]);
    const filterNameRef = useRef<HTMLDivElement>(null);
    const columns: ColumnsType<SiteUser> = useMemo(() => {
        return [
            {
                key: 'userName',
                dataIndex: 'userName',
                title: 'User Name',
            },
            {
                key: 'eMail',
                dataIndex: 'eMail',
                title: 'E-mail',
            },
            {
                align: 'right',
                key: 'userGroup',
                dataIndex: 'userGroup',
                title: 'User Group',
                render: (value, record) => {
                    return <UserGroupSelector record={record} />;
                },
                filterIcon: () => <div ref={filterNameRef} />,
                filterDropdownVisible: true,
                filterDropdown: (props: FilterDropdownProps) => {
                    const { selectedKeys, setSelectedKeys, confirm } = props;
                    if (groupFilter && selectedKeys.length === 0) {
                        setSelectedKeys([groupFilter]);
                        confirm();
                    }

                    if (filterNameRef.current) {
                        return createPortal(
                            <Select
                                loading={groupsLoading}
                                allowClear={true}
                                placeholder={'Search by group'}
                                value={selectedKeys[0]}
                                onChange={(selectedValue) => {
                                    setSelectedKeys([selectedValue]);
                                    setGroupFilter(selectedValue ? String(selectedValue) : null);
                                    confirm();
                                }}
                                options={groupFilters}
                            />,
                            filterNameRef.current
                        );
                    } else {
                        return null;
                    }
                },
                onFilter: (value, record) => {
                    if (!value) {
                        return true;
                    } else {
                        return record.userGroup?.id === value;
                    }
                },
            },
            {
                key: 'actions',
                title: 'Actions',
                render: (value, record) => {
                    return <Actions record={record} refetch={refetch} />;
                },
            },
        ];
    }, [groupFilter, groupFilters, groupsLoading, refetch, setGroupFilter]);
    const title = useCallback(() => (error ? <Alert type={'error'} message={error.message} /> : undefined), [error]);
    const [page, setPage] = useState(1);
    const pagination: TablePaginationConfig = useMemo(
        () => ({ pageSize: PAGE_SIZE, current: page, onChange: (page1) => setPage(page1) }),
        [page]
    );
    const [addNewSiteUserModalVisible, setAddNewSiteUserModalVisible] = useState(false);
    const handleAddNewSiteUserSuccess = useCallback(
        (newRecord: { userName: string }) => {
            setAddNewSiteUserModalVisible(false);
            refetch()
                .then((res) => {
                    const recordIndex = res.data.siteUsers.findIndex(
                        (record) => record.userName === newRecord.userName
                    );
                    if (recordIndex >= 0) {
                        setPage(Math.ceil(recordIndex / PAGE_SIZE));
                    }
                })
                .catch((err) => {
                    notification.error({
                        message: err instanceof Error ? err.message : String(err),
                    });
                });
        },
        [refetch]
    );
    return (
        <div>
            <Breadcrumb />
            <Typography.Title>ACL Users</Typography.Title>
            <div className={styles.operations}>
                <Button type={'primary'} onClick={() => setAddNewSiteUserModalVisible(true)}>
                    Add site user
                </Button>
            </div>
            <Table
                rowKey={'userName'}
                loading={loading}
                columns={columns}
                dataSource={data?.siteUsers}
                title={title}
                pagination={pagination}
            />
            <Modal
                title={'Add new site user'}
                footer={null}
                visible={addNewSiteUserModalVisible}
                destroyOnClose={true}
                onCancel={() => setAddNewSiteUserModalVisible(false)}
            >
                <AddNewSiteUser onSuccess={handleAddNewSiteUserSuccess} />
            </Modal>
        </div>
    );
};

export default ACLUsersPage;
