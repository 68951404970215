import { Card, Popconfirm } from 'antd';
import { IngredientInformation } from 'src/model/ingredientInfromation';
import MDEditor from '@uiw/react-md-editor';
import { DeleteFilled, LinkOutlined } from '@ant-design/icons';
import { useDeleteIngredientInformation } from 'src/api/ingredient-information';
import { useState } from 'react';
import { ID } from 'src/utils/type';
import Empty from 'antd/es/empty';

interface InformationListProps {
    ingredientInfromation: IngredientInformation[];
}
export const InformationList = (props: InformationListProps) => {
    const { ingredientInfromation } = props;
    const [deletedStatements, setDeletedStatements] = useState<ID[]>([]);
    const [deleteIngredient] = useDeleteIngredientInformation();
    // Stores a local copy of the removed since they are not keep
    // track of using the API
    const notDeletedIngredientInfromation = ingredientInfromation.filter((v) => !deletedStatements.includes(v.id));

    return (
        <>
            {notDeletedIngredientInfromation.length === 0 && <Empty />}
            {notDeletedIngredientInfromation.map((ingredientInfo) => {
                return (
                    <Card
                        key={ingredientInfo.id}
                        extra={
                            <>
                                <Popconfirm
                                    title="Are you sure you want to disconnect this info?"
                                    okText="Yes"
                                    cancelText="No"
                                    placement="leftBottom"
                                >
                                    <LinkOutlined style={{ marginLeft: '10px' }} />
                                </Popconfirm>
                                <Popconfirm
                                    onConfirm={() => {
                                        setDeletedStatements([...deletedStatements, ingredientInfo.id]);
                                        deleteIngredient({ variables: { id: ingredientInfo.id } });
                                    }}
                                    title="Are you sure you want to delete this info?"
                                    okText="Yes"
                                    cancelText="No"
                                    placement="bottom"
                                >
                                    <DeleteFilled style={{ marginLeft: '10px' }} />
                                </Popconfirm>
                            </>
                        }
                        title={ingredientInfo.source?.name}
                        style={{ marginBottom: '10px' }}
                    >
                        {ingredientInfo.type === 'RICH_TEXT' && (
                            <MDEditor.Markdown source={JSON.parse(ingredientInfo.body)['richText']} />
                        )}
                    </Card>
                );
            })}
        </>
    );
};
