import { ReloadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Popover, Row, Space, Typography, message } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { useRecalculateNutriScore } from 'src/api/product';
import { AuthContext } from 'src/components/AuthProvider';
import { DetailedNutriScore } from 'src/model/product';

export interface NutriScoreProps {
    nutriScore: string;
    detailedNutriScore?: DetailedNutriScore;
    productId: number;
}

interface DetailedNutriScoreViewProps {
    score: DetailedNutriScore;
}

const DetailedNutriScoreView: FC<DetailedNutriScoreViewProps> = ({ score }) => {
    if (!score) {
        return (
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <p>No detailed score available</p>
                </Col>
            </Row>
        );
    }
    return (
        <>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <p>
                        Food type treated as <b>{score.input.foodType}</b> and using the lookup tables as{' '}
                        <b>{score.input.lookupTableKey}</b>
                    </p>
                    <p>Total score: {score.total}</p>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <table style={{ tableLayout: 'fixed', border: '1px solid rgb(226, 67, 70, 1)' }}>
                        <thead>
                            <tr style={{ backgroundColor: 'rgb(226, 67, 70, .8)' }}>
                                <th style={{ width: '80%' }}>Negative points</th>
                                <th style={{ width: '20%' }}>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ backgroundColor: 'rgb(226, 67, 70, .2)' }}>
                                <td>Energy</td>
                                <td>{score.energy}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(226, 67, 70, .8)' }}>
                                <td>Sugars</td>
                                <td>{score.sugar}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(226, 67, 70, .2)' }}>
                                <td>Saturated fatty acids</td>
                                <td>{score.fat}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(226, 67, 70, .8)' }}>
                                <td>Salt</td>
                                <td>{score.salt}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(226, 67, 70, .2)' }}>
                                <td>Sweeteners</td>
                                <td>{score.sweeteners !== null ? score.sweeteners : '-'}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(226, 67, 70, .8)' }}>
                                <td style={{ fontWeight: 600 }}>Total</td>
                                <td style={{ fontWeight: 600 }}>{score.negative}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
                <Col span={24}>
                    <table style={{ tableLayout: 'fixed', border: '1px solid rgb(43, 182, 115, 1)' }}>
                        <thead>
                            <tr style={{ backgroundColor: 'rgb(43, 182, 115, .8)' }}>
                                <th style={{ width: '80%' }}>Positive points</th>
                                <th style={{ width: '20%' }}>Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr style={{ backgroundColor: 'rgb(43, 182, 115, .2)' }}>
                                <td>Fruits, vegetables etc.</td>
                                <td>{score.fruit}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(43, 182, 115, .8)' }}>
                                <td>Fibers</td>
                                <td>{score.fiber}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(43, 182, 115, .2)' }}>
                                <td>Proteins</td>
                                <td>{score.protein}</td>
                            </tr>
                            <tr style={{ backgroundColor: 'rgb(43, 182, 115, .8)' }}>
                                <td style={{ fontWeight: 600 }}>Total</td>
                                <td style={{ fontWeight: 600 }}>{score.positive}</td>
                            </tr>
                        </tbody>
                    </table>
                </Col>
            </Row>
        </>
    );
};

const RecalculateButton: FC<{ productId: number }> = (props) => {
    const { productId } = props;
    const [isClicked, setIsClicked] = useState(false);
    const [recalculateNutriScore, { loading, data }] = useRecalculateNutriScore();

    useEffect(() => {
        if (!isClicked || loading) return;

        if (data?.recalculateNutriScore) message.success('Successfully recalculated Nutri-Score');
        else if (!data?.recalculateNutriScore) message.error('Failed to recalculate  Nutri-Score');

        setIsClicked(false);
        setTimeout(() => {
            window.location.reload();
        }, 1500); // Force reload the page so that the new nutri score is displayed. TODO: find a better way to do this
    }, [loading, data, isClicked]);

    return (
        <Button
            onClick={() => {
                recalculateNutriScore({ variables: { productId } });
                setIsClicked(true);
            }}
            disabled={loading}
        >
            Recalculate <ReloadOutlined spin={loading} />
        </Button>
    );
};

export const NutriScoreCard: FC<NutriScoreProps> = ({ nutriScore, detailedNutriScore, productId }) => {
    const [detailedNutriScoreVisible, setDetailedNutriScoreVisible] = useState<boolean>(false);
    const user = useContext(AuthContext);

    return (
        <Card
            title="Nutri-Score"
            extra={
                user?.userGroup.permissions.productService.write ? (
                    <RecalculateButton productId={productId} />
                ) : undefined
            }
        >
            <Space>
                <Col>
                    <Popover
                        content={detailedNutriScore ? <DetailedNutriScoreView score={detailedNutriScore} /> : null}
                        title="Detailed nutri score result"
                        trigger="click"
                        visible={detailedNutriScoreVisible}
                        onVisibleChange={() => setDetailedNutriScoreVisible((prevState) => !prevState)}
                    >
                        {nutriScore && (
                            <img
                                src={process.env.PUBLIC_URL + `/Nutri-score-${nutriScore}.svg`}
                                style={{
                                    maxHeight: '40px',
                                    marginRight: '10px',
                                    cursor: 'pointer',
                                }}
                                alt={`Nutriscore ${nutriScore}`}
                            />
                        )}
                    </Popover>
                </Col>

                <Col>
                    <Space>
                        <Typography.Text>What is the Nutri-Score?</Typography.Text>
                        <Popover
                            overlay={true}
                            color="white"
                            title={<Typography.Title level={5}>What is the Nutri-Score?</Typography.Title>}
                            trigger={'click'}
                            placement={'right'}
                            zIndex={0}
                            content={
                                <div style={{ maxWidth: '500px', maxHeight: '550px', overflowY: 'auto' }}>
                                    <Typography.Title level={5}>What does the symbol mean?</Typography.Title>
                                    <Typography.Paragraph>
                                        Nutri-Score summarizes the nutritional content of a product simplified to five
                                        letters, from A to E. It takes into account positive nutritional values (fiber,
                                        protein and content of fruits, vegetables, rapeseed, walnut, and olive oil) and
                                        those one should be careful with (energy content, sugar, saturated fat and
                                        salt).
                                    </Typography.Paragraph>

                                    <Typography.Title level={5}>Estimate</Typography.Title>
                                    <Typography.Paragraph>
                                        The value displayed is an estimate that is calculated automatically based on the
                                        nutrition declaration and the supplier's product classification. The real value
                                        may differ from this estimate in some cases, for example if the supplier
                                        provided incomplete information or if the quantity of vegetables and fruits is
                                        difficult to assess automatically.
                                    </Typography.Paragraph>
                                    <Typography.Title level={5}>Who is behind the Nutri-Score</Typography.Title>
                                    <Typography.Paragraph>
                                        Nutri-Score is developed by the French government in collaboration with
                                        researchers. It is the officially recommended label for brief nutritional
                                        information in France, Germany, the Netherlands, Belgium and the UK, among
                                        others, and is also a candidate to be recommended across the EU. The Swedish
                                        Food Agency, in cooperation with the other Nordic countries, has so far chosen
                                        to continue to use the Keyhole for food that is useful to eat.
                                    </Typography.Paragraph>
                                </div>
                            }
                        >
                            <Typography.Text strong={true} style={{ cursor: 'pointer' }}>
                                Read here
                            </Typography.Text>
                        </Popover>
                    </Space>
                </Col>
            </Space>
        </Card>
    );
};
