import { InterpretedIngredient } from '../../../model/ingredients';
import React, { FC, useCallback, useContext } from 'react';
import { Button, Popconfirm, Space, Typography } from 'antd';
import { ItemsOnDeleteContext, IsEditingContext, IsNotEditableContext, ItemUpdateInProgressContext } from './context';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';

interface IOperationsProps {
    record: InterpretedIngredient;
    onEdit: (itemId: InterpretedIngredient['id']) => void;
    onCancel: () => void;
    onDelete: (itemId: InterpretedIngredient['id']) => void;
    onSave: () => void;
}
export const Operations: FC<IOperationsProps> = (props) => {
    const { record, onSave, onDelete, onEdit, onCancel } = props;
    const isEditing = useContext(IsEditingContext);
    const disabled = useContext(IsNotEditableContext);
    const updateInProgress = useContext(ItemUpdateInProgressContext);
    const itemsOnDelete = useContext(ItemsOnDeleteContext);

    const handleEdit = useCallback(
        (e?: React.MouseEvent) => {
            e?.stopPropagation();
            onEdit(record.id);
        },
        [onEdit, record.id]
    );
    const handleDelete = useCallback(
        (e?: React.MouseEvent) => {
            e?.stopPropagation();
            onDelete(record.id);
        },
        [onDelete, record.id]
    );
    return isEditing ? (
        <Space>
            <span onClick={(e) => e.stopPropagation()}>
                <Button type={'link'} onClick={onSave} loading={updateInProgress}>
                    Save
                </Button>
            </span>
            <span onClick={(e) => e.stopPropagation()}>
                <Button type={'link'} onClick={onCancel}>
                    Cancel
                </Button>
            </span>
        </Space>
    ) : (
        <>
            <Typography.Link disabled={disabled} onClick={handleEdit}>
                <EditOutlined /> Edit
            </Typography.Link>
            <span onClick={(e) => e.stopPropagation()}>
                <Popconfirm title="Sure to delete?" onConfirm={handleDelete}>
                    <Button
                        icon={<DeleteOutlined />}
                        danger={true}
                        type={'link'}
                        loading={itemsOnDelete.has(record.id)}
                    >
                        Delete
                    </Button>
                </Popconfirm>
            </span>
        </>
    );
};
