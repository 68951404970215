import React, { FC, useCallback } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { browserHistory } from './history';
import { ApolloProvider } from '@apollo/client';
import { client } from './client';
import { Layout, Typography } from 'antd';
import { env } from './utils/env';
import SideMenu from './components/SideMenu';
import Header from './components/Header';
import { ROUTER_PAGES } from './routes';
import { QueryParamProvider } from 'use-query-params';
import { AuthProvider } from './components/AuthProvider';

const { Content, Footer } = Layout;

const App: FC = () => {
    const renderRoutes = useCallback(() => {
        const routes: JSX.Element[] = [];
        for (const key in ROUTER_PAGES) {
            const route = (ROUTER_PAGES as any)[key];
            if (!route.skip) {
                routes.push(
                    <Route exact={route.exact} key={route.path} path={route.path} component={route.component} />
                );
            }
        }
        return routes;
    }, []);

    return (
        <ApolloProvider client={client}>
            <Router history={browserHistory}>
                <QueryParamProvider ReactRouterRoute={Route as any}>
                    <AuthProvider>
                        <Layout style={{ minHeight: '100vh' }}>
                            <Header />
                            <Layout className="main-wrapper">
                                <SideMenu />
                                <Layout style={{ marginTop: '20vh' }}>
                                    <Content>
                                        <div className="content">
                                            <Switch>
                                                {renderRoutes()}
                                                <Redirect to="/" />
                                            </Switch>
                                        </div>
                                    </Content>
                                    <Footer style={{ display: 'flex' }}>
                                        <Typography.Text style={{ margin: 'auto' }}>
                                            {`stage ${env.REACT_APP_STAGE}, build version ${env.REACT_APP_BUILD_VERSION}`}
                                        </Typography.Text>
                                    </Footer>
                                </Layout>
                            </Layout>
                        </Layout>
                    </AuthProvider>
                </QueryParamProvider>
            </Router>
        </ApolloProvider>
    );
};

export default App;
