import React, { FC, Key, useCallback, useMemo, useRef, useState } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useDeleteOrigin, useFetchOrigins } from './hooks';
import { Alert, Button, Input, Modal, notification, PageHeader, Popconfirm, Space, Table, Tag, Tooltip } from 'antd';
import { TableProps } from 'antd/lib/table/Table';
import { notNullGuard } from '../../utils/typescript';
import { DeleteOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import AddNewOriginForm from './AddNewOriginForm';
import { IngredientOrigin } from './types';
import { ID } from '../../utils/type';
import UpdateOriginForm from './UpdateOriginForm';
import { NetworkStatus } from '@apollo/client';
import { FilterConfirmProps, FilterDropdownProps } from 'antd/lib/table/interface';
import Highlighter from 'react-highlight-words';
import { createPortal } from 'react-dom';

const DeleteButton: FC<{ id: ID; onDelete: () => void }> = ({ id, onDelete }) => {
    const [deleteOrigin, { loading }] = useDeleteOrigin({});
    const handleDelete = useCallback(() => {
        deleteOrigin({
            variables: {
                id,
            },
        })
            .then(() => {
                onDelete();
            })
            .catch((err) => {
                notification.error({
                    message: err instanceof Error ? err.message : String(err),
                });
            });
    }, [deleteOrigin, id, onDelete]);
    return (
        <Popconfirm title="Sure to delete?" onConfirm={handleDelete}>
            <Button icon={<DeleteOutlined />} danger type={'primary'} loading={loading} />
        </Popconfirm>
    );
};
const PAGE_SIZE = 15;
const OriginsPage: FC = () => {
    const {
        data: origins,
        loading,
        error,
        refetch,
        networkStatus,
    } = useFetchOrigins({
        variables: {
            limit: 90000000,
            offset: 0,
        },
        notifyOnNetworkStatusChange: true,
    });
    const dataSource: NonNullable<TableProps<IngredientOrigin>['dataSource']> = useMemo(() => {
        return origins?.getIngredientOrigins.data?.filter(notNullGuard) || [];
    }, [origins?.getIngredientOrigins.data]);
    const [updateOrigin, setUpdateOrigin] = useState<ID>();
    const selectedOrigin = useMemo(
        () => (updateOrigin ? dataSource.find((origin) => origin.id === updateOrigin) : undefined),
        [dataSource, updateOrigin]
    );

    const [searchText, setSearchText] = useState<Key>('');
    const [searchedColumn, setSearchedColumn] = useState<keyof IngredientOrigin>();
    const onRefetch = useCallback(() => {
        refetch().catch((err) =>
            notification.error({
                message: err instanceof Error ? err.message : String(err),
            })
        );
    }, [refetch]);
    const handleSearch = useCallback(
        (
            selectedKeys: Key[],
            confirm: (filterConfirmProps?: FilterConfirmProps) => void,
            dataIndex: typeof searchedColumn
        ) => {
            confirm();
            setSearchText(selectedKeys[0]);
            setSearchedColumn(dataIndex);
        },
        []
    );

    const filterNameRef = useRef<HTMLDivElement>(null);

    const columns: TableProps<IngredientOrigin>['columns'] = useMemo(() => {
        return [
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                filterDropdownVisible: true,
                filterDropdown: (props: FilterDropdownProps) => {
                    const { selectedKeys, setSelectedKeys, confirm } = props;

                    if (filterNameRef.current) {
                        return createPortal(
                            <Input
                                allowClear={true}
                                placeholder={'Search by name'}
                                value={selectedKeys[0]}
                                onChange={(e) => {
                                    const newSelectedKeys = e.target.value ? [e.target.value] : [];
                                    setSelectedKeys(newSelectedKeys);
                                    handleSearch(newSelectedKeys, confirm, 'name');
                                }}
                            />,
                            filterNameRef.current
                        );
                    } else {
                        return null;
                    }
                },
                filterIcon: () => <div ref={filterNameRef} />,
                onFilter: (value, record) => record.name.toString().toLowerCase().includes(String(value).toLowerCase()),
                render: (text) => {
                    return searchedColumn === 'name' ? (
                        <Highlighter
                            highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                            searchWords={[String(searchText)]}
                            autoEscape
                            textToHighlight={text ? text.toString() : ''}
                        />
                    ) : (
                        text
                    );
                },
            },
            {
                title: 'Synonyms',
                dataIndex: 'synonyms',
                key: 'synonyms',
                render(fieldValue, origin) {
                    return (
                        <>
                            {(origin.synonyms?.filter(notNullGuard) || []).map((synonym) => (
                                <Tag key={synonym}>{synonym}</Tag>
                            ))}
                        </>
                    );
                },
            },
            {
                title: 'Actions',
                key: 'actions',
                render(v, origin) {
                    return (
                        <Space direction={'horizontal'}>
                            <Tooltip title={'Edit origin'}>
                                <Button
                                    icon={<EditOutlined />}
                                    type={'primary'}
                                    onClick={() => setUpdateOrigin(origin.id)}
                                />
                            </Tooltip>
                            <DeleteButton id={origin.id} onDelete={onRefetch} />
                        </Space>
                    );
                },
                align: 'right',
                width: 150,
            },
        ];
    }, [handleSearch, onRefetch, searchText, searchedColumn]);
    const pagination: TableProps<IngredientOrigin>['pagination'] = useMemo(() => {
        return {
            position: ['bottomCenter'],
            pageSize: PAGE_SIZE,
            showSizeChanger: false,
        };
    }, []);

    const [createNew, setCreateNew] = useState(false);

    return (
        <div>
            <Breadcrumb />
            <PageHeader
                title={'Origins'}
                extra={[
                    <Tooltip key={'add'} title={'Add a new Origin'}>
                        <Button
                            shape="circle"
                            icon={<PlusOutlined />}
                            onClick={() => setCreateNew(true)}
                            type="primary"
                        />
                    </Tooltip>,
                ]}
            />
            {error && <Alert message={error.message} type={'error'} />}
            {!error && (
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    rowKey={'id'}
                    loading={loading || networkStatus === NetworkStatus.refetch}
                    pagination={pagination}
                    sticky={true}
                />
            )}
            <Modal
                title={'Add Origin'}
                destroyOnClose={true}
                visible={createNew}
                onCancel={() => {
                    setCreateNew(false);
                    onRefetch();
                }}
                footer={null}
            >
                <AddNewOriginForm />
            </Modal>
            <Modal
                title={`Update origin "${selectedOrigin?.name || ''}"`}
                destroyOnClose={true}
                visible={!!selectedOrigin}
                footer={null}
                onCancel={() => {
                    setUpdateOrigin(undefined);
                    onRefetch();
                }}
            >
                {selectedOrigin ? <UpdateOriginForm origin={selectedOrigin} /> : null}
            </Modal>
        </div>
    );
};

export default OriginsPage;
