import { ID } from '../../../../../utils/type';
import { gql, QueryResult, useQuery } from '@apollo/client';

const GET_INGREDIENT_GROUP_SCHEMA = gql`
    query getIngredientGroupWithIngredients($groupId: ID!) {
        getIngredientCategory(id: $groupId) {
            id
            ingredients {
                data {
                    id
                    name
                }
            }
        }
    }
`;

export interface IngredientGroupWithIngredientsResult {
    getIngredientCategory: {
        id: ID;
        ingredients: {
            data: ({ id: ID; name: string } | null)[] | null;
        } | null;
    } | null;
}
export function useIngredientGroupWithIngredients(
    groupId: ID
): QueryResult<IngredientGroupWithIngredientsResult, { groupId: ID }> {
    return useQuery(GET_INGREDIENT_GROUP_SCHEMA, {
        variables: {
            groupId,
        },
    });
}
