import React, { FC, useContext } from 'react';
import { InterpretedIngredient } from '../../../../model/ingredients';
import { IsEditingContext } from '../context';
import { Form, Select } from 'antd';

export interface IDescriptionsCellProps {
    record: InterpretedIngredient;
}

export const DescriptionsCell: FC<IDescriptionsCellProps> = (props) => {
    const { record } = props;
    const isEditing = useContext(IsEditingContext);
    if (!isEditing) {
        return <>{record.descriptions?.join(', ') || '-'}</>;
    }

    return (
        <Form.Item name={'descriptions'} style={{ marginBottom: 0 }}>
            <Select mode={'tags'} />
        </Form.Item>
    );
};
