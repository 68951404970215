import React, { FC, useEffect } from 'react';
import { Typography, Spin, notification } from 'antd';
import { ProductViewer } from '../../components/ProductViewer/ProductViewer';
import { useParams } from 'react-router-dom';
import { useProduct } from '../../api/product';
import { Product } from '../../model/product';

export const parse = (product: Product): Product => {
    if (!product) {
        return null as any; // FIXME: come on! it accepts not nullable value and returns not nullable. Why the code is so perfect?
    }
    const allergens = product.allergenInformation
        ? JSON.parse(JSON.stringify(product.allergenInformation.allergens))
        : [];
    const preDefinedOrder = ['CONTAINS', 'MAY_CONTAIN', 'UNDECLARED', 'FREE_FROM'];

    /* FIXME: in place sort?! */
    allergens.sort((a: any, b: any /* FIXME: any */) => {
        if (preDefinedOrder.indexOf(a.containmentCode.code) - preDefinedOrder.indexOf(b.containmentCode.code) > 0) {
            return 1;
        } else {
            return -1;
        }
    });

    return {
        ...product,
        allergenInformation: {
            allergens: allergens,
            statement: product.allergenInformation?.statement,
        },
    };
};

const WARNING_KEY = 'public_page_removed_soon_viewed';
const shouldShowWarning = () => {
    return !localStorage.getItem(WARNING_KEY);
};
const hideWarning = () => {
    localStorage.setItem(WARNING_KEY, '1');
};
const PreviewProductPage: FC = () => {
    useEffect(() => {
        if (shouldShowWarning()) {
            notification.warning({
                message:
                    'This page (public pages) will be removed soon. To gain access to product data in the future, please contact us!',
                duration: 0,
                onClose: () => {
                    hideWarning();
                },
            });
        }
    }, []);

    const params = useParams<{ id: string }>();

    const { data, error, loading } = useProduct({ variables: { id: Number(params.id) } });

    const product = parse(data?.product! /* FIXME: undefined */);

    if (error) {
        return (
            <div>
                <Typography.Title level={1}>Unexpected error occurred</Typography.Title>
                <pre>
                    {error.graphQLErrors.map(({ message }, i) => (
                        <span key={i}>{message}</span>
                    ))}
                </pre>
            </div>
        );
    }

    return (
        <div>
            {loading && <Spin />}
            {params.id && product && <ProductViewer isAdmin={false} data={product} isPublic={true} />}
        </div>
    );
};

export default PreviewProductPage;
