import { Button, Col, Input, Row, Space } from 'antd';
import { Form, Divider } from 'antd';
import { useEffect, useState } from 'react';
import { useAdvancedProductSearch, useAdvancedProductSearchCsv, useAdvancedProductSearchTotal } from 'src/api/product';
import { ProductList } from 'src/components/ProductList';
import { AdvancedSearch, SearchBar } from 'src/components/SearchBar/SearchBar';
import { BooleanParam, useQueryParam } from 'use-query-params';

interface TagFormProps {
    defaultValues?: {
        name: string;
        query: string;
        id: string;
    };
    onSave?: (tag: { name: string; query: string; id: string }) => void;
}

export const TagProducts = (props: TagFormProps) => {
    const { defaultValues } = props;
    const filter: any = defaultValues ? JSON.parse(defaultValues?.query) : undefined;
    const [offset, setOffset] = useState<number>(0);
    const limit = 5;
    const [showCSV, setShowCSV] = useQueryParam<boolean | null | undefined>('csv', BooleanParam);

    const { data, fetchMore, loading } = useAdvancedProductSearch({
        variables: {
            filter,
            offset,
            limit,
            lite: showCSV !== true,
        },
    });

    const products = data?.response.products;
    const hasMore = data?.response.hasMore;

    useEffect(() => {
        fetchMore({ variables: { filter, offset, limit, lite: showCSV !== true } });
    }, [offset, limit, filter, fetchMore, showCSV]);

    return (
        <div>
            <Row gutter={[20, 20]}>
                <Col md={24} lg={24}>
                    {products && (
                        <ProductList
                            loading={loading}
                            hasMore={hasMore}
                            manualLoad
                            loadMore={() => {
                                const dataLength = data?.response.products.length;
                                if (dataLength) setOffset(dataLength);
                            }}
                            products={products}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};
