import { gql } from '@apollo/client';
import { Stats } from '../model/stats';
import { q } from '../utils/utils';

const useStatsSchema = gql`
    query stats($offset: Int, $limit: Int) {
        stats {
            lastUpdatedProducts(offset: $offset, limit: $limit) {
                productImage(size: x_small) {
                    url
                }

                id
                productIdentifier {
                    externalId
                }
                name
                brandName
                informationProvider {
                    name
                }
                packageInformation {
                    measurement {
                        size
                    }
                }
                modifiedDateAndTime
                creationDateAndTime
                sourceLastChangedDateAndTime
            }
            totalNumberOfProducts
            totalNumberOfProductsFromFoodFactsGLN
            totalNumberOfProductsWithDiscoveredTags
            totalNumberOfProductsWithIngredientStatements
            totalNumberOfProductsWithConsumerUnit
            totalNumberOfProductsUpdatedToday
        }
    }
`;
export const useStats = q<{ stats: Stats }, { offset: number; limit: number }>(useStatsSchema);
