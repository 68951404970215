import { gql, useMutation } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';
import { IngredientOrigin } from '../types';
import { ID } from '../../../utils/type';

export function useUpdateOrigin(): MutationTuple<
    { updateIngredientOrigin: IngredientOrigin },
    { id: ID; payload: { name: string; synonyms: string[] | null } }
> {
    return useMutation(gql`
        mutation UpdateNewOriginForm($id: ID!, $payload: UpdateIngredientOriginInput!) {
            updateIngredientOrigin(id: $id, updateValues: $payload) {
                id
                name
                synonyms
            }
        }
    `);
}
