import { DeleteFilled } from '@ant-design/icons';
import { Row, Col, Drawer, Table, Form, Input, Divider, message, Button } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import {
    useCreateIngredientInformationSources,
    useDeleteIngredientInformationSources,
    useIngredientInformationSources,
    useUpdateIngredientInformationSources,
} from 'src/api/ingredient-information';
import { IngredientInformationSource } from 'src/model/ingredientInfromation';
import { useQueryParam, StringParam, BooleanParam } from 'use-query-params';
import { AddNewSourceModal } from '../AddIngredientInformation/CreateInformationPage';

const UpdateSourceDrawerBody = (props: { source: IngredientInformationSource; onComplete: () => void }) => {
    const { source, onComplete } = props;
    const [updateSource, { loading: isUpdating }] = useUpdateIngredientInformationSources();
    const [deleteSource] = useDeleteIngredientInformationSources();

    return (
        <>
            <Form
                onFinish={(form) => {
                    updateSource({ variables: { id: source.id, values: form } })
                        .then((v) => {
                            message.success('Successfully update ' + v.data?.ingredientInformationSource.name);
                        })
                        .catch((v) => {
                            message.error('Not able to update source');
                        });
                }}
                initialValues={source}
            >
                <Row gutter={[20, 20]}>
                    <Col span={12}>
                        <Form.Item name="name" label="Name">
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name="website" label="Website">
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item name="iconUrl" label="Icon">
                            <Input></Input>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Button
                            style={{ float: 'right', marginLeft: '10px' }}
                            loading={isUpdating}
                            htmlType="submit"
                            type="primary"
                        >
                            Update
                        </Button>
                        <Button
                            style={{ float: 'right' }}
                            loading={isUpdating}
                            onClick={() => {
                                deleteSource({
                                    variables: {
                                        id: source.id,
                                    },
                                })
                                    .then(() => {
                                        message.success('Successfully delete source');
                                        onComplete();
                                    })
                                    .catch((v) => {
                                        message.error('Not able to delete');
                                    });
                            }}
                            shape="circle"
                            icon={<DeleteFilled></DeleteFilled>}
                        ></Button>
                    </Col>
                </Row>
                <Divider></Divider>
                <img alt="" src={source.iconUrl}></img>
            </Form>
        </>
    );
};

export const IngredientSourceList = () => {
    const { data } = useIngredientInformationSources();
    const [selectedId, setSelectedId] = useQueryParam<string | null | undefined>('selected', StringParam);
    const [creatingNew, setCreatingNew] = useQueryParam<boolean | null | undefined>('creatingNew', BooleanParam);
    const [createNewSource] = useCreateIngredientInformationSources();
    const selectedSource: IngredientInformationSource | undefined = data?.sources.data.find((v) => v.id === selectedId);

    const columns: ColumnsType<IngredientInformationSource> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Logo',
            dataIndex: 'iconUrl',
            key: 'iconUrl',
            render(src: string) {
                return <img alt="" style={{ maxWidth: '64px' }} src={src}></img>;
            },
        },
        {
            title: 'Website',
            dataIndex: 'website',
            key: 'website',
            render(src: string) {
                return <a href={src}>Click here</a>;
            },
        },
    ];

    return (
        <>
            <AddNewSourceModal
                visible={creatingNew === true}
                onCancel={() => setCreatingNew(false)}
                onSuccess={(form) => {
                    createNewSource({ variables: { values: form } }).then((v) => {
                        message.success('Create new source');
                        setCreatingNew(false);
                    });
                }}
            ></AddNewSourceModal>
            <Drawer
                width="50vw"
                onClose={() => setSelectedId(undefined)}
                title={'Editing ' + selectedSource?.name}
                visible={selectedSource !== undefined}
            >
                {selectedSource && (
                    <UpdateSourceDrawerBody
                        onComplete={() => setSelectedId(undefined)}
                        source={selectedSource}
                    ></UpdateSourceDrawerBody>
                )}
            </Drawer>
            {data?.sources && (
                <Table
                    title={() => (
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button type={'primary'} onClick={() => setCreatingNew(true)}>
                                Add new
                            </Button>
                        </div>
                    )}
                    onRow={(data, index) => {
                        return {
                            style: {
                                cursor: 'pointer',
                            },
                            onClick: () => {
                                setSelectedId(data.id);
                            },
                        };
                    }}
                    columns={columns}
                    dataSource={data?.sources.data}
                ></Table>
            )}
        </>
    );
};
