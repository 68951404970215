import React, { FC, useCallback, useState } from 'react';
import { Button, Card, Col, Divider, message, Modal, Row, Spin, Tooltip, Typography } from 'antd';
import { ApprovedBadge } from '../ApproveBadge';
import { EyeOutlined, WarningOutlined } from '@ant-design/icons';
import { DiffViewer } from '../../DiffViewer';
import { InterpretedIngredientList } from '../../../pages/IngredientsPage/InterpretedIngredientList';
import { LifeStyleTagsInfo } from '../LifeStyleTagsInfo';
import { Product } from '../../../model/product';
import { useInterpretProductIngredients } from '../../../api/ingredients';

interface ProductIngredientsProps {
    nutrientsInformation: Product['nutrientsInformation'];
    id: number;
    isAdmin: boolean;
    isPublic: boolean;
    interpretedIngredients: Product['interpretedIngredients'];
    interpretationIngredientStatement: Product['interpretationIngredientStatement'];
    ingredientStatement: string | undefined;
    interpretedLifestyles: Product['interpretedLifestyles'];
}
const ProductIngredients: FC<ProductIngredientsProps> = (props) => {
    const {
        nutrientsInformation,
        isAdmin,
        isPublic,
        id,
        interpretedIngredients,
        ingredientStatement,
        interpretationIngredientStatement,
        interpretedLifestyles,
    } = props;

    const [interpretIngredients, { loading: interpreting }] = useInterpretProductIngredients();
    const interpret = useCallback(
        (newIngredientsStatement?: string) => {
            interpretIngredients({
                variables: {
                    id,
                    ingredientStatement: newIngredientsStatement,
                },
            }).catch((err) => {
                return message.error(String(err));
            });
        },
        [interpretIngredients, id]
    );

    const updateIngredientsString = useCallback(
        (newIngredientsStatement: string) => {
            interpret(newIngredientsStatement);
        },
        [interpret]
    );

    const restore = useCallback(() => {
        interpret();
    }, [interpret]);

    const ingredientsListEditable = !interpretedIngredients?.approval?.ok && isAdmin;
    const ingredientsListOverwritten =
        !!interpretationIngredientStatement && interpretationIngredientStatement !== ingredientStatement;

    const [showOriginalStatement, setShowOriginalStatement] = useState(false);
    return (
        <Row gutter={[20, 20]}>
            {nutrientsInformation && nutrientsInformation.nutrientStatements.length > 0 && (
                <Col span={24}>
                    <Card title="Nutrient statements">
                        {nutrientsInformation.nutrientStatements.map((statement, index) => (
                            <p key={index}>{statement}</p>
                        ))}
                    </Card>
                </Col>
            )}

            <Col span={24}>
                <ApprovedBadge editable={isAdmin} approved={interpretedIngredients?.approval || null} productId={id}>
                    <Card title="Ingredients">
                        <Spin spinning={interpreting}>
                            <Typography.Text
                                editable={
                                    ingredientsListEditable
                                        ? {
                                              onChange: updateIngredientsString,
                                          }
                                        : false
                                }
                            >
                                {isPublic
                                    ? ingredientStatement
                                    : interpretationIngredientStatement || ingredientStatement}
                            </Typography.Text>
                            {ingredientsListOverwritten && !isPublic && (
                                <>
                                    <Tooltip title={'View original ingredient statement'}>
                                        <EyeOutlined
                                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                                            onClick={() => setShowOriginalStatement(true)}
                                        />
                                        <Modal
                                            width={'80%'}
                                            title={'Original ingredient statement'}
                                            visible={showOriginalStatement}
                                            onCancel={() => setShowOriginalStatement(false)}
                                            footer={null}
                                        >
                                            <DiffViewer
                                                leftTitle={'Original'}
                                                rightTitle={'Overwritten'}
                                                oldValue={ingredientStatement || ''}
                                                newValue={interpretationIngredientStatement || ''}
                                                splitView={true}
                                            />
                                        </Modal>
                                    </Tooltip>
                                    <Tooltip title={'Ingredients list overwritten. Restore?'}>
                                        <Button
                                            icon={<WarningOutlined />}
                                            type={'link'}
                                            style={{
                                                color: '#ffce00',
                                            }}
                                            loading={interpreting}
                                            onClick={restore}
                                            disabled={!ingredientsListEditable}
                                        >
                                            <span style={{ color: '#FF9900' }}>
                                                {ingredientsListEditable ? 'Restore' : 'Ingredients list overwritten'}
                                            </span>
                                        </Button>
                                    </Tooltip>
                                </>
                            )}
                        </Spin>
                        {interpretedIngredients && (
                            <>
                                <Divider />
                                <Typography.Title level={5}>
                                    {isAdmin && (
                                        <>
                                            Interpreted score ({Math.round(interpretedIngredients.average * 100)}
                                            /100)
                                        </>
                                    )}
                                    {!isAdmin && <>Interpreted ingredients</>}
                                </Typography.Title>
                                <div
                                    style={{
                                        maxHeight: '50vh',
                                        marginTop: '20px',
                                        overflowY: 'scroll',
                                    }}
                                >
                                    <InterpretedIngredientList
                                        isExpandable={isAdmin}
                                        originalIngredients={ingredientStatement || ''}
                                        ingredients={interpretedIngredients.data}
                                        productId={id}
                                        disabled={interpretedIngredients.approval?.ok === true || !isAdmin}
                                    />
                                    {interpretedIngredients.message && (
                                        <>
                                            <Divider />
                                            <p>
                                                <b>Product message: </b>
                                                {interpretedIngredients.message}
                                            </p>
                                        </>
                                    )}
                                    <Divider />
                                    <LifeStyleTagsInfo interpretedLifestyles={interpretedLifestyles} />
                                </div>
                            </>
                        )}
                    </Card>
                </ApprovedBadge>
            </Col>
        </Row>
    );
};

export default ProductIngredients;
