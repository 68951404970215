import { useState } from 'react';
import { Card, Col, Divider, Row, Statistic } from 'antd';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useStats } from '../../api/stats';
import moment from 'moment';
import { ProductList } from '../../components/ProductList';
import Barcode from 'react-barcode';
import { useIngredientStats } from 'src/api/ingredients';

const MainPage = () => {
    const fetchSize = 25;
    const [hasMore, setHasMore] = useState(true);
    const { data, loading, fetchMore } = useStats({ variables: { offset: 0, limit: fetchSize } });
    const { data: ingredientsStats } = useIngredientStats({ variables: { limit: 0, maxJaccard: 0 } });

    const onLoadMore = () => {
        const currentTotal = data?.stats.lastUpdatedProducts?.length;

        fetchMore({
            variables: {
                offset: currentTotal,
                limit: fetchSize,
            },
        }).then((v) => {
            setHasMore(v.data!.stats.lastUpdatedProducts!.length !== 0); // FIXME: undefined
        });
    };

    return (
        <div>
            <Breadcrumb />
            <Card title="Statistics" loading={loading}>
                <Row gutter={[20, 20]}>
                    <Col lg={6} md={24}>
                        <Statistic
                            title="Total ingredients parsed"
                            value={ingredientsStats?.stats.globalParsedProductsCount}
                        />
                    </Col>
                    <Col lg={6} md={24}>
                        <Statistic
                            title="Number of products"
                            value={data?.stats.totalNumberOfProductsWithConsumerUnit}
                        />
                    </Col>
                    <Col lg={6} md={24}>
                        <Statistic
                            title="Number of products with ingredients"
                            value={data?.stats.totalNumberOfProductsWithIngredientStatements}
                        />
                    </Col>

                    <Col lg={6} md={24}>
                        <Statistic
                            title="Number of products updated today"
                            value={data?.stats.totalNumberOfProductsUpdatedToday}
                        />
                    </Col>

                    <Col lg={6} md={24}>
                        <Statistic
                            title="Number of merged products"
                            value={data?.stats.totalNumberOfProductsFromFoodFactsGLN}
                        />
                    </Col>
                </Row>
                <Divider></Divider>
                <Row gutter={[20, 20]}>
                    <Col span={24}>
                        <p style={{ color: 'rgba(0, 0, 0, 0.45)' }}>Last updated products (non-alcoholic)</p>
                        {data?.stats.lastUpdatedProducts && (
                            <ProductList
                                hasMore={hasMore}
                                loadMore={onLoadMore}
                                actions={(p) => {
                                    const lastUpdated = moment(p.modifiedDateAndTime);

                                    return [
                                        <>
                                            {p.modifiedDateAndTime && (
                                                <>{lastUpdated.format('YYYY-MM-DD HH:mm') || ''}</>
                                            )}
                                        </>,
                                        <>{p.informationProvider.name}</>,
                                    ];
                                }}
                                content={(p) => {
                                    return (
                                        <Row gutter={[30, 0]} style={{ width: '50%' }} align="middle">
                                            <Col span={24}>
                                                <div
                                                    style={{
                                                        maxWidth: '100%',
                                                        maxHeight: '100%',
                                                        display: 'flex',
                                                    }}
                                                >
                                                    <div style={{ margin: 'auto', display: 'flex' }}>
                                                        <Barcode
                                                            width={1}
                                                            value={p.productIdentifier.externalId}
                                                            height={50}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    );
                                }}
                                loading={loading}
                                products={data?.stats.lastUpdatedProducts}
                            />
                        )}
                    </Col>
                </Row>
            </Card>
        </div>
    );
};

export default MainPage;
