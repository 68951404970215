import React, { FunctionComponent } from 'react';
import { Tags } from '../model/product';
import { Badge, Button, Card, Col, Collapse, Row, Tag as DesignTag, Tooltip, Divider, Tag, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons/lib';
import { useHistory } from 'react-router-dom';
import { buildPath } from '../utils/build-path';
import { ROUTER_PAGES } from '../routes';
import { ProductAllergen } from '../model/ingredients';

const { Panel } = Collapse;

interface MarkingsTags {
    tags: Tags;
    productTags?: { name: string; id: string }[];
    allergens: ProductAllergen[];
    productId: number;
    editable?: boolean;
    onTagDelete?: (tag: any) => void;
}

export const title = (value: string): string => {
    switch (value) {
        case 'FREE_FROM':
            return 'Free from';
        case 'CONTAINS':
            return 'Contains';
        case 'MAY_CONTAIN':
            return 'May contain';
        case 'UNDECLARED':
            return 'Undeclared';
        default:
            return value;
    }
};

export const getColor = (value: string): string => {
    switch (value) {
        case 'CONTAINS':
            return 'red';
        case 'FREE_FROM':
            return 'green';
        case 'MAY_CONTAIN':
            return 'orange';
        case 'UNDECLARED':
            return 'black';
    }
    return 'black';
};

interface AllergenInformationCardProps {
    allergens?: ProductAllergen[];
}

const AllergenInformationCard: FunctionComponent<AllergenInformationCardProps> = ({ allergens }) => {
    const getAllergens = (list: ProductAllergen[]): JSX.Element[] => {
        return list.map((allergen, index) => {
            const getColor = (): string => {
                switch (allergen.allergenLevel) {
                    case 'CONTAINS':
                        return 'red';
                    case 'FREE_FROM':
                        return 'green';
                    case 'MAY_CONTAIN':
                        return 'orange';
                    case 'UNDECLARED':
                        return 'black';
                }
                return 'black';
            };

            const color = getColor();
            return (
                <Tooltip
                    title={
                        <>
                            {'Reason for allergen : ' + title(allergen.reason)}
                            <br />
                            {'Allergen level : ' + allergen.allergenLevel}
                        </>
                    }
                    color={color}
                    key={allergen.allergen + '_tooltip_' + index}
                >
                    <Tag style={{ marginTop: '4px' }} color={color} key={allergen + '_tag_' + index}>
                        {allergen.allergen}
                    </Tag>
                </Tooltip>
            );
        });
    };

    return (
        <>
            <Typography.Title level={5}>Allergens</Typography.Title>

            {/* {allergenInformation.statement && <p>{allergenInformation.statement}</p>} */}
            {getAllergens(allergens!) /* FIXME: undefined */}
        </>
    );
};

export const MarkingsHealthRelatedTagsCardContent: FunctionComponent<MarkingsTags> = (props) => {
    const healthRelated = props.tags.healthRelated;

    if (healthRelated.length <= 0) return <></>;

    return (
        <>
            <Typography.Title level={5}>Health related</Typography.Title>
            {/* <Collapse>
                <Panel
                    key="health_related"
                    header={
                        <Row style={{ display: 'flex' }}>
                            <Col span={12}>{'Health related'}</Col>
                            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Badge count={healthRelated.length} />
                            </Col>
                        </Row>
                    }
                    showArrow={true}
                > */}
            {healthRelated.map((tag, index) => (
                <Tooltip
                    title={title(tag.description || tag.name || '')}
                    color={'blue'}
                    key={'allergenTag_tooltip_' + index}
                >
                    <DesignTag
                        onClose={() => {
                            if (props.onTagDelete) {
                                props.onTagDelete(tag);
                            }
                        }}
                        color={'blue'}
                        key={'allergenTag_tag_' + index}
                        closable={props.editable}
                    >
                        {tag.name || tag.tag}
                    </DesignTag>
                </Tooltip>
            ))}
            {/* </Panel>
            </Collapse> */}
        </>
    );
};

export const DynamicTags: FunctionComponent<MarkingsTags> = (props) => {
    const dynamic = props.productTags;

    return (
        <>
            <Typography.Title level={5}>Dynamic tags</Typography.Title>
            {dynamic?.map((tag) => (
                <Tag style={{ marginTop: '4px' }} color={'cyan'} key={tag.id}>
                    {tag.name}
                </Tag>
            ))}
        </>
    );
};

export const MarkingsTagsCard: FunctionComponent<MarkingsTags> = (props) => {
    const history = useHistory();

    return (
        <Card title="Generated tags">
            <MarkingsHealthRelatedTagsCardContent {...props} />
            <Divider />
            <AllergenInformationCard {...props} />
            <Divider />
            <DynamicTags {...props}></DynamicTags>
            {/* <MarkingsAllergenTagsCardContent tags={props.tags} productId={props.productId} editable={false} /> */}
        </Card>
    );
};
