export const placeInList = <T>(originalList: T[], addedItems: T[], offset: number) => {
    const startPosition = offset;
    const endPosition = offset + addedItems.length;
    let output = new Array<T>(...originalList);

    if (endPosition > originalList.length) {
        output = new Array<T>(endPosition);
        for (let index = 0; index < output.length; index++) output[index] = originalList[index];
    }

    for (let index = 0; index < endPosition - startPosition; index++) output[startPosition + index] = addedItems[index];

    return output;
};
