import { FC, FunctionComponent, useContext, useMemo, useState } from 'react';
import {
    Badge,
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    notification,
    Popover,
    Row,
    Select,
    Tag,
    Tooltip,
    Typography,
    Tree,
} from 'antd';
import type { DataNode } from 'antd/es/tree';
import {
    AccreditationTypeCode,
    AllergenInformation,
    FishAndAquacultureInformation,
    MarketingInformation,
    Markings,
    NutrientStatement,
    OriginInformation,
    Packaging,
    Product,
    ProductCategory,
    ProductImage,
    ProductsDates,
    Quantity,
    TypeCode,
} from '../../model/product';
import { MarkingsTagsCard, title } from '../MarkingsTagsCard';
import { Link } from 'react-router-dom';
import { ROUTER_PAGES } from '../../routes';
import { buildPath } from '../../utils/build-path';
import ReactMarkdown from 'react-markdown';
import Barcode from 'react-barcode';
import { Image } from '../Image';
import { htmlDecode } from 'src/utils/decode-html';
import round from 'src/utils/round';
import ProductIngredients from './ProductIngredients';
import VolumeEstimateCard from './VolumeEstimate';
import CarbonEstimateCard from './CarbonEstimate';
import { FormOutlined } from '@ant-design/icons';
import styles from './styles.module.scss';
import { gql, useMutation } from '@apollo/client';
import { ID } from '../../utils/type';
import { NovaScoreCard } from './NovaScore';
import { NutriScoreCard } from './NutriScore';
import { notNullGuard } from 'src/utils/typescript';
import { AuthContext } from '../AuthProvider';
import moment from 'moment';

interface ProductViewerProps {
    data: Product;
    compact?: boolean;
    isAdmin?: boolean;
    isPublic?: boolean; // not everything should be visible to public
}

interface NutrientInformationCardProps {
    statement: NutrientStatement;
}

interface MarketingInformationCardProps {
    marketingInformation?: MarketingInformation;
}

interface MarkingsCardProps {
    markings?: Markings;
}

interface AllergenInformationCardProps {
    allergenInformation?: AllergenInformation;
}

interface ProductHeaderProps {
    product: Product;
    compact: boolean;
}

/**
 * NOVA score is a classification system for food products according to their processing.
 * The NOVA classification system is used in the European Union and in Switzerland.
 * We emulate this data with dynamic tags.
 *
 * So we need to exclude these tags from the list of tags,
 * and show them in a separate section.
 */
function parseNOVATag(name: string): null | 1 | 2 | 3 | 4 {
    const match = name.match(/(NOVA) (\d)/);
    if (match) {
        const n = Number(match[2]);
        if (n === 1 || n === 2 || n === 3 || n === 4) {
            return n;
        } else {
            return null;
        }
    } else {
        return null;
    }
}

const ProductHeader: FunctionComponent<ProductHeaderProps> = ({ product, compact }) => {
    const CompactHeaderView = (): JSX.Element => (
        <>
            {compact && (
                <Row gutter={[20, 0]}>
                    <Col span={12} style={{ fontWeight: 600 }}>
                        Id
                    </Col>
                    <Col span={12}>
                        <Link
                            to={{
                                pathname: buildPath(ROUTER_PAGES.products.path, {
                                    id: product.id + '',
                                }),
                            }}
                        >
                            {product.id}
                        </Link>
                    </Col>
                </Row>
            )}
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    GTIN
                </Col>
                <Col span={12}>{product.productIdentifier.externalId}</Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Information provider
                </Col>
                <Col span={12}>
                    <Tooltip title={'GLN: ' + product.informationProvider.gln}>
                        <span>{product.informationProvider.name}</span>
                    </Tooltip>
                </Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Market
                </Col>
                <Col span={12}>{product.productIdentifier.targetMarket.name}</Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Last updated
                </Col>
                <Col span={12}>
                    {product.modifiedDateAndTime?.toLocaleString() || product.creationDateAndTime.toLocaleString()}
                </Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Category
                </Col>
                <Col span={12}>
                    <Tooltip title={'GPC: ' + product.gpc.code}>
                        <span>{product.gpc.text}</span>
                    </Tooltip>
                </Col>
            </Row>
            {compact && (
                <Row gutter={[20, 0]}>
                    <Col span={12} style={{ fontWeight: 600 }}>
                        Name
                    </Col>
                    <Col span={12}>{product.name}</Col>
                </Row>
            )}
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Brand name
                </Col>
                <Col span={12}>{product.brandName}</Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Pack size
                </Col>
                <Col span={12}>{product.packageInformation?.measurement?.size ?? '<NULL>'}</Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Consumer unit
                </Col>
                <Col span={12}>{product.isConsumerUnit ? 'Yes' : 'No'}</Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Last Dynamic Tag Calculation
                </Col>
                <Col span={12}>
                    {' '}
                    {product.lastTagCalc ? (
                        `${moment(product.lastTagCalc).format('YYYY-MM-DD')} (${moment(product.lastTagCalc).fromNow()})`
                    ) : (
                        <span style={{ color: 'green' }}>In queue for calculation</span>
                    )}
                </Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={12} style={{ fontWeight: 600 }}>
                    Net Content
                </Col>
                <Col span={12}>
                    {product.packageInformation?.measurement?.netContents?.[0]?.value ?? '<NULL>'}{' '}
                    {product.packageInformation?.measurement?.netContents?.[0]?.measurementUnitCode?.code ?? '<NULL>'}
                </Col>
            </Row>
        </>
    );

    const NonCompactHeaderView = (): JSX.Element => (
        <>
            <Row gutter={[20, 0]} style={{ fontWeight: 600 }}>
                <Col span={6}>GTIN</Col>
                <Col span={6}>Information provider</Col>
                <Col span={6}>Market</Col>
                <Col span={6}>Last updated</Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col span={6}>
                    <Popover placement={'left'} content={<Barcode value={product.productIdentifier.externalId} />}>
                        <span>{product.productIdentifier.externalId}</span>
                    </Popover>
                </Col>
                <Col span={6}>
                    <Tooltip title={'GLN: ' + product.informationProvider.gln}>
                        <span>{product.informationProvider.name}</span>
                    </Tooltip>
                </Col>
                <Col span={6}>{product.productIdentifier.targetMarket.name}</Col>
                <Col span={6}>
                    {product.modifiedDateAndTime?.toLocaleString() || product.creationDateAndTime.toLocaleString()}
                </Col>
            </Row>

            <Row gutter={[20, 0]} style={{ fontWeight: 600 }}>
                <Col span={6}>Category</Col>
                <Col span={6}>Brand name</Col>
                <Col span={6}>Pack size</Col>
                <Col span={6}>Consumer unit</Col>
            </Row>

            <Row gutter={[20, 0]}>
                <Col span={6}>
                    <Tooltip title={'GPC: ' + product.gpc.code}>
                        <span>{product.gpc.text}</span>
                    </Tooltip>
                </Col>
                <Col span={6}>{product.brandName}</Col>
                <Col span={6}>{product.packageInformation?.measurement?.size ?? '<NULL>'}</Col>
                <Col span={6}>{product.isConsumerUnit ? 'Yes' : 'No'}</Col>
            </Row>

            <Row gutter={[20, 0]} style={{ fontWeight: 600 }}>
                <Col span={6}>Last Dynamic Tag Calculation</Col>
                <Col span={6}>Net Content</Col>
            </Row>
            <Row gutter={[20, 0]}>
                <Col span={6}>
                    {product.lastTagCalc ? (
                        `${moment(product.lastTagCalc).format('YYYY-MM-DD')} (${moment(product.lastTagCalc).fromNow()})`
                    ) : (
                        <span style={{ color: 'green' }}>In queue for calculation</span>
                    )}
                </Col>
                <Col span={6}>
                    {product.packageInformation?.measurement?.netContents?.[0]?.value ?? '<NULL>'}{' '}
                    {product.packageInformation?.measurement?.netContents?.[0]?.measurementUnitCode?.code ?? '<NULL>'}
                </Col>
            </Row>
        </>
    );

    if (compact) {
        return (
            <Card style={{ backgroundColor: '#f8f8f8' }}>
                <CompactHeaderView />
            </Card>
        );
    }

    return (
        <Card style={{ backgroundColor: '#f8f8f8' }}>
            <div className={'disableOnSmallScreen'}>
                <NonCompactHeaderView />
            </div>

            <div className={'enableOnSmallScreen'}>
                <CompactHeaderView />
            </div>
        </Card>
    );
};

export const NutrientInformationCard: FunctionComponent<NutrientInformationCardProps> = ({ statement }) => {
    return (
        <>
            <Row gutter={[20, 20]} style={{ fontWeight: 600, backgroundColor: '#f8f8f8' }}>
                <Col span={12} style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                    Näringsinformation
                </Col>
                <Col span={8}>
                    {statement.nutrientBasisQuantityTypeCode?.name} {statement.nutrientBasisQuantity?.value}{' '}
                    {statement.nutrientBasisQuantity?.measurementUnitCode?.name}
                </Col>
                <Col span={2}>DRI (%)</Col>
            </Row>
            {statement.nutrients.map((nutrient, index) => {
                return (
                    <Row
                        gutter={[20, 20]}
                        style={{ backgroundColor: index % 2 === 0 ? 'transparent' : '#f8f8f8' }}
                        key={index}
                    >
                        <Col span={12}>
                            {nutrient.typeCode.name} <br />
                            <span style={{ marginLeft: '1rem', fontStyle: 'italic' }}>
                                {nutrient.measurementPrecisionCode!.name /* FIXME: undefined */}
                            </span>
                        </Col>
                        <Col span={8}>
                            <br />
                            {nutrient.quantityContained[0].value}{' '}
                            {nutrient.quantityContained[0].measurementUnitCode.name}
                        </Col>
                        <Col span={2}>
                            <br />
                            {nutrient.dailyValueIntakePercent && !Number.isInteger(nutrient.dailyValueIntakePercent)
                                ? round(nutrient.dailyValueIntakePercent, 4)
                                : nutrient.dailyValueIntakePercent}
                        </Col>
                    </Row>
                );
            })}
        </>
    );
};

export const ProductDateCard: FunctionComponent<{ productDates: ProductsDates }> = ({ productDates }) => {
    const BuildDate = (title: string, value?: Date) => {
        if (!value) return undefined;

        return (
            <>
                <Col span={12} style={{ fontWeight: 600 }}>
                    {title}
                </Col>
                <Col span={12}>{value.toLocaleString()}</Col>
            </>
        );
    };

    return (
        <Card title="Product dates">
            <Row gutter={[20, 20]}>
                {BuildDate('Launch date', productDates.launchDateAndTime)}
                {BuildDate('Available from date', productDates.availableFromDateAndTime)}
                {BuildDate('Delivery date', productDates.deliveryDateAndTime)}
                {BuildDate('Available to date', productDates.availableToDateAndTime)}
            </Row>
        </Card>
    );
};

interface FishAndAquaCultureCardProps {
    fishAndAquacultureInformation?: FishAndAquacultureInformation;
}

const FishAndAquaCultureCard: FunctionComponent<FishAndAquaCultureCardProps> = ({ fishAndAquacultureInformation }) => {
    const [showCode, setShowCode] = useState(false);

    return (
        <Card
            title="Fish and aquaculture"
            extra={
                <Button size="small" onClick={() => setShowCode(!showCode)}>
                    {showCode ? 'Hide' : 'Show'} codes
                </Button>
            }
        >
            {fishAndAquacultureInformation?.speciesCode?.latinName && (
                <Row gutter={[20, 20]}>
                    <Col span={12}>The scientific name of the species</Col>
                    <Col span={12}>
                        {
                            /* FIXME: undefined */ fishAndAquacultureInformation!.speciesCode.englishName ? (
                                <Tooltip
                                    title={
                                        /* FIXME: undefined */ fishAndAquacultureInformation!.speciesCode.englishName
                                    }
                                >
                                    <span>
                                        {/* FIXME: undefined */ fishAndAquacultureInformation!.speciesCode.latinName}
                                    </span>
                                </Tooltip>
                            ) : (
                                <span>
                                    {/* FIXME: undefined */ fishAndAquacultureInformation!.speciesCode.latinName}
                                </span>
                            )
                        }{' '}
                        {showCode ? (
                            <Typography.Text code>
                                {/* FIXME: undefined */ fishAndAquacultureInformation!.speciesCode.code}
                            </Typography.Text>
                        ) : null}
                    </Col>
                </Row>
            )}
            {
                /* FIXME: undefined */ fishAndAquacultureInformation!.storageState && (
                    <Row gutter={[20, 20]}>
                        <Col span={12}>Storage status</Col>
                        <Col span={12}>
                            {/* FIXME: undefined */ fishAndAquacultureInformation!.storageState?.name}{' '}
                            {showCode ? (
                                <Typography.Text code>
                                    {/* FIXME: undefined */ fishAndAquacultureInformation!.storageState?.code}
                                </Typography.Text>
                            ) : null}
                        </Col>
                    </Row>
                )
            }
            {
                /* FIXME: undefined */ fishAndAquacultureInformation!.catchMethods.map((catchMethod, index) => (
                    <Row gutter={[20, 20]} key={'catchMethod_' + index}>
                        <Col span={12}>{index === 0 ? 'Catch methods' : null}</Col>
                        <Col span={12}>
                            {catchMethod.name}{' '}
                            {showCode ? <Typography.Text code>{catchMethod.code}</Typography.Text> : null}
                        </Col>
                    </Row>
                ))
            }
        </Card>
    );
};

const MarketingInformationCard: FunctionComponent<MarketingInformationCardProps> = ({ marketingInformation }) => {
    return (
        <Card title="Market information">
            {marketingInformation?.marketingMessages.map((marketingMessage, index) => {
                return (
                    <Row gutter={[20, 20]} key={index}>
                        <Col span={24}>
                            <p>{marketingMessage}</p>
                        </Col>
                    </Row>
                );
            })}
        </Card>
    );
};

const MarkingsCard: FunctionComponent<MarkingsCardProps> = ({ markings }) => {
    const InfoBody = (accreditationCode: AccreditationTypeCode): JSX.Element => {
        return (
            <div style={{ width: '250px' }}>
                <Typography.Paragraph>{accreditationCode?.info?.longDescription}</Typography.Paragraph>
                {accreditationCode.info?.logo?.url && (
                    <div style={{ width: '100%', display: 'flex' }}>
                        <img
                            alt="icon"
                            style={{ width: '120px', margin: 'auto' }}
                            src={accreditationCode.info?.logo?.url}
                        />
                    </div>
                )}
            </div>
        );
    };

    return (
        <>
            {
                /* FIXME: undefined */ markings!.accreditations?.providedAccreditationCodes?.length > 0 && (
                    <>
                        <Badge.Ribbon text="Information from us" color="blue">
                            <Card title="Provided tags">
                                {
                                    /* FIXME: undefined */ markings!.accreditations?.providedAccreditationCodes?.map(
                                        (accreditationCode, index) => {
                                            return (
                                                <Popover
                                                    content={() => InfoBody(accreditationCode)}
                                                    trigger="hover"
                                                    placement="bottomRight"
                                                    title={
                                                        <Typography.Title level={3}>
                                                            {accreditationCode?.info?.name}
                                                        </Typography.Title>
                                                    }
                                                    key={'accreditationCode_tooltip_' + index}
                                                >
                                                    <Tag color="blue">
                                                        {accreditationCode?.info?.name || accreditationCode.code}
                                                    </Tag>
                                                </Popover>
                                            );
                                        }
                                    )
                                }
                            </Card>
                        </Badge.Ribbon>

                        <Divider />
                    </>
                )
            }
            <Card title="Tags">
                {
                    /* FIXME: undefined */ markings!.accreditations.accreditationCodes.map(
                        (accreditationCode, index) => {
                            return (
                                <Popover
                                    content={() => InfoBody(accreditationCode)}
                                    trigger="hover"
                                    placement="bottomRight"
                                    title={
                                        <Typography.Title level={3}>{accreditationCode?.info?.name}</Typography.Title>
                                    }
                                    key={'accreditationCode_tooltip_' + index}
                                >
                                    <Tag color="blue" key={'accreditationCode_tag_' + index}>
                                        {accreditationCode.name || accreditationCode.code}
                                    </Tag>
                                </Popover>
                            );
                        }
                    )
                }

                {
                    /* FIXME: undefined */ markings!.healthRelated.allergenCodes.map((allergenCode, index) => {
                        return (
                            <Tooltip
                                title="Health-related allergy or diet labeling"
                                color="red"
                                key={'allergenCode_tooltip_' + index}
                            >
                                <Tag color="red" key={'allergenCode_tag_' + index}>
                                    {allergenCode.name || allergenCode.code}
                                </Tag>
                            </Tooltip>
                        );
                    })
                }

                {
                    /* FIXME: undefined */ markings!.healthRelated.freeFromCodes.map((freeFromCode, index) => {
                        return (
                            <Tooltip
                                title="Health-related free from labeling"
                                color="green"
                                key={'freeFrom_tooltip_' + index}
                            >
                                <Tag color="green" key={'freeFrom_tag_' + index}>
                                    {freeFromCode.name || freeFromCode.code}
                                </Tag>
                            </Tooltip>
                        );
                    })
                }

                {
                    /* FIXME: undefined */ markings!.healthRelated.dietCodes
                        .concat(/* FIXME: undefined */ markings!.healthRelated.dietSubCategoryCodes)
                        .map((dietCode, index) => {
                            return (
                                <Tooltip title="Diet labeling" color="orange" key={'dietCode_tooltip_' + index}>
                                    <Tag color="orange" key={'dietCode_tag_' + index}>
                                        {dietCode.name || dietCode.code}
                                    </Tag>
                                </Tooltip>
                            );
                        })
                }
            </Card>
        </>
    );
};

const AllergenInformationCard: FunctionComponent<AllergenInformationCardProps> = ({ allergenInformation }) => {
    const getAllergens = (allergenInformation: AllergenInformation): JSX.Element[] => {
        return /* FIXME: undefined */ allergenInformation.allergens!.map((allergen, index) => {
            const getColor = (): string => {
                switch (allergen.containmentCode.code) {
                    case 'CONTAINS':
                        return 'red';
                    case 'FREE_FROM':
                        return 'green';
                    case 'MAY_CONTAIN':
                        return 'orange';
                    case 'UNDECLARED':
                        return 'black';
                }
                return 'black';
            };

            const color = getColor();
            return (
                <Tooltip
                    title={title(allergen.containmentCode.code) || allergen.containmentCode.description}
                    color={color}
                    key={allergen.typeCode.code + '_tooltip_' + index}
                >
                    <Tag color={color} key={allergen.typeCode.code + '_tag_' + index}>
                        {allergen.typeCode.name || allergen.typeCode.code}
                    </Tag>
                </Tooltip>
            );
        });
    };

    return (
        <Card title="Allergens">
            {/* FIXME: undefined */ allergenInformation!.statement && <p>{allergenInformation!.statement}</p>}
            {getAllergens(allergenInformation!)}
        </Card>
    );
};

const buildImages = (image: ProductImage): JSX.Element => {
    // const allowedMimes = ['image/png', 'image/jpeg', 'image/svg+xml'];
    return (
        <Row gutter={[20, 20]} justify={'center'}>
            <Col>
                <Card
                    cover={
                        <Badge.Ribbon
                            text={
                                <a
                                    href={image.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ color: 'white' }}
                                >
                                    Download
                                </a>
                            }
                        >
                            <div style={{ display: 'flex', width: '40vh', height: '40vh' }}>
                                <Image
                                    src={image.url}
                                    style={{ maxWidth: '40vh', maxHeight: '40vh', margin: 'auto' }}
                                    alt={image.url}
                                />
                            </div>
                        </Badge.Ribbon>
                    }
                    style={{
                        width: '40vh',
                        height: '40vh',
                    }}
                />
            </Col>
        </Row>
    );
};

const hasMarketingMessages = (marketingInformation?: MarketingInformation): boolean | undefined =>
    marketingInformation && marketingInformation.marketingMessages.length > 0;

const hasOriginInformation = (originInformation?: OriginInformation): boolean | undefined =>
    originInformation &&
    [
        originInformation.activityArea,
        originInformation.activityType,
        originInformation.originDeclaration,
        originInformation.originArea,
        originInformation.country,
    ].filter((prop) => prop !== null).length > 0;

export const buildCategories = (cagories: ProductCategory[]): JSX.Element => {
    const InfoPanel = (category: ProductCategory): JSX.Element => (
        <Row style={{ width: '30vw' }}>
            <Col span={24}>
                {
                    <>
                        <ReactMarkdown children={category?.rule} />
                    </>
                }
            </Col>
        </Row>
    );

    return (
        <div style={{ display: 'flex' }}>
            {cagories.map((v) => (
                <Popover
                    title={<Typography.Title level={3}>{v.marking.name}</Typography.Title>}
                    content={() => InfoPanel(v)}
                >
                    <img
                        alt="Not able get logo"
                        style={{ margin: 'auto', maxHeight: '50px', maxWidth: '50px' }}
                        src={v?.marking?.logo?.url}
                    />
                </Popover>
            ))}
        </div>
    );
};

const SuggestAnEditForm: FC<{ productId: ID; onSuccess: () => void }> = ({ productId, onSuccess }) => {
    const [sendReport, { loading }] = useMutation<
        unknown,
        { values: { message: string; productId: ID; userName: string } }
    >(
        gql`
            mutation SuggestAnEditForm($values: ProductReportInput!) {
                createProductReport(values: $values) {
                    id
                }
            }
        `,
        {
            onError: (error) => {
                notification.error({
                    message: error.message,
                });
            },
            onCompleted: () => {
                notification.success({
                    message: 'Thank you for providing us with valuable feedback',
                });
                onSuccess();
            },
        }
    );
    return (
        <Form
            layout={'vertical'}
            onFinish={(values: { name: string; message: string }) => {
                sendReport({
                    variables: {
                        values: {
                            userName: values.name,
                            message: values.name,
                            productId,
                        },
                    },
                }).catch((e) => console.error(e));
            }}
        >
            <Typography.Paragraph>
                If you have noted any potential inaccuracy, please fill in your comments and provide as much details as
                possible. We will investigate it further. Thank you for helping us improve our data quality!
            </Typography.Paragraph>
            <Form.Item label={'Your name'} name={'name'} required={true}>
                <Input />
            </Form.Item>
            <Form.Item label={'Comment'} name={'message'} required={true}>
                <Input.TextArea />
            </Form.Item>
            <Button htmlType={'submit'} type={'primary'} loading={loading}>
                Submit
            </Button>
        </Form>
    );
};

const formattedQuantity = (quantity: Quantity[] | undefined | null): string | undefined => {
    if (!quantity || !(quantity.length > 0 && !!quantity[0].value && !!quantity[0]?.measurementUnitCode.name))
        return undefined;
    return quantity[0].value + ' ' + quantity[0].measurementUnitCode.name;
};

const nameOrCode = (typeCode: TypeCode | undefined | null): string | undefined => {
    if (!typeCode || !(!!typeCode?.name || !!typeCode?.code)) return undefined;
    return typeCode.name ? typeCode.name : typeCode.code;
};

export const PackagingCard: FC<{ packaging: Packaging[] }> = ({ packaging }) => {
    const treeData: DataNode[] = packaging
        .filter((v) => nameOrCode(v.packagingType) !== undefined)
        .map((v, i) => ({
            key: `packaging-${i}`,
            title:
                nameOrCode(v.packagingType) +
                (formattedQuantity(v.packagingWeight) !== undefined
                    ? ` (${formattedQuantity(v.packagingWeight)})`
                    : ''),
            selectable: false,
            checkable: false,
            children: v.material
                ?.filter((v) => nameOrCode(v.packagingMaterialCode) !== undefined)
                .map((v, j) => ({
                    key: `packaging-${i}-${j}`,
                    title:
                        nameOrCode(v.packagingMaterialCode) +
                        (formattedQuantity(v.weight) ? ` (${formattedQuantity(v.weight)})` : ''),
                    selectable: false,
                    children: v.rawMaterial
                        ?.filter((v) => nameOrCode(v.rawMaterialCode) !== undefined)
                        .map((v, k) => ({
                            key: `packaging-${i}-${j}-${k}`,
                            title:
                                nameOrCode(v.rawMaterialCode) +
                                (v.rawMaterialPercentage ? ` (${v.rawMaterialPercentage.toString()}%)` : ''),

                            selectable: false,
                        })),
                })),
        }));

    return (
        <Card title={'Packaging'}>
            <Tree treeData={treeData} defaultExpandAll={true} blockNode />
        </Card>
    );
};

export const ProductViewer: FC<ProductViewerProps> = (props) => {
    const { isAdmin = true, isPublic = false } = props;
    const [selectedNutrientBasisIndex, setSelectedNutrientBasisIndex] = useState<number>(0);

    const user = useContext(AuthContext);

    const hasMarkings = (product: Product): boolean => {
        if (!product.markings) {
            return false;
        }

        return (
            product.markings.healthRelated.freeFromCodes
                .concat(product.markings.healthRelated.allergenCodes)
                .concat(product.markings.healthRelated.dietSubCategoryCodes)
                .concat(product.markings.healthRelated.dietCodes)
                .concat(product.markings.accreditations.providedAccreditationCodes)
                .concat(product.markings.accreditations.accreditationCodes).length > 0
        );
    };

    const hasAllergens = (product: Product): boolean => {
        return !!(
            product.allergenInformation &&
            ((product.allergenInformation.statement && product.allergenInformation.statement.length > 0) ||
                (product.allergenInformation.allergens && product.allergenInformation.allergens.length > 0))
        );
    };

    const hasProductDates = Object.values(props.data.productDates).filter((v) => !!v).length > 1;

    const [suggestEnEditVisible, setSuggestAnEditVisible] = useState(false);

    const novaScoreTags = useMemo(() => {
        return props?.data?.productTags
            ? props?.data?.productTags?.map((tag) => parseNOVATag(tag.name)).filter(notNullGuard)
            : [];
    }, [props.data.productTags]);
    const novaScore = novaScoreTags.length > 0 ? (Math.max(...novaScoreTags) as 1 | 2 | 3 | 4) : null;
    return (
        <div>
            <Tooltip title={'Suggest an edit'} trigger={['focus', 'hover']}>
                <Button
                    className={styles.suggestEditButton}
                    icon={<FormOutlined tabIndex={0} />}
                    type={'text'}
                    onClick={() => setSuggestAnEditVisible(true)}
                />
            </Tooltip>
            <Badge
                count={
                    props.data.productCategory && props.data.productCategory.length > 0
                        ? buildCategories(props.data.productCategory!)
                        : null
                }
            >
                <div style={{ paddingRight: '30px' }}>
                    <Row align="middle" style={{ paddingTop: '20px' }}>
                        <Col>
                            <Typography.Title style={props.compact ? { display: 'none' } : undefined} level={1}>
                                {htmlDecode(props.data.name)}
                                <Modal
                                    destroyOnClose={true}
                                    visible={suggestEnEditVisible}
                                    onCancel={() => setSuggestAnEditVisible(false)}
                                    footer={null}
                                    title={'Suggest an edit'}
                                >
                                    <SuggestAnEditForm
                                        onSuccess={() => setSuggestAnEditVisible(false)}
                                        productId={props.data.id}
                                    />
                                </Modal>
                            </Typography.Title>
                        </Col>
                    </Row>
                </div>
            </Badge>

            <Row gutter={[20, 20]} style={{ paddingTop: '20px' }}>
                <Col span={24}>{props.data?.productImage && buildImages(props.data?.productImage)}</Col>
            </Row>

            <Row gutter={[20, 20]} style={{ paddingTop: '20px' }}>
                <Col span={24}>
                    <ProductHeader product={props.data} compact={props.compact as any /* FIXME: undefined */} />
                </Col>
            </Row>

            <Row gutter={[20, 20]} style={{ paddingTop: '20px' }}>
                <Col sm={24} lg={props.compact || props?.data.nutrientsInformation!.content.length === 0 ? 24 : 12}>
                    <Row gutter={[20, 20]}>
                        {props.data.nutrientsInformation?.nutriScore && (
                            <Col span={24}>
                                <NutriScoreCard
                                    nutriScore={props.data.nutrientsInformation?.nutriScore}
                                    detailedNutriScore={props.data.nutrientsInformation.detailedNutriScore}
                                    productId={props.data.id}
                                />
                            </Col>
                        )}

                        {novaScore && (
                            <Col span={24}>
                                <NovaScoreCard novaScore={novaScore} />
                            </Col>
                        )}
                        {props.data.id && user?.userGroup.permissions.carbonService.read && (
                            <Col span={24}>
                                <CarbonEstimateCard productId={props.data.id}></CarbonEstimateCard>
                            </Col>
                        )}
                        {(props.data.nutrientsInformation as any) /* FIXME: undefined */.content.length > 0 && (
                            <Col span={24}>
                                <Card
                                    title="Nutritional information"
                                    extra={
                                        <>
                                            <Select
                                                key={1}
                                                style={{ minWidth: '250px' }}
                                                defaultValue={selectedNutrientBasisIndex}
                                                onSelect={(index: number) => setSelectedNutrientBasisIndex(index)}
                                            >
                                                {
                                                    /* FIXME: undefined */ props.data.nutrientsInformation!.content.map(
                                                        (statement, index) => (
                                                            <Select.Option key={index} value={index}>
                                                                {`${
                                                                    statement.nutrientBasisQuantityTypeCode?.name ||
                                                                    'Per'
                                                                } ${statement.nutrientBasisQuantity?.value} ${
                                                                    statement.nutrientBasisQuantity?.measurementUnitCode
                                                                        .name
                                                                }`}
                                                            </Select.Option>
                                                        )
                                                    )
                                                }
                                            </Select>
                                        </>
                                    }
                                >
                                    <NutrientInformationCard
                                        statement={
                                            /* FIXME: undefined */ props.data.nutrientsInformation!.content[
                                                selectedNutrientBasisIndex
                                            ]
                                        }
                                    />
                                </Card>
                            </Col>
                        )}
                        {props.data.markings?.tags && props?.data.interpretedAllergens && (
                            <Col span={24}>
                                <MarkingsTagsCard
                                    productTags={props?.data.productTags}
                                    allergens={props?.data.interpretedAllergens.data}
                                    tags={props.data.markings?.tags}
                                    productId={props.data.id}
                                    editable={!props.compact}
                                />
                            </Col>
                        )}
                        <Col span={24}>
                            {hasProductDates && <ProductDateCard productDates={props.data.productDates} />}
                        </Col>
                    </Row>
                </Col>

                <Col
                    xs={24}
                    lg={
                        props.compact || props?.data.nutrientsInformation!.content.length === 0
                            ? 24
                            : 12 /* FIXME: undefined */
                    }
                >
                    <ProductIngredients
                        id={props.data.id}
                        interpretedIngredients={props.data.interpretedIngredients}
                        ingredientStatement={props.data.ingredientInformation?.ingredientStatement}
                        interpretationIngredientStatement={props.data.interpretationIngredientStatement}
                        interpretedLifestyles={props.data.interpretedLifestyles}
                        nutrientsInformation={props.data.nutrientsInformation}
                        isAdmin={isAdmin}
                        isPublic={isPublic}
                    />
                    <Row gutter={[20, 20]} style={{ paddingTop: '20px' }}>
                        {props.data.id && user?.userGroup.permissions.volumeEstimateService.read && (
                            <Col span={24}>
                                <VolumeEstimateCard productId={props.data.id} />
                            </Col>
                        )}
                        {hasMarketingMessages(props.data.marketingInformation) && (
                            <Col span={24}>
                                <MarketingInformationCard marketingInformation={props.data.marketingInformation} />
                            </Col>
                        )}
                        {hasOriginInformation(props.data.originInformation) && (
                            <Col span={24}>
                                <Card title="Origin">
                                    {props.data.originInformation?.country?.name && (
                                        <Row gutter={[20, 20]}>
                                            <Col span={12}>Country</Col>
                                            <Col span={12}>{props.data.originInformation?.country?.name}</Col>
                                        </Row>
                                    )}
                                    {props.data.originInformation?.originArea && (
                                        <Row gutter={[20, 20]}>
                                            <Col span={12}>Area</Col>
                                            <Col span={12}>{props.data.originInformation?.originArea}</Col>
                                        </Row>
                                    )}
                                    {props.data.originInformation?.originDeclaration && (
                                        <Row gutter={[20, 20]}>
                                            <Col span={12}>Declaration</Col>
                                            <Col span={12}>{props.data.originInformation?.originDeclaration}</Col>
                                        </Row>
                                    )}
                                    {props.data.originInformation?.activityArea?.code && (
                                        <Row gutter={[20, 20]}>
                                            <Col span={12}>Activity area</Col>
                                            <Col span={12}>{props.data.originInformation?.activityArea?.name}</Col>
                                        </Row>
                                    )}
                                    {props.data.originInformation?.activityType?.code && (
                                        <Row gutter={[20, 20]}>
                                            <Col span={12}>Activity type</Col>
                                            <Col span={12}>{props.data.originInformation?.activityType?.name}</Col>
                                        </Row>
                                    )}
                                </Card>
                            </Col>
                        )}
                        {props.data.fishAndAquacultureInformation.length > 0 && (
                            <Col span={24}>
                                {props.data.fishAndAquacultureInformation.map(
                                    (fishAndAquacultureInformation, index) => (
                                        <FishAndAquaCultureCard
                                            fishAndAquacultureInformation={fishAndAquacultureInformation}
                                            key={'fishAndAqua' + index}
                                        />
                                    )
                                )}
                            </Col>
                        )}
                        {hasMarkings(props.data) && (
                            <Col span={24}>
                                <MarkingsCard markings={props.data.markings} />
                            </Col>
                        )}
                        {hasAllergens(props.data) && (
                            <Col span={24}>
                                {props.data.interpretedAllergens?.data && (
                                    <AllergenInformationCard allergenInformation={props.data.allergenInformation} />
                                )}
                            </Col>
                        )}
                        {props.data.packageInformation?.packaging &&
                            props.data.packageInformation?.packaging.length > 0 && (
                                <Col span={24}>
                                    <PackagingCard packaging={props.data.packageInformation.packaging} />
                                </Col>
                            )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
