import { gql } from '@apollo/client';
import { TypeCode } from '../model/product';
import { q } from '../utils/utils';

const useTypeCodeSchema = gql`
    query typeCodes($code: String!) {
        typeCodes(code: $code) {
            code
            name
            usageInSweden
        }
    }
`;
export const useTypeCode = q<{ typeCodes: TypeCode[] }, { code: string }>(useTypeCodeSchema);
