import { gql } from '@apollo/client';
import { Subscription } from '../model/subscription';
import { q, m } from '../utils/utils';
import { ID } from '../utils/type';

const useSubscriptionsSchema = gql`
    query subscriptions {
        subscriptions {
            id
            type
            body {
                gtins
                gpcCategoryCode
                lastChangeDateTimeFrom
            }
            createdAt
            modifiedAt
        }
    }
`;
export const useSubscriptions = q<{ subscriptions: Subscription[] }>(useSubscriptionsSchema);

const useSubscriptionSchema = gql`
    query subscription($id: ID!) {
        subscription(id: $id) {
            id
            type
            body {
                gtins
                gpcCategoryCode
                lastChangeDateTimeFrom
            }
            createdAt
            modifiedAt
        }
    }
`;
export const useSubscription = q<{ subscription: Subscription }, { id: ID }>(useSubscriptionSchema);

const useSaveSubscriptionSchema = gql`
    mutation subscriptionSave($subscription: SubscriptionInput!) {
        subscription: subscriptionSave(subscription: $subscription) {
            id
            type
            body {
                gpcCategoryCode
                gtins
                lastChangeDateTimeFrom
            }
            createdAt
            modifiedAt
        }
    }
`;
export const useSaveSubscription = m<{ subscription: Subscription }, { subscription: any }>(useSaveSubscriptionSchema, {
    update(cache, fetchResult) {
        //update the cache
        const { subscriptions } = cache.readQuery<{ subscriptions: Subscription[] }>({
            query: useSubscriptionsSchema,
        })!; /* FIXME: undefined */
        cache.writeQuery({
            query: useSubscriptionsSchema,
            data: { subscriptions: subscriptions.concat([fetchResult.data!.subscription]) } /* FIXME: undefined */,
        });
    },
});

const useDeleteSubscriptionSchema = gql`
    mutation subscriptionDelete($id: ID!) {
        subscriptionDelete(id: $id)
    }
`;
export const useDeleteSubscription = m<{ subscriptionDelete: number }, { id: ID }>(useDeleteSubscriptionSchema, {
    update(cache, fetchResult) {
        //update the cache
        //TODO solve warning...
        const { subscriptions } = cache.readQuery<{ subscriptions: Subscription[] }>({
            query: useSubscriptionsSchema,
        })!;
        cache.writeQuery({
            query: useSubscriptionsSchema,
            data: {
                subscriptions: subscriptions.filter(
                    (subscription) =>
                        subscription.id !== fetchResult.data!.subscriptionDelete + '' /* FIXME: undefined */
                ),
            },
        });
    },
});

const useRerunSubscriptionSchema = gql`
    mutation reRunSubscription($id: ID!, $onlyNew: Boolean!, $lastChangeDateTimeFrom: DateTime) {
        reRunSubscription(id: $id, onlyNew: $onlyNew, lastChangeDateTimeFrom: $lastChangeDateTimeFrom)
    }
`;
export const useRerunSubscription = m<{ reRunSubscription: number }, any>(useRerunSubscriptionSchema);
