import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Auth } from 'aws-amplify';
import { env } from './utils/env';
import { onError } from '@apollo/client/link/error';
import { message } from 'antd';
import produce from 'immer';
import { ProductsDates } from './model/product';
import moment from 'moment';
import { placeInList } from './utils/placeInList';

const httpLink = createHttpLink({
    uri: env.REACT_APP_FEDERATIONS_ENDPOINT + '/graphql',
});

const errorLink = onError(({ graphQLErrors, networkError, forward, operation, response }) => {
    if (graphQLErrors) console.error(graphQLErrors);
    if (networkError) console.error(networkError);

    const schemaErrorsCodes = ['DOWNSTREAM_SERVICE_ERROR'];
    const graphQLErrorsCodes = graphQLErrors?.map((v) => v.extensions?.code) || [];

    if (response) {
        if (graphQLErrorsCodes.findIndex((v) => schemaErrorsCodes.includes(v)) !== -1) {
            // Not valid
            alert('GraphQL error: See logs for more information');
        } else {
            const msg = response?.errors![0]?.message;
            message.warn(msg);
            response.errors = undefined;
        }
    }

    forward(operation);
});

const authLink = setContext(async (_, { headers }) => {
    const sessionIsValid = !!(await Auth.currentUserInfo());
    const cognitoUserSession = sessionIsValid ? await Auth.currentSession() : undefined;
    const pathname = window.location.pathname;
    const isPublicPage = pathname.startsWith('/public');
    const securityHeader: {
        'x-api-key'?: string;
    } = {
        'x-api-key': '#*V#wF8&yn#o*EayQu!8HJVk@zoA$yb$^Tfp1G%MNY3ha%tMbs',
    };
    if (!isPublicPage) {
        delete securityHeader['x-api-key'];
    }
    return {
        headers: {
            ...headers,
            authorization: cognitoUserSession ? `Bearer ${cognitoUserSession.getAccessToken().getJwtToken()}` : '',
            ...securityHeader,
        },
    };
});

export const client = new ApolloClient({
    link: errorLink.concat(authLink.concat(httpLink)),
    defaultOptions: {
        query: {
            errorPolicy: 'ignore',
        },
    },

    cache: new InMemoryCache({
        typePolicies: {
            SiteUser: {
                keyFields: ['userName'],
            },
            PredictedIngredientsStatistics: {
                fields: {
                    missingIngredients: {
                        keyArgs: ['maxJaccard'],

                        merge(existing = [], incoming, { variables }) {
                            const output = placeInList(existing, incoming, variables?.offset || 0);
                            return output;
                        },
                    },
                },
            },
            Query: {
                fields: {
                    getIngredient: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'Ingredient',
                                id: args?.id,
                            });
                        },
                    },
                    getIngredientCategory: {
                        read(_, { args, toReference }) {
                            return toReference({
                                __typename: 'IngredientCategory',
                                id: args?.id,
                            });
                        },
                    },
                    productsSearch: {
                        keyArgs: ['groupId', 'brandNames', 'informationProviderGln'],
                        merge(existing, incoming, { variables }) {
                            if (variables?.offset) {
                                return {
                                    ...incoming,
                                    products: [...(existing?.products || []), ...(incoming?.products || [])],
                                };
                            }

                            return incoming;
                        },
                    },

                    products: {
                        keyArgs: ['filter'],
                        merge(existing, incoming, { variables }) {
                            console.log('incoming', incoming);

                            if (incoming.products) {
                                const products = placeInList(
                                    existing?.products || [],
                                    incoming.products,
                                    variables?.offset || 0
                                );

                                return { ...incoming, products };
                            }

                            return { ...existing, ...incoming };
                        },
                    },

                    getProductsFromIngredients: {
                        keyArgs: ['ingredientId'],
                        merge(existing, incoming, { variables }) {
                            if (variables?.offset) {
                                return {
                                    ...incoming,
                                    data: [...(existing?.data || []), ...(incoming?.data || [])],
                                };
                            }

                            return incoming;
                        },
                    },
                    getIngredients: {
                        keyArgs: ['q'],
                        merge(existing, incoming, { variables }) {
                            return produce(incoming, (draft: any) => {
                                if (existing && !existing?.data) {
                                    existing.data = new Array(existing.totalAmount).fill(null);
                                }

                                if (draft.totalAmount && variables?.offset) {
                                    const arr = incoming.data;
                                    draft.data = new Array(draft.totalAmount).fill(null);
                                    existing?.data.forEach((v: any, i: number) => {
                                        draft.data[i] = v;
                                    });

                                    arr.forEach((v: any, i: number) => {
                                        draft.data[variables.offset + i] = v;
                                    });
                                }
                            });
                        },
                    },
                },
            },
            Product: {
                fields: {
                    creationDateAndTime: {
                        read(creationDateAndTime): Date | null {
                            return creationDateAndTime ? moment(creationDateAndTime).toDate() : null;
                        },
                    },
                    modifiedDateAndTime: {
                        read(modifiedDateAndTime): Date | null {
                            return modifiedDateAndTime ? moment(modifiedDateAndTime).toDate() : null;
                        },
                    },
                    sourceLastChangedDateAndTime: {
                        read(sourceLastChangedDateAndTime): Date | null {
                            return sourceLastChangedDateAndTime ? moment(sourceLastChangedDateAndTime).toDate() : null;
                        },
                    },
                    productDates: {
                        read(data): ProductsDates {
                            const {
                                launchDateAndTime,
                                availableToDateAndTime,
                                deliveryDateAndTime,
                                availableFromDateAndTime,
                            } = data;

                            return {
                                ...data,
                                launchDateAndTime: launchDateAndTime ? moment(launchDateAndTime).toDate() : null,
                                availableToDateAndTime: availableToDateAndTime
                                    ? moment(availableToDateAndTime).toDate()
                                    : null,
                                deliveryDateAndTime: deliveryDateAndTime ? moment(deliveryDateAndTime).toDate() : null,
                                availableFromDateAndTime: availableFromDateAndTime
                                    ? moment(availableFromDateAndTime).toDate()
                                    : null,
                            };
                        },
                    },
                },
            },
            IngredientProductHitResult: {
                fields: {
                    data: {
                        merge(existing, incoming) {
                            return incoming;
                        },
                    },
                },
            },
            IdentifierGroup: {
                fields: {
                    products: {
                        keyArgs: ['id'],
                        merge(existing, incoming, { variables }) {
                            console.log('existing', existing);
                            console.log('incoming', incoming);
                            if (variables?.productOffset) {
                                return {
                                    ...incoming,
                                    products: [...(existing?.products || []), ...(incoming?.products || [])],
                                };
                            }

                            return {
                                ...existing,
                                ...incoming,
                            };
                        },
                    },
                },
            },
            Stats: {
                fields: {
                    lastUpdatedProducts: {
                        keyArgs: false,
                        merge(existing, incoming, { variables }) {
                            if (variables?.offset) {
                                return [...(existing || []), ...(incoming || [])];
                            }

                            return incoming;
                        },
                    },
                },
            },

            Tag: {
                keyFields: ['tag'],
            },
        },
    }),
});
