import { DeleteOutlined, PaperClipOutlined, PlusOutlined, RestOutlined } from '@ant-design/icons';
import { Button, Col, Drawer, Input, Row, Select, Space, Table, Typography } from 'antd';
import Item from 'antd/lib/list/Item';
import { ColumnsType } from 'antd/lib/table';
import { add } from 'lodash';
import { useState } from 'react';
import { Product } from 'src/model/product';
import { ProductQuery } from 'src/model/productQuery';

interface IProps {
    fetcher?: () => Product[];
    previewProducts?: Product[];
    expanded?: boolean;
    onClose?: () => void;
}

type KeyValueMapping = [string | undefined, string | undefined];

const ValueSelect = (props: { onChange: (value: string) => void }) => {
    const { onChange } = props;

    return (
        <Select
            onChange={(e) => {
                onChange(e.toString());
            }}
            style={{ width: '20em' }}
        >
            <Select.Option value="productIdentifier.externalId">Product GTIN</Select.Option>
            <Select.Option value="name">Product Name</Select.Option>
            <Select.Option value="interpretedLifestyles.isVegan">Vegan</Select.Option>
            <Select.Option value="interpretedLifestyles.isVegetarian">Vegitarian</Select.Option>

            <Select.Option value="interpretedIngredients.data.$flat(children).$map_get(ingredient>name).$join( ,)">
                Ingredient
            </Select.Option>

            <Select.Option value="interpretedIngredients.data.$flat(children).$filter().$length()">
                Total ingredients
            </Select.Option>
        </Select>
    );
};

const KeyValueSelector = (props: {
    onDelete: () => void;
    mapping: KeyValueMapping;
    onChange: (mapping: KeyValueMapping) => void;
}) => {
    const { mapping, onChange, onDelete } = props;

    return (
        <div>
             
            <Space style={{ marginBottom: '1em' }}>
                <Input
                    style={{ width: '20em' }}
                    placeholder="Enter column name"
                    onChange={(e) => onChange([e.target.value, mapping[1]])}
                    value={mapping[0]}
                ></Input>
                :<ValueSelect onChange={(e) => onChange([mapping[0], e])} />
                <Button onClick={onDelete} type="link" icon={<DeleteOutlined></DeleteOutlined>}></Button>
            </Space>
        </div>
    );
};

const getValueFromPath = (product: any, path: string, delimiter = '.') => {
    const subPaths = path.split(delimiter);
    let curr: any = product;

    const $flat = (list: any[], key: string) => {
        let accumulative = list.map((item: any) => ({ ...item }));
        for (const acc of accumulative) {
            if (!acc[key]) continue;
            const extra = $flat(acc[key], key);
            accumulative = accumulative.concat(extra);
        }

        return accumulative;
    };

    const $map_get = (list: any[], key: string) => {
        console.log(key);
        return list.map((item) => getValueFromPath(item, key, '>'));
    };

    const $filter = (list: any[], key: string, value: any) => {
        return list.filter((v) => v[key] === value);
    };

    const associatedFunctions: any = {
        $length: (value: any) => {
            console.log(value);
            return value.length;
        },
        $join: (value: any[], joiner: string) => {
            return value.join(joiner);
        },
        $map_get,
        $filter,
        $flat,
    };

    for (const subPath of subPaths) {
        if (!curr) break;
        if (subPath.startsWith('$')) {
            const args = subPath.slice(subPath.indexOf('(') + 1, subPath.indexOf(')')).split(';');
            const functionName = subPath.split('(')[0];
            curr = associatedFunctions[functionName](curr, ...args);
            continue;
        }

        curr = curr[subPath];
    }

    return curr;
};

export const CSVSelector = (props: IProps) => {
    const { previewProducts, expanded, onClose } = props;

    // const [expanded, setExpanded] = useState<boolean>(false);
    const [mappings, setMappings] = useState<KeyValueMapping[]>();

    const buildColumns = (mappings: KeyValueMapping[]): ColumnsType<Product> => {
        return mappings.map((mapping, i) => ({
            title: mapping[0],
            key: i,
            render(p) {
                const path = mapping[1];
                if (path) return <Typography style={{ fontSize: '10px' }}>{getValueFromPath(p, path)}</Typography>;
            },
        }));
    };

    return (
        <>
            <Drawer placement="top" height="100vh" visible={expanded} onClose={onClose}>
                <Row>
                    <Col span={12}>
                        <Typography.Title>Select fields</Typography.Title>
                        {mappings?.map((mapping, index) => {
                            return (
                                <KeyValueSelector
                                    onChange={(mapping) => {
                                        setMappings(mappings.map((m, i) => (i === index ? mapping : m)));
                                    }}
                                    onDelete={() => {
                                        setMappings(mappings.filter((_, i) => i !== index));
                                    }}
                                    mapping={mapping}
                                ></KeyValueSelector>
                            );
                        })}
                        <Button
                            onClick={() => setMappings([...(mappings || []), [undefined, undefined]])}
                            icon={<PlusOutlined></PlusOutlined>}
                        ></Button>
                    </Col>
                    <Col span={12}>
                        <Typography.Title>Preview</Typography.Title>
                        {mappings && <Table dataSource={previewProducts} columns={buildColumns(mappings)} />}
                    </Col>
                </Row>
            </Drawer>
        </>
    );
};
