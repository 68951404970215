import { DataNode } from 'antd/lib/tree';
import { notNullGuard } from '../../../utils/typescript';
import React from 'react';
import { IIngredientNodeProps } from './types';
import { findNode, ITreeNodeLike } from 'src/utils/tree';
import { IMoveInterpretedIngredientPayload } from './api/gql';

/**
 * Our tree nodes have to have at least these fields
 *
 * @note: recursive type enforce children to have the same type as parents
 */
export interface IMinimalNode extends ITreeNodeLike {
    readonly jaccard: number;
    readonly id: string | number;
}
export function handleNode<I extends IMinimalNode>(dependencies: {
    item: I;
    jaccardCutoff: number;
    jaccardMap: Map<I['id'], { lowest: number; highest: number }>;
    onClickItem?: (ing: I) => void;
    ItemComponent: React.ComponentType<IIngredientNodeProps<I>>;
    hasItemsAbove: boolean;
    hasItemsBellow: boolean;
    hasParent: boolean;
    isEditable: boolean;
}): DataNode | null {
    const {
        isEditable,
        ItemComponent,
        jaccardCutoff,
        jaccardMap,
        item,
        onClickItem,
        hasItemsAbove,
        hasItemsBellow,
        hasParent,
    } = dependencies;
    const itemJaccardBranchScores = jaccardMap.get(item.id);
    if (itemJaccardBranchScores && jaccardCutoff > itemJaccardBranchScores.highest) {
        return null;
    }
    const { id, children } = item;
    return {
        key: id, // well, I can build it like parent-child kebab thing but I do not have to. .id is much simpler
        title: (
            <ItemComponent
                item={item}
                isEditable={isEditable}
                hasItemsAbove={hasItemsAbove}
                hasItemsBelow={hasItemsBellow}
                hasParent={hasParent}
            />
        ),
        children: children
            ?.map((item, index) =>
                handleNode({
                    item: item,
                    jaccardCutoff,
                    jaccardMap,
                    isEditable,
                    onClickItem,
                    ItemComponent,
                    hasItemsAbove: index !== 0,
                    hasItemsBellow: children && index < children.length - 1,
                    hasParent: true,
                })
            )
            .filter(notNullGuard),
        isLeaf: !children || children.length === 0,
    };
}
export function buildTreeData<I extends IMinimalNode>(dependencies: {
    items: readonly I[];
    jaccardCutoff: number;
    isEditable: boolean;
    onClickItem?: (ing: I) => void;
    ItemComponent: React.ComponentType<IIngredientNodeProps<I>>;
}): DataNode[] {
    const { ItemComponent, items, jaccardCutoff, onClickItem, isEditable } = dependencies;
    const jaccardMap = buildJaccardBranchMap(items);

    return items
        .map((ingredient, index) =>
            handleNode({
                isEditable,
                item: ingredient,
                jaccardCutoff,
                jaccardMap,
                onClickItem: onClickItem,
                ItemComponent,
                hasItemsAbove: index !== 0,
                hasItemsBellow: index < items.length - 1,
                hasParent: false,
            })
        )
        .filter(notNullGuard);
}

/**
 * map of the lowest and highest values of jaccard index for a branch
 */
export function buildJaccardBranchMap<I extends IMinimalNode>(
    items: readonly I[]
): Map<I['id'], { lowest: number; highest: number }> {
    const map = new Map<I['id'], { lowest: number; highest: number }>();
    const handleItem = (item: I, parentIds: I['id'][]): void => {
        for (const id of [...parentIds, item.id]) {
            const thatItemChunk = map.get(id) || {
                lowest: Number.MAX_SAFE_INTEGER,
                highest: Number.MIN_SAFE_INTEGER,
            };
            thatItemChunk.highest = Math.max(thatItemChunk.highest, item.jaccard);
            thatItemChunk.lowest = Math.min(thatItemChunk.lowest, item.jaccard);

            map.set(id, thatItemChunk);
        }
        for (const child of item.children || []) {
            handleItem(child, [...parentIds, item.id]);
        }
    };
    for (const item of items) {
        handleItem(item, []);
    }
    return map;
}

export interface IProtoTree<K> extends ITreeNodeLike {
    id: K;
    children?: this[] | null;
}

interface IMinimalDataNode<K> {
    key: K;
    children?: this[] | null;
}
type MinimalEventDataNode<K> = IMinimalDataNode<K> & {
    pos: string;
};
export function dropToPayload<T extends IProtoTree<K>, K extends string | number>(
    ingredients: T[],
    parentsMap: Map<K, IMinimalDataNode<K> | null>,
    tree: IMinimalDataNode<K>[],
    info: { node: MinimalEventDataNode<K> } & {
        dragNode: MinimalEventDataNode<K>;
        dragNodesKeys: K[];
        dropPosition: number;
        dropToGap: boolean;
    }
): IMoveInterpretedIngredientPayload {
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split('-');
    const dropPosLast = Number(dropPos[dropPos.length - 1]);
    const dropPosition = (info.dropPosition - dropPosLast) as -1 | 0 | 1; // Limited sets of results

    const draggedIngredient = findNode(ingredients, (i) => i.id === dragKey);
    if (!draggedIngredient) {
        throw new Error('The node in the ui tree but not in the original tree');
    }

    let dropParent: IMinimalDataNode<K> | undefined | null;
    if (info.dropToGap) {
        dropParent = parentsMap.get(info.node.key);
    } else {
        dropParent = info.node;
    }

    const dragParent = parentsMap.get(dragKey);
    const dragParentIngredient = (dragParent && findNode(ingredients, (node) => node.id === dragParent.key)) || null;

    let dropParentIngredient: T | null;
    if (dropParent) {
        const dropParentKey = dropParent.key;
        dropParentIngredient = findNode(ingredients, (node) => node.id === dropParentKey) || null;
        if (!dropParentIngredient) {
            throw new Error('The node in the ui tree but not in the original tree');
        }
    } else {
        dropParentIngredient = null;
    }

    let anchorElement: IMinimalDataNode<K> | undefined;
    if (info.dropToGap) {
        if (dropParent) {
            if (dropPosition === -1) {
                anchorElement = (dropParent.children || [])[dropPosLast - 1];
            } else {
                anchorElement = info.node;
            }
        } else {
            anchorElement = tree[dropPosLast];
        }
    } else {
        anchorElement = undefined;
    }
    console.debug({ anchorElement });

    const afterId = anchorElement ? Number(anchorElement.key) : null;

    return {
        item: {
            id: Number(draggedIngredient.id),
        },
        target: {
            id: Number(dropParentIngredient?.id) || null,
            afterId,
        },
        source: {
            id: Number(dragParentIngredient?.id) || null,
        },
    };
}
