import React, { CSSProperties, FC, useCallback, useEffect, useMemo } from 'react';
import { YesNoMaybe } from '../../../../../model/ingredients';
import { Alert, Button, Col, Form, message, Radio, Row, Typography } from 'antd';
import { useUpdateProductLifeTags } from '../../hooks';
import { Rule } from 'rc-field-form/lib/interface';
import { ColProps } from 'antd/lib/grid/col';

// https://altostruct.atlassian.net/browse/FD-292
type LifeStyleTypes = Omit<IInterpretedLifestyles, 'id'>;
type RULES_MAP = {
    [K in keyof LifeStyleTypes]: Array<keyof Omit<LifeStyleTypes, K>>;
};
const TRUE_REQUIRED_MAP: RULES_MAP = {
    isVegan: ['isLactoVegetarian', 'isOvoVegetarian', 'isVegetarian', 'isPescetarian'],
    isLactoVegetarian: ['isVegetarian', 'isPescetarian'],
    isOvoVegetarian: ['isVegetarian', 'isPescetarian'],
    isVegetarian: ['isPescetarian'],
    isPescetarian: [],
};
const FALSE_REQUIRED_MAP: RULES_MAP = {
    isVegan: [],
    isLactoVegetarian: ['isVegan'],
    isOvoVegetarian: ['isVegan'],
    isVegetarian: ['isVegan', 'isLactoVegetarian', 'isOvoVegetarian'],
    isPescetarian: ['isVegan', 'isLactoVegetarian', 'isOvoVegetarian', 'isVegetarian'],
};
const TRANSLATIONS: Record<string, string> = {
    isVegan: 'Vegan',
    isLactoVegetarian: 'Lacto vegetarian',
    isOvoVegetarian: 'Ovo vegetarian',
    isVegetarian: 'Vegetarian',
    isPescetarian: 'Pescetarian',
};

interface IInterpretedLifestyles {
    id: string | number;
    isLactoVegetarian: YesNoMaybe;
    isVegan: YesNoMaybe;
    isVegetarian: YesNoMaybe;
    isOvoVegetarian: YesNoMaybe;
    isPescetarian: YesNoMaybe;
}
export interface ILifeTagsFormProps {
    productId: string | number;
    loading: boolean;
    interpretedLifestyles: IInterpretedLifestyles | null;
}
const YesNoMaybeSelector: FC<{ styles?: CSSProperties }> = (props) => (
    <Radio.Group {...props} style={{ ...props.styles, width: '100%' }}>
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Radio value={YesNoMaybe.yes} />
            <Radio value={YesNoMaybe.maybe} />
            <Radio value={YesNoMaybe.no} />
        </div>
    </Radio.Group>
);
interface IExpectedFormState {
    isLactoVegetarian: YesNoMaybe;
    isPescetarian: YesNoMaybe;
    isVegan: YesNoMaybe;
    isOvoVegetarian: YesNoMaybe;
    isVegetarian: YesNoMaybe;
}

const rules: Rule[] = [{ required: true }];
const dataToFormValues = (interpretedLifestyles: IInterpretedLifestyles | null): Partial<IExpectedFormState> => {
    return {
        isLactoVegetarian: interpretedLifestyles?.isLactoVegetarian,
        isPescetarian: interpretedLifestyles?.isPescetarian,
        isVegan: interpretedLifestyles?.isVegan,
        isOvoVegetarian: interpretedLifestyles?.isOvoVegetarian,
        isVegetarian: interpretedLifestyles?.isVegetarian,
    };
};

const smallItemColProps: ColProps = {
    span: 3,
};
const smallWrapperColProps: ColProps = {
    span: 9,
};

// @note: there is a bug related to the https://github.com/ant-design/ant-design/issues/31271
// If we have dependencies set then getFieldError does not work as expected.
const dependencies: (keyof LifeStyleTypes | 'foo')[] = [
    'foo',
    'isVegan',
    'isVegetarian',
    'isOvoVegetarian',
    'isLactoVegetarian',
    'isPescetarian',
];
const LifeTags: FC<ILifeTagsFormProps> = (props) => {
    const { productId, loading, interpretedLifestyles } = props;
    const [updateLifeTags, { loading: lifeTagsUpdating }] = useUpdateProductLifeTags();
    const [form] = Form.useForm();
    const initialValues = useMemo(() => dataToFormValues(interpretedLifestyles), [interpretedLifestyles]);
    useEffect(() => {
        form.setFieldsValue({ ...dataToFormValues(interpretedLifestyles) });
    }, [form, interpretedLifestyles]);
    const handleFinish = useCallback(
        (values: IExpectedFormState) => {
            if (loading || lifeTagsUpdating) {
                return;
            }
            updateLifeTags({
                variables: {
                    productId,
                    values,
                },
            })
                .then(() => {
                    message.success('Lifestyle tags updated');
                })
                .catch((err) => {
                    console.error(String(err));
                });
        },
        [lifeTagsUpdating, loading, productId, updateLifeTags]
    );

    const setAll = useCallback(
        (val: YesNoMaybe) => {
            form.setFieldsValue({
                isLactoVegetarian: val,
                isPescetarian: val,
                isVegan: val,
                isOvoVegetarian: val,
                isVegetarian: val,
            });
        },
        [form]
    );
    const setAllYes = useCallback(() => {
        setAll(YesNoMaybe.yes);
    }, [setAll]);
    const setAllMaybe = useCallback(() => {
        setAll(YesNoMaybe.maybe);
    }, [setAll]);
    const setAllNo = useCallback(() => {
        setAll(YesNoMaybe.no);
    }, [setAll]);

    useEffect(() => {
        // make it touched and validatable
        form.setFields([
            {
                name: 'foo',
                touched: true,
            },
        ]);
    }, [form]);

    return (
        <Form
            labelAlign={'left'}
            layout={'horizontal'}
            onFinish={handleFinish}
            initialValues={initialValues}
            form={form}
            labelCol={smallItemColProps}
            wrapperCol={smallWrapperColProps}
        >
            <Form.Item
                name={'foo'}
                dependencies={dependencies}
                hidden={true}
                rules={[
                    {
                        async validator() {
                            const values: IExpectedFormState & { foo: unknown } = form.getFieldsValue();
                            for (const [key, otherRequiredFields] of Object.entries(TRUE_REQUIRED_MAP)) {
                                const typedKey = key as unknown as keyof LifeStyleTypes;
                                const keyFieldValue = values[typedKey];
                                if (keyFieldValue === YesNoMaybe.yes) {
                                    for (const otherKey of otherRequiredFields) {
                                        const otherKeyValue = values[otherKey];
                                        if (otherKeyValue !== YesNoMaybe.yes) {
                                            throw new Error(
                                                `If ${TRANSLATIONS[key]} is "Yes" then ${TRANSLATIONS[otherKey]} should be "Yes" too`
                                            );
                                        }
                                    }
                                }
                            }
                            for (const [key, otherRequiredFields] of Object.entries(FALSE_REQUIRED_MAP)) {
                                const typedKey = key as unknown as keyof LifeStyleTypes;
                                const keyFieldValue = values[typedKey];
                                if (keyFieldValue === YesNoMaybe.no) {
                                    for (const otherKey of otherRequiredFields) {
                                        const otherKeyValue = values[otherKey];
                                        if (otherKeyValue !== YesNoMaybe.no) {
                                            throw new Error(
                                                `If ${TRANSLATIONS[key]} is "No" then ${TRANSLATIONS[otherKey]} should be "No" too`
                                            );
                                        }
                                    }
                                }
                            }
                        },
                    },
                ]}
            >
                <div />
            </Form.Item>
            <Form.Item shouldUpdate={true}>
                {({ getFieldError }) => {
                    const fooErrors = getFieldError('foo');
                    if (!fooErrors.length) {
                        return null;
                    }

                    return (
                        <>
                            {fooErrors.map((err) => (
                                <Alert key={err} message={err} type={'error'} />
                            ))}
                        </>
                    );
                }}
            </Form.Item>
            <Row>
                <Col xs={3} />
                <Col xs={9}>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Typography.Text code={true} type={'success'}>
                            Yes
                        </Typography.Text>
                        <Typography.Text code={true} type={'warning'}>
                            Maybe
                        </Typography.Text>
                        <Typography.Text code={true} type={'danger'}>
                            No
                        </Typography.Text>
                    </div>
                </Col>
            </Row>
            <Form.Item label="Is vegan?" name="isVegan" rules={rules}>
                <YesNoMaybeSelector />
            </Form.Item>
            <Form.Item name="isLactoVegetarian" label="Is lacto-vegetarian?" rules={rules}>
                <YesNoMaybeSelector />
            </Form.Item>
            <Form.Item name="isOvoVegetarian" label="Is ovo-vegetarian?" rules={rules}>
                <YesNoMaybeSelector />
            </Form.Item>
            <Form.Item name="isVegetarian" label="Is vegetarian?" rules={rules}>
                <YesNoMaybeSelector />
            </Form.Item>
            <Form.Item name="isPescetarian" label="Is pescetarian?" rules={rules}>
                <YesNoMaybeSelector />
            </Form.Item>

            <Row>
                <Col xs={3} />
                <Col xs={9}>
                    <Button.Group style={{ width: '100%', justifyContent: 'space-around' }}>
                        <Button value={YesNoMaybe.yes} onClick={setAllYes}>
                            Set all = Yes
                        </Button>
                        <Button value={YesNoMaybe.maybe} onClick={setAllMaybe}>
                            Set all = Maybe
                        </Button>
                        <Button value={YesNoMaybe.no} onClick={setAllNo}>
                            Set all = No
                        </Button>
                    </Button.Group>
                </Col>
            </Row>
            <Form.Item shouldUpdate={true}>
                {({ getFieldError }) => {
                    const disabled = !!getFieldError('foo').length;
                    return (
                        <Button
                            type={'primary'}
                            htmlType={'submit'}
                            loading={loading || lifeTagsUpdating}
                            disabled={disabled}
                        >
                            Save lifestyle tags
                        </Button>
                    );
                }}
            </Form.Item>
        </Form>
    );
};

export default LifeTags;
