import { Typography } from 'antd';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useGetIngredient, useGetProductsFromIngredients } from 'src/api/ingredients';
import { ProductList } from 'src/components/ProductList';
import { InterpretButton } from 'src/pages/GroupPage/EditGroupPage/InterpretButton';

const ProductIngredientSearch = () => {
    const { id: ingredientId } = useParams<{ id: string }>();
    const fetchSize = 25;
    const { data, loading, fetchMore } = useGetProductsFromIngredients({
        variables: { ingredientId, limit: fetchSize },
    });
    const { data: ingredient } = useGetIngredient({ variables: { id: ingredientId } });
    const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const totalProducts = data?.products.totalProducts;
        const currentTotal = data?.products.data.length;

        if (hasMore && totalProducts !== undefined && totalProducts === currentTotal) {
            setHasMore(false);
        }
    }, [data, hasMore]);

    const onLoadMore = () => {
        const currentTotal = data?.products.data.length;
        fetchMore({
            variables: {
                ingredientId,
                offset: currentTotal,
                limit: fetchSize,
            },
        });
    };

    return (
        <>
            <Typography.Title>Listing products including: {ingredient?.ingredient.name}</Typography.Title>
            {data && (
                <ProductList
                    content={(p) => {
                        return (
                            <>
                                <InterpretButton
                                    productId={p.id}
                                    interpretationIngredientStatement={undefined}
                                    disabled={false}
                                ></InterpretButton>
                                <div
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                    style={{ width: '30vw', marginLeft: '10px' }}
                                >
                                    <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }}>
                                        {p.interpretationIngredientStatement}
                                    </Typography.Paragraph>
                                </div>
                            </>
                        );
                    }}
                    hasMore={hasMore}
                    loadMore={onLoadMore}
                    loading={loading}
                    products={data?.products.data}
                />
            )}
        </>
    );
};

export default ProductIngredientSearch;
