import { Input, Checkbox, Typography, Button, Tabs, Select, Form, Row, Modal, message, Divider } from 'antd';
import { useState } from 'react';
import style from './CreateInformationPage.module.scss';
import MDEditor from '@uiw/react-md-editor';
import { IngredientInformation, IngredientInformationSource } from 'src/model/ingredientInfromation';
import {
    useConnectIngredientInformationSources,
    useCreateIngredientInformation,
    useCreateIngredientInformationSources,
    useIngredientInformationSources,
} from 'src/api/ingredient-information';
import Col from 'antd/es/grid/col';
import { useForm } from 'antd/es/form/Form';
import { PlusCircleOutlined, OrderedListOutlined, PlusOutlined } from '@ant-design/icons';
import { ID } from 'src/utils/type';

interface AddNewSourceModalProps {
    visible: boolean;
    onCancel: () => void;
    onSuccess: (source: IngredientInformationSource) => void;
}

export const AddNewSourceModal = (props: AddNewSourceModalProps) => {
    const { visible, onSuccess, onCancel } = props;
    const [iconUrl, setIconUrl] = useState<string>();
    const [form] = useForm();

    return (
        <Modal
            onOk={() => {
                form.submit();
            }}
            onCancel={onCancel}
            visible={visible}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={(form) => {
                    onSuccess(form);
                }}
            >
                <Form.Item label="Name of source" required name="name">
                    <Input></Input>
                </Form.Item>
                <Form.Item label="Website to source" required name="website">
                    <Input></Input>
                </Form.Item>
                <Form.Item label="Link to icon of source" required name="iconUrl">
                    <Input
                        onChange={(v) => {
                            setIconUrl(v.target.value);
                        }}
                    ></Input>
                </Form.Item>
            </Form>
            <div>{iconUrl && <img alt="Not able to find icon url" style={{ width: '100%' }} src={iconUrl}></img>}</div>
        </Modal>
    );
};

const RichText = (props: { onChange: (str: string) => void }) => {
    const [value, setValue] = useState<string | undefined>('');
    return (
        <>
            <MDEditor
                style={{ height: '100%' }}
                value={value}
                onChange={(v) => {
                    setValue(v);
                    props.onChange(JSON.stringify({ richText: v }));
                }}
            />
        </>
    );
};

interface Props {
    ingredientId: ID;
    default?: IngredientInformation;
    onComplete?: (v: IngredientInformation) => void;
}

const CreateIngredientInformation = (props: Props) => {
    const { onComplete, ingredientId } = props;
    const { data } = useIngredientInformationSources();
    const sources = data?.sources.data;
    const [addNewSource, setAddNewSource] = useState(false);
    const [body, setBody] = useState<string>();
    const [form] = useForm();
    const [createNewSource] = useCreateIngredientInformationSources();
    const [createInformation] = useCreateIngredientInformation();
    const [connectWithIngredient] = useConnectIngredientInformationSources();

    return (
        <>
            <AddNewSourceModal
                onSuccess={(v) => {
                    createNewSource({ variables: { values: v } })
                        .then((v) => {
                            if (v.data?.ingredientInformationSource.id) {
                                setAddNewSource(false);
                                message.success('Succesfully created new source');
                            }
                        })
                        .catch((error) => {
                            message.error(error.toString());
                        });
                }}
                onCancel={() => setAddNewSource(false)}
                visible={addNewSource}
            ></AddNewSourceModal>
            <div className={style['whole']}>
                <div className={style['top-section']}>
                    <Typography.Title>Ingredient information</Typography.Title>
                    <Row>
                        <Col lg={18} md={24}>
                            <Form
                                initialValues={{ isPublic: true }}
                                form={form}
                                onFinish={(form) => {
                                    createInformation({
                                        variables: { values: { ...form, type: 'RICH_TEXT', body } },
                                    }).then((v) => {
                                        if (v.data?.ingredientInformation.id) {
                                            connectWithIngredient({
                                                variables: {
                                                    informationId: v.data?.ingredientInformation.id,
                                                    ingredientId: ingredientId,
                                                },
                                            });
                                            onComplete?.(v.data!.ingredientInformation);
                                            message.success('Successfully created new information');
                                        }
                                    });
                                }}
                                layout="vertical"
                            >
                                {sources && (
                                    <Row align="middle" gutter={[10, 0]}>
                                        <Col span={8}>
                                            <Form.Item required name="sourceId" label="Source of information">
                                                <Select>
                                                    {sources.map((v) => {
                                                        return <Select.Option value={v.id}>{v.name}</Select.Option>;
                                                    })}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item label=" ">
                                                <Button onClick={() => setAddNewSource(true)} block>
                                                    View all <OrderedListOutlined></OrderedListOutlined>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={4}>
                                            <Form.Item label=" ">
                                                <Button onClick={() => setAddNewSource(true)} block>
                                                    Add new <PlusCircleOutlined></PlusCircleOutlined>
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={1}>
                                            <Divider type="vertical"></Divider>
                                        </Col>
                                        <Col span={6}>
                                            <Form.Item name="isPublic" label=" " valuePropName="checked">
                                                <Checkbox>Information is public</Checkbox>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                )}
                            </Form>
                        </Col>
                    </Row>
                </div>

                <div className={style['middle-section']}>
                    <Tabs style={{ height: '100%' }}>
                        <Tabs.TabPane key="richText" tab="Rich Text">
                            <RichText
                                onChange={(v) => {
                                    setBody(v);
                                }}
                            ></RichText>
                        </Tabs.TabPane>
                        {/* <Tabs.TabPane key="image" tab="Image"></Tabs.TabPane> */}
                    </Tabs>
                </div>
                <div className={style['footer-section']}>
                    <Button
                        onClick={() => form.submit()}
                        type="primary"
                        style={{ float: 'right' }}
                        shape="circle"
                        size="large"
                    >
                        <PlusOutlined></PlusOutlined>
                    </Button>
                </div>
            </div>
        </>
    );
};

export default CreateIngredientInformation;
