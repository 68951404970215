import { InterpretedIngredient } from '../../../../model/ingredients';
import React, { FC, useCallback, useContext } from 'react';
import { Button, Tooltip, Typography } from 'antd';
import { IIngredientNodeProps } from '../types';
import { ArrowDownOutlined, ArrowLeftOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { OnMoveIngredientDown, OnMoveIngredientUp, OnMoveIngredientUpper } from '../context';

interface IIngredientProps extends IIngredientNodeProps<InterpretedIngredient> {
    onClick?: (ing: InterpretedIngredient) => void;
}

export const Ingredient: FC<IIngredientProps> = (props) => {
    const { item, isEditable = true, onClick, hasItemsAbove, hasItemsBelow, hasParent } = props;
    const moveUp = useContext(OnMoveIngredientUp);
    const moveUpper = useContext(OnMoveIngredientUpper);
    const onMoveUp = useCallback(() => moveUp(item), [item, moveUp]);
    const moveDown = useContext(OnMoveIngredientDown);
    const onMoveDown = useCallback(() => moveDown(item), [item, moveDown]);
    const onMoveUpper = useCallback(() => moveUpper(item), [item, moveUpper]);

    const onClickHandler = useCallback(() => {
        if (onClick) {
            onClick(item);
        }
    }, [item, onClick]);

    return (
        <div onClick={onClickHandler}>
            <Tooltip
                title={
                    <>
                        <b>{item.ingredient?.name || 'Composed ingredient'}</b>

                        {item.descriptions && (
                            <>
                                <br />
                                <i>{item.descriptions.join(', ')}</i>
                            </>
                        )}
                        {item.amount && (
                            <>
                                <br />
                                <i>{item.amount}</i>
                            </>
                        )}
                        {item.eco && (
                            <>
                                <br />
                                <i>{item.eco} </i>✅
                            </>
                        )}
                        {item.origin && (
                            <>
                                <br />
                                <i>{item.origin}</i>
                            </>
                        )}
                    </>
                }
            >
                <Typography.Text>
                    {item.foundName} ({item.jaccard})
                </Typography.Text>
            </Tooltip>
            {isEditable && (
                <>
                    {hasParent && <Button icon={<ArrowLeftOutlined />} type={'link'} onClick={onMoveUpper} />}
                    {hasItemsAbove && <Button icon={<ArrowUpOutlined />} type={'link'} onClick={onMoveUp} />}
                    {hasItemsBelow && <Button icon={<ArrowDownOutlined />} type={'link'} onClick={onMoveDown} />}
                </>
            )}
        </div>
    );
};
