import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Form, Input, message, Select } from 'antd';
import { useAddIngredientHit, useIngredients, useIngredientsDescriptors } from '../../hooks';

export interface IAddIngredientFormProps {
    onFinish(): void;
    productId: number;
}

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};
const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
};
// amount?: string;
// descriptions?: string[];
// eco?: string;
// ingredientId: number;
// origin?: string;
// parentId?: number;
export interface IExpectedFormState {
    ingredientId: number;
    amount: string;
    descriptions: string[];
    eco: string;
    foundName: string;
    origin: string;
}
export const AddIngredientForm: FC<IAddIngredientFormProps> = (props) => {
    const { onFinish, productId } = props;
    const { data: ingredientsData, loading: ingredientsLoading, error: ingredientsError } = useIngredients();
    const [addIngredientHit, { loading: itemAdding }] = useAddIngredientHit();
    const ingredientOptions = useMemo(() => {
        return ingredientsData?.getIngredients.data.map((ingredient) => ({
            label: ingredient.name,
            value: ingredient.id,
        }));
    }, [ingredientsData?.getIngredients.data]);

    useEffect(() => {
        if (ingredientsError) {
            message.error(String(ingredientsError));
        }
    }, [ingredientsError]);
    const { data: originsData, loading: originsLoading, error: originsError } = useIngredientsDescriptors();
    const originsOptions = useMemo(
        () =>
            originsData?.getIngredientDescriptors.data
                .filter((_) => _.isOrigin)
                .map((_) => ({
                    value: _.name,
                    label: _.name,
                })),
        [originsData?.getIngredientDescriptors.data]
    );
    const ecoOptions = useMemo((): { value: string; label: string }[] => {
        return [
            {
                value: 'Ekologisk',
                label: 'Ekologisk',
            },
            {
                value: 'KRAV',
                label: 'KRAV',
            },
        ];
    }, []);
    useEffect(() => {
        if (originsError) {
            message.error(String(originsError));
        }
    }, [originsError]);
    const initialValues = useMemo(
        (): Partial<IExpectedFormState> => ({
            amount: '',
            descriptions: [],
            foundName: '',
            eco: '',
            origin: '',
        }),
        []
    );
    const handleFinish = useCallback(
        (values: IExpectedFormState) => {
            addIngredientHit({
                variables: {
                    productId,
                    values: {
                        amount: values.amount,
                        descriptions: values.descriptions,
                        eco: values.eco,
                        foundName: values.foundName,
                        ingredientId: values.ingredientId,
                        origin: values.origin,
                    },
                },
            })
                .then(() => {
                    onFinish();
                })
                .catch((err) => {
                    message.error(String(err));
                });
        },
        [addIngredientHit, onFinish, productId]
    );
    return (
        <Form {...layout} onFinish={handleFinish} initialValues={initialValues}>
            <Form.Item name={'ingredientId'} label={'Ingredient'}>
                <Select
                    loading={ingredientsLoading}
                    showSearch={true}
                    showArrow={false}
                    allowClear={true}
                    options={ingredientOptions}
                    optionFilterProp={'label'}
                />
            </Form.Item>
            <Form.Item name={'amount'} label={'Amount'}>
                <Input />
            </Form.Item>
            <Form.Item name={'descriptions'} label={'Descriptions'}>
                <Select mode={'tags'} />
            </Form.Item>
            <Form.Item name={'eco'} label={'Eco'}>
                <Select
                    showSearch={true}
                    showArrow={false}
                    allowClear={true}
                    options={ecoOptions}
                    optionFilterProp={'label'}
                />
            </Form.Item>
            <Form.Item name={'foundName'} label={'Found name'}>
                <Input />
            </Form.Item>
            <Form.Item name={'origin'} label={'Origin'}>
                <Select
                    loading={originsLoading}
                    showSearch={true}
                    showArrow={false}
                    allowClear={true}
                    options={originsOptions}
                    optionFilterProp={'label'}
                />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit" loading={itemAdding}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddIngredientForm;
