import React, { FC, useMemo } from 'react';
import { Alert, Button, Form, Input, notification, Select } from 'antd';
import { gql, useMutation } from '@apollo/client';
import { SITE_USER_ROW_FRAGMENT } from '../hooks';
import { useUserGroups } from '../../ACLPage/hooks';

const MUTATION = gql`
    mutation AddNewSiteUser($values: InputSiteUser!) {
        insertSiteUser(values: $values) {
            userName
            ...SiteUserRow
        }
    }
    ${SITE_USER_ROW_FRAGMENT}
`;
const AddNewSiteUser: FC<{ onSuccess: (newRecord: { userName: string }) => void }> = ({ onSuccess }) => {
    const [addSiteUser, { loading, error }] = useMutation<
        {
            insertSiteUser: { userName: string };
        },
        {
            values: {
                eMail: string;
                userGroupID: string;
                userName: string;
            };
        }
    >(MUTATION);
    const { data: groups, loading: groupsLoading } = useUserGroups();
    const groupOptions = useMemo(() => {
        if (!groups) {
            return [];
        } else {
            return groups.userGroups.map((group) => ({
                label: group.name,
                value: group.id,
            }));
        }
    }, [groups]);
    return (
        <Form
            initialValues={{
                eMail: '',
                userGroupID: '',
                userName: '',
            }}
            onFinish={(values: { eMail: string; userGroupID: string; userName: string }) => {
                addSiteUser({
                    variables: {
                        values: {
                            eMail: values.eMail,
                            userGroupID: values.userGroupID,
                            userName: values.userName,
                        },
                    },
                })
                    .then((res) => {
                        notification.success({
                            message: 'A new user created',
                        });
                        onSuccess({
                            userName: res.data?.insertSiteUser.userName || '',
                        });
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            }}
        >
            {error && <Alert type={'error'} message={error.message} />}
            <Form.Item name={'userName'} label="UserName" required={true}>
                <Input />
            </Form.Item>
            <Form.Item name={'eMail'} label={'Email'} required={true}>
                <Input />
            </Form.Item>
            <Form.Item name={'userGroupID'} label={'User Group'} required={true}>
                <Select options={groupOptions} loading={groupsLoading} />
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'} loading={loading}>
                    Add user
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AddNewSiteUser;
