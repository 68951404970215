import React, { FC } from 'react';
import { InterpretedIngredient } from '../../../../model/ingredients';
import { EditableField } from '../EditableField';

export interface IFoundNameCellProps {
    record: InterpretedIngredient;
}

export const FoundNameCell: FC<IFoundNameCellProps> = (props) => {
    const { record } = props;
    return <EditableField record={record} fieldName={'foundName'} value={record.foundName} />;
};
