import React, { FC, useCallback, useMemo } from 'react';
import { Button, Form, Input, message as antdMessage, Radio, Typography } from 'antd';
import { Status } from '../types';
import { useChangeApproveStatus } from './hooks';

export interface IApproveFormProps {
    productId: number;
    message: string;
    status: Status;
    onFinish(): void;
    lastChangedBy?: string;
}
interface IExpectedFormState {
    message: string;
    status: Status;
    lastChangedBy: string;
}
const rules = [{ required: true }];
export const ApproveForm: FC<IApproveFormProps> = (props) => {
    const { productId, message, status, onFinish, lastChangedBy } = props;
    const [changeApprovalStatus] = useChangeApproveStatus();
    const handleFinish = useCallback(
        (values: IExpectedFormState) => {
            const ok = values.status === Status.Approved;
            changeApprovalStatus({
                variables: {
                    productId,
                    approval: {
                        ok,
                        message: values.message,
                    },
                },
            })
                .then(() => onFinish())
                .catch((err) => {
                    return antdMessage.error(String(err));
                });
        },
        [changeApprovalStatus, onFinish, productId]
    );

    const initialValues = useMemo(
        () => ({
            message: message || '',
            status: status === Status.NotDefined ? null : status,
        }),
        [message, status]
    );

    return (
        <Form onFinish={handleFinish} initialValues={initialValues}>
            <Form.Item label={'Approved by'}>
                <Typography>{lastChangedBy}</Typography>
            </Form.Item>
            <Form.Item name={'message'} label={'Message'}>
                <Input />
            </Form.Item>
            <Form.Item name={'status'} rules={rules} label={'Status'}>
                <Radio.Group>
                    <Radio.Button value={Status.Disapproved}>Disapproved</Radio.Button>
                    <Radio.Button value={Status.Approved}>Approved</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <Form.Item>
                <Button type={'primary'} htmlType={'submit'}>
                    Submit
                </Button>
            </Form.Item>
        </Form>
    );
};
