import { gql } from '@apollo/client';
import { UserData } from 'src/model/users';
import { q } from '../utils/utils';

const useUserSettingsCSVSchema = gql`
    query {
        userSettingsCSV {
            CSV
        }
    }
`;
export const useUserSettingsCSV = q<{ userSettingsCSV: UserData }>(useUserSettingsCSVSchema, {});
