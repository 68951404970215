import React, { useEffect, useState } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import {
    Table,
    Modal,
    Checkbox,
    Row,
    Col,
    Typography,
    Button,
    message,
    Form,
    Input,
    Drawer,
    Divider,
    Spin,
} from 'antd';
import {
    useGetIngredientsDescriptors,
    useCreateIngredientsDescriptor,
    useDeleteIngredientDescriptor,
    useUpdateIngredientsDescriptor,
} from '../../api/ingredients';
import { useQueryParam, BooleanParam, StringParam, NumberParam } from 'use-query-params';
import { ColumnsType } from 'antd/lib/table';
import { IngredientDescriptor } from '../../model/ingredients';
import { PlusOutlined, DeleteFilled } from '@ant-design/icons';

const { useForm } = Form;
const IngredientDescriptorListPage = () => {
    const pageSize = 20;
    const [refetching, setRefetching] = useState(false);
    const [query, setQuery] = useQueryParam<string | undefined | null>('query', StringParam);
    const [page, setPage] = useQueryParam<number | undefined | null>('page', NumberParam);

    const q = query !== undefined && query !== null ? query : undefined;
    const { data, fetchMore, error, loading, refetch } = useGetIngredientsDescriptors({
        variables: {
            limit: pageSize,
            offset: pageSize * Math.max(0, (page || 0) - 1),
            q: q,
        },
    });

    const [selectedId, setSelectedId] = useQueryParam<string | undefined | null>('selected', StringParam); // FIXME: check nulls
    const [createNew, setCreateNew] = useQueryParam<boolean | undefined | null>('createNew', BooleanParam); // FIXME: check nulls

    const [update] = useUpdateIngredientsDescriptor();
    const [create, { loading: isCreating }] = useCreateIngredientsDescriptor();

    const [_delete] = useDeleteIngredientDescriptor();

    useEffect(() => {
        if (query !== undefined) {
            setRefetching(true);

            refetch({
                q: q,
                limit: pageSize,
                offset: 0,
            }).finally(() => {
                setRefetching(false);
            });
        }
    }, [query, refetch, q]);

    useEffect(() => {
        if (error) {
            message.error(error.message);
        }
    }, [error]);

    const columns: ColumnsType<IngredientDescriptor> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Color',
            dataIndex: 'isColor',
            key: 'isVegan',
            render: (ing: boolean, _, index) => {
                return <Checkbox checked={ing}></Checkbox>;
            },
        },
        {
            title: 'Joinable',
            dataIndex: 'isJoinable',
            key: 'isJoinable',
            render: (ing: boolean, _, index) => {
                return <Checkbox checked={ing}></Checkbox>;
            },
        },
        {
            title: 'Piece part',
            dataIndex: 'isPiecePart',
            key: 'isPiecePart',
            render: (ing: boolean, _, index) => {
                return <Checkbox checked={ing}></Checkbox>;
            },
        },
        {
            title: 'Origin',
            dataIndex: 'isOrigin',
            key: 'isOrigin',
            render: (ing: boolean, _, index) => {
                return <Checkbox checked={ing}></Checkbox>;
            },
        },
        {
            title: 'Preperation Method',
            dataIndex: 'isPreparationMethod',
            key: 'isPreparationMethod',
            render: (ing: boolean, _, index) => {
                return <Checkbox checked={ing}></Checkbox>;
            },
        },
        {
            title: 'Shape',
            dataIndex: 'isShape',
            key: 'isShape',
            render: (ing: boolean, _, index) => {
                return <Checkbox checked={ing}></Checkbox>;
            },
        },
    ];

    const loadMore = (offset: number) => {
        setRefetching(true);
        fetchMore({
            variables: {
                offset: offset,
                limit: pageSize,
            },
        }).finally(() => {
            setRefetching(false);
        });
    };

    const Title = () => {
        return (
            <>
                <Row gutter={[20, 0]} justify="start">
                    <Col span={18}>
                        <Form
                            onFinish={(form) => {
                                setPage(1);
                                setQuery(form.query);
                            }}
                            initialValues={{ query }}
                        >
                            <Row gutter={[10, 0]}>
                                <Col>
                                    <Form.Item name="query">
                                        <Input allowClear></Input>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button htmlType="submit">Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    <Col span={6}>
                        <Button
                            style={{ float: 'right' }}
                            shape="circle"
                            icon={<PlusOutlined></PlusOutlined>}
                            onClick={() => setCreateNew(true)}
                            type="primary"
                        ></Button>
                    </Col>
                </Row>
            </>
        );
    };

    const onSave = (form: IngredientDescriptor) => {
        create({ variables: { values: form } })
            .then((v) => {
                message.success('Successfully created new product descriptor!');
                refetch().then((v) => {
                    setCreateNew(false);
                });
            })
            .catch((error) => {
                message.error(error.message);
            });
    };

    const UpdateIngredient = ({ visible }: { visible: boolean }) => {
        const current = data?.descriptors.data.find((v) => v?.id === selectedId?.toString());

        const onSave = (form: IngredientDescriptor) => {
            update({
                variables: {
                    id: current?.id!, // FIXME: undefined
                    values: form,
                },
            })
                .then((res) => {
                    message.success('Updated ingredient ' + res.data?.descriptor.name);
                })
                .catch((error) => {
                    message.error(error.message);
                });
        };

        return (
            <Drawer
                width="40vw"
                title={'Editing ' + current?.name}
                placement="right"
                onClose={() => setSelectedId(undefined as any)} // FIXME: undefined
                visible={visible && !!current}
            >
                {current && (
                    <Form
                        initialValues={current}
                        onFinish={onSave}
                        labelCol={{ span: 0 }}
                        wrapperCol={{ span: 24 }}
                        layout="vertical"
                    >
                        <Divider></Divider>
                        <Row gutter={[20, 0]}>
                            <Col span={12}>
                                <Form.Item label="Name" name="name">
                                    <Input></Input>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col span={8}>
                                <Form.Item name="isColor" valuePropName="checked">
                                    <Checkbox>Is color?</Checkbox>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="isJoinable" valuePropName="checked">
                                    <Checkbox>Is joinable?</Checkbox>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="isPiecePart" valuePropName="checked">
                                    <Checkbox>Is piece part? </Checkbox>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="isOrigin" valuePropName="checked">
                                    <Checkbox>Is origin?</Checkbox>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="isPreparationMethod" valuePropName="checked">
                                    <Checkbox>Is Preperation Method?</Checkbox>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item name="isShape" valuePropName="checked">
                                    <Checkbox>Is shape? </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[10, 0]} justify="end">
                            <Col>
                                <Button
                                    onClick={() => {
                                        Modal.confirm({
                                            title: 'Are you sure?',
                                            content: (
                                                <Typography.Text>
                                                    Are you sure you want to remove {current.name}?
                                                </Typography.Text>
                                            ),
                                            onOk: () => {
                                                _delete({ variables: { id: current.id } }).then(() => {
                                                    setSelectedId(undefined as any); // FIXME: undefined
                                                    refetch();
                                                });
                                            },
                                        });
                                    }}
                                    icon={<DeleteFilled></DeleteFilled>}
                                    shape="circle"
                                ></Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                )}
            </Drawer>
        );
    };

    const AddNewModal = ({ visible }: { visible: boolean }) => {
        const [form] = useForm();

        return (
            <Modal
                onCancel={() => setCreateNew(false)}
                onOk={(e) => {
                    form.submit();
                }}
                visible={visible}
            >
                <Typography.Title>New descriptor</Typography.Title>
                <Form onFinish={onSave} form={form}>
                    <Form.Item label="Descriptor name" name="name">
                        <Input disabled={isCreating} required></Input>
                    </Form.Item>
                    {isCreating && <Spin></Spin>}
                </Form>
            </Modal>
        );
    };

    return (
        <div>
            <Breadcrumb />
            {UpdateIngredient({ visible: !!selectedId })}
            {AddNewModal({ visible: !!createNew })}

            {data && (
                <Table
                    loading={loading || refetching}
                    title={Title}
                    sticky
                    pagination={{
                        onChange: (page, pageSize) => {
                            loadMore((page - 1) * pageSize!); // FIXME: undefined
                            setPage(page);
                        },
                        defaultCurrent: page || 0,
                        position: ['bottomCenter'],
                        pageSize,
                        showSizeChanger: false,
                        total: data.descriptors.totalAmount,
                    }}
                    dataSource={data.descriptors.data}
                    columns={columns}
                    onRow={(data, index) => {
                        return {
                            style: {
                                cursor: 'pointer',
                            },
                            onClick: () => {
                                setSelectedId(data.id);
                            },
                        };
                    }}
                />
            )}
        </div>
    );
};

export default IngredientDescriptorListPage;
