export interface NutrientDescription {
    'Code value': string;
    Name: string;
    Description: string;
    'Used in Sweden': 'X' | '';
}
// https://gs1.se/en/guides/documentation/code-lists/t4073-nutrient-type-code/
export const nutrients: ReadonlyArray<NutrientDescription> = [
    {
        'Code value': 'BIOT',
        Name: 'Biotin',
        Description: 'Biotin',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'CA',
        Name: 'Calcium',
        Description: 'Calcium',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'CHOAVL',
        Name: 'Carbohydrate',
        Description: 'Carbohydrate',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'CLD',
        Name: 'Chloride',
        Description: 'Chloride',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'CR',
        Name: 'Chromium',
        Description: 'Chromium',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'CU',
        Name: 'Copper',
        Description: 'Copper',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'ENER-',
        Name: 'Energy',
        Description: 'Energy',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'ENERSF',
        Name: 'Calories From Saturated Fat',
        Description: 'Calories From Saturated Fat',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAMSCIS',
        Name: 'Of which monounsaturated fat',
        Description: 'Of which monounsaturated fat',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'FAPUCIS',
        Name: 'Of which polyunsaturated fat',
        Description: 'Of which polyunsaturated fat',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'FASAT',
        Name: 'Of which saturated fat',
        Description: 'Of which saturated fat',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'FAT',
        Name: 'Fat',
        Description: 'Fat',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'FD',
        Name: 'Fluoride',
        Description: 'Fluoride',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'FE',
        Name: 'Iron',
        Description: 'Iron',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'FIBTG',
        Name: 'Fibre',
        Description: 'Fibre',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'FOLDFE',
        Name: 'Folic acid',
        Description: 'Folic acid',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'G_CMO',
        Name: 'Carbon Monoxide',
        Description: 'Carbon Monoxide',
        'Used in Sweden': '',
    },
    {
        'Code value': 'G_HC',
        Name: 'Bicarbonate (or hydrogen carbonate',
        Description:
            'Bicarbonate (or hydrogen carbonate) is an intermediate form in the deprotonation of carbonic acid. It is an anion with the chemical formula HCO3. Bicarbonate serves a crucial biochemical role in the physiological pH buffering system. Bicarbonate is a major element in our body, and is present in all body fluids and organs. Secreted by the stomach, it is necessary for digestion. When ingested, for example, with mineral water, it helps buffer lactic acid generated during exercise and also reduces the acidity of dietary components. Finally, it has a prevention effect on dental cavities.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'G_NICT',
        Name: 'Nicotine',
        Description: 'Nicotine',
        'Used in Sweden': '',
    },
    {
        'Code value': 'G_NMES',
        Name: 'Non-milk Extrinsic Sugars',
        Description: 'Non-milk Extrinsic Sugars',
        'Used in Sweden': '',
    },
    {
        'Code value': 'G_TAR',
        Name: 'Tar',
        Description: 'Tar',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GINSENG',
        Name: 'Ginseng',
        Description:
            'Ginseng may be included in small doses in energy drinks or herbal teas, such as ginseng coffee. Ginseng is known to contain phytoestrogens. The ginseng root has been used in Asia for more than two millennia to enhance energy, support the immune system, and improve concentration. Ginseng is a mild stimulant.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HMB',
        Name: 'Beta-Hydroxy-Beta-Methylbutyrate',
        Description: 'Beta-Hydroxy-Beta-Methylbutyrate',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ID',
        Name: 'Iodide',
        Description: 'Iodide',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'IODIZED_SALT',
        Name: 'Iodized Salt',
        Description: 'Table salt mixed with a minute amount of various iodine-containing salts (Temporary GS1 Code)',
        'Used in Sweden': '',
    },
    {
        'Code value': 'K',
        Name: 'Potassium',
        Description: 'Potassium',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'L_CARNITINE',
        Name: 'Carnitine',
        Description:
            "Carnitine is a substance that helps the body turn fat into energy. Your body makes it in the liver and kidneys and stores it in the skeletal muscles, heart, brain, and sperm. Carnitine is available as a supplement in a variety of forms: a. L-carnitine: the most widely available and least expensive b. Acetyl-L-carnitine: Often used in studies for Alzheimer's disease and other brain disorders c. Propionyl-L-carnitine: Often used in studies for heart disease and peripheral vascular disease (Temporary GS1 Code)",
        'Used in Sweden': '',
    },
    {
        'Code value': 'MG',
        Name: 'Magnesium',
        Description: 'Magnesium',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'MN',
        Name: 'Manganese',
        Description: 'Manganese',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'MO',
        Name: 'Molybdenum',
        Description: 'Molybdenum',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'NA',
        Name: 'Sodium',
        Description: 'Sodium',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'NACL',
        Name: 'NACL',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NIA',
        Name: 'Niacin',
        Description: 'Niacin',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'NUCLEOTIDE',
        Name: 'Beta-hydroxy-beta-methylbutyrate',
        Description:
            '(beta-hydroxy-beta-methylbutyrate) - increases muscle mass and decrease muscle breakdown. (Temporary GS1 Code)',
        'Used in Sweden': '',
    },
    {
        'Code value': 'P',
        Name: 'Phosphorus',
        Description: 'Phosphorus',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'PANTAC',
        Name: 'Pantothenic acid',
        Description: 'Pantothenic acid',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'POLYL',
        Name: 'Of which polyols',
        Description: 'Of which polyols',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'PRO-',
        Name: 'Protein',
        Description: 'Protein',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'RIBF',
        Name: 'Riboflavin',
        Description: 'Riboflavin',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'SALTEQ',
        Name: 'Salt equivalent',
        Description: 'The calculated level of salt equal to Na (natrium (sodium)) x 2,5. (Temporary GS1 Code)',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'SE',
        Name: 'Selenium',
        Description: 'Selenium',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'STARCH',
        Name: 'Of which starch',
        Description: 'Of which starch',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'SUGAR',
        Name: 'Of which sugars, not used',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUGAR-',
        Name: 'Of which sugars',
        Description: 'Of which sugars',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'THIA',
        Name: 'Thiamin',
        Description: 'Thiamin',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'VITA-',
        Name: 'Vitamin A',
        Description: 'Vitamin A',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'VITB12',
        Name: 'Vitamin B-12',
        Description: 'Vitamin B-12',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'VITB6-',
        Name: 'Vitamin B-6',
        Description: 'Vitamin B-6',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'VITC-',
        Name: 'Vitamin C',
        Description: 'Vitamin C',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'VITD-',
        Name: 'Vitamin D',
        Description: 'Vitamin D',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'VITE-',
        Name: 'Vitamin E',
        Description: 'Vitamin E',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'VITK',
        Name: 'Vitamin K',
        Description: 'Vitamin K',
        'Used in Sweden': 'X',
    },
    {
        'Code value': 'X_2FL',
        Name: '2´-fucosyllactose (2´-FL)',
        Description:
            '2´-fucosyllactose (2´-FL) is an oligosaccharide, more precisely, fucosylated, neutral trisaccharide composed of L-fucose, D-galactose, and D-glucose units. It is the most prevalent human milk oligosaccharide (HMO) naturally present in human breast milk, making up about 30% of all of HMOs.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ACAI_BERRY_EXTRACT',
        Name: 'Acai berry extract',
        Description: 'Acai berry extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ACEROLA_FRUIT',
        Name: 'Acerola fruit',
        Description: 'Acerola fruit',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ARGAN_OIL',
        Name: 'Argan oil',
        Description: 'Argan oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ARTICHOKE_BLOSSOM',
        Name: 'Artichoke blossom',
        Description: 'Artichoke blossom',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ARTICHOKE_EXTRACT',
        Name: 'Artichoke extract',
        Description: 'Artichoke extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ASPARAGUS_EXTRACT',
        Name: 'Asparagus extract',
        Description: 'Asparagus extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_AVOCADO_EXTRACT',
        Name: 'Avocado extract',
        Description: 'Avocado extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BACCOA',
        Name: 'Bacillus coagulans',
        Description:
            "Bacillus coagulans is approved for veterinary purposes as GRAS by the U.S. Food and Drug Administration's Center for Veterinary Medicine, as well as by the European Union, and is listed by AAFCO for use as a direct-fed microbial in livestock production. It is often used in veterinary applications, especially as a probiotic in pigs, cattle, poultry, and shrimp. Many references to use of this bacterium in humans exist, especially in improving the vaginal flora, improving abdominal pain and bloating in irritable bowel syndrome patients, and increasing immune response to viral challenges. There is evidence from animal research that suggests that Bacillus coagulans is effective in both treating as well as preventing recurrence of clostridium difficile associated diarrhea. https://en.wikipedia.org/wiki/Bacillus_coagulans",
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BIFIIDOBACTERIA',
        Name: 'Bifiidobacteria',
        Description: 'A genus of gram-positive, nonmotile, often branched anaerobic bacteria.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BIFIN',
        Name: 'Bifidobacterium Infantis',
        Description:
            'Bifidobacterium infantis is a member of the bifidobacteria family, a strain of bacteria that is normally found in the human intestines.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BITTER_ORANGE_FRUIT',
        Name: 'Bitter orange fruit',
        Description: 'Bitter orange fruit',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BLUEBERRY_EXTRACT',
        Name: 'Blueberry extract',
        Description: 'Blueberry extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BROMELAIN',
        Name: 'Bromelain',
        Description:
            'An enzyme extract derived from the stems of pineapples, although it exists in all parts of the fresh pineapple. The extract has a history of folk medicine use. As an ingredient, it is used in cosmetics, as a topical medication, and as a meat tenderizer.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_CARDIF',
        Name: 'Carbohydrates Difference',
        Description:
            'Carbohydrates difference (generally expressed in grams) for the product based on the nutritional serving size provided. “Carbohydrate difference” generally reflects the amount of starch in a product and is defined as the difference between total carbohydrate and the sum of dietary fibre, sugars, and sugar alcohols. Also known by some regulations as "Carbohydrates Other"',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_CHONDR',
        Name: 'Chondroitin Sulphate',
        Description:
            'Chondroitin sulphate is a chemical that is normally found in cartilage around joints in the body. Chondroitin sulphate is usually manufactured from animal sources, such as shark and cow cartilage. Chondroitin sulphate is used for osteoarthritis. It is often used in combination with other ingredients, including manganese ascorbate, glucosamine sulfate, glucosamine hydrochloride, or N-acetyl glucosamine.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_CITRUS_BIOFLAVONOIDS',
        Name: 'Citrus bioflavonoids',
        Description: 'Potent antioxidants that help combat free radicals and work synergistically with vitamin C',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_CITRUS_FRUIT_EXTRACT',
        Name: 'Citrus fruit extract',
        Description: 'Citrus fruit extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_COENZYME_Q10',
        Name: 'Coenzyme Q10',
        Description: "Coenzyme Q10 ensures that the energy can be produced in the body's cells.",
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_COLLAGEN_HYDROLYSATE',
        Name: 'Collagen hydrolysate',
        Description: 'Collagen hydrolysate',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_CRANBERRY_EXTRACT',
        Name: 'Cranberry extract',
        Description: 'Cranberry extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_CURCUMA_LONGA_EXTRACT',
        Name: 'Curcuma longa extract',
        Description: 'Curcuma longa extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_DANDELION_LEAF',
        Name: 'Dandelion leaf',
        Description: 'Dandelion leaf',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ELDER_FRUIT',
        Name: 'Elder fruit',
        Description: 'Elder fruit',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ELDERBERRY_EXTRACT',
        Name: 'Elderberry extract',
        Description: 'Elderberry extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ENTFAE',
        Name: 'Enterococcus faecium',
        Description:
            'Enterococcus faecium is a Gram-positive, alpha-hemolytic or nonhemolytic bacterium in the genus Enterococcus. It can be commensal (innocuous, coexisting organism) in the gastrointestinal tract of humans and animals, but it may also be pathogenic, causing diseases such as neonatal meningitis or endocarditis. This bacterium has developed multi-drug antibiotic resistance and uses colonization and secreted factors in virulence (enzymes capable of breaking down fibrin, protein and carbohydrates to regulate adherence bacteria to inhibit competitive bacteria). https://en.wikipedia.org/wiki/Enterococcus_faecium.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_EVENING_PRIMROSE_OIL',
        Name: 'Evening primrose oil',
        Description: 'Evening primrose oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_FATRNAN',
        Name: 'Transfatty Acids animal',
        Description: 'Transfatty Acids, total trans fatty acids, animal origin',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_FATRNPL',
        Name: 'Transfatty Acids plant',
        Description: 'Transfatty Acids, total trans fatty acids, plant origin',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_FATRSA',
        Name: 'Fat, Combined Saturated and Trans',
        Description: 'Combined saturated fat and trans fat',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_FIBDIF',
        Name: 'Fibre Difference',
        Description:
            'Fibre difference defines the amount of non-digestible carbohydrates not included in the definition of dietary fibre (e.g., synthetic fibres without a physiological effect that is beneficial to human health). Also known by some regulations as "Other Fibre"',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_FIBTPE',
        Name: 'Dietary Fibre, total',
        Description:
            'Total dietary fibre in relevant part as non-digestible soluble and insoluble carbohydrates (with 3 or more monomeric units), and lignin that are intrinsic and intact in plants; isolated or synthetic non-digestible carbohydrates (with 3 or more monomeric units) as determined by regulation agencies to have physiological effects that are beneficial to human health.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GINGER_ROOT',
        Name: 'Ginger root',
        Description: 'Ginger root',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GINGER_ROOT_EXTRACT',
        Name: 'Ginger root extract',
        Description: 'Ginger root extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GINGKO_EXTRACT',
        Name: 'Gingko extract',
        Description: 'Gingko extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GINGKO_LEAF_EXTRACT',
        Name: 'Ginkgo leaf extract',
        Description: 'Ginkgo leaf extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GINSENG_EXTRACT',
        Name: 'Ginseng extract',
        Description: 'Ginseng extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GOJI_BERRY_EXTRACT',
        Name: 'Goji berry extract',
        Description: 'Goji berry extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GOS',
        Name: 'Galactooligosaccharides (GOS)',
        Description:
            'Galactooligosaccharides (GOS) are oligosaccharides composed of different galactosyl residues (from 2 to 9 units) and a terminal glucose linked by β-glycosidic bonds, such as β-(1–2), β-(1–3), β-(1–4), and β-(1–6) (Illanes et al., 2016).',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GRAPE_EXTRACT',
        Name: 'Grape extract',
        Description: 'Grape extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GREEN_TEA_EXTRACT',
        Name: 'Green tea extract',
        Description: 'Green tea extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GUARANA_EXTRACT',
        Name: 'Guarana extract',
        Description: 'Guarana extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GUARANA_SEED',
        Name: 'Guarana seed',
        Description: 'Guarana seed',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_HIBISCUS_BLOSSOM',
        Name: 'Hibiscus blossom',
        Description: 'Hibiscus blossom',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_HIBISCUS_FLOWER_EXTRACT',
        Name: 'Hibiscus flower extract',
        Description: 'Hibiscus flower extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_HMO',
        Name: 'Human milk-oligosaccharides (HMO)',
        Description:
            'Human milk-oligosaccharides (HMO) are unconjugated glycans that constitute an important component of the protection conferred by breast milk on the neonate. HMOs may act locally on the neonatal intestine by acting as signalling molecules and directly interacting with the host cells.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_HONEY',
        Name: 'Honey',
        Description:
            'GS1 Tagname Description: A thick, sweet, syrupy substance that bees make as food from the nectar of flowers and store in honeycombs.- UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_HOP_CONE_BLOSSOM',
        Name: 'Hop cone blossom',
        Description: 'Hop cone blossom',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_HORSETAIL_EXTRACT',
        Name: 'Horsetail extract',
        Description: 'Horsetail extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_KRILL_OIL',
        Name: 'Krill oil',
        Description: 'Krill oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LACTASE',
        Name: 'Lactase',
        Description: 'Enzyme, found in the intestine that splits lactose into its components - galactose and glucose',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LACTIC_ACID_BACTERIA',
        Name: 'Lactic acid bacteria',
        Description: 'Lactic acid bacteria',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LADYS_MANTLE_LEAF',
        Name: "Lady's mantle leaf",
        Description: "Lady's mantle leaf",
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LAVENDER_BLOSSOM',
        Name: 'Lavender blossom',
        Description: 'Lavender blossom',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LAVENDER_BLOSSOM_EXTRACT',
        Name: 'Lavender blossom extract',
        Description: 'Lavender blossom extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LAVENDER_OIL',
        Name: 'Lavender oil',
        Description: 'Lavender oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LEMON_BALM_LEAF_EXTRACT',
        Name: 'Lemon balm leaf extract',
        Description: 'Lemon balm leaf extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LIME_BLOSSOM',
        Name: 'Lime blossom',
        Description: 'Lime blossom',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LNNT',
        Name: 'Lacto-N-neotetraose (LNnt)',
        Description: 'Lacto-N-neotetraose (LNnt) is a neutral oligosaccharide present in human colostrum and milk.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MACADAMIA_NUT_OIL',
        Name: 'Macadamia nut oil',
        Description: 'Macadamia nut oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MCT',
        Name: 'Middle Chain Triglycerides',
        Description:
            'Middle (or Medium) Chain Triglycerides (MCT) are fats found in foods like coconut oil and palm kernel oil. They are metabolized differently than the long-chain triglycerides (LCT) found in most other foods, and contain fatty acids that have a chain length of 6–12 carbon atoms.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MELA',
        Name: 'Melatonin',
        Description:
            'Melatonin is a hormone secreted by the pineal gland that inhibits melanin formation and is thought to be concerned with regulating the reproductive cycle.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MILK_THISTLE_FRUIT',
        Name: 'Milk thistle fruit',
        Description: 'Milk thistle fruit',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MILLET_SEED_EXTRACT',
        Name: 'Millet seed extract',
        Description: 'Millet seed extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_NETTE_LEAF_EXTRACT',
        Name: 'Nettle leaf extract',
        Description: 'Nettle leaf extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_OLIVE_OIL_OMEGA_9_FATTY_ACIDS',
        Name: 'Omega-9 fatty acids from olive oil',
        Description: 'Omega-9 fatty acids from olive oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ORGANIC_AGAVE',
        Name: 'Organic Agave Syrup',
        Description:
            'GS1 Tagname Description: A thick, sweet liquid commercially produced organically from the centre of the agave plant, used a sweetener.- UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_PAPAIN',
        Name: 'Papain',
        Description:
            'An enzyme that occurs naturally in relatively high concentrations in the still greenish peel and seeds of the fruit papaya and is extracted from it.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_POLDE',
        Name: 'Polydextrose',
        Description:
            'An indigestible synthetic polymer of dextrose/glucose. It is a food ingredient classified as soluble fibre by Health Canada. Expressed in grams. It is obtained by condensation of a melt consisting of approximately 90% glucose and 10% sorbitol in the presence of catalytic amounts of citric acid or phosphoric acid',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_POMEGRANATE_EXTRACT',
        Name: 'Pomegranate extract',
        Description: 'Pomegranate extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_POMEGRANATE_FRUIT',
        Name: 'Pomegranate fruit',
        Description: 'Pomegranate fruit',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_PUMPKIN_SEED_EXTRACT',
        Name: 'Pumpkin seed extract',
        Description: 'Pumpkin seed extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_RED_CLOVER_CONCENTRATE',
        Name: 'Red clover concentrate',
        Description: 'Red clover concentrate',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ROSEMARY_LEAF_EXTRACT',
        Name: 'Rosemary leaf extract',
        Description: 'Rosemary leaf extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SAGE_EXTRACT',
        Name: 'Sage extract',
        Description: 'Sage extract',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SAGE_LEAF',
        Name: 'Sage leaf',
        Description: 'Sage leaf',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SALIC',
        Name: 'Salicylate',
        Description: 'Salicylate is a salt or ester of salicylic acid.',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SANDORN_FRUIT',
        Name: 'Sandorn fruit',
        Description: 'Sandorn fruit',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SEA_FISH_OIL',
        Name: 'Sea fish oil',
        Description: 'Sea fish oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SILICEOUS_EARTH',
        Name: 'Siliceous earth',
        Description: 'Siliceous earth',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SPIRULINA',
        Name: 'Spirulina',
        Description: 'Spirulina',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_TURMERIC_ROOT',
        Name: 'Turmeric root',
        Description: 'Turmeric root',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_WHEAT_GERM_OIL',
        Name: 'Wheat germ oil',
        Description: 'Wheat germ oil',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ZN',
        Name: 'Zinc',
        Description: 'Zinc',
        'Used in Sweden': 'X',
    },
    {
        'Code value': '10FDHF',
        Name: '10-Formyldihydrofolate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': '10FFOLAC',
        Name: '10-Formylfolic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': '5FTHF',
        Name: '5-Formyltetrahydrofolate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': '5MDHF',
        Name: '5-Methyl-Dihydrofolic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': '5MTHF',
        Name: '5-Methyltetrahydrofolate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAA',
        Name: 'Amino Acids, Total Aromatic',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAAN',
        Name: 'Amino Acids, Total Aromatic; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAAP',
        Name: 'Amino Acids, Total Aromatic; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE-',
        Name: 'Amino Acids, Total Essential; Unknown Or Variable',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE10A',
        Name: 'Amino Acids, Total Essential: 8 Basic Plus Arginine And Histidine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE10AN',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen;Includes The Eight Basic Essential Amino Acids Plus Arginine And Histidine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE10AP',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Arginine And Histidine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE10B',
        Name: 'Amino Acids, Total Essential: 8 Basic Plus Cystine And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE10BN',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Cystine And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE10BP',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Cystine And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE12',
        Name: 'Amino Acids, Total Essential: 8 Basic Plus Arginine, Histidine, Cystine And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE12N',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen; Includes The Eight Basic Essential Amino Acids Plus Arginine, Histidine, Cystine, And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE12P',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids Plus Arginine, Histidine, Cystine, And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE7',
        Name: 'Amino Acid (Aa 7 Ess. Excl.Trp)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE8',
        Name: 'Amino Acids, 8 Basic Essential: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan And Valine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE8N',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen; Includes The Eight Basic Essential Amino Acids: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan, And Valine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE8P',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Includes The Eight Basic Essential Amino Acids: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan And Valine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAE9',
        Name: 'Amino Acid (Aa 9 Ess. Incl. His)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAEN-',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Nitrogen; Unknown Which Amino Acids Are Included In The Total Value',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAEP-',
        Name: 'Amino Acids, Total Essential; Expressed Per Quantity Of Protein; Unknown Which Amino Acids Are Included In The Total Value',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAET-',
        Name: 'Amino Acids, Essential To Total Amino Acid Ratio; Neither The Numerator (Which Amino Acids Are Considered Essential',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAET10A',
        Name: 'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids Plus Arginine And Histidine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAET10B',
        Name: 'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids Plus Cystine And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAET12',
        Name: 'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids Plus Arginine, Histidine, Cystine, And Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAET8-',
        Name: 'Amino Acids, Essential To Total Amino Acid Ratio; Includes The Eight Basic Essential Amino Acids: Isoleucine, Leucine, Lysine, Methionine, Phenylalanine, Threonine, Tryptophan, And Valine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AALB',
        Name: 'Amino Acids, Limiting Amino Acid; Determined Through Biological Experiment',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AALC',
        Name: 'Amino Acids, Limiting Amino Acid; Determined Through Calculation',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AANE',
        Name: 'Amino Acid (Aa Non Ess.)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAS',
        Name: 'Amino Acids, Total Sulphur-Containing: Cystine Plus Methionine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AASN',
        Name: 'Amino Acids, Total Sulphur-Containing; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AASP',
        Name: 'Amino Acids, Total Sulphur-Containing; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAT-',
        Name: 'Amino Acids, Total, Precise Definition Not Specified',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAT19',
        Name: 'Amino Acids (Aa 19)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAT20',
        Name: 'Amino Acids (Aa 20)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AAT24',
        Name: 'Amino Acids (Aa 24)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AATN',
        Name: 'Amino Acids, Total; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AATP',
        Name: 'Amino Acids, Total; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ACEAC',
        Name: 'Acetic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ACESK',
        Name: 'Acesulfam-K',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ADEN',
        Name: 'Adenine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AG',
        Name: 'Silver (Agar-Agar)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AGAR',
        Name: 'Agar-Agar (Agar)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AL',
        Name: 'Aluminium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ALA',
        Name: 'Alanine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ALAN',
        Name: 'Alanine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ALAP',
        Name: 'Alanine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ALBU',
        Name: 'Albumin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ALC',
        Name: 'Alcohol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ALGL',
        Name: 'Albumin And Globulin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ALGNT',
        Name: 'Alginates',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AMMON',
        Name: 'Ammonia',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AMYP',
        Name: 'Amylopectin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AMYPM',
        Name: 'Amylopectin; Expressed In',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AMYS',
        Name: 'Amylose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AMYSM',
        Name: 'Amylose; Expressed In Monosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ANTCYAN',
        Name: 'Anthocyanidin, Tot.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'APIGEN',
        Name: 'Apigenin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ARAFB',
        Name: 'Arabinose In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ARAN',
        Name: 'Arabinan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ARAS',
        Name: 'Arabinose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ARG',
        Name: 'Arginine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ARGHIS',
        Name: 'Arginine + Histidine (ARG+HIS)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ARGN',
        Name: 'Arginine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ARGP',
        Name: 'Arginine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AS',
        Name: 'Arsenic',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AS3+',
        Name: 'Arsenic 3+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AS5+',
        Name: 'Arsenic 5+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASCDL',
        Name: 'L-Dehydroascorbic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASCL',
        Name: 'L-Ascorbic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASH',
        Name: 'Ash',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASIO',
        Name: 'Arsenic, Inorganic',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASN',
        Name: 'Asparagine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASNN',
        Name: 'Asparagine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASNP',
        Name: 'Asparagine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASO',
        Name: 'Arsenic, Organic',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASP',
        Name: 'Aspartic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASPM',
        Name: 'Aspartame',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASPN',
        Name: 'Aspartic Acid; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ASPP',
        Name: 'Aspartic Acid; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ATX',
        Name: 'Astaxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AU',
        Name: 'Gold',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AVED5',
        Name: 'Avenasterol (delta 5-avenasterol)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AVED7',
        Name: 'Avenasterol (delta 7-avenasterol)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'AVEDT',
        Name: 'Avenasterol, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'B',
        Name: 'Boron',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'BA',
        Name: 'Barium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'BENAC',
        Name: 'Benzoic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'BETN',
        Name: 'Betaine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'BI',
        Name: 'Bismuth',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'BRASTR',
        Name: 'Brassicasterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'BRD',
        Name: 'Bromide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CADAVT',
        Name: 'Cadaverine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CAFFN',
        Name: 'Caffeine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CAMD5',
        Name: 'Delta 5-Campesterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CAMD7',
        Name: 'Delta 7-Campesterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CAMT',
        Name: 'Campesterol, Total.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CAPSA',
        Name: 'Capsaicin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CARGN',
        Name: 'Carageenan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CAROT',
        Name: 'Carotene, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CARTA',
        Name: 'Alpha-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CARTB',
        Name: 'Beta-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CARTBCIS',
        Name: 'B-Carotene Cis',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CARTBEQ',
        Name: 'Beta-Carotene Equivalents',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CARTG',
        Name: 'Gamma-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CARTOID',
        Name: 'Carotenoids, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CASN',
        Name: 'Casein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CATEC',
        Name: 'Catechins, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CD',
        Name: 'Cadmium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CELLO',
        Name: 'Cellobiose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CELLU',
        Name: 'Cellulose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHEMSC',
        Name: 'Chemical Score',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHITIN',
        Name: 'Chitin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHLNP',
        Name: 'Chlorogenic Acid Phosphatidyl Choline',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHLRAC',
        Name: 'Chlorogenic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHO-',
        Name: 'Carbohydrate, Total; Method Of Determination Unknown Or Variable',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOAVL-',
        Name: 'Carbohydrate, Other (CHO avl. unk.)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOAVLDF',
        Name: 'Carbohydrate, Other (CHO avl. diff.)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOAVLM',
        Name: 'Carbohydrate, Other (carbohydrate, available; expressed in monosaccharide equivalents)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOCAL',
        Name: 'Cholecalciferol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOCALDOH',
        Name: 'Cholecalciferol (1,25 dihydroxyD2)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOCALOH',
        Name: 'Cholecalciferol (25 hydroxyD2)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOCDF',
        Name: 'Cholecalciferol (carbohydrate, total; calculated by difference)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOCSM',
        Name: 'Cholecalciferol (carbohydrate, total; calculated by summation)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOL-',
        Name: 'Cholesterol; Method Of Determination Unknown Or Variable',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLC',
        Name: 'Cholesterol; Determined By Chemical Method (Classical',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLE',
        Name: 'Cholesterol; Determined By Enzymatic Or Chromatographic Method',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLM',
        Name: 'Cholesterol (24-methylcholest-7-erol)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLME',
        Name: 'Cholesterol (24-methylene cholesterol)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLN',
        Name: 'Choline, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLNF',
        Name: 'Choline, Free',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLNGPC',
        Name: 'Choline From Glycerophosphocholine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLNPC',
        Name: 'Choline From Phosphocholine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLNPTC',
        Name: 'Choline From Phosphatidylcholine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHOLNSM',
        Name: 'Choline From Sphingomyelin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CHRPRO',
        Name: 'Chromoproteins',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CITAC',
        Name: 'Citric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CO',
        Name: 'Cobalt',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CO2+',
        Name: 'Cobalt 2+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CO2F',
        Name: 'Carbon Dioxide, Free',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CO3+',
        Name: 'Cobalt 3+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'COLG',
        Name: 'Collagen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CONPRO',
        Name: 'Protein (connective tissue protein)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'COUMEST',
        Name: 'Coumestrol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CR3+',
        Name: 'Chromium 3+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CR6+',
        Name: 'Chromium 6+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CREATN',
        Name: 'Creatinine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CRYPX',
        Name: 'Cryptoxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CRYPXA',
        Name: 'A-Cryptoxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CRYPXB',
        Name: 'B-Cryptoxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CS',
        Name: 'Cesium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CS137',
        Name: 'Cesium-137',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CTX',
        Name: 'Canthaxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CU1+',
        Name: 'Copper 1+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CU2+',
        Name: 'Copper 2+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CYAD',
        Name: 'Cyanidin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CYAN',
        Name: 'Cyanide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CYCL',
        Name: 'Cyclamate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CYS',
        Name: 'Cystine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CYSN',
        Name: 'Cystine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CYSP',
        Name: 'Cystine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'CYSTE',
        Name: 'Cysteine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DAIDZN',
        Name: 'Daidzin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DAIDZNA',
        Name: 'Acetyl-Daidzin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DAIDZNM',
        Name: 'Malonyl-Daidzin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DDZEIN',
        Name: 'Daidzein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DELPH',
        Name: 'Delphinidin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DEN',
        Name: 'Density',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DEXTN',
        Name: 'Dextrins',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DEXTNM',
        Name: 'Dextrins; Expressed In Monosaccharide Equivalents',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DGLY',
        Name: 'Diglycerides, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DHF',
        Name: 'Dihydrofolic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DISAC',
        Name: 'Disaccharides, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DISACM',
        Name: 'Disaccharides, Total; Expressed In Monosaccharide Equivalents',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DM',
        Name: 'Dry Matter',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'DOPN',
        Name: 'Dopamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'EDIBLE',
        Name: 'Edible',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ELAN',
        Name: 'Elastin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERA',
        Name: 'Calories/Energy (energy, gross; determined by direct analysis using bomb calorimetry)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERC',
        Name: 'Calories/Energy (energy, total metabolizable; calculated from the energy-producing food components)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERPA',
        Name: 'Energy (energy % from alc.)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERPC',
        Name: 'Energy, Percent Contributed By Carbohydrate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERPDF',
        Name: 'Energy % From Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERPF',
        Name: 'Energy, Percent Contributed By Fat',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERPP',
        Name: 'Energy, Percent Contributed By Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERPSA',
        Name: 'Energy % From Added Sugar',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ENERPSN',
        Name: 'Energy % From Natural Sugar',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'EPICATEC',
        Name: 'Epicatechin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'EPICATECG3',
        Name: 'Epicatechin-3-Gallate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'EPICATEGC',
        Name: 'Epigallocatechin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ERGCAL',
        Name: 'Ergocalciferol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ERGCALOH',
        Name: '25 Hydroxy D3',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ERGSTR',
        Name: 'Ergosterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ERYTHL',
        Name: 'Erythritol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ETHAM',
        Name: 'Ethanolamines, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F10D0',
        Name: 'Fat (fatty acid 10:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F10D0F',
        Name: 'Fat (fatty acid 10:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F10D1',
        Name: 'Fat (fatty acid 10:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F10D1F',
        Name: 'Fat (fatty acid 10:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F12D0',
        Name: 'Fat (fatty acid 12:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F12D0F',
        Name: 'Fat (fatty acid 12:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F12D1',
        Name: 'Fat (fatty acid 12:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F12D1F',
        Name: 'Fat (fatty acid 12:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F13D0',
        Name: 'Fat (fatty acid 13:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F13D0F',
        Name: 'Fat (fatty acid 13:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F14D0',
        Name: 'Fat (fatty acid 14:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F14D0F',
        Name: 'Fat (fatty acid 14:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F14D1',
        Name: 'Fat (fatty acid 14:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F14D1F',
        Name: 'Fat (fatty acid 14:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F15D0',
        Name: 'Fat (fatty acid 15:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F15D0F',
        Name: 'Fat (fatty acid 15:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F15D1',
        Name: 'Fat (fatty acid 15:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F15D1F',
        Name: 'Fat (fatty acid 15:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F15D1N9',
        Name: 'Fat (fatty acid 15:1 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F15D1N9F',
        Name: 'Fat (fatty acid 15:1; n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D0',
        Name: 'Fat (fatty acid 16:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D0F',
        Name: 'Fat (fatty acid 16:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D1',
        Name: 'Fat (fatty acid 16:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D1C',
        Name: 'Fat (fatty acid cis 16:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D1F',
        Name: 'Fat (fatty acid 16:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D1T',
        Name: 'Fat, Trans (fatty acid trans 16:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D2',
        Name: 'Fat (FA 16:2)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D2N4',
        Name: 'Fat (fatty acid 16:2 n-4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D2N4F',
        Name: 'Fat (fatty acid 16:2; n-4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D2N7',
        Name: 'Fat (fatty acid 16:2 n-7)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D2N7F',
        Name: 'Fat (fatty acid 16:2; n-7)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D3',
        Name: 'Fat (FA 16:3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D3N3',
        Name: 'Fat (fatty acid 16:3 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D3N3F',
        Name: 'Fat (fatty acid 16:3; n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D3N4',
        Name: 'Fat (fatty acid 16:3 n-4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D3N4F',
        Name: 'Fat (fatty acid 16:3; n-4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D4N1',
        Name: 'Fat (fatty acid 16:4 n-1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D4N1F',
        Name: 'Fat (fatty acid 16:4; n-1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D4N4',
        Name: 'Fat (fatty acid 16:4 n-4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F16D4N4F',
        Name: 'Fat (fatty acid 16:4; n-4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F17D0',
        Name: 'Fat (fatty acid 17:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F17D0F',
        Name: 'Fat (fatty acid 17:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F17D1',
        Name: 'Fat (fatty acid 17:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F17D1F',
        Name: 'Fat (fatty acid 17:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D0',
        Name: 'Fat (fatty acid 18:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D0F',
        Name: 'Fat (fatty acid 18:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1',
        Name: 'Fat (fatty acid 18:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1C',
        Name: 'Fat (fatty acid cis 18:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1CN9',
        Name: 'Fat (fatty acid 18:1 cis n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1CN9F',
        Name: 'Fat (fatty acid 18:1 ; cis n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1F',
        Name: 'Fat (fatty acid 18:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1N7',
        Name: 'Fat (fatty acid 18:1 n-7)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1N7F',
        Name: 'Fat (fatty acid 18:1; n-7 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1N9',
        Name: 'Fat (fatty acid 18:1 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1N9F',
        Name: 'Fat (fatty acid 18:1; n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1T',
        Name: 'Fat, Trans (fatty acid trans 18:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1TN9',
        Name: 'Fat, Trans (fatty acid trans 18:1 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D1TN9F',
        Name: 'Fat, Trans (fatty acid 18:1 Trans ; n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2',
        Name: 'Fat (fatty acid 18:2)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2CN3',
        Name: 'Fat (fatty acid 18:2cis n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2CN3F',
        Name: 'Fat (fatty acid 18:2cis n-3F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2CN6',
        Name: 'Fat (fatty acid cis,cis 18:2 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2CN6F',
        Name: 'Fat (fatty acid cis,cis 18:2 n-6; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2F',
        Name: 'Fat (fatty acid 18:2; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2N4',
        Name: 'Fat (fatty acid 18:2 n-4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2N4F',
        Name: 'Fat (fatty acid 18:2 n-4F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2N6',
        Name: 'Fat (fatty acid 18:2, n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2N6F',
        Name: 'Fat (fatty acid 18:2, n-6F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2N9',
        Name: 'Fat (fatty acid 18:2 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2N9F',
        Name: 'Fat (fatty acid 18:2 n-9F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2TN6',
        Name: 'Fat, Trans (fatty acid 18:2trans n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D2TN6F',
        Name: 'Fat, Trans (fatty acid 18:2trans n-6F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D3',
        Name: 'Fat (fatty acid 18:3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D3CN3',
        Name: 'Fat (fatty acid cis, cis, cis 18:3 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D3F',
        Name: 'Fat (fatty acid 18:3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D3N3',
        Name: 'Fat (fatty acid 18:3 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D3N3F',
        Name: 'Fat (fatty acid 18:3 n-3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D3N6',
        Name: 'Fat (fatty acid 18:3 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D3N6F',
        Name: 'Fat (fatty acid 18:3 n-6; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D4',
        Name: 'Fat (fatty acid 18:4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D4F',
        Name: 'Fat (fatty acid 18:4; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D4N3',
        Name: 'Fat (fatty acid 18:4 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F18D4N3F',
        Name: 'Fat (fatty acid 18:4 n-3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F19D0',
        Name: 'Fat (fatty acid 19:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F19D0F',
        Name: 'Fat (fatty acid 19:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F19D1N7',
        Name: 'Fat (fatty acid 19:1 n-7)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F19D1N7F',
        Name: 'Fat (fatty acid 19:1; n-7 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D0',
        Name: 'Fat (fatty acid 20:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D0F',
        Name: 'Fat (fatty acid 20:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1',
        Name: 'Fat (fatty acid 20:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1F',
        Name: 'Fat (fatty acid 20:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1N11',
        Name: 'Fat (fatty acid 20:1 n-11)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1N11F',
        Name: 'Fat (fatty acid 20:1; n-11 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1N7',
        Name: 'Fat (fatty acid 20:1 n-7)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1N7F',
        Name: 'Fat (fatty acid 20:1; n-7 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1N9',
        Name: 'Fat (fatty acid 20:1 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D1N9F',
        Name: 'Fat (fatty acid 20:1; n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D2',
        Name: 'Fat (fatty acid 20:2)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D2CN6',
        Name: 'Fat (fatty acid cis, cis 20:2 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D2F',
        Name: 'Fat (fatty acid 20:2; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D2N6',
        Name: 'Fat (fatty acid 20:2 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D2N6F',
        Name: 'Fat (fatty acid 20:2 n-6F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3',
        Name: 'Fat (fatty acid 20:3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3F',
        Name: 'Fat (fatty acid 20:3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3N3',
        Name: 'Fat (fatty acid 20:3 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3N3F',
        Name: 'Fat (fatty acid 20:3 n-3F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3N6',
        Name: 'Fat (fatty acid 20:3 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3N6F',
        Name: 'Fat (fatty acid 20:3 n-6F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3N9',
        Name: 'Fat (fatty acid 20:3 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D3N9F',
        Name: 'Fat (fatty acid 20:3 n-9F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D4',
        Name: 'Fat (fatty acid 20:4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D4F',
        Name: 'Fat (fatty acid 20:4; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D4N3',
        Name: 'Fat (fatty acid 20:4 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D4N3F',
        Name: 'Fat (fatty acid 20:4 n-3F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D4N6',
        Name: 'Fat (fatty acid 20:4 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D4N6F',
        Name: 'Fat (fatty acid 20:4 n-6; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D5',
        Name: 'Fat (fatty acid 20:5)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D5F',
        Name: 'Fat (fatty acid 20:5; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D5N3',
        Name: 'Fat (fatty acid 20:5 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F20D5N3F',
        Name: 'Fat (fatty acid 20:5 n-3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F21D0',
        Name: 'Fat (fatty acid 21:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F21D0F',
        Name: 'Fat (fatty acid 21:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F21D5N3',
        Name: 'Fat (fatty acid 21:5 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F21D5N3F',
        Name: 'Fat (fatty acid 21:5 n-3F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D0',
        Name: 'Fat (fatty acid 22:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D0F',
        Name: 'Fat (fatty acid 22:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1',
        Name: 'Fat (fatty acid 22:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1CN11',
        Name: 'Fat (fatty acid cis 22:1 n-11)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1CN9',
        Name: 'Fat (fatty acid cis 22:1 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1CN9F',
        Name: 'Fat (fatty acid 22:1; cis n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1F',
        Name: 'Fat (fatty acid 22:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1N9',
        Name: 'Fat (fatty acid 22:1 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1N9F',
        Name: 'Fat (fatty acid 22:1; n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1TN9',
        Name: 'Fat, Trans (fatty acid trans 22:1 n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D1TN9F',
        Name: 'Fat, Trans (fatty acid trans 22:1; n-9 expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D2',
        Name: 'Fat (fatty acid 22:2)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D2F',
        Name: 'Fat (fatty acid 22:2; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D3',
        Name: 'Fat (fatty acid 22:3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D3F',
        Name: 'Fat (fatty acid 22:3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D4',
        Name: 'Fat (fatty acid 22:4)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D4F',
        Name: 'Fat (fatty acid 22:4; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D4N3',
        Name: 'Fat (fatty acid 22:4 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D4N3F',
        Name: 'Fat (fatty acid 22:4 n-3F)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D5',
        Name: 'Fat (fatty acid 22:5)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D5F',
        Name: 'Fat (fatty acid 22:5; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D5N3',
        Name: 'Fat (fatty acid 22:5 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D5N3F',
        Name: 'Fat (fatty acid 22:5 n-3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D5N6',
        Name: 'Fat (fatty acid 22:5 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D5N6F',
        Name: 'Fat (fatty acid 22:5 n-6; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D6',
        Name: 'Fat (fatty acid 22:6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D6F',
        Name: 'Fat (fatty acid 22:6; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D6N3',
        Name: 'Fat (fatty acid 22:6 n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F22D6N3F',
        Name: 'Fat (fatty acid 22:6 n-3; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F23D0',
        Name: 'Fat (fatty acid 23:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F23D0F',
        Name: 'Fat (fatty acid 23:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F23D1',
        Name: 'Fat (fatty acid 23:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F23D1F',
        Name: 'Fat (fatty acid 23:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F24D0',
        Name: 'Fat (fatty acid 24:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F24D0F',
        Name: 'Fat (fatty acid 24:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F24D1',
        Name: 'Fat (fatty acid 24:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F24D1C',
        Name: 'Fat (fatty acid cis 24:1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F24D1F',
        Name: 'Fat (fatty acid 24:1; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F24D6',
        Name: 'Fat (fatty acid 24:6; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F24D6F',
        Name: 'Fat (fatty acid 24:6 n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F4D0',
        Name: 'Fat, Total (fatty acid 4:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F4D0F',
        Name: 'Fat (fatty acid 4:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F5D0',
        Name: 'Fat (fatty acid 5:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F5D0F',
        Name: 'Fat (fatty acid 5:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F6D0',
        Name: 'Fat (fatty acid 6:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F6D0F',
        Name: 'Fat (fatty acid 6:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F8D0',
        Name: 'Fat (fatty acid 8:0)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'F8D0F',
        Name: 'Fat (fatty acid 8:0; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FACID',
        Name: 'Fat, Total (fatty acids, total)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FACIS',
        Name: 'Fat (FA, total, cis)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FACN3',
        Name: 'Omega 3 Acids (FA, total, cis n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FACN6',
        Name: 'Omega 6 Acids (FA, total, cis n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FACN9',
        Name: 'Fat (FA, total, cis n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FADT',
        Name: 'Fat, Trans (fatty acids, total double trans)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAESS',
        Name: 'Fat (fatty acids, total essential)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAESSF',
        Name: 'Fat (fatty acids, total essential; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAFRE',
        Name: 'Fat (fatty acids, total free)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAFREF',
        Name: 'Fat (fatty acids, total free; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAMS',
        Name: 'Fat, Monounsaturated (fatty acids, total monounsaturated)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAMSF',
        Name: 'Fat, Monounsaturated (fatty acids, total monounsaturated; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAN3',
        Name: 'Omega 3 Acids (FA, n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAN6',
        Name: 'Omega 6 Acids (FA, n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAN9',
        Name: 'Fat (FA, total, n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPU',
        Name: 'Fat, Polyunsaturated (fatty acids, total polyunsaturated)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUF',
        Name: 'Fat, Polyunsaturated (fatty acids, total polyunsaturated; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPULC',
        Name: 'Fat, Polyunsaturated (FA, polyunsat. long chain)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUN3',
        Name: 'Fat, Polyunsaturated- Omega 3 Acids (fatty acids, total n-3 polyunsaturated)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUN3F',
        Name: 'Fat, Polyunsaturated- Omega 3 Acids (fatty acids, total n-3 polyunsaturated; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUN3FI',
        Name: 'Fat, Polyunsaturated- Omega 3 Acids (FA, polyunsat.n-3, fish)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUN3VE',
        Name: 'Fat, Polyunsaturated- Omega 3 Acids (FA, polyunsat.n-3, vegetables)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUN6',
        Name: 'Fat, Polyunsaturated- Omega 6 Acids (fatty acids, total n-6 polyunsaturated)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUN6F',
        Name: 'Omega 6 Acids (fatty acids, total n-6 polyunsaturated; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAPUN9',
        Name: 'Fat (FA, polyunsat.cis n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FASATF',
        Name: 'Fat, Saturated (fatty acids, total saturated; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAT-',
        Name: 'Fat, Total (fat, unknown)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATAN',
        Name: 'Fat (Fat, animal)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATCAN',
        Name: 'Fat (Fat, animal, Old code)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATCE',
        Name: 'Fat, Total (fat, total; derived by analysis using continuous extraction)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATCPL',
        Name: 'Fat (Fat, plant, Old code)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATN3',
        Name: 'Fat, Trans (FA, total, trans, n-3)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATN6',
        Name: 'Fat, Trans (FA, total, trans n-6)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATN9',
        Name: 'Fat, Trans (FA, total, trans n-9)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATNLEA',
        Name: 'Fat, Total (total fat by NLEA definition (triglyceride equivalents of fatty acids))',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATPL',
        Name: 'Fat, Plant (Fat, plant)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATRN',
        Name: 'Fat, Trans (fatty acids, total trans)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATRNF',
        Name: 'Fat, Trans (fatty acids, total trans; expressed per quantity of total fatty acids)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATRNM',
        Name: 'Fat, Trans (fatty acids, total trans-monoenoic)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FATRNP',
        Name: 'Fat, Trans (fatty acids, total trans-polyenoic)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FAUN',
        Name: 'Fat, Other (Fatty acids, other, non specified from Danish FCT)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FBRN',
        Name: 'Fibrin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FE2+',
        Name: 'Iron (Ferrous)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FE3+',
        Name: 'Iron (Ferric)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FEMFP',
        Name: 'Iron (MFP iron)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FERAC',
        Name: 'Iron (ferulic acid)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIB-',
        Name: 'Fibre; Method Of Determination Unknown Or Variable',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBAD',
        Name: 'Fibre; Determined By Acid Detergent',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBADC',
        Name: 'Fibre, Acid Detergent Method, Clancy',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBC',
        Name: 'Fibre, Crude',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBHEX',
        Name: 'Hexoses In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBINS',
        Name: 'Fibre, Water-Insoluble',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBND',
        Name: 'Fibre; Determined By Neutral Detergent',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBPEN',
        Name: 'Pentoses In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBSOL',
        Name: 'Fibre, Water-Soluble',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBTS',
        Name: 'Dietary Fibre, total (fibre, total dietary; sum of non-starch polysaccharide components and lignin)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FIBTSW',
        Name: 'Dietary Fibre, total (fibre, total dietary; Wenlock modification)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FOL',
        Name: 'Folate, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FOL-',
        Name: 'Folate Unknown',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FOLAC',
        Name: 'Folic Acid, Synthetic Folic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FOLC',
        Name: 'Folate, Conjugated',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FOLFD',
        Name: 'Folate Food, Naturally Occuring Food Folates',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FOLFRE',
        Name: 'Folate, Free',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FOLSUM',
        Name: 'Folate, Sum Vitamers',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FORAC',
        Name: 'Formic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FORMO',
        Name: 'Formononetin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FRUFB',
        Name: 'Fructose In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FRUOLSAC',
        Name: 'Fructooligosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FRUOLSACM',
        Name: 'Fructooligosaccharide; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FRUS',
        Name: 'Fructose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FRUTN',
        Name: 'Fructan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FRUTNM',
        Name: 'Fructan; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FUCOS',
        Name: 'Fucose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FUCSTR',
        Name: 'Fucosterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FUCSTR28',
        Name: '28-Iso-Fucosterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'FUMAC',
        Name: 'Fumaric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALAAC',
        Name: 'Galacturonic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALCATEGC',
        Name: 'Gallocatechin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALFB',
        Name: 'Galactose In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALLAC',
        Name: 'Gallic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALMN',
        Name: 'Galactomannan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALMNM',
        Name: 'Galactomannan; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALS',
        Name: 'Galactose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALTL',
        Name: 'Galactitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALTN',
        Name: 'Galactan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GALURAC',
        Name: 'Galacturonic Acid D-Galacturonic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLN',
        Name: 'Glutamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLNN',
        Name: 'Glutamine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLNP',
        Name: 'Glutamine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLOB',
        Name: 'Globulin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLU',
        Name: 'Glutamic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUAKAC',
        Name: 'Alpha-Keto-Glutaric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUCAC',
        Name: 'Gluconic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUCNB',
        Name: 'Betaglucan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUFB',
        Name: 'Glucose In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUMN',
        Name: 'Glucomannan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUMNM',
        Name: 'Glucomannan; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUN',
        Name: 'Glutamic Acid; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUP',
        Name: 'Glutamic Acid; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUS',
        Name: 'Glucose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLUTN',
        Name: 'Gluten',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLY',
        Name: 'Glycine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYC',
        Name: 'Glycogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCER-',
        Name: 'Glycerides, Total; Method Of Determination Unknown Or Variable',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCERA',
        Name: 'Glycerides, Total; Determined By Analysis',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCERC',
        Name: 'Glycerides, Total; Calculated From Fatty Acid Composition',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCLAC',
        Name: 'Glycolic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCM',
        Name: 'Glycogen; Expressed In Monosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCTEIN',
        Name: 'Glycitein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCTN',
        Name: 'Glycitin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCTNA',
        Name: 'Acetyl-Glycitin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYCTNM',
        Name: 'Malonyl-Glycitin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYLIP',
        Name: 'Glycolipids, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYN',
        Name: 'Glycine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYP',
        Name: 'Glycine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GLYRL',
        Name: 'Glycerol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GNSTEIN',
        Name: 'Genistein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GNSTIN',
        Name: 'Genistin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GNSTINA',
        Name: 'Acetyl-Genistin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GNSTINM',
        Name: 'Malonyl-Genistin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GPCHOLN',
        Name: 'Glycerophosphocholine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GULDKAC',
        Name: 'Di-Keto-Cholanic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'GUMS',
        Name: 'Gums',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HAEM',
        Name: 'Iron, Haem',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HB',
        Name: 'Hemoglobin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HEMCEL',
        Name: 'Hemicellulose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HESPD',
        Name: 'Hesperidin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HESPT',
        Name: 'Hesperetin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HEXSN',
        Name: 'Hexosan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HG',
        Name: 'Mercury',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HG1+',
        Name: 'Mercury 1+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HG2+',
        Name: 'Mercury 2+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HGME',
        Name: 'Methyl Mercury',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HIS',
        Name: 'Histidine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HISN',
        Name: 'Histidine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HISP',
        Name: 'Histidine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HISTN',
        Name: 'Histamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HYL',
        Name: 'Hydroxylysine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HYP',
        Name: 'Hydroxyproline',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HYPN',
        Name: 'Hydroxyproline; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'HYPP',
        Name: 'Hydroxyproline; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ID131',
        Name: 'Iodide 131',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ILE',
        Name: 'Isoleucine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ILEN',
        Name: 'Isoleucine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ILEP',
        Name: 'Isoleucine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'INOTL',
        Name: 'Inositol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'INULN',
        Name: 'Inulin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ISOCAC',
        Name: 'Isocitric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ISOFLVND',
        Name: 'Total Isoflavonoids',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ISOMALT',
        Name: 'Isomalt',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'KAEMF',
        Name: 'Kaempferol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LA',
        Name: 'Lanthanum',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LACAC',
        Name: 'Lactic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LACACD',
        Name: 'D-Lactic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LACACL',
        Name: 'L-Lactic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LACS',
        Name: 'Lactose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LACSM',
        Name: 'Lactose; Expressed In Monosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LACTL',
        Name: 'Lactitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LEU',
        Name: 'Leucine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LEUN',
        Name: 'Leucine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LEUP',
        Name: 'Leucine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LI',
        Name: 'Lithium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LIGN',
        Name: 'Lignin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LIGNANS',
        Name: 'Lignans, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LUTEOL',
        Name: 'Luteolin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LUTN',
        Name: 'Lutein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LYCPN',
        Name: 'Lycopene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LYS',
        Name: 'Lysine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LYSN',
        Name: 'Lysine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'LYSP',
        Name: 'Lysine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALAC',
        Name: 'Malonic acid from Slovak table. propanedioic acid, methanedicarboxylic acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALS',
        Name: 'Maltose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALSM',
        Name: 'Maltose; Expressed In Monosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALTDEX',
        Name: 'Maltodextrin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALTDEXM',
        Name: 'Maltodextrin M',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALTL',
        Name: 'Maltitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALTRS',
        Name: 'Maltotriose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALTRSM',
        Name: 'Maltotriose; Expressed In Monosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MALVIDIN',
        Name: 'Malvidin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MAN',
        Name: 'Mannose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MANFB',
        Name: 'Mannose In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MANN',
        Name: 'Mannan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MANNM',
        Name: 'Mannan; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MANTL',
        Name: 'Mannitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MATAIRES',
        Name: 'Matairesinol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MB',
        Name: 'Myoglobin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MEA',
        Name: 'Ethanolamines, Total Emulsifier',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MET',
        Name: 'Methionine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'METH',
        Name: 'Methyl Alcohol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'METN',
        Name: 'Methionine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'METP',
        Name: 'Methionine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MGLY',
        Name: 'Monoglycerides, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK10',
        Name: 'Menaquinone-10',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK11',
        Name: 'Menaquinone-11',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK12',
        Name: 'Menaquinone-12',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK13',
        Name: 'Menaquinone-13',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK4',
        Name: 'Menaquinone-4',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK5',
        Name: 'Menaquinone-5',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK6',
        Name: 'Menaquinone-6',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK7',
        Name: 'Menaquinone-7',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK8',
        Name: 'Menaquinone-8',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MK9',
        Name: 'Menaquinone-9',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MN2+',
        Name: 'Manganese 2+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MN3+',
        Name: 'Manganese3+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MNSAC',
        Name: 'Monosaccharides, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MO4+',
        Name: 'Molybdenum 4+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MO6+',
        Name: 'Molybdenum 6+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MOLAC',
        Name: 'Malonic acid from Slovak table.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MUCIL',
        Name: 'Mucilages',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'MYRIC',
        Name: 'Myricetin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NAM',
        Name: 'Nitrogen, Amino',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NARIN',
        Name: 'Naringin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NARING',
        Name: 'Naringenin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NARIR',
        Name: 'Narirutin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NHAEM',
        Name: 'Iron, Non-Haem',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NI',
        Name: 'Nickel',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NI2+',
        Name: 'Nickel 2+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NI3+',
        Name: 'Nickel 3+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NIAAVL',
        Name: 'Niacin, Available',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NIAEQ',
        Name: 'Niacin Equivalents, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NIATRP',
        Name: 'Niacin Equivalents From Tryptophan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NITRA',
        Name: 'Nitrates',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NITRI',
        Name: 'Nitrites',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NITRN',
        Name: 'Nitrosamine, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NITRNN',
        Name: 'Nitrosamine, Non-Volatile',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NITRNV',
        Name: 'Nitrosamine, Volatile',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NNP',
        Name: 'Nitrogen, Non-Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NPR',
        Name: 'Nitrogen-Protein Ratio',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NPRO',
        Name: 'Nitrogen, Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NPU',
        Name: 'Net Protein Utilization',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NSP',
        Name: 'Polysaccharides (NSP; Englyst)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'NT',
        Name: 'Nitrogen, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'OA',
        Name: 'Organic Acids, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'OLSAC',
        Name: 'Oligosaccharides, Total Available',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'OLSACM',
        Name: 'Oligosaccharides, Total Available; Expressed In Monosaccharide Equivalents',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ORN',
        Name: 'Ornithine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'OXACAC',
        Name: 'Oxaloacetic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'OXALAC',
        Name: 'Oxalic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PARHBAC',
        Name: 'Parahydrobenzoic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PB',
        Name: 'Lead',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PB2+',
        Name: 'Lead 2+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PB4+',
        Name: 'Lead 4+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PCHOLN',
        Name: 'Phosphocholine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PECT',
        Name: 'Pectin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PENSN',
        Name: 'Pentosan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PEONIDIN',
        Name: 'Peonidin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PER',
        Name: 'Protein Efficiency Ratio',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PETUNIDIN',
        Name: 'Petunidin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PH',
        Name: 'Ph, Hydrogen Ion Concentration',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHE',
        Name: 'Phenylalanine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHEN',
        Name: 'Phenylalanine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHEP',
        Name: 'Phenylalanine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHETN',
        Name: 'Phenylethylamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHOA',
        Name: 'Phosphatidic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHOETHN',
        Name: 'Phosphatidylethanolamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHOINOTL',
        Name: 'Phosphatidylinositol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHOLIP',
        Name: 'Phospholipids, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHOSER',
        Name: 'Phosphatidylserine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHYSTR',
        Name: 'Phytosterols, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHYTAC',
        Name: 'Phytic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PHYTSTRU',
        Name: 'Phytosterol, Unsat.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PIPN',
        Name: 'Piperine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PLG',
        Name: 'Plasmalogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'POLYSAC',
        Name: 'Polysaccharides, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PRO',
        Name: 'Proline',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROA',
        Name: 'Protein, Total; Determined By Direct Analysis',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROANI',
        Name: 'Protein From Animal Sources',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROCNA',
        Name: 'Protein, Total; Calculated From Amino',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROCNP',
        Name: 'Protein, Total; Calculated From Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROCNT',
        Name: 'Protein, Total; Calculated From Total Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROCYA',
        Name: 'Procyanidins, Tot.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PRON',
        Name: 'Proline; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROP',
        Name: 'Proline; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROPAC',
        Name: 'Propionic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROPLA',
        Name: 'Protein From Plant Sources',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROT',
        Name: 'Protein, Tot., Cal. From Tot.Nitro.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROTAN',
        Name: 'Protein, Animal',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROTMFP',
        Name: 'Mfp Protein (Meat, Fish, Poultry',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PROTPL',
        Name: 'Protein, Plant',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PSACALG',
        Name: 'Polysaccharides, Algal',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PSACNC',
        Name: 'Polysaccharides, Non-Cellulosic',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PSACNCI',
        Name: 'Polysaccharides, Non-Cellulosic, Water-Insoluble',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PSACNCS',
        Name: 'Polysaccharides, Non-Cellulosic, Water-Soluble',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PSACNS',
        Name: 'Polysaccharides, Non-Starch',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PSACNSI',
        Name: 'Polysaccharides, Non-Starch, Water-Insoluble',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PSACNSS',
        Name: 'Polysaccharides, Non-Starch, Water-Soluble',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PU239',
        Name: 'Plutonium 239',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PURAC',
        Name: 'Polyuronic Acids',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PURN',
        Name: 'Purines',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PUTRSC',
        Name: 'Putrescine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PYRAC',
        Name: 'Pyruvic a+D705:E1002cid Propanoic acid, BTS;',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PYRUAC',
        Name: 'Pyruvic acid USDA 247, SFK',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PYRXL',
        Name: 'Pyridoxal',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PYRXM',
        Name: 'Pyridoxamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PYRXN',
        Name: 'Pyridoxine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'PYRXNHCL',
        Name: 'Pyridoxine Hcl',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'QUERCE',
        Name: 'Quercetin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'QUINAC',
        Name: 'Quinic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RAFS',
        Name: 'Raffinose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RAFSM',
        Name: 'Raffinose, Expressed In Monosaccharide Equivalents',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RB',
        Name: 'Rubidium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'REFUSE',
        Name: 'Refuse',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RETAL',
        Name: 'Retinal',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RETALD',
        Name: 'Retinaldehyde',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RETINAC',
        Name: 'Retinoic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RETOL',
        Name: 'Retinol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RETOL13',
        Name: '13-Cis Retinol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RETOLDH',
        Name: 'Dehydroretinol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RETOLEQ',
        Name: 'All-Trans Retinol Equivalent',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RHAFB',
        Name: 'Rhamnose In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RHAS',
        Name: 'Rhamnose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RIBS',
        Name: 'Ribose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RUBA',
        Name: 'Rubner Energy Cf Choav',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RUBT',
        Name: 'Rubner Energy Cf Cho Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'RUTIN',
        Name: 'Rutin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'S',
        Name: 'Sulphur',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'S4+',
        Name: 'Sulfur 4+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'S6+',
        Name: 'Sulfur 6+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SACCNA',
        Name: 'Na-Saccharin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SALAC',
        Name: 'Salicylic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SB',
        Name: 'Antimony',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SE4+',
        Name: 'Selenium 4+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SE6+',
        Name: 'Selenium 6+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SECORES',
        Name: 'Secoisolarisiresinol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SEIO',
        Name: 'Selenium, Inorganic',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SEO',
        Name: 'Selenium, Organic',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SER',
        Name: 'Serine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SERN',
        Name: 'Serine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SEROTN',
        Name: 'Serotonin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SERP',
        Name: 'Serine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SHIKAC',
        Name: 'Shikimic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SI',
        Name: 'Silicon',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SI2+',
        Name: 'Silicon 2+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SI4+',
        Name: 'Silicon 4+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SITSTR',
        Name: 'Sitosterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SN',
        Name: 'Tin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SOLID',
        Name: 'Solids, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SOLO',
        Name: 'Other Solids',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SORAC',
        Name: 'Sorbic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SORTL',
        Name: 'Sorbitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SPERDN',
        Name: 'Spermindine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SPERN',
        Name: 'Spermine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SPHL',
        Name: 'Sphingolipid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SPISTR',
        Name: 'Spinasterol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SR',
        Name: 'Strontium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SR90',
        Name: 'Strontium 90',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STAHY',
        Name: 'Starch, Part. Hydrolysed',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STAHYM',
        Name: 'Starch, Part. Hydrolysed; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STAMO',
        Name: 'Starch, Modified',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STAMOM',
        Name: 'Starch, Modif.; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STARCH-',
        Name: 'Starch, Avl, Expression Unknown',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STARCHM',
        Name: 'Starch, Total; Expressed In Monosaccharide Equivalents',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STARES',
        Name: 'Starch, Resistant',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STARES1',
        Name: 'Starch, Resist. Rs1',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STARES2',
        Name: 'Starch, Resist. Rs2',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STARES3',
        Name: 'Starch, Resist.Rs3',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STARES4',
        Name: 'Starch, Resist. Rs4',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STAS',
        Name: 'Stachyose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STASM',
        Name: 'Stachyose; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STEOTH',
        Name: 'Sterols, Other',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STERFRE',
        Name: 'Sterols, Free',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STERT',
        Name: 'Sterols, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STGSTR',
        Name: 'Stigmasterol (stigmasterol )',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STID7',
        Name: 'Stigmasterol (delta 7-stigmasterol)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'STID7911',
        Name: 'Stigmasterol (delta 7911-stigmastadienol)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUCAC',
        Name: 'Succinic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUCS',
        Name: 'Sucrose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUCSM',
        Name: 'Sucrose; Expressed In Monosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUGAD',
        Name: 'Sugar, Added',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUGAN',
        Name: 'Sugars, Natural',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUGARM',
        Name: 'Sugars, Total; Expressed In Monosaccharide',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUGIN',
        Name: 'Invert Sugar',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUGNRD',
        Name: 'Sugars, Non-Reducing',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'SUGRD',
        Name: 'Sugars, Reducing',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TAN',
        Name: 'Tannins',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TANGER',
        Name: 'Tangeretin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TARAC',
        Name: 'Tartaric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TAU',
        Name: 'Taurine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TAUN',
        Name: 'Taurine Based On Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TGLY',
        Name: 'Triglycerides, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'THEBRN',
        Name: 'Theobromine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'THF',
        Name: 'Tetrahydrofolate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'THR',
        Name: 'Threonine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'THRN',
        Name: 'Threonine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'THRP',
        Name: 'Threonine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TI',
        Name: 'Titanum',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCPHA',
        Name: 'Alpha-Tocopherol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCPHB',
        Name: 'Beta-Tocopherol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCPHD',
        Name: 'Delta-Tocopherol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCPHG',
        Name: 'Gamma-Tocopherol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCPHT',
        Name: 'Total Tocopheral',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCTRA',
        Name: 'Alpha-Tocotrienol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCTRB',
        Name: 'Beta-Tocotrienol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCTRD',
        Name: 'Delta-Tocotrienol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCTRG',
        Name: 'Gamma-Tocotrienol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TOCTRT',
        Name: 'Tocotrienols, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TRES',
        Name: 'Trehalose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TRP',
        Name: 'Tryptophan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TRPN',
        Name: 'Tryptophan; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TRPP',
        Name: 'Tryptophan; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TRYPN',
        Name: 'Tryptamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TYR',
        Name: 'Tyrosine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TYRA',
        Name: 'Tyramine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TYRN',
        Name: 'Tyrosine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'TYRP',
        Name: 'Tyrosine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'UNSAP',
        Name: 'Unsaponifiable Matter',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'V',
        Name: 'Vanadium',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'V4+',
        Name: 'Vanadium 4+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'V5+',
        Name: 'Vanadium 5+',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VAL',
        Name: 'Valine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VALN',
        Name: 'Valine; Expressed Per Quantity Of Nitrogen',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VALP',
        Name: 'Valine; Expressed Per Quantity Of Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VERS',
        Name: 'Verbascose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VERSM',
        Name: 'Verbascose; Monosacch. Eq',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITA',
        Name: 'Vitamin A; Calculated By Summation Of The Vitamin A Activities Of Retinol And The Active Carotenoids',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITAA',
        Name: 'Vitamin A; Determined By Bioassay',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITAACT',
        Name: 'Vitamin A Acetate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITAPAL',
        Name: 'Vitamin A Palmitate',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITB6A',
        Name: 'Vitamin B-6, Total; Determined By Analysis',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITB6C',
        Name: 'Vitamin B-6, Total; Calculated By Summation',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITC',
        Name: 'Vitamin C',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITD',
        Name: 'Vitamin D (vitamin D; calculated by summation of ergocalciferol and cholecalciferol)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITDA',
        Name: 'Vitamin D (vitamin D; determined by bioassay)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITDEQ',
        Name: 'Vitamin D3 (Vit D3 + 5 x 25 hydroxyD2)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITE',
        Name: 'Vitamin E; Calculated By Summation Of The Vitamin E Activities Of The Active Tocopherols And Tocotrienols; Expressed As Alpha-Tocopherol Equivalents',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITEA',
        Name: 'Vitamin E; Determined By Bioassay',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITK1',
        Name: 'Vitamin K-1',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITK1D',
        Name: 'Vitamin K-1 (dihydro-vita K-1)',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'VITK2',
        Name: 'Vitamin K-2',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'WATER',
        Name: 'Water',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'WAX',
        Name: 'Wax, Total',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'WHEY',
        Name: 'Whey Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ACEROLA_JUICE_CONCENTRATE',
        Name: 'Acerola juice concentrate',
        Description:
            'GS1 Tagname Description: A juice concentrate made from acerola tree cherries (bot.: malpighia glabra). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_AGAVE',
        Name: 'Agave Syrup',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BLACK_NETTLE_EXTRACT',
        Name: 'Black nettle extract',
        Description:
            'GS1 Tagname Description: An extract made from black nettle (bot.: ballota nigra). Commenly, five phenylpropanoids (verbascoside, forsythoside B, arenarioside, ballotetroside, and caffeoyl malic acid) are isolated from a hydroalcoholic extract. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_BUCKWHEAT_GERM_POWDER',
        Name: 'Buckwheat germ powder',
        Description:
            'GS1 Tagname Description: A dry powder made from buckwheat germ (bot.: fagopyrum) which is used as a source of vitamin B. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_CASNWH',
        Name: 'Casein to Whey Protein Ratio',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_FLAVONOIDS',
        Name: 'Flavonoids',
        Description:
            'GS1 Tagname Description: Flavonoids (lat.: flavus meaning yellow) are a class of polyphenolic secondary metabolites found in plants. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_FUNS',
        Name: 'Fat, Total Unsaturated',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GARLIC_POWDER',
        Name: 'Garlic powder',
        Description:
            'GS1 Tagname Description: Garlic powder is derived from dehydrated garlic (bot.: allium sativum). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GLUCMN',
        Name: 'Glucosamine',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GLUCOSAMINE_HYDROCHLORIDE',
        Name: 'Glucosamine hydrochloride',
        Description:
            'GS1 Tagname Description: Glucosamine hydrochloride is similiar to glucosamine, an amino sugar, but has a different chemical structure. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GLUCOSAMINE_SULPHATE_POTASSIUM_CHLORIDE',
        Name: 'Glucosamine sulphate potassium chloride',
        Description:
            'GS1 Tagname Description: Glucosamine sulphate potassium chloride is a naturally occurring chemical found in the human body and is used in dietary supplements. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GOOSEBERRY_LEAVES',
        Name: 'Gooseberry leaves',
        Description:
            'GS1 Tagname Description: Gooseberry leaves (bot.: withania somnifera folium) are eatable leaves gathered from the gooseberry bush. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_GOOSEBERRY_ROOT_EXTRACT',
        Name: 'Gooseberry root extract',
        Description:
            'GS1 Tagname Description: An extract made from gooseberry roots (bot.: withaniae somnifera radix) - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_HAWTHORN_EXTRACT',
        Name: 'Hawthorn extract',
        Description:
            'GS1 Tagname Description: A liquid or dry extract made from hawthorn (bot.: crataegus) - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_ICELAND_MOSS_EXTRACT',
        Name: 'Iceland moss extract',
        Description:
            'GS1 Tagname Description: Iceland moss extract (bot.: cetraria islandica) - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LADYS_MANTLE_EXTRACT',
        Name: "Lady's mantle extract",
        Description:
            "GS1 Tagname Description: A concentrated liquid extract made from lady's mantle (bot.: alchemilla vulgaris). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:",
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_LIME_BLOSSOM_EXTRACT',
        Name: 'Lime blossom extract',
        Description:
            'GS1 Tagname Description: A liquid or dry extract made from lime blossoms (bot.: tilia vulgaris) which contains flavonoids and glycosides. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MAITAKE_POWDER',
        Name: 'Maitake powder',
        Description:
            'GS1 Tagname Description: A dry powder made from maitake mushrooms (bot.: grifola frondosa). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MALLOW_FLOWERS_EXTRACT',
        Name: 'Mallow flowers extract',
        Description:
            'GS1 Tagname Description: Mallow flowers extract is a glycerinic-aqueous extract made from mallow flowers (bot.: malvae flos) - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MALLOW_LEAVES_EXTRACT',
        Name: 'Mallow leaves extract',
        Description:
            'GS1 Tagname Description: Mallow leaves extract is a glycerinic-aqueous extract made from mallow leaves (bot.: malvae folium) - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MARSHMALLOW_ROOT_EXTRACT',
        Name: 'Marshmallow root extract',
        Description:
            'GS1 Tagname Description: A liquid or dry extract made from marshmallow root (bot.: althaea radix) which looks like a brown, fibrous husk. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_MILK_THISTLE_EXTRACT',
        Name: 'Milk thistle extract',
        Description:
            'GS1 Tagname Description: Milk thistle extract is an herbal remedy derived from the milk thistle plant, also known as silybum marianum. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_PASSION_FLOWER_EXTRACT',
        Name: 'Passion flower extract',
        Description:
            'GS1 Tagname Description: An extract made from passion flower (bot.: passiflora) which is used for flavoring of food and beverages - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_PLANTAIN_LEAVES_EXTRACT',
        Name: 'Plantain leaves extract',
        Description:
            'GS1 Tagname Description: A dry or liquid extract made from plantain leaves (bot.: plantago folium). The most important components of the leaves are catalpol, aucubin, and acteoside - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_PROSOL',
        Name: 'Soluble Protein',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_RED_WINE_GRAPE_CONCENTRATE',
        Name: 'Red wine grape concentrate',
        Description:
            'GS1 Tagname Description: A liquid concentrate made from red wine grape juice (bot.: vitis vinifera rubrum). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SACCHAROMYCES_CEREVISIAE_CONCENTRATE',
        Name: 'Saccharomyces cerevisiae concentrate',
        Description:
            'GS1 Tagname Description: A concentrate made from saccharomyces cerevisiae which is a species of yeast. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SALATRIM',
        Name: 'Salatrim',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SILYMARIN',
        Name: 'Silymarin',
        Description:
            'GS1 Tagname Description: Silymarin is the active compound in milk thistle seeds. It can be extracted for example from artichoke hearts. - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SPIRULINA_ALGAE_POWDER',
        Name: 'Spirulina algae powder',
        Description:
            'GS1 Tagname Description: A dry powder made from spirulina algae with a tipically green color (bot.: spirulina). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_SUCRA',
        Name: 'Sucralose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_THYME_EXTRACT',
        Name: 'Thyme extract',
        Description:
            'GS1 Tagname Description: A liquid or dry extract made from thyme (bot.: thymum). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'X_VITAMIN_B_COMPLEX',
        Name: 'Vitamin B complex',
        Description:
            'GS1 Tagname Description: B-complex vitamins usually contain the following 8 vitamins: B1 (thiamine), B2 (riboflavin), B3 (niacin), B5 (pantothenic acid), B6 (pyridoxine), B7 (biotin), B9 (folate) and B12 (cobalamin). - UOM for Nutrient Panel: - GS1 Notes, Keywords, and Examples: - GS1 Comment: GS1 temporary code - GS1 Synonyms:',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XACEAC',
        Name: 'Energy Cf For Acetic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XALC',
        Name: 'Energy Cf For Alcohol.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XATT',
        Name: 'Conv. Fact. For Ate Of A-Tocotrienol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XBCARTA',
        Name: 'Conv. Fact. For B-Carot. Eq. Of A-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XBCARTG',
        Name: 'Conv. Fact. For B-Carot. Eq. Of Gamma-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XBCRYPXA',
        Name: 'Conv. Fact. For B-Carot. Eq. Of A-Cryptoxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XBCRYPXB',
        Name: 'Conv. Fact. For B-Carot. Eq. Of B-Cryptoxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XBENAC',
        Name: 'Energy Cf For Benzoic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XBTP',
        Name: 'Conv. Fact. For Ate Of B-Tocopherol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XBTT',
        Name: 'Conv. Fact. For Ate Of B-Tocotrienol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XCHOAVL',
        Name: 'Energy Cf Choavl',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XCHOAVLM',
        Name: 'Energy Cf Choavlm',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XCHOCALOH',
        Name: 'Conv. Fact. 25 Hydroxyd2',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XCHOCDF',
        Name: 'Energy Cf Chocdf',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XCITAC',
        Name: 'Energy Cf For Citric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XDTP',
        Name: 'Conv. Fact. For Ate Of D-Tocopherol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XFAT',
        Name: 'Energy Cf For Fat.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XFOLAC',
        Name: 'Conv. Fact. For Diet. Folate Eq. Of Folic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XFUMAC',
        Name: 'Energy Cf For Fumaric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XGTP',
        Name: 'Conv. Fact. For Ate Of G-Tocopherol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XGTT',
        Name: 'Conv. Fact. For Ate Of G-Tocotrienol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XGULDKAC',
        Name: 'Energy Cf For Di-Keto-Cholanic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XINOTL',
        Name: 'Energy Cf For Inositol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XISOCAC',
        Name: 'Energy Cf For Iso-Citric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XLACAC',
        Name: 'Energy Cf For Lactic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XLACACD',
        Name: 'Energy Cf For D-Lactic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XLACACL',
        Name: 'Energy Cf For L-Lactic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XMALAC',
        Name: 'Energy Cf For Malic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XMANTL',
        Name: 'Energy Cf For Mannitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XOA',
        Name: 'Energy Cf For Organic Acids',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XOXALAC',
        Name: 'Energy Cf For Oxalic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XPHYTAC',
        Name: 'Energy Cf For Phytic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XPOLYL',
        Name: 'Energy Cf For Total Polyols',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XPROPAC',
        Name: 'Energy Cf For Propionic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XPROT',
        Name: 'Energy Cf For Protein.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XQUINAC',
        Name: 'Energy Cf For Quinic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRCARTG',
        Name: 'Conv. Fact. For Re Of Gamma-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRECARTA',
        Name: 'Conv. Fact. For Re Of A-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRECARTB',
        Name: 'Conv. Fact. For Re Of B-Carotene',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRECARTBEQ',
        Name: 'Conv. Fact. For Re Of B-Carotene Eq.',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRECRYPXA',
        Name: 'Conv. Fact. For Re. Of A-Cryptoxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRECRYPXB',
        Name: 'Conv. Fact. For Re. Of B-Cryptoxanthin',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRETALD',
        Name: 'Conv. Fact. Ret. Eq. For Retinaldehyde',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XRETOL13',
        Name: 'Conv. Fact. Ret. Eq. For 13-Cis Retinol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XSALAC',
        Name: 'Energy Cf For Salicylic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XSORTL',
        Name: 'Energy Cf For Sorbitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XSUCAC',
        Name: 'Energy Cf For Succinic Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XTARAC',
        Name: 'Energy Cf For Tartaric Acid',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XTRP',
        Name: 'Con. Fact. For Niacin Eq. Of Tryptophan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XXYLTL',
        Name: 'Energy Cf For Xylitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XYLFB',
        Name: 'Xylose In Dietary Fibre',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XYLN',
        Name: 'Xylan',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XYLS',
        Name: 'Xylose',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'XYLTL',
        Name: 'Xylitol',
        Description: '',
        'Used in Sweden': '',
    },
    {
        'Code value': 'ZEA',
        Name: 'Zeaxanthin',
        Description: '',
        'Used in Sweden': '',
    },
];
