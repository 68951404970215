import { TreeSelect } from 'antd';
import { IdentifierGroup } from 'src/model/group';
import { ArrayElement, notGuard, notNullGuard } from '../../utils/typescript';
import React, { ComponentProps } from 'react';

interface IProps {
    groups: IdentifierGroup[];
    extraProps: any;
    selected: string[];
    onChange: (groupIds: string[]) => void;
}

type TreeSelectOptionType = ArrayElement<NonNullable<ComponentProps<typeof TreeSelect>['treeData']>>;
const buildTreeData = (
    v: IdentifierGroup,
    path: React.Key[] = [],
    appendix?: (group: IdentifierGroup, isRoot: boolean, key: React.Key[]) => JSX.Element | undefined,
    extraDataNode?: (path: React.Key[]) => TreeSelectOptionType
): TreeSelectOptionType | null => {
    if (!v) return null;

    const key = path.join('-');
    const children = v.children?.data
        ?.map((v) => buildTreeData(v, [...path, v.id], appendix, extraDataNode))
        .filter(notNullGuard);
    const isRoot = path.length === 1;
    const amountOfChildren = v.totalProducts;

    return {
        selectable: !isRoot,
        key: [...path].join('-'),
        value: [...path].join('-'),
        title: (
            <>
                {v.name}
                {` (${amountOfChildren}) `}
                {appendix?.(v, isRoot, [key])}
            </>
        ),
        children: [...(children || []), extraDataNode?.(path)].filter(notGuard),
    };
};

const GroupTreeSelect = (props: IProps) => {
    const { groups, extraProps, selected, onChange } = props;
    const data = groups.map((group) => buildTreeData(group, [group.id]));

    return (
        <>
            <TreeSelect
                {...extraProps}
                value={selected ? selected : undefined}
                onChange={(values) => onChange(values.toString().split(','))}
                treeData={data}
                treeCheckable
                showCheckedStrategy={TreeSelect.SHOW_PARENT}
            />
        </>
    );
};

export default GroupTreeSelect;
