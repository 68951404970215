/**
 * Example of query
 *
 * const q: ProductQuery = {
 * or: [
 *      {
 *          and: [
 *              {
 *                  brandName: {
 *                      contains: 'a',
 *                   },
 *              },
 *              {
 *                  brandName: {
 *                      eq: 'e',
 *                   },
 *              },
 *          ],
 *        },
 *      {
 *          and: [
 *              {
 *                  brandName: {
 *                      contains: 'a',
 *                   },
 *              },
 *              {
 *                  isVegan: {
 *                      eq: 'maybe',
 *                   },
 *              },
 *          ],
 *        },
 *    ],
 * };
 *
 */
import { YesNoMaybe } from './ingredients';

type StringComparator = {
    eq?: string;
    includes?: string;
    startsWith?: string;
    endsWith?: string;
};
export type IDComparator = {
    eq?: string;
    maximumAmount?: number;
    minimumAmount?: number;
    exactAmount?: number;
};

type DateComparator = {
    eq?: Date;
    gte?: Date;
    lte?: Date;
};

type BooleanComparator = {
    eq?: boolean;
};

type YesNoMaybeComparator = {
    eq?: YesNoMaybe;
};

export enum Unit {
    GRM = 'GRM',
    KJO = 'KJO',
    MLT = 'MLT',
}
export enum QuantityUnit {
    GRM = 'GRM',
    MLT = 'MLT',
}
export type NutrientComparator = {
    eq?: number;
    gte?: number;
    lte?: number;
    nutrient: string;
    unit: Unit;
    baseQuantityUnit: QuantityUnit;
};

export type CountComparator = {
    eq?: number;
    gte?: number;
    lte?: number;
};

export type TagComparator = {
    eq?: string;
};

export type AverageComparator = {
    eq?: number;
    gte?: number;
    lte?: number;
};
export type NumberComparator = {
    eq?: number;
    gte?: number;
    lte?: number;
};

export enum CaloriePercentageNutrient {
    TOTAL_SUGAR = 'TOTAL_SUGAR',
    FREE_SUGAR = 'FREE_SUGAR',
}

export type CaloriePercentageComparator = {
    eq?: number;
    gte?: number;
    lte?: number;
    nutrient?: CaloriePercentageNutrient;
};

export interface ProductQueryCondition {
    brandName?: StringComparator;
    accreditedMarkings?: StringComparator;
    name?: StringComparator;
    ingredients?: IDComparator;
    gpc?: IDComparator;
    // countryOfOrigin?: IDComparator;
    ingredientStatement?: StringComparator;
    gtin?: StringComparator;
    informationProviderGln?: IDComparator;
    groupId?: IDComparator;
    isVegan?: YesNoMaybeComparator;
    isVegetarian?: YesNoMaybeComparator;
    interpredIngredientsIsApproved?: BooleanComparator;
    launchDate?: DateComparator;
    nutrientValue?: NutrientComparator;
    subIngredients?: IDComparator;
    numberOfIngredients?: CountComparator;
    ingredientsFromCategories?: IDComparator;
    hasTag?: TagComparator;
    jaccard?: AverageComparator;
    naturalSugar?: NumberComparator;
    freeSugar?: NumberComparator;
    caloriePercentage?: CaloriePercentageComparator;
    numberOfIngredientsWithAddedSugar?: CountComparator;
    allergen?: IDComparator;
}

export type ProductConditionChoices = keyof ProductQueryCondition;
export type ConditionComparator =
    | StringComparator
    | IDComparator
    | YesNoMaybeComparator
    | BooleanComparator
    | DateComparator
    | NutrientComparator
    | CountComparator
    | CaloriePercentageComparator;

export interface AndStatement {
    and?: ProductQuery[];
}

export interface OrStatement {
    or?: ProductQuery[];
}

export type ProductQuery =
    | ProductQueryCondition
    | (ProductQueryCondition & AndStatement)
    | (OrStatement & AndStatement);
