import { gql, useMutation } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';

const CHANGE_APPROVE_STATUS = gql`
    mutation changeApproveStatus($productId: ID!, $approval: ProductApprovalInput!) {
        approveProductIngredients(id: $productId, approval: $approval) {
            id
            message
            ok
        }
    }
`;
interface IApprovedStatusVariables {
    productId: number;
    approval: {
        message: string;
        ok: boolean;
    };
}
export function useChangeApproveStatus(): MutationTuple<unknown, IApprovedStatusVariables> {
    return useMutation(CHANGE_APPROVE_STATUS);
}
