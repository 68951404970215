import { Card, Col, Popover, Row, Space, Typography } from 'antd';
import { FC } from 'react';

export interface NovaScoreProps {
    novaScore: number;
}

export const NovaScoreCard: FC<NovaScoreProps> = ({ novaScore }) => {
    let novaScoreDescription;
    switch (novaScore) {
        case 1:
            novaScoreDescription = 'Unprocessed or minimally processed foods';
            break;
        case 2:
            novaScoreDescription = 'Processed culinary ingredients';
            break;
        case 3:
            novaScoreDescription = 'Processed foods';
            break;
        case 4:
            novaScoreDescription = 'Ultra-processed products';
            break;
        default:
            novaScoreDescription = '';
            break;
    }
    return (
        <Card title="NOVA score">
            <Space>
                <Col>
                    <img src={`${process.env.PUBLIC_URL}/nova-${novaScore}.svg`} alt={`NOVA ${novaScore} icon`} />
                </Col>
                <Col>
                    <Row>
                        <Typography.Text>{novaScoreDescription}</Typography.Text>
                    </Row>
                    <Row>
                        <Space>
                            <Typography.Text>What is the Nova Score?</Typography.Text>
                            <Popover
                                overlay={true}
                                color="white"
                                title={<Typography.Title level={5}>What is the Nova Score?</Typography.Title>}
                                trigger={'click'}
                                placement={'right'}
                                content={
                                    <div style={{ maxWidth: '500px', maxHeight: '500px', overflowY: 'scroll' }}>
                                        <Typography.Title level={5}>What does NOVA mean?</Typography.Title>
                                        <Typography.Paragraph>
                                            NOVA is a classification of foods in four groups to highlight the degree of
                                            processing, from completely unprocessed to ultra-processed.
                                        </Typography.Paragraph>

                                        <Typography.Paragraph>
                                            Group 1 is foods that are unprocessed or minimally processed, such as
                                            vegetables and meat.
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            Group 2 is processed culinary ingredients, such as oils, salt, butter and
                                            sugar.
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            Group 3 is processed foods such as fresh bread, cheeses, or canned
                                            vegetables.
                                        </Typography.Paragraph>
                                        <Typography.Paragraph>
                                            Group 4 is ultra-processed foods and drinks that have undergone an
                                            industrial transformation and have many ingredients, such as soft drinks and
                                            ready-made foods.
                                        </Typography.Paragraph>

                                        <Typography.Title level={5}>Who is behind NOVA?</Typography.Title>
                                        <Typography.Paragraph>
                                            The NOVA classification system is developed by Carlos A. Monteiro, professor
                                            of nutrition and public health at São Paulo University in Brazil, and his
                                            team of researchers. It is used as a validated tool in research on public
                                            health and nutrition and is also used in several countries in the European
                                            Union.
                                        </Typography.Paragraph>
                                        <Typography.Title level={5}>A complex categorization</Typography.Title>
                                        <Typography.Paragraph>
                                            Our categorization of NOVA is based on a set of rules based partly on
                                            selected ingredients and degree of processing but also on human reasoning.
                                            To categorize products, the intention of the use of a certain ingredient is
                                            taken into account, which makes the interpretation dynamic. In some
                                            instances, a human conclusion is needed for the grouping to be correct. For
                                            example, foods with ingredients that manipulate taste or texture, such as
                                            aromas and certain additives, will end up in NOVA group 4. We are proud of
                                            the quality of our NOVA categorization and see it as an accurate and helpful
                                            tool to better understand the level of processing of a product.
                                        </Typography.Paragraph>
                                    </div>
                                }
                            >
                                <Typography.Text strong={true} style={{ cursor: 'pointer' }}>
                                    Read here
                                </Typography.Text>
                            </Popover>
                        </Space>
                    </Row>
                </Col>
            </Space>
        </Card>
    );
};
