export interface GroupPermission {
    read: boolean;
    write: boolean;
}
export interface PermissionsType {
    imageFetcherService: GroupPermission;
    ingredientService: GroupPermission;
    productService: GroupPermission;
    searchService: GroupPermission;
    sustainabilityService: GroupPermission;
    translationService: GroupPermission;
    usersService: GroupPermission;
    volumeEstimateService: GroupPermission;
    carbonService: GroupPermission;
}

export interface UserGroup {
    id: string;
    name: string;
    admin: boolean;
    permissions: PermissionsType;
}

export const PERMISSION_KEYS = [
    'imageFetcherService',
    'ingredientService',
    'productService',
    'searchService',
    'sustainabilityService',
    'translationService',
    'usersService',
    'volumeEstimateService',
    'carbonService',
] as const;
