import React, { FC, useMemo, useState } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import {
    Alert,
    Button,
    Checkbox,
    Comment,
    Form,
    Input,
    Modal,
    notification,
    Space,
    Switch,
    Table,
    Typography,
} from 'antd';
import { gql, useMutation, useQuery } from '@apollo/client';
import { ID } from '../../utils/type';
import { ColumnsType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { ROUTER_PAGES } from '../../routes';
import { Auth } from 'aws-amplify';
import { useAsync } from 'react-use';

export interface ReviewsProps {}

export interface ProductReportRow {
    id: ID;
    isResolved: boolean;
    productId: ID;
    userName: string | null;
    userId: ID | null;
    createdDataAndTime: string;
    message: string | null;
}
const PRODUCT_REPORT_FRAGMENT = gql`
    fragment ProductReportFragment on ProductReport {
        id
        isResolved
        productId
        userName
        userId
        createdDateAndTime
        message
    }
`;

function useProductReports() {
    return useQuery<{ productReports: ProductReportRow[] }>(gql`
        query ProductReportsPageQuery {
            productReports {
                ...ProductReportFragment
            }
        }
        ${PRODUCT_REPORT_FRAGMENT}
    `);
}

function useUpdateReport() {
    return useMutation<
        unknown,
        {
            values: {
                resolvedByUser: string;
                isResolved: boolean;
                message: string;
            };
            reportId: number;
        }
    >(gql`
        mutation UpdateProductReport($values: ProductReportUpdateInput!, $reportId: Float!) {
            updateProductReport(values: $values, reportId: $reportId) {
                ...ProductReportFragment
            }
        }
        ${PRODUCT_REPORT_FRAGMENT}
    `);
}
export const ProductReportsPage: FC<ReviewsProps> = () => {
    const { data, loading, error } = useProductReports();
    const [editedReportId, setEditedReportId] = useState<string | null>(null);
    const [updateReport, { loading: updateInProgress }] = useUpdateReport();
    const userInfo = useAsync(() => Auth.currentUserInfo());

    const columns = useMemo((): ColumnsType<ProductReportRow> => {
        return [
            {
                dataIndex: 'id',
                title: 'ID',
            },
            {
                title: 'Product ID',
                dataIndex: 'productId',
                render: (value) => {
                    if (value) {
                        return (
                            <Link
                                to={ROUTER_PAGES.products.path.replace(':id', value)}
                                style={{
                                    fontSize: '0.7em',
                                }}
                            >
                                View Product
                            </Link>
                        );
                    }
                },
            },
            {
                title: 'message',
                dataIndex: 'message',
                render: (value, record) => {
                    let author: string;
                    if (record.userId) {
                        author = `Mobile App: ${record.userId}`;
                    } else if (record.userName) {
                        author = `Desktop App: ${record.userName}`;
                    } else {
                        author = 'Unknown';
                    }
                    return <Comment content={value} author={author} />;
                },
            },
            {
                title: 'Created at',
                dataIndex: 'createdDateAndTime',
                render: (value) => {
                    return (
                        <Typography.Text type={'secondary'} style={{ fontSize: '0.7em' }}>
                            {new Date(value).toLocaleString()}
                        </Typography.Text>
                    );
                },
            },
            {
                title: 'Resolved',
                dataIndex: 'isResolved',
                render: (value) => {
                    return <Checkbox checked={value} disabled={true} />;
                },
                filters: [
                    {
                        value: false,
                        text: 'Not resolved',
                    },
                    {
                        value: true,
                        text: 'Resolved',
                    },
                ],
                onFilter: (value, record) => {
                    return record.isResolved === value;
                },
            },
            {
                title: 'Actions',
                render: (value, record) => {
                    return (
                        <>
                            <Button type={'primary'} onClick={() => setEditedReportId(String(record.id))}>
                                Update
                            </Button>
                        </>
                    );
                },
            },
        ];
    }, []);
    const editedReport = useMemo(() => {
        return data?.productReports.find((pr) => pr.id === editedReportId);
    }, [data?.productReports, editedReportId]);

    return (
        <div>
            <Breadcrumb />
            <Typography.Title>Reviews</Typography.Title>
            <Space style={{ float: 'right', marginBottom: 16 }}>
                {error && <Alert message={error.message} type={'error'} />}
            </Space>
            <Table
                dataSource={data?.productReports}
                rowKey={'id'}
                loading={loading}
                columns={columns}
                pagination={false}
            />
            <Modal
                title={'Update product report'}
                visible={!!editedReportId}
                destroyOnClose={true}
                onCancel={() => setEditedReportId(null)}
                footer={null}
            >
                <Form
                    initialValues={{
                        message: editedReport?.message,
                        resolved: editedReport?.isResolved,
                        resolvedBy: userInfo.value?.username,
                    }}
                    onFinish={(values: { message?: string | null; resolved?: boolean | null }) => {
                        if (editedReportId) {
                            updateReport({
                                variables: {
                                    values: {
                                        message: values.message || '',
                                        isResolved: !!values.resolved,
                                        resolvedByUser: userInfo.value.username,
                                    },
                                    reportId: Number(editedReportId),
                                },
                                onCompleted: () => {
                                    notification.success({
                                        message: 'The report updated',
                                    });
                                    setEditedReportId(null);
                                },
                                onError: (error) => {
                                    notification.error({
                                        message: 'The report is not updated',
                                        description: error.message,
                                    });
                                },
                            }).catch((err) => console.error(err));
                        } else {
                            console.warn('Report to edit is not selected');
                        }
                    }}
                >
                    <Form.Item name={'message'} label={'Message'}>
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item name={'resolved'} label={'Is resolved'} valuePropName={'checked'}>
                        <Switch />
                    </Form.Item>
                    <Form.Item name={'resolvedBy'} label={'Editor name'} required={true}>
                        <Input disabled={true} />
                    </Form.Item>
                    <Button htmlType={'submit'} loading={updateInProgress} disabled={!userInfo.value}>
                        Submit
                    </Button>
                </Form>
            </Modal>
        </div>
    );
};
