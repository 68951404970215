import { ProductSearchQuery } from './product';
import { env } from '../utils/env';

export class ProductREST {
    private readonly URL = env.REACT_APP_PRODUCTS_ENDPOINT;

    public async getCSVFile(query: ProductSearchQuery): Promise<void> {
        let uri = `${this.URL}/products/csv?`;

        const headers = new Headers();
        headers.append('Content-Type', 'application/json');

        if (query.offset) {
            uri += `offset=${query.offset}&`;
        }

        if (query.name) {
            uri += `name=${query.name}&`;
        }

        if (query.limit) {
            uri += `limit=${query.limit}&`;
        }

        if (query.accreditedMarking) {
            uri += `accreditedMarking=${query.accreditedMarking}&`;
        }

        if (query.groupId) {
            uri += `groupId=${query.groupId}&`;
        }

        if (query.brandNames && query.brandNames.length > 0) {
            uri += query.brandNames.map((brandName) => `brandNames=${brandName}`).join('&') + '&';
        }
        if (query.gpc && query.gpc.length > 0) {
            uri += query.gpc.map((gpc) => `gpc=${gpc}`).join('&') + '&';
        }
        if (query.informationProviderGln && query.informationProviderGln.length > 0) {
            uri += query.informationProviderGln.map((gln) => `informationProviderGln=${gln}`).join('&') + '&';
        }

        const res = await fetch(uri, {
            method: 'GET',
            headers,
        });

        if (res.status !== 200) {
            throw new Error('Not able to connect to download the CSV file from the product service');
        }

        const data = await res.blob();
        const csvURL = window.URL.createObjectURL(data);
        const tempLink = document.createElement('a');

        tempLink.href = csvURL;
        tempLink.setAttribute('download', 'products.csv');
        tempLink.click();
        tempLink.remove();
    }
}
