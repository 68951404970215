import { gql, useQuery } from '@apollo/client';
import { useCallback } from 'react';
import { client } from '../../../../../client';

interface GroupProductIdsResponse {
    group: {
        id: number;
        products?: {
            products: {
                id: number;
                name: string | null;
                interpretationIngredientStatement: string | null;
                interpretedIngredients: {
                    approval: {
                        ok: boolean | null;
                    } | null;
                };
            }[];
        };
    };
}

const GET_GROUP_PRODUCT_IDS = gql`
    query getWholeGroupProducts($id: ID!) {
        group: getGroup(id: $id) {
            id
            products(limit: 999999, offset: 0) @connection(key: "allProducts", filter: []) {
                products {
                    id
                    name
                    interpretationIngredientStatement
                    interpretedIngredients {
                        approval {
                            ok
                        }
                    }
                }
            }
        }
    }
`;

export function useGroupProducts(
    groupId: number,
    skip: boolean
): { loading: boolean; error?: Error; data?: GroupProductIdsResponse } {
    return useQuery<
        GroupProductIdsResponse,
        {
            id: number;
        }
    >(GET_GROUP_PRODUCT_IDS, {
        variables: {
            id: groupId,
        },
        skip,
    });
}

const INTERPRET_INGREDIENTS_LITE = gql`
    mutation interpretIngredientsLite($id: ID!, $ingredientStatement: String) {
        repsonse: predictProductIngredientsOnProduct(id: $id, ingredientStatement: $ingredientStatement) {
            id
            interpretationIngredientStatement
            interpretedIngredients {
                approval {
                    ok
                }
            }
        }
    }
`;
export function useInterpretProduct(): (
    productId: number,
    interpretationIngredientStatement: string | null | undefined
) => Promise<unknown> {
    return useCallback(async (productId: number, interpretationIngredientStatement: string | null | undefined) => {
        await client.mutate<
            unknown,
            {
                id: number;
                ingredientStatement: string | null | undefined;
            }
        >({
            mutation: INTERPRET_INGREDIENTS_LITE,
            variables: {
                id: productId,
                ingredientStatement: interpretationIngredientStatement,
            },
        });
    }, []);
}
