import React, { FC, useCallback, useMemo, useState } from 'react';
import { Alert, Button, Modal, notification, Result, Space, Statistic, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { ProductToHandle } from './types';
import { CheckCircleFilled, CloseCircleFilled, QuestionCircleOutlined } from '@ant-design/icons';
import { useGroupProducts, useInterpretProduct } from './hooks';
import { colors } from '../../../../../styles/colors';

const columns: ColumnsType<ProductToHandle> = [
    {
        title: '#',
        render(_, __, index) {
            return <>{index + 1}</>;
        },
    },
    {
        dataIndex: 'id',
        title: 'Id',
    },
    {
        dataIndex: 'name',
        title: 'Name',
    },
    {
        dataIndex: 'approved',
        title: 'Approved',
        align: 'center',
        render: (_: ProductToHandle['approved'], record) => {
            return (
                <>
                    {_ === true && (
                        <>
                            <CheckCircleFilled style={{ color: colors.success }} />
                        </>
                    )}
                    {_ === false && (
                        <>
                            <CloseCircleFilled style={{ color: colors.error }} />
                        </>
                    )}
                    {typeof _ !== 'boolean' && record.ingredientStatement && (
                        <>
                            <QuestionCircleOutlined style={{ color: colors.warning }} />
                        </>
                    )}
                </>
            );
        },
    },
];

export interface InternalModalProps {
    visible: boolean;
    close: () => void;
    groupId: number;
}

export const InternalModal: FC<InternalModalProps> = (props) => {
    const { visible, close, groupId } = props;

    const { loading, data: groupData, error } = useGroupProducts(groupId, !visible);
    const [handled, setHandled] = useState([] as number[]);

    const tableData = useMemo((): ProductToHandle[] => {
        return (
            groupData?.group.products?.products
                .map((p) => ({
                    id: Number(p.id),
                    name: p.name || '-',
                    ingredientStatement: p.interpretationIngredientStatement,
                    approved:
                        typeof p.interpretedIngredients.approval?.ok === 'boolean'
                            ? p.interpretedIngredients.approval.ok
                            : null,
                }))
                .filter((_) => !handled.includes(_.id))
                .sort((a, b) => a.id - b.id) || []
        );
    }, [groupData?.group.products?.products, handled]);

    const [progress, setProgress] = useState(0);
    const [working, setWorking] = useState(false);
    const [done, setDone] = useState(false);

    const interpretProduct = useInterpretProduct();
    const doJob = useCallback(
        async (productsToHandle: ProductToHandle[]) => {
            setWorking(true);
            setDone(false);
            const idsToHandle = productsToHandle.map((pth) => pth.id);
            const handledIds = tableData.map((_) => _.id).filter((_) => !idsToHandle.includes(_));
            setHandled(handledIds);
            setProgress(handledIds.length);
            const chunkSize = 5;
            while (productsToHandle.length > 0) {
                const chunk = productsToHandle.slice(0, chunkSize); // get chunk
                try {
                    await Promise.all(
                        chunk.map((product) => interpretProduct(product.id, product.ingredientStatement))
                    );
                    setProgress((p) => p + chunk.length); // count nly successful attempts
                } catch (e) {
                    notification.error({
                        message: 'Interpretation error',
                        description: String(e),
                    });
                } finally {
                    setHandled((_) => _.concat(chunk.map((__) => __.id)));
                    productsToHandle.splice(0, chunkSize); // remove chunk anyway
                }
            }
            setWorking(false);
            setDone(true);
        },
        [interpretProduct, tableData]
    );

    const handleNotParsed = useCallback(async () => {
        const productsToHandle = tableData.filter((p) => !p.ingredientStatement);
        await doJob(productsToHandle);
    }, [doJob, tableData]);
    const handleUnknown = useCallback(async () => {
        const productsToHandle = tableData.filter((p) => typeof p.approved !== 'boolean');
        await doJob(productsToHandle);
    }, [doJob, tableData]);

    return (
        <Modal visible={visible} onCancel={close} footer={null} title={'Interpret group'} width={800}>
            {done ? (
                <Result
                    status="success"
                    title="Interpretation of products finished!!"
                    subTitle="To apply changes reload the page"
                    extra={[
                        <Button type="primary" key="reload" onClick={() => window.location.reload()}>
                            Reload
                        </Button>,
                    ]}
                />
            ) : (
                <Space direction={'vertical'}>
                    <Alert
                        message={'Please note'}
                        description={
                            <ul>
                                <li>The whole group will be interpreted. It may require a time.</li>
                                <li>
                                    Already interpreted products will not be interpreted again even not approved ones.
                                </li>
                            </ul>
                        }
                        type={'warning'}
                    />
                    {error && <Alert message={String(error)} type={'error'} />}

                    <Space direction={'horizontal'}>
                        <Button type={'primary'} onClick={handleNotParsed} loading={working} disabled={loading}>
                            Interpret not parsed
                        </Button>
                        <Button type={'default'} onClick={handleUnknown} loading={working} disabled={loading}>
                            Re-interpret all unknown
                        </Button>
                        <Statistic title="Interpreted" value={progress} suffix={` / ${tableData.length}`} />
                    </Space>

                    <Table
                        columns={columns}
                        loading={loading}
                        dataSource={tableData}
                        pagination={false}
                        rowKey={'id'}
                    />
                </Space>
            )}
        </Modal>
    );
};
