import { ID } from '../../../../../utils/type';
import React, { FC, useMemo } from 'react';
import { Alert, Card, Tree } from 'antd';
import { DataNode } from 'rc-tree/lib/interface';
import { useIngredientGroupWithIngredients } from './hooks';
import { notNullGuard } from '../../../../../utils/typescript';
import { Link } from 'react-router-dom';
import { ROUTER_PAGES } from '../../../../../routes';

export interface SubIngredientsProps {
    groupId: ID;
}

const SubIngredients: FC<SubIngredientsProps> = (props) => {
    const { groupId } = props;
    const { data, loading, error } = useIngredientGroupWithIngredients(groupId);
    const treeData: DataNode[] = useMemo(() => {
        if (!data || !data.getIngredientCategory?.ingredients?.data) {
            return [];
        }
        return data.getIngredientCategory.ingredients.data
            .filter(notNullGuard)
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((ingredient) => {
                return {
                    title: (
                        <Link to={`${ROUTER_PAGES.ingredientsEdit.path}?selected=${ingredient.id}`}>
                            {ingredient.name}
                        </Link>
                    ),
                    key: ingredient.id,
                };
            });
    }, [data]);
    return (
        <Card loading={loading} title={'Ingredients in the group'}>
            {error && <Alert message={'Can not load ingredients'} description={error.message} type={'error'} />}
            {!error && !loading && treeData.length === 0 && (
                <Alert message={'There are no attached ingredients'} type={'info'} />
            )}
            <Tree treeData={treeData} height={500} />
        </Card>
    );
};

export default SubIngredients;
