import { useEffect, useRef } from 'react';

type MouseEventCallback = (ev: MouseEvent) => void;

export const useDocumentMouseEvent = (onChange: MouseEventCallback) => {
    const r = useRef<MouseEventCallback>();

    useEffect(() => {
        r.current = onChange;
    }, [onChange]);

    useEffect(() => {
        const eventListener = (ev: MouseEvent) => r?.current?.(ev);
        document.addEventListener('mousedown', eventListener);

        return () => {
            document.removeEventListener('mousedown', eventListener);
        };
    }, [r]);
};
