import React, { FC, useContext, useMemo } from 'react';
import { InterpretedIngredient } from '../../../../model/ingredients';
import { Form, Select } from 'antd';
import { IsEditingContext } from '../context';

export interface IEcoCellProps {
    record: InterpretedIngredient;
}

export const EcoCell: FC<IEcoCellProps> = (props) => {
    const { record } = props;
    const isEditing = useContext(IsEditingContext);
    const options = useMemo((): { value: string; label: string }[] => {
        return [
            {
                value: 'Ekologisk',
                label: 'Ekologisk',
            },
            {
                value: 'KRAV',
                label: 'KRAV',
            },
        ];
    }, []);
    if (!isEditing) {
        return <>{record.eco}</>;
    }
    return (
        <Form.Item name={'eco'} style={{ marginBottom: 0 }}>
            <Select
                showSearch={true}
                options={options}
                optionFilterProp={'label'}
                allowClear={true}
                showArrow={false}
            />
        </Form.Item>
    );
};
