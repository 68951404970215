export interface ITreeNodeLike {
    children?: this[] | null;
}

export function buildParentsMap<T extends ITreeNodeLike>(tree: readonly T[]): Map<T, T | null> {
    return buildParentsMapKeyed(tree, (node) => node);
}

export function buildParentsMapKeyed<T extends ITreeNodeLike, K>(
    tree: readonly T[],
    keyGen: (item: T) => K
): Map<K, T | null> {
    const map = new Map<K, T | null>();
    function handleNode(node: T, parent: T | null): void {
        map.set(keyGen(node), parent);
        node.children?.forEach((child) => handleNode(child, node));
    }
    tree.forEach((treeNode) => handleNode(treeNode, null));
    return map;
}

export function findNode<T extends ITreeNodeLike>(tree: readonly T[], q: (node: T) => boolean): T | undefined {
    const searchAtNode = (node: T): T | undefined => {
        if (q(node)) {
            return node;
        }
        const { children } = node;
        if (children) {
            for (const child of children) {
                const res = searchAtNode(child);
                if (res) {
                    return res;
                }
            }
        }

        return undefined;
    };
    for (const node of tree) {
        const res = searchAtNode(node);
        if (res) {
            return res;
        }
    }

    return undefined;
}
