import { Button, Form, FormProps, Input, Select } from 'antd';
import React, { FC, useCallback } from 'react';
import { useUpdateOrigin } from './hooks';
import { notification } from 'antd';
import { IngredientOrigin } from '../types';

export interface Values {
    name: string;
    synonyms: string[];
}
export interface UpdateNewOriginFormProps {
    origin: IngredientOrigin;
}
const UpdateNewOriginForm: FC<UpdateNewOriginFormProps> = ({ origin }) => {
    const [updateOrigin, { loading }] = useUpdateOrigin();
    const onSave: FormProps<Values>['onFinish'] = useCallback(
        (values) => {
            updateOrigin({
                variables: {
                    id: origin.id,
                    payload: {
                        name: values.name,
                        synonyms: values.synonyms,
                    },
                },
            })
                .then(() => {
                    notification.success({
                        message: 'The origin is updated',
                    });
                })
                .catch((err) => {
                    notification.error({
                        message: err instanceof Error ? err.message : String(err),
                    });
                });
        },
        [origin.id, updateOrigin]
    );
    return (
        <Form
            onFinish={onSave}
            initialValues={{
                name: origin.name,
                synonyms: origin.synonyms,
            }}
        >
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label={'Synonyms'} name={'synonyms'}>
                <Select mode="tags" style={{ width: '100%' }} />
            </Form.Item>
            <Button loading={loading} htmlType={'submit'} type={'primary'}>
                Create
            </Button>
        </Form>
    );
};

export default UpdateNewOriginForm;
