import { gql, QueryResult, useQuery } from '@apollo/client';
import { ID } from '../../../../utils/type';

const GET_INGREDIENT_SCHEMA = gql`
    query getIngredientGroup($id: ID!) {
        group: getIngredientCategory(id: $id) {
            id
            name
            parentGroup {
                id
                name
            }
        }
    }
`;

interface GetIngredientGroupResult {
    group: {
        id: ID;
        name: string;
        parentGroup: {
            id: ID;
            name: string;
        } | null;
    } | null;
}
export function useIngredientGroup(groupId: string) {
    return useQuery<GetIngredientGroupResult, { id: string }>(GET_INGREDIENT_SCHEMA, {
        variables: {
            id: groupId,
        },
    });
}
