import { gql } from '@apollo/client';
import { m, q } from 'src/utils/utils';

export const useTags = q<{
    tags: {
        id: string;
        name: string;
        query: string;
        isPublic: boolean | null;
        description: string | null;
        publicName: string | null;
        userGroup: {
            id: string;
            name: string;
        };
    }[];
}>(gql`
    query {
        tags: productTags {
            id
            name
            query
            description
            publicName
            isPublic
            userGroup {
                id
                name
            }
        }
    }
`);

export const useUpdateTag = m<
    {
        tag: {
            id: string;
            name: string;
            query: string;
            isPublic: boolean | null;
            description: string | null;
            publicName: string | null;
            userGroup: {
                id: string;
                name: string;
            };
        };
    },
    { id: string; values: { name: string; query: string; isPublic: boolean; description: string; publicName: string } }
>(gql`
    mutation ($values: ProductTagInput!, $id: ID!) {
        tag: updateProductTag(id: $id, values: $values) {
            id
            name
            query
            description
            publicName
            isPublic
            userGroup {
                id
                name
            }
        }
    }
`);

export const useCreateTag = m<
    {
        tag: {
            id: string;
            name: string;
            query: string;
            isPublic: boolean | null;
            description: string | null;
            publicName: string | null;
            userGroup: {
                id: string;
                name: string;
            };
        };
    },
    { values: { name: string; query: string } }
>(gql`
    mutation ($values: ProductTagInput!) {
        tag: createProductTag(values: $values) {
            id
            name
            query
            description
            publicName
            isPublic
            userGroup {
                id
                name
            }
        }
    }
`);

export const useDeleteTag = m<{ ok: boolean }, { id: string }>(gql`
    mutation ($id: ID!) {
        ok: deleteProductTag(id: $id)
    }
`);
