import React, { useEffect, useState } from 'react';
import {
    Button,
    Card,
    Col,
    Divider,
    Form,
    Input,
    message,
    Modal,
    Popover,
    Row,
    Space,
    Spin,
    Statistic,
    Tooltip,
    Typography,
    Upload,
} from 'antd';
import { Breadcrumb } from '../../../components/Breadcrumb';

import {
    CheckCircleFilled,
    CloseCircleFilled,
    CloudDownloadOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    FileSyncOutlined,
    InboxOutlined,
    PlusOutlined,
} from '@ant-design/icons/lib';
import { Link, useHistory, useParams } from 'react-router-dom';

import TextArea from 'antd/lib/input/TextArea';
import { ROUTER_PAGES } from '../../../routes';
import { ProductList } from '../../../components/ProductList';
import _ from 'lodash';
import { useStartReindexJob } from '../../../api/job';
import { ProductREST } from '../../../api/REST-product';
import { useDeleteFromGroup, useSingleGroup, useUpdateGroup } from '../../../api/group';
import { InterpretButton } from './InterpretButton';
import { BulkInterpretButton } from './BulkInterpretButton';
import { JaccardBadge } from './JaccardBadge';
import { LifestyleTags } from './LifestyleTags';
import { notNullGuard } from 'src/utils/typescript';

const { Dragger } = Upload;

const parseFile = (text: string, rowSeperator: string, colSeperator: string): string[][] => {
    const rows = text.split(rowSeperator);
    return rows.map((v) => v.split(colSeperator));
};

const EditGroupPage: React.FC = () => {
    const { id: groupIdStr } = useParams<{ id: string }>();
    const groupId = Number.parseInt(groupIdStr);

    const fetchSize = 25;
    const history = useHistory();
    const [form] = Form.useForm();
    const [fileUploadModalVisible, setFileUploadModalVisible] = useState(false);
    const [idsToText, setIdsToText] = useState<Record<string, string>>({});
    const [rowSeperator] = useState('\n');
    const [isDownloadingGroup, setIsDownloadingGroup] = useState(false);
    const [colSeperator] = useState(',');
    const [updateGroup] = useUpdateGroup();
    const [startReIndexJob] = useStartReindexJob();
    const [deleteFromGroup] = useDeleteFromGroup();

    const {
        data: group,
        fetchMore,
        loading,
    } = useSingleGroup({
        variables: {
            id: groupId,
            productLimit: fetchSize,
        },
    });

    const [hasMore, setHasMore] = useState<boolean>();

    useEffect(() => {
        if (group) {
            form.setFieldsValue({ ...group.group });
        }
    }, [group, form]);

    useEffect(() => {
        if (group && hasMore === undefined) {
            if (group.group.products?.products.length === 0) {
                setHasMore(false);
            } else {
                setHasMore(true);
            }
        }

        if (group === undefined) {
            setHasMore(undefined);
        }
    }, [group, hasMore]);

    const onFormSubmit = (formData: any): void => {
        updateGroup({
            variables: { ...formData, id: groupId },
        }).then(() => {
            message.success('Saved group!');
        });
    };

    const onFileUpload = (): void => {
        setFileUploadModalVisible(false);

        Modal.confirm({
            title: 'You previously entered articles will be removed',
            icon: <ExclamationCircleOutlined />,
            content: 'Warning your previously entered GTIN will be removed when you provide a new CSV file.',
            okText: 'Continue',
            cancelText: 'Cancel',
            onOk() {
                let list: any[] = [];

                for (const id in idsToText) {
                    const content = idsToText[id];

                    try {
                        const parsedFile = parseFile(content, rowSeperator, colSeperator);
                        const values = parsedFile.flat();
                        const prevExternalIds = form.getFieldValue('externalIds') || [];
                        list = list.concat([...prevExternalIds, ...values]);
                    } catch {
                        message.error('Not able to parse the CSV file with id ' + id + ' see log for more info');
                        console.log({ message: 'Not able to parse file with id ' + id, content, idsToText, id });
                    }
                }

                const uniqueList = _.uniq(list);
                form.setFieldsValue({ externalIds: uniqueList });

                updateGroup({
                    variables: { externalIds: uniqueList, id: groupId },
                })
                    .then((v) => {
                        message.success('Updated GTIN from file!');
                    })
                    .catch((err) => {
                        message.error(err.message);
                    });
            },
        });
    };

    const onStartJobOnGroup = () => {
        Modal.confirm({
            title: 'Warning',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you wish to start a reindex job on group ${groupId}?`,
            okText: 'Continue',
            cancelText: 'Cancel',
            onOk() {
                startReIndexJob({
                    variables: { groupId: groupId },
                }).then((res) => {
                    return message.warn(
                        <>
                            <Typography>
                                <b>Fetching product in the group from validoo</b>
                                <br />
                                This process can take a while before it is propagated threw the entire system. The
                                changes should be visible soon!
                            </Typography>
                        </>
                    );
                });
            },
        });
    };

    const onSaveCSV = () => {
        setIsDownloadingGroup(true);
        new ProductREST()
            .getCSVFile({
                limit: group?.group.products?.products.length!, // FIXME: number | undefined. Is it correct?
                offset: 0,
                groupId: groupId,
            })
            .catch((csvError) => {
                message.error(<Typography.Text type="danger">{csvError.message}</Typography.Text>);
            })
            .finally(() => {
                setIsDownloadingGroup(false);
            });
    };

    const UploadCSVModal = () => {
        return (
            <Modal
                closable={false}
                title={'Upload CSV file'}
                visible={fileUploadModalVisible}
                onOk={onFileUpload}
                onCancel={() => setFileUploadModalVisible(false)}
            >
                <Dragger
                    onRemove={(e) => {
                        const copy: any = {};
                        for (const key in idsToText) {
                            if (key !== e.uid) copy[key] = idsToText[key];
                        }
                        setIdsToText(copy);
                    }}
                    beforeUpload={(file) => {
                        file.text()
                            .then((text) => {
                                setIdsToText({ ...idsToText, [file.uid]: text });
                            })
                            .catch(() => {
                                message.error('Not able to parse file into text');
                            });
                        return false;
                    }}
                >
                    <p className="ant-upload-drag-icon">
                        <InboxOutlined />
                    </p>
                    <Typography.Paragraph>Click or drag file to this area to upload</Typography.Paragraph>
                </Dragger>
            </Modal>
        );
    };

    const onLoadMore = () => {
        fetchMore({
            variables: {
                id: groupId,
                productOffset: group?.group.products?.products.length,
                productLimit: fetchSize,
            },
        }).then((v) => {
            console.log(v.data?.group.products?.products.length);
            setHasMore(v.data?.group.products?.products.length !== 0);
        });
    };

    const deleteProduct = (gtin: string) => {
        deleteFromGroup({
            variables: { externalIds: [gtin], id: groupId },
        })
            .then(() => {
                message.success(`Removed ${gtin}`);
            })
            .catch((error) => {
                message.error(error?.message || `uknown error when trying to remove ${gtin}`);
            });
    };

    return (
        <div>
            {UploadCSVModal()}
            {/* {AddGTINModal()} */}
            <Breadcrumb />
            <Typography.Title level={1}>
                Editing group {loading && <Spin></Spin>}
                {!loading && (
                    <>
                        {group?.group.name} ({group?.group.totalProducts})
                    </>
                )}
            </Typography.Title>

            <Row gutter={[20, 20]}>
                <Col>
                    <Link
                        to=""
                        onClick={(e) => {
                            e.preventDefault();
                            onSaveCSV();
                        }}
                    >
                        <Tooltip title="Downloads the current result as a CSV file">
                            <Button
                                icon={<CloudDownloadOutlined spin={isDownloadingGroup} disabled={isDownloadingGroup} />}
                            >
                                {isDownloadingGroup ? 'Downloading' : 'Download csv'}
                            </Button>
                        </Tooltip>
                    </Link>
                </Col>
                <Col>
                    <Tooltip title="Generates tags and reuploads to elasticsearch">
                        <Button
                            onClick={onStartJobOnGroup}
                            style={{ marginRight: '10px', margin: 'auto', float: 'right' }}
                        >
                            Reindex group <FileSyncOutlined />
                        </Button>
                    </Tooltip>
                </Col>
                <Col>
                    <BulkInterpretButton groupId={groupId} />
                </Col>

                <Col>
                    <Tooltip title="Add more products to add to this group">
                        <Button
                            icon={<PlusOutlined />}
                            type="primary"
                            onClick={() =>
                                history.push(ROUTER_PAGES.findProduct.path.replace(':id', groupId.toString()))
                            }
                            style={{ marginRight: '10px', margin: 'auto', float: 'right' }}
                        >
                            Add products
                        </Button>
                    </Tooltip>
                </Col>

                <Col span={24}>
                    <Card loading={loading}>
                        <Row align="bottom">
                            <Col sm={6}>
                                <Statistic
                                    value={group?.group.totalProducts}
                                    title={
                                        <>
                                            Total product in
                                            <br />
                                            group and subgroups
                                        </>
                                    }
                                />
                            </Col>
                            <Col sm={6}>
                                <Statistic
                                    value={group?.group.products?.total}
                                    suffix={`/ ${group?.group.totalProducts}`}
                                    title={
                                        <>
                                            Product in this
                                            <br />
                                            group
                                        </>
                                    }
                                />
                            </Col>
                            <Col sm={6}>
                                <Popover
                                    content={group?.group.children?.data?.map((v) => (
                                        <Button
                                            onClick={() => {
                                                history.push('/groups/' + v.id);
                                            }}
                                            type="link"
                                        >
                                            {v.name}
                                            <br />
                                        </Button>
                                    ))}
                                    title="Subgroups"
                                    placement="leftBottom"
                                >
                                    <Statistic value={group?.group.children?.total} title="Number of subgroups" />
                                </Popover>
                            </Col>
                        </Row>
                        <Divider />
                        <Form
                            labelCol={{ span: 3 }}
                            form={form}
                            name="subscription-form"
                            onFinish={onFormSubmit}
                            initialValues={{ externalIds: [] }}
                        >
                            <Form.Item required name="name" label="Group name">
                                <Input placeholder="Project A" />
                            </Form.Item>

                            <Form.Item name="description" label="Description">
                                <TextArea placeholder="Small description of the group" />
                            </Form.Item>

                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => setFileUploadModalVisible(true)} style={{ marginRight: '10px' }}>
                                    Upload CSV file <FileSyncOutlined />
                                </Button>

                                <Button htmlType={'submit'} type={'primary'}>
                                    Save
                                </Button>
                            </div>

                            <Form.Item>
                                {group?.group.products && (
                                    <ProductList
                                        hasMore={hasMore}
                                        actions={(product) => {
                                            const jaccard = product.interpretedIngredients?.average
                                                ? Math.round(product.interpretedIngredients.average * 100) / 100
                                                : null;

                                            return [
                                                <LifestyleTags interpretedLifestyles={product.interpretedLifestyles} />,
                                                jaccard ? <JaccardBadge score={jaccard} /> : null,
                                                <>
                                                    {product.interpretedIngredients?.approval?.ok === true && (
                                                        <>
                                                            Ingredients <CheckCircleFilled style={{ color: 'green' }} />
                                                        </>
                                                    )}
                                                    {product.interpretedIngredients?.approval?.ok === false && (
                                                        <>
                                                            Ingredients <CloseCircleFilled style={{ color: 'red' }} />
                                                        </>
                                                    )}
                                                </>,
                                            ].filter(notNullGuard);
                                        }}
                                        content={(product) => {
                                            return (
                                                <Space>
                                                    <InterpretButton
                                                        productId={product.id}
                                                        interpretationIngredientStatement={
                                                            product.interpretationIngredientStatement
                                                        }
                                                        disabled={product.interpretedIngredients?.approval?.ok === true}
                                                    />
                                                    <Button
                                                        icon={<DeleteOutlined />}
                                                        shape={'circle'}
                                                        onClick={(event) => {
                                                            event.stopPropagation();
                                                            deleteProduct(product?.productIdentifier?.externalId);
                                                        }}
                                                    />
                                                </Space>
                                            );
                                        }}
                                        loadMore={onLoadMore}
                                        products={group.group.products.products}
                                    />
                                )}
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default EditGroupPage;
