import React, { ComponentProps, FC, useCallback, useEffect, useState } from 'react';
import { Input, Button, Select, Col, Row, DatePicker, Card, Tooltip, Typography, Empty, Tag, InputNumber } from 'antd';
import { CopyOutlined, DeleteOutlined, PlusCircleFilled, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Collapse } from 'antd';
import {
    ProductConditionChoices,
    ConditionComparator,
    ProductQueryCondition,
    AndStatement,
    OrStatement,
    NutrientComparator,
    IDComparator,
    CaloriePercentageComparator,
    CaloriePercentageNutrient,
} from 'src/model/productQuery';
import { useInformationProviders } from 'src/api/informationProvider';
import moment from 'moment';
import { useGroupSearch } from 'src/api/group';
import GroupTreeSelect from 'src/components/GroupTreeSelect';
import { useAccreditedMarkings } from 'src/api/accreditedMarkings';
import { useGetIngredients, useIngredientCategories, useIngredientsById } from 'src/api/ingredients';
import { useAllergens } from 'src/api/productAllergens';
import ProductCategoryTree from 'src/components/ProductCategoryTree';
import useDebounce from 'src/utils/hooks/useDebounce';
import { SelectProps } from 'antd/lib/select';
import { NutrientsSelector } from '../../NutrientsSelector';
import { useTags } from 'src/pages/TagPages/hooks/useTags';

const { Panel } = Collapse;

const searchFilter = (input: string, option: any) => {
    const label: string = option?.children as string;
    return label?.toLowerCase().startsWith(input.toLowerCase());
};

type ConditionTypes =
    | 'DateCondition'
    | 'StringCondition'
    | 'YesNoMaybeCondition'
    | 'BooleanCondition'
    | 'IDCondition'
    | 'NutrientValueCondition'
    | 'CountCondition'
    | 'TagCondition'
    | 'AverageCondition'
    | 'NumberCondition'
    | 'CaloriePercentageCondition';
type ConditionConfig = {
    type: ConditionTypes;
    title: string;
    valuesComponent?: (props: any) => JSX.Element;
};

const InfromationProviderSearch = (props: any) => {
    const { data, loading } = useInformationProviders();
    return (
        <>
            <Select {...props} filterOption={searchFilter} mode="multiple" loading={loading}>
                {data &&
                    data.informationProviders.map((informationProvider) => (
                        <Select.Option key={informationProvider.gln} value={informationProvider.gln}>
                            {informationProvider.name || informationProvider.gln}
                        </Select.Option>
                    ))}
            </Select>
        </>
    );
};

const GroupSearch = (props: any) => {
    const { data, loading } = useGroupSearch();

    return (
        <>
            {data && (
                <GroupTreeSelect
                    selected={props.value}
                    onChange={(commaValues) => {
                        props.onChange(commaValues);
                    }}
                    extraProps={props}
                    groups={data?.groups}
                />
            )}
            {loading && <Select {...props} loading />}
        </>
    );
};

const SelectedIngredientOption = (props: any) => {
    const { value, closable, onClose, selectedValues } = props;
    const { data } = useIngredientsById({ variables: { ingredientIds: selectedValues } });

    return (
        <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {data?.ingredients.find((ing) => ing.id === value)?.name}
        </Tag>
    );
};

const IngredientSearch = (props: any) => {
    const { onChange, value = [] } = props;
    const [searchTerm, setSearchTerm] = useState<string>();
    const debouncedValue = useDebounce(searchTerm, 500);
    const { data, loading } = useGetIngredients({
        variables: { q: debouncedValue, limit: 10 },
    });

    return (
        <>
            <Select
                loading={loading}
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="Please select"
                value={value}
                filterOption={false}
                onSearch={setSearchTerm}
                onChange={onChange}
                tagRender={(props: any) => <SelectedIngredientOption {...props} selectedValues={value} />}
            >
                {data?.ingredients.data.map((ing) => {
                    return (
                        <Select.Option key={ing.id} value={ing.id}>
                            {ing.name}
                        </Select.Option>
                    );
                })}
            </Select>

            {/* {loading && <Select {...props} loading></Select>} */}
        </>
    );
};

const SelectedIngredientCategoryOption = (props: any) => {
    const { value, closable, onClose } = props;
    const { data } = useIngredientCategories({ variables: { limit: 1000000 } });

    return (
        <Tag closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
            {data?.category.data.find((category) => category.id === value)?.name}
        </Tag>
    );
};

const IngredientCategorySearch = (props: any) => {
    const { onChange, value = [] } = props;
    const [searchTerm, setSearchTerm] = useState<string>();
    const debouncedValue = useDebounce(searchTerm, 500);
    const { data, loading } = useIngredientCategories({
        variables: { q: debouncedValue, limit: 1000 },
    });
    return (
        <Select
            {...props}
            mode="multiple"
            filterOption={searchFilter}
            loading={loading}
            placeholder="Please select"
            onSearch={setSearchTerm}
            onChange={onChange}
            tagRender={SelectedIngredientCategoryOption}
            value={value}
        >
            {data &&
                data.category.data.map((category) => (
                    <Select.Option key={category.id} value={category.id}>
                        {category.name || category.id}
                    </Select.Option>
                ))}
        </Select>
    );
};

const TagSearch = (props: any) => {
    const { data, loading } = useTags();
    return (
        <>
            <Select {...props} filterOption={searchFilter} loading={loading}>
                {data &&
                    data.tags.map((tag) => (
                        <Select.Option key={tag.id} value={tag.id}>
                            {tag.name || tag.id}
                        </Select.Option>
                    ))}
            </Select>
        </>
    );
};

const AccreditedMarkingsSearch = (props: any) => {
    const { data, loading } = useAccreditedMarkings();

    return (
        <>
            <Select {...props} mode="multiple" filterOption={searchFilter} loading={loading}>
                {data &&
                    data.typeCodes.map((code) => (
                        <Select.Option key={code.code} value={code.code}>
                            {code.name || code.code}
                        </Select.Option>
                    ))}
            </Select>
        </>
    );
};

const AllergenSearch = (props: any) => {
    const { data, loading } = useAllergens();

    return (
        <>
            <Select {...props} filterOption={searchFilter} loading={loading}>
                {data &&
                    data.productAllergens.data.map((allergen) => (
                        <Select.Option key={allergen.name} value={allergen.name}>
                            {allergen.name}
                        </Select.Option>
                    ))}
            </Select>
        </>
    );
};

const conditionConfig: Record<ProductConditionChoices, ConditionConfig> = {
    launchDate: {
        title: 'Launch date',
        type: 'DateCondition',
    },
    brandName: {
        title: 'Brand name',
        type: 'StringCondition',
    },
    ingredientStatement: {
        title: 'Ingredient statement',
        type: 'StringCondition',
    },
    name: {
        title: 'Product name',
        type: 'StringCondition',
    },
    gtin: {
        title: 'Product GTIN',
        type: 'StringCondition',
    },
    isVegan: {
        title: 'Is vegan',
        type: 'YesNoMaybeCondition',
    },
    isVegetarian: {
        title: 'Is vegetarian',
        type: 'YesNoMaybeCondition',
    },

    interpredIngredientsIsApproved: {
        title: 'Product approved',
        type: 'BooleanCondition',
    },
    accreditedMarkings: {
        title: 'Accredited markings',
        type: 'IDCondition',
        valuesComponent: AccreditedMarkingsSearch,
    },
    informationProviderGln: {
        title: 'Information provider',
        type: 'IDCondition',
        valuesComponent: InfromationProviderSearch,
    },
    groupId: {
        title: 'Group',
        type: 'IDCondition',
        valuesComponent: GroupSearch,
    },
    ingredients: {
        title: 'Ingredients',
        type: 'IDCondition',
        valuesComponent: IngredientSearch,
    },
    gpc: {
        title: 'Category(GPC)',
        type: 'IDCondition',
        valuesComponent: (props) => (
            <>
                <ProductCategoryTree {...props} style={{ width: '100%' }} />
            </>
        ),
    },
    nutrientValue: {
        title: 'Nutrient Value',
        type: 'NutrientValueCondition',
    },
    subIngredients: {
        title: 'Sub-ingredients',
        type: 'IDCondition',
        valuesComponent: IngredientSearch,
    },
    numberOfIngredients: {
        title: 'Number of ingredients',
        type: 'CountCondition',
    },
    ingredientsFromCategories: {
        title: 'Ingredient categories',
        type: 'IDCondition',
        valuesComponent: IngredientCategorySearch,
    },
    hasTag: {
        title: 'Dynamic tag',
        type: 'TagCondition',
        valuesComponent: TagSearch,
    },
    jaccard: {
        title: 'Jaccard',
        type: 'AverageCondition',
    },
    naturalSugar: {
        title: 'Natural sugar',
        type: 'NumberCondition',
    },
    freeSugar: { title: 'Free sugar', type: 'NumberCondition' },
    caloriePercentage: { title: 'Calorie percentage', type: 'CaloriePercentageCondition' },
    numberOfIngredientsWithAddedSugar: { title: 'Number of ingredients with added sugar', type: 'CountCondition' },
    allergen: { title: 'Allergens', type: 'IDCondition', valuesComponent: AllergenSearch },
    // countryOfOrigin: {
    //     title: 'Origin',
    //     type: 'IDCondition',
    //     valuesComponent: OriginSearch,
    // },
};

interface SearchConditionProps {
    value: ProductQueryCondition;
    onChange: (value: ProductQueryCondition) => void;
    onDelete: () => void;
    onCopy: (value: ProductQueryCondition) => void;
}

const selectProps = { style: { width: '100%' } };

interface ComparatorSelectorProps {
    onBlur?: () => void;
    type?: ConditionTypes;
    value?: keyof ConditionComparator;
    onChange: (value: keyof ConditionComparator) => void;
    disabled?: boolean;
}

const ComparatorSelector: FC<ComparatorSelectorProps> = (props) => {
    const { onChange, type, value, onBlur, disabled } = props;
    const equalitySelectorProps: SelectProps = {
        ...selectProps,
        onChange,
        placeholder: 'Compare',
        onBlur,
        value,
        disabled,
    };

    if (!type) return <Select {...equalitySelectorProps} disabled />;

    switch (type) {
        case 'DateCondition':
            return (
                <Select {...equalitySelectorProps} style={{ width: '100%' }}>
                    <Select.Option value="eq">is</Select.Option>
                    <Select.Option value="gte">after</Select.Option>
                    <Select.Option value="lte">before</Select.Option>
                </Select>
            );

        case 'NutrientValueCondition':
            return (
                <Select {...equalitySelectorProps} style={{ width: '100%' }}>
                    <Select.Option value="eq">equal</Select.Option>
                    <Select.Option value="gte">more than or equal</Select.Option>
                    <Select.Option value="lte">less than or equal</Select.Option>
                </Select>
            );

        case 'StringCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">=</Select.Option>
                    <Select.Option value="includes">includes</Select.Option>
                    <Select.Option value="startsWith">starts with</Select.Option>
                    <Select.Option value="endsWith">ends with</Select.Option>
                    <Select.Option value="regex">regex</Select.Option>
                </Select>
            );

        case 'IDCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">=</Select.Option>
                    <Select.Option value="in">is</Select.Option>
                    <Select.Option value="notIn">is not</Select.Option>
                </Select>
            );

        case 'YesNoMaybeCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">=</Select.Option>
                </Select>
            );

        case 'BooleanCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">=</Select.Option>
                </Select>
            );
        case 'CountCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">equal</Select.Option>
                    <Select.Option value="gte">more than or equal</Select.Option>
                    <Select.Option value="lte">less than or equal</Select.Option>
                </Select>
            );
        case 'TagCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">is</Select.Option>
                    <Select.Option value="notEq">is not</Select.Option>
                </Select>
            );
        case 'AverageCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">equal</Select.Option>
                    <Select.Option value="gte">more than or equal</Select.Option>
                    <Select.Option value="lte">less than or equal</Select.Option>
                </Select>
            );
        case 'NumberCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">equal</Select.Option>
                    <Select.Option value="gte">more than or equal</Select.Option>
                    <Select.Option value="lte">less than or equal</Select.Option>
                </Select>
            );
        case 'CaloriePercentageCondition':
            return (
                <Select {...equalitySelectorProps}>
                    <Select.Option value="eq">equal</Select.Option>
                    <Select.Option value="gte">more than or equal</Select.Option>
                    <Select.Option value="lte">less than or equal</Select.Option>
                </Select>
            );
    }
};

interface ValueSelectorProps {
    onBlur?: () => void;
    config?: ConditionConfig;
    value?: any;
    onChange: (value: any) => void;
    disabled?: boolean;
}

const ValueSelector: FC<ValueSelectorProps> = (props) => {
    const { onChange, value, config, onBlur, disabled } = props;
    const type = config?.type;
    const ValuesComponent = config?.valuesComponent;

    const valueSelectorProps = {
        ...selectProps,
        disabled,
        required: true,
        placeholder: 'Value',
        onBlur,
        value: value,
        onChange: (e: any) => {
            if (type === 'DateCondition') return onChange(e);
            if (type === 'NutrientValueCondition') return onChange(e);
            if (type === 'StringCondition') return onChange(e.target.value);
            if (type === 'BooleanCondition') /* Gets a stringified boolean value */ return onChange(JSON.parse(e));
            if (type === 'YesNoMaybeCondition') return onChange(e);
            if (type === 'IDCondition') return onChange(e);
            if (type === 'CountCondition') return onChange(e);
            if (type === 'TagCondition') return onChange(e);
            if (type === 'AverageCondition') return onChange(e);
            if (type === 'NumberCondition') return onChange(e);
            if (type === 'CaloriePercentageCondition') return onChange(e);

            throw new Error('Not able to find an onChange event for the type: ' + type);
        },
    };

    if (!type) return <Select {...valueSelectorProps} disabled />;
    if (ValuesComponent) return <ValuesComponent {...valueSelectorProps} />;

    switch (type) {
        case 'DateCondition':
            return (
                <DatePicker
                    {...valueSelectorProps}
                    value={valueSelectorProps.value ? moment(valueSelectorProps.value) : null}
                />
            );

        case 'StringCondition':
            return <Input {...valueSelectorProps} />;
        case 'NutrientValueCondition':
            return <InputNumber {...valueSelectorProps} parser={(value) => (value ? value.replace(',', '.') : '')} />;

        case 'IDCondition':
            return <Input {...valueSelectorProps} />;

        case 'YesNoMaybeCondition':
            return (
                <Select {...valueSelectorProps}>
                    <Select.Option value="yes">yes</Select.Option>
                    <Select.Option value="no">no</Select.Option>
                    <Select.Option value="maybe">maybe</Select.Option>
                </Select>
            );
        case 'BooleanCondition':
            return (
                <Select {...valueSelectorProps}>
                    <Select.Option value={true}>true</Select.Option>
                    <Select.Option value={false}>false</Select.Option>
                    <Select.Option value={null}>Not yet set</Select.Option>
                </Select>
            );
        case 'CountCondition':
            return <InputNumber {...valueSelectorProps} min={0}></InputNumber>;
        case 'TagCondition':
            return <Input {...valueSelectorProps} />;
        case 'AverageCondition':
            return (
                <InputNumber
                    {...valueSelectorProps}
                    min={0}
                    max={1}
                    parser={(value) => (value ? value.replace(',', '.') : '')}
                    step={0.1}
                />
            );
        case 'NumberCondition':
            return (
                <InputNumber
                    {...valueSelectorProps}
                    min={0}
                    parser={(value) => (value ? value.replace(',', '.') : '')}
                ></InputNumber>
            );
        case 'CaloriePercentageCondition':
            return (
                <InputNumber
                    {...valueSelectorProps}
                    min={0}
                    max={1}
                    step={0.1}
                    parser={(value) => (value ? value.replace(',', '.') : '')}
                ></InputNumber>
            );
    }
};

const UNIT_OPTIONS = ['GRM', 'KJO', 'MLT'].map((unit) => ({
    label: unit,
    value: unit,
}));
const BASE_QUANTITY_UNIT_OPTIONS = ['GRM', 'MLT'].map((unit) => ({
    label: unit,
    value: unit,
}));

type IDAmountTypes = 'maximumAmount' | 'minimumAmount' | 'exactAmount';

const ID_AMOUNT_OPTIONS: { label: string; value: IDAmountTypes }[] = [
    { label: 'max', value: 'maximumAmount' },
    { label: 'min', value: 'minimumAmount' },
    { label: 'exact', value: 'exactAmount' },
];

const CALORIE_PERCENTAGE_OPTIONS: { label: string; value: CaloriePercentageNutrient }[] = [
    {
        label: 'total sugar',
        value: CaloriePercentageNutrient.TOTAL_SUGAR,
    },
    {
        label: 'free sugar',
        value: CaloriePercentageNutrient.FREE_SUGAR,
    },
];

const extraKeys = new Set(['nutrient', 'unit']);

// Fixme: If type "NutrientValueCondition" - do not trust typings. it is a more complex case than existing components can handle. E.g. `keyof typeof comparator` - is not a correct type
const SearchCondition = (props: SearchConditionProps) => {
    const { value, onChange, onDelete, onCopy } = props;
    const conditionKey = Object.keys(value)[0] as keyof typeof value | undefined;
    const comparator = conditionKey && value[conditionKey];
    const selectedConfig = conditionKey && conditionConfig[conditionKey];
    const type = selectedConfig?.type;
    const valueSelectorProps = { style: { width: '100%' } };

    // not comparators, but additional info FIXME: it is ugly to hardcode it here
    let comparatorKey: keyof NonNullable<typeof comparator> | undefined = undefined;
    if (comparator) {
        if (type === 'NutrientValueCondition' || type === 'CaloriePercentageCondition') {
            comparatorKey = Object.keys(comparator).filter((key) => !extraKeys.has(key))[0] as keyof typeof comparator;
        } else {
            comparatorKey = Object.keys(comparator)[0] as keyof typeof comparator;
        }
    }

    let initialIdConditionAmount;
    if (comparator) {
        if (type === 'IDCondition') {
            initialIdConditionAmount = Object.keys(comparator).filter((key) =>
                ID_AMOUNT_OPTIONS.map((option) => option.value.toString()).includes(key)
            )[0] as IDAmountTypes;
        }
    }

    const queryValue: any | undefined = comparator && comparatorKey && comparator[comparatorKey];
    const [error, setError] = useState<string>();
    const [idConditionAmount, setIdConditionAmount] = useState<IDAmountTypes | undefined>(initialIdConditionAmount);

    useEffect(() => {
        if (conditionKey === undefined) return setError('Select a key');
        if (comparator === undefined) return setError('Select a comparator');
        if (queryValue === undefined) return setError('Select a value');
        if (type === 'NutrientValueCondition') {
            if (!comparator || !(comparator as NutrientComparator).unit) return setError('Select an unit');
            if (!comparator || !(comparator as NutrientComparator).nutrient) return setError('Select a nutrient');
        }
        if (type === 'IDCondition') {
            if (idConditionAmount && (comparator as IDComparator)[idConditionAmount] === undefined)
                return setError('Select an amount');
        }
        if (type === 'CaloriePercentageCondition') {
            if (!comparator || !(comparator as CaloriePercentageComparator).nutrient)
                return setError('Select a nutrient');
        }
        setError(undefined);
    }, [comparator, conditionKey, idConditionAmount, queryValue, type]);

    const handleChangeComparator = useCallback(
        (value) => {
            if (conditionKey) {
                const keysToPreserve = Object.keys(comparator || {}).filter((k) => extraKeys.has(k));
                const preserve = comparator
                    ? Object.fromEntries(keysToPreserve.map((key) => [key, (comparator as any)[key]]))
                    : {};
                onChange({
                    [conditionKey]: {
                        ...preserve,
                        [value]: queryValue || undefined,
                    },
                });
            }
        },
        [comparator, conditionKey, onChange, queryValue]
    );

    const handleChangeValue = useCallback(
        (value) => {
            if (conditionKey && comparatorKey) {
                onChange({
                    [conditionKey]: {
                        ...(comparator || {}),
                        [comparatorKey]: value,
                    },
                });
            }
        },
        [comparator, comparatorKey, conditionKey, onChange]
    );

    const clearIdConditionAmount = () => {
        if (conditionKey && idConditionAmount) {
            onChange({
                [conditionKey]: {
                    ...(comparator || {}),
                    [idConditionAmount]: undefined,
                },
            });
        }
    };

    const selectBeforeIDCondition = (
        <>
            <Select
                placeholder={'optional'}
                disabled={!conditionKey}
                options={ID_AMOUNT_OPTIONS}
                allowClear={true}
                value={idConditionAmount}
                onClear={clearIdConditionAmount}
                onChange={(value) => {
                    clearIdConditionAmount();
                    setIdConditionAmount(value);
                }}
            ></Select>
        </>
    );

    return (
        <Row align="middle" gutter={[10, 10]} style={{ marginBottom: '10px' }}>
            <Col span={7}>
                <Select
                    placeholder="Key"
                    {...selectProps}
                    value={conditionKey}
                    onChange={(v) => {
                        onChange({
                            [v.toString()]: undefined,
                        });
                    }}
                >
                    {Object.entries(conditionConfig).map(([key, config]) => {
                        return (
                            <Select.Option key={key} value={key}>
                                {config.title}
                            </Select.Option>
                        );
                    })}
                </Select>
            </Col>
            <Col span={5}>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    <ComparatorSelector value={comparatorKey} onChange={handleChangeComparator} type={type} />
                    {type === 'NutrientValueCondition' && (
                        <>
                            <NutrientsSelector
                                value={comparator ? (comparator as NutrientComparator)['nutrient'] : null}
                                onChange={(nutrient) => {
                                    if (conditionKey) {
                                        onChange({
                                            [conditionKey]: {
                                                ...(comparator || {}),
                                                nutrient: nutrient || undefined,
                                            },
                                        });
                                    }
                                }}
                            />

                            <Select
                                disabled={!conditionKey}
                                placeholder={'Unit'}
                                value={comparator ? (comparator as NutrientComparator)['unit'] : null}
                                options={UNIT_OPTIONS}
                                onChange={(unit) => {
                                    if (conditionKey) {
                                        onChange({
                                            [conditionKey]: {
                                                ...(comparator || {}),
                                                unit: unit || undefined,
                                            },
                                        });
                                    }
                                }}
                            />

                            <Select
                                disabled={!conditionKey}
                                placeholder={'Base Quantity unit'}
                                value={comparator ? (comparator as NutrientComparator)['baseQuantityUnit'] : null}
                                options={BASE_QUANTITY_UNIT_OPTIONS}
                                onChange={(unit) => {
                                    if (conditionKey) {
                                        onChange({
                                            [conditionKey]: {
                                                ...(comparator || {}),
                                                baseQuantityUnit: unit || undefined,
                                            },
                                        });
                                    }
                                }}
                            />
                        </>
                    )}
                    {type === 'IDCondition' && (
                        <>
                            <InputNumber
                                {...valueSelectorProps}
                                disabled={!idConditionAmount}
                                placeholder={'amount'}
                                addonBefore={selectBeforeIDCondition}
                                min={1}
                                value={
                                    comparator && idConditionAmount
                                        ? (comparator as IDComparator)[idConditionAmount]
                                        : undefined
                                }
                                onChange={(amount) => {
                                    if (conditionKey && idConditionAmount) {
                                        onChange({
                                            [conditionKey]: {
                                                ...(comparator || {}),
                                                [idConditionAmount]: amount ? amount : undefined,
                                            },
                                        });
                                    }
                                }}
                            ></InputNumber>
                        </>
                    )}
                    {type === 'CaloriePercentageCondition' && (
                        <>
                            <Select
                                {...valueSelectorProps}
                                disabled={!conditionKey}
                                placeholder={'nutrient'}
                                value={comparator ? (comparator as CaloriePercentageComparator)['nutrient'] : null}
                                options={CALORIE_PERCENTAGE_OPTIONS}
                                onChange={(nutrient) => {
                                    if (conditionKey) {
                                        onChange({
                                            [conditionKey]: {
                                                ...(comparator || {}),
                                                nutrient: nutrient || undefined,
                                            },
                                        });
                                    }
                                }}
                            ></Select>
                        </>
                    )}
                </div>
            </Col>
            <Col span={8}>
                <ValueSelector
                    onChange={handleChangeValue}
                    value={queryValue}
                    config={selectedConfig}
                    disabled={!comparatorKey}
                />
            </Col>
            <Col span={4}>
                <Button type="link" onClick={onDelete} icon={<DeleteOutlined />} />
                <Button type="link" onClick={() => onCopy(value)} icon={<CopyOutlined />} />
                {error && (
                    <Tooltip title={error}>
                        <Button type="link" icon={<WarningOutlined style={{ color: 'red' }} />} />
                    </Tooltip>
                )}
            </Col>
        </Row>
    );
};

interface AndStatementFieldProps {
    i: number;
    v: ProductQueryCondition;
    andStatement: AndStatement;
    onStatementChange: (form: AndStatement) => void;
}
const AndStatementField: FC<AndStatementFieldProps> = ({ onStatementChange, v, i, andStatement }) => {
    const handleChange: ComponentProps<typeof SearchCondition>['onChange'] = useCallback(
        (newObj) => {
            onStatementChange({
                and: andStatement.and?.map((statement) => (statement === v ? newObj : statement)),
            });
        },
        [andStatement.and, onStatementChange, v]
    );
    const handleDelete: ComponentProps<typeof SearchCondition>['onDelete'] = useCallback(() => {
        onStatementChange({
            and: andStatement.and?.filter((_, deletedIndex) => i !== deletedIndex),
        });
    }, [andStatement.and, i, onStatementChange]);
    const handleCopy: ComponentProps<typeof SearchCondition>['onCopy'] = useCallback(
        (v) => {
            onStatementChange({
                and: [...(andStatement.and || []), { ...v }],
            });
        },
        [andStatement.and, onStatementChange]
    );
    return (
        <SearchCondition
            value={v as ProductQueryCondition}
            onChange={handleChange}
            onDelete={handleDelete}
            onCopy={handleCopy}
        />
    );
};

interface AndStatementsProps {
    onDeleteRow: () => void;
    onCopyRow: (form: AndStatement) => void;
    onStatementChange: (form: AndStatement) => void;
    andStatement: AndStatement;
}

const AndStatements = (props: AndStatementsProps) => {
    const { onDeleteRow, onCopyRow, onStatementChange, andStatement } = props;

    return (
        <Card
            actions={[
                <Button
                    onClick={() => {
                        onStatementChange({ and: [...(andStatement.and || []), {}] });
                    }}
                    type="link"
                    key="add"
                    icon={<PlusOutlined />}
                >
                    AND STATEMENT
                </Button>,
                <Button
                    onClick={() => {
                        onCopyRow(andStatement);
                    }}
                    type="link"
                    key="copy"
                    icon={<CopyOutlined key="copy" />}
                />,
                <Button
                    onClick={() => {
                        onDeleteRow();
                    }}
                    type="link"
                    key="delete"
                    icon={<DeleteOutlined key="delete" />}
                />,
            ]}
        >
            {andStatement.and?.map((v, i) => {
                return (
                    <AndStatementField
                        key={`cnd-${i}`}
                        v={v as ProductQueryCondition}
                        onStatementChange={onStatementChange}
                        i={i}
                        andStatement={andStatement}
                    />
                );
            })}
            {andStatement.and && andStatement.and.length === 0 && (
                <Empty description="No statements">
                    <Button
                        onClick={() => {
                            onStatementChange({ and: [...(andStatement.and || []), {}] });
                        }}
                        type="primary"
                        key="add"
                        icon={<PlusOutlined />}
                    >
                        AND STATEMENT
                    </Button>
                </Empty>
            )}
        </Card>
    );
};

interface SearchFilterProps {
    onChange: (query: OrStatement) => void;
    query: OrStatement;
}

export interface StatementProps {
    statement: AndStatement;
    setOrStatements: (query: OrStatement) => void;
    i: number;
    or: OrStatement['or'];
}
const Statement: FC<StatementProps> = ({ statement, setOrStatements, i, or }) => {
    const handleStatementChange = useCallback(
        (form: AndStatement) => {
            setOrStatements({
                or: or?.map((statement, currentIndex) => {
                    if (currentIndex === i) return form;
                    else return statement;
                }),
            });
        },
        [i, or, setOrStatements]
    );
    const handleCopyOnRow = useCallback(
        (form: AndStatement) => {
            setOrStatements({
                or: [...(or || []), form],
            });
        },
        [or, setOrStatements]
    );
    const handleDeleteRow = useCallback(() => {
        setOrStatements({
            or: or?.filter((_, index) => i !== index),
        });
    }, [i, or, setOrStatements]);
    return (
        <>
            <Col span={24}>
                <AndStatements
                    andStatement={statement}
                    onStatementChange={handleStatementChange}
                    onCopyRow={handleCopyOnRow}
                    onDeleteRow={handleDeleteRow}
                />
            </Col>
        </>
    );
};
const SearchFilter = (props: SearchFilterProps) => {
    const { query: orStatement, onChange: setOrStatements } = props;

    return (
        <Collapse key="1">
            <Panel
                header="Filters"
                key="Filters"
                extra={
                    <Tooltip title="If one of the conditions matches then the product is added">
                        <Button
                            size="small"
                            onClick={(e) => {
                                e.stopPropagation();
                                setOrStatements({ or: [...(orStatement.or || []), { and: [] }] });
                            }}
                            icon={<PlusCircleFilled />}
                        >
                            OR CONDITION
                        </Button>
                    </Tooltip>
                }
            >
                <Row justify="center" gutter={[0, 10]}>
                    {orStatement.or?.map((v, i, list) => {
                        const statement = v as AndStatement;

                        return (
                            <React.Fragment key={'col-' + i}>
                                <Col key={'col1-' + i} span={24}>
                                    <AndStatements
                                        key={'and-stmt' + i}
                                        andStatement={statement}
                                        onStatementChange={(form) => {
                                            setOrStatements({
                                                or: orStatement.or?.map((statement, currentIndex) => {
                                                    if (currentIndex === i) return form;
                                                    else return statement;
                                                }),
                                            });
                                        }}
                                        onCopyRow={(form) => {
                                            setOrStatements({
                                                or: [...(orStatement.or || []), form],
                                            });
                                        }}
                                        onDeleteRow={() => {
                                            setOrStatements({
                                                or: orStatement.or?.filter((_, index) => i !== index),
                                            });
                                        }}
                                    ></AndStatements>
                                </Col>
                                {/* skips the final gap */}
                                {i !== list.length - 1 && (
                                    <Col key={'col2-' + i}>
                                        <Typography.Text type="success">OR</Typography.Text>
                                    </Col>
                                )}
                            </React.Fragment>
                        );
                    })}
                </Row>
            </Panel>
        </Collapse>
    );
};

export default SearchFilter;
