import { Button, Input, Switch } from 'antd';
import { Form, Divider } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { AdvancedSearch } from 'src/components/SearchBar/SearchBar';
import { Rule } from 'rc-field-form/lib/interface';

interface TagFormProps {
    existingNames: readonly string[];
    loading: boolean;
    tag: {
        name: string;
        query: string;
        id: string;
        isPublic: boolean | null;
        description: string | null;
        publicName: string | null;
    };
    onSave: (tag: {
        name: string;
        query: string;
        id: string;
        isPublic: boolean;
        description: string;
        publicName: string;
    }) => void;
}

export const TagForm = (props: TagFormProps) => {
    const { tag, onSave, loading, existingNames } = props;

    const [query, setQuery] = useState<any>(() => JSON.parse(tag?.query || '[]'));
    const [form] = Form.useForm();
    useEffect(() => {
        // reset the form after the save to avoid any difference between backend and frontend (what if frontend sends value "A" and backend has additional logic and saves and returns "A-patched")
        setQuery(JSON.parse(tag.query || '[]'));
        form.resetFields();
    }, [form, tag]);

    const nameValidators = useMemo((): Rule[] => {
        const existingNamesSet = new Set(existingNames);
        return [
            { required: true, message: "'tag' is required" },
            {
                validator: async (rule, value) => {
                    if (value !== tag.name && existingNamesSet.has(value.trim())) {
                        throw new Error('This tag already exists');
                    }
                },
            },
        ];
    }, [existingNames, tag.name]);
    return (
        <Form
            form={form}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 14 }}
            initialValues={{
                name: tag.name,
                description: tag.description || '',
                publicName: tag.publicName || '',
                isPublic: tag.isPublic || false,
            }}
            onFinish={(values: { name: string; description: string; publicName: string; isPublic: boolean }) => {
                onSave({
                    id: tag.id,
                    name: values.name,
                    description: values.description,
                    publicName: values.publicName,
                    isPublic: values.isPublic,
                    query: JSON.stringify(query),
                });
            }}
        >
            <div style={{ maxWidth: '600px' }}>
                <Form.Item label="Tag" name={'name'} rules={nameValidators}>
                    <Input placeholder={'NEW_TAG'} />
                </Form.Item>
                <Form.Item label="Description" name={'description'}>
                    <Input.TextArea rows={6} />
                </Form.Item>
                <Form.Item label="Public name" name={'publicName'} rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Is public?" name={'isPublic'} valuePropName={'checked'}>
                    <Switch />
                </Form.Item>
            </div>
            <Divider />
            <AdvancedSearch onQueryChange={setQuery} query={query} />

            <Button htmlType={'submit'} type="primary" style={{ marginTop: '4vh', float: 'right' }} loading={loading}>
                Save
            </Button>
        </Form>
    );
};
