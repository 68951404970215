// Imports all the default style sheet
import 'src/styles/index.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

import Amplify from '@aws-amplify/core';
import { env } from './utils/env';

Amplify.configure({
    aws_user_pools_web_client_id: env.REACT_APP_COGNITO_CLIENT_ID,
    aws_user_pools_id: env.REACT_APP_COGNITO_USERPOOL_ID,
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
