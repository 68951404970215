import React from 'react';
import { Button, Col, Popconfirm, Row, Table, Typography } from 'antd';
import { Breadcrumb } from '../../components/Breadcrumb';

import { DeleteOutlined } from '@ant-design/icons/lib';
import { useHistory } from 'react-router-dom';
import { useGroupSearch, useDeleteGroup } from '../../api/group';
import { ROUTER_PAGES } from '../../routes';
import { Breakpoint } from 'antd/es/_util/responsiveObserve';
import { useQueryParam, withDefault, NumberParam } from 'use-query-params';
import { ColumnsType } from 'antd/lib/table/interface';
import { IdentifierGroup } from '../../model/group';
import produce from 'immer';

export const groupListPageSize = 10;

const GroupListPage: React.FC = () => {
    const { data: groups, refetch, loading } = useGroupSearch();
    const history = useHistory();

    const [page, setPage] = useQueryParam<number>('page', withDefault(NumberParam, 1));
    const [deleteGroup] = useDeleteGroup();

    const onDeleteGroup = (groupId: number): void => {
        deleteGroup({
            variables: {
                id: groupId,
            },
        })
            .then(() => {
                return refetch();
            })
            .catch((v) => {
                console.error(v);
            });
    };

    const columns: ColumnsType<IdentifierGroup> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',

            render(_, item) {
                return <b>{item.name}</b>;
            },
        },
        {
            title: 'Description',
            key: 'description',
            render(_, item) {
                return item.description || '-';
            },
            responsive: ['lg'] as Breakpoint[],
        },
        {
            title: '',
            key: 'actions',
            render: (actions, group) => {
                return (
                    <Row gutter={[20, 0]} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Col>
                            <Popconfirm
                                placement={'left'}
                                title="Are you sure to delete this group?"
                                onConfirm={(event) => {
                                    event?.stopPropagation();
                                    onDeleteGroup(group.id);
                                }}
                                onCancel={(event) => {
                                    event?.stopPropagation();
                                }}
                                okText="Yes"
                                cancelText="No"
                            >
                                <Button
                                    icon={<DeleteOutlined />}
                                    shape={'circle'}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                    }}
                                />
                            </Popconfirm>
                        </Col>
                    </Row>
                );
            },
        },
    ];

    return (
        <div>
            <Breadcrumb />
            <Typography.Title level={1}>Groups</Typography.Title>
            <Table
                loading={loading}
                size={'middle'}
                dataSource={produce(groups?.groups, (groups) => {
                    if (!groups) return undefined;

                    groups.forEach((item) => {
                        delete item.children;
                    });

                    return groups;
                })}
                columns={columns}
                rowKey={'id'}
                title={() => (
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button type={'primary'} onClick={() => history.push(ROUTER_PAGES.addNewGroup.path)}>
                            Add new
                        </Button>
                    </div>
                )}
                pagination={{
                    current: page,
                    pageSize: groupListPageSize,
                    position: ['bottomCenter'],
                    onChange: (page) => setPage(page, 'replace'),
                }}
                onRow={(record) => {
                    return {
                        onClick: () => {
                            history.push(ROUTER_PAGES.editGroups.path.replace(':id', String(record?.id)));
                        },
                    };
                }}
            />
        </div>
    );
};

export default GroupListPage;
