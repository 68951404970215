import { FC, useContext, useEffect, useState } from 'react';
import { useRecalculateVolumeEstimate, useVolumeEstimate } from 'src/api/volumeEstimate';
import { Column } from '@ant-design/plots';
import { Button, Card, message } from 'antd';
import { colors } from 'src/styles/colors';
import { ReloadOutlined } from '@ant-design/icons';
import { AuthContext } from 'src/components/AuthProvider';

const RecalculateButton: FC<{ productId: number }> = (props) => {
    const { productId } = props;
    const [isClicked, setIsClicked] = useState(false);
    const [recalculateVolumeEstimate, { loading, data }] = useRecalculateVolumeEstimate();

    useEffect(() => {
        if (!isClicked || loading) return;

        if (data?.recalculateVolumeEstimate) message.success('Successfully recalculated volume estimate');
        else if (!data?.recalculateVolumeEstimate) message.error('Failed to recalculate volume estimate');

        setIsClicked(false);
        setTimeout(() => {
            window.location.reload();
        }, 1500); // Force reload the page so that the new volume estimate is displayed. TODO: find a better way to do this
    }, [loading, data, isClicked]);

    return (
        <Button
            onClick={() => {
                recalculateVolumeEstimate({ variables: { productId } });
                setIsClicked(true);
            }}
            disabled={loading}
        >
            Recalculate <ReloadOutlined spin={loading} />
        </Button>
    );
};

interface VolumeEstimateProps {
    productId: number;
}

const VolumeEstimateCard: FC<VolumeEstimateProps> = (props) => {
    const { productId } = props;
    const { data, loading } = useVolumeEstimate({ variables: { productId: Number(productId) } });

    const user = useContext(AuthContext);

    return (
        <>
            {(loading || data?.product?.volumeEstimates) && (
                <Card
                    title="Ingredients volume estimate"
                    loading={loading}
                    extra={
                        user?.userGroup.permissions.volumeEstimateService.write ? (
                            <RecalculateButton productId={productId} />
                        ) : undefined
                    }
                >
                    {data?.product?.volumeEstimates && (
                        <Column
                            {...{
                                data:
                                    data.product.volumeEstimates.length > 0
                                        ? data.product.volumeEstimates.map((v) => {
                                              // values under 1% should be 0%
                                              return {
                                                  type: v.ingredientName,
                                                  estimate: v.estimate >= 0.01 ? v.estimate : 0,
                                              };
                                          })
                                        : [],
                                xField: 'type',
                                yField: 'estimate',
                                color: colors.foodfactsGreen,
                                maxColumnWidth: 50,
                                xAxis: {
                                    label: {
                                        autoHide: false,
                                        autoRotate: true,
                                    },
                                },
                                meta: {
                                    estimate: {
                                        formatter: (data: any) => {
                                            return data * 100 + '%';
                                        },
                                    },
                                },
                                tooltip: {
                                    formatter: (data: any) => {
                                        // values under 1% should be displayed as < 1%
                                        const percentage =
                                            data.estimate < 0.01 ? '< 1%' : `${(data.estimate * 100).toFixed(2)}%`;
                                        return { name: data.ingredientName, value: percentage };
                                    },
                                },
                            }}
                        />
                    )}
                </Card>
            )}
        </>
    );
};

export default VolumeEstimateCard;
