import React, { useEffect } from 'react';
import { Breadcrumb } from '../../components/Breadcrumb';
import { useParams } from 'react-router-dom';
import { Button, Card, Col, DatePicker, Form, message, Row, Select, Typography } from 'antd';
import { useSubscription, useSaveSubscription } from 'src/api/subscription';
import moment from 'moment';
import { RuleObject, StoreValue } from 'rc-field-form/lib/interface';
import { Subscription } from '../../model/subscription';
import ProductCategoryTree from '../../components/ProductCategoryTree';

export const gtinValidator = (rule: RuleObject, value: StoreValue): Promise<void> => {
    if (!value) {
        return Promise.resolve();
    }
    const gtinRegexp = /^[0-9]{8,14}$/;
    const gtins = value as string[];
    const errors = gtins.filter((gtin) => !gtin.trim().match(gtinRegexp)).map((gtin) => `"${gtin}" is not a GTIN`);
    if (errors.length === 0) {
        return Promise.resolve();
    }
    return Promise.reject(errors);
};

const EditSubscriptionPage = () => {
    const params = useParams<{ id: string }>();
    const { data } = useSubscription({ variables: { id: Number(params.id) } });
    const [saveSubscriptionMutation] = useSaveSubscription();

    const [form] = Form.useForm();

    useEffect(() => {
        const gpcCodes = data?.subscription.body?.gpcCategoryCode;
        const gtins = data?.subscription.body?.gtins;
        if (gpcCodes) {
            form.setFieldsValue({ gpcCategoryCode: gpcCodes });
        }
        form.setFieldsValue({ gtins: gtins || [] });
    }, [data, form]);

    const onFormSubmit = (formData: any) => {
        const subscription: Subscription = {
            id: data?.subscription.id,
            type: data?.subscription.type!, // FIXME: check undefined
            body: {
                gpcCategoryCode: formData.gpcCategoryCode,
                gtins: formData.gtins.map((gtin: any) => gtin.trim()),
                lastChangeDateTimeFrom: (formData.lastChangeDateTimeFrom
                    ? (formData.lastChangeDateTimeFrom as moment.Moment).startOf('day').toISOString()
                    : null) as any, // FIXME: check null
            },
        };

        saveSubscriptionMutation({ variables: { subscription: subscription } })
            .then((data) => {
                message.success('Subscription saved successfully');
            })
            .catch((err) => {
                message.error(err.message);
            });
    };

    const onFormReset = () => {
        const gtins = data?.subscription.body?.gtins;
        if (gtins) {
            form.setFieldsValue({ gtins: gtins });
        } else {
            form.setFieldsValue({ gtins: [] });
        }
        form.setFieldsValue({ lastChangeDateTimeFrom: data?.subscription.body?.lastChangeDateTimeFrom });
    };

    return (
        <div>
            <Breadcrumb />
            <Typography.Title level={1}>Subscription {params.id}</Typography.Title>

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Card style={{ backgroundColor: '#f8f8f8' }}>
                        <Row gutter={[20, 0]} style={{ fontWeight: 600 }}>
                            <Col span={6}>id</Col>
                            <Col span={6}>type</Col>
                            <Col span={6}>createdAt</Col>
                            <Col span={6}>modifiedAt</Col>
                        </Row>
                        <Row gutter={[20, 0]}>
                            <Col span={6}>{data?.subscription.id}</Col>
                            <Col span={6}>{data?.subscription.type}</Col>
                            <Col span={6}>
                                {data?.subscription.createdAt
                                    ? moment(data.subscription.createdAt).format('YYYY-MM-DD HH:mm')
                                    : null}
                            </Col>
                            <Col span={6}>
                                {data?.subscription.modifiedAt
                                    ? moment(data.subscription.modifiedAt).format('YYYY-MM-DD HH:mm')
                                    : null}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row gutter={[20, 20]}>
                <Col span={24}>
                    <Card>
                        <Form form={form} labelCol={{ span: 3 }} name="subscription-form" onFinish={onFormSubmit}>
                            <ProductCategoryTree formItemName="gpcCategoryCode" formItemLabel="Choose categories" />
                            <Form.Item name="gtins" label="GTINs" rules={[{ validator: gtinValidator }]}>
                                <Select
                                    mode="tags"
                                    placeholder="Enter or paste in a comma separated gtin list"
                                    tokenSeparators={[',']}
                                    maxTagCount={5}
                                    allowClear={true}
                                ></Select>
                            </Form.Item>
                            <Form.Item name="lastChangeDateTimeFrom" label="From date">
                                <DatePicker />
                            </Form.Item>
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={onFormReset} style={{ marginRight: '10px' }}>
                                    Reset
                                </Button>
                                <Button htmlType={'submit'} type={'primary'}>
                                    Save
                                </Button>
                            </div>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default EditSubscriptionPage;
