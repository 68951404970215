import { FC, useCallback, useMemo } from 'react';
import { PERMISSION_KEYS, PermissionsType, UserGroup } from '../types';
import { Form, notification, Switch } from 'antd';
import { useUpdateGroup } from '../hooks';

export interface AdminPermissionProps {
    group: UserGroup;
}

interface FormValues {
    admin: boolean;
}
const AdminPermission: FC<AdminPermissionProps> = ({ group }) => {
    const [form] = Form.useForm();

    const [updateGroupPermissions, { loading }] = useUpdateGroup();

    const handleChange = useCallback(
        (changed: unknown, values: FormValues) => {
            const permissions = {} as PermissionsType;
            for (const key of PERMISSION_KEYS) {
                const value = group.permissions[key] || {
                    read: false,
                    write: false,
                };
                permissions[key] = {
                    read: value.read,
                    write: value.write,
                };
            }
            updateGroupPermissions({
                variables: {
                    id: group.id,
                    values: {
                        admin: values.admin,
                        name: group.name,
                        permissions,
                    },
                },
            }).catch((err) => {
                notification.error({
                    message: err instanceof Error ? err.message : String(err),
                });
                form.resetFields();
            });
        },
        [form, group, updateGroupPermissions]
    );
    const initialValues: FormValues = useMemo(() => {
        return {
            admin: group.admin,
        };
    }, [group.admin]);

    return (
        <Form layout={'inline'} onValuesChange={handleChange} initialValues={initialValues} form={form}>
            <Form.Item name={'admin'} label={'Admin'} valuePropName={'checked'}>
                <Switch loading={loading} />
            </Form.Item>
        </Form>
    );
};

export default AdminPermission;
