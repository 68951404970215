import React, { FC, useCallback, useState } from 'react';
import { Badge, Modal } from 'antd';
import { ProductApproval } from '../../../model/product';
import { ApproveForm } from './ApproveForm';
import { Status } from './types';

export interface IApproveBadgeProps {
    approved: ProductApproval | null;
    productId: number;
    editable?: boolean;
}

const pointerStyle = {
    cursor: 'pointer',
};
export const ApprovedBadge: FC<IApproveBadgeProps> = (props) => {
    const { approved, editable = true, productId, children } = props;
    const status =
        !approved || approved.ok === null ? Status.NotDefined : approved.ok ? Status.Approved : Status.Disapproved;
    const [visible, setVisible] = useState(false);
    const open = useCallback(() => editable && setVisible(true), [editable]);
    const close = useCallback(() => setVisible(false), []);

    return (
        <>
            <Badge.Ribbon
                text={
                    <span onClick={open}>
                        {status === Status.Approved
                            ? 'Approved'
                            : status === Status.Disapproved
                            ? 'Not approved'
                            : 'Not defined'}
                    </span>
                }
                style={editable ? pointerStyle : undefined}
                color={status === Status.Approved ? 'green' : status === Status.Disapproved ? 'yellow' : 'blue'}
            >
                {children}
            </Badge.Ribbon>
            <Modal
                title={'Approve product ingredient'}
                onCancel={close}
                footer={false}
                destroyOnClose={true}
                visible={visible}
            >
                <sup>
                    Obs: Quick-Approve has been changed to "Ctrl + &#60;" and middle-mouse button.
                    <br />
                    Quick-Disapprove has been changed to "Ctrl + Shift + &#60;" and "Shift + middle-mouse button"
                </sup>
                <ApproveForm
                    lastChangedBy={approved?.lastChangedBy}
                    message={approved?.message || ''}
                    status={status}
                    productId={productId}
                    onFinish={close}
                />
            </Modal>
        </>
    );
};
