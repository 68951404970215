import React, { FC, useCallback, useState } from 'react';
import { Breadcrumb } from '../../../components/Breadcrumb';
import { Table, Modal, Typography, Form, Input, Button, Col, Row, Spin, message } from 'antd';
import { useGetIngredientsCategory, useCreateIngredientsGroup } from '../../../api/ingredients';
import { useQueryParam, BooleanParam, StringParam, NumberParam } from 'use-query-params';
import { ColumnsType } from 'antd/lib/table';
import { IngredientCategory } from '../../../model/ingredients';
import { PlusOutlined } from '@ant-design/icons';
import UpdateIngredientGroupDrawer from './UpdateIngredientGroupDrawer';

const { useForm } = Form;

const AddNewModal: FC<{
    visible: boolean;
    isCreating: boolean;
    setCreateNew: (v: boolean) => void;
    onSave: (form: IngredientCategory) => void;
}> = ({ visible, isCreating, setCreateNew, onSave }) => {
    const [form] = useForm();

    return (
        <Modal
            onCancel={() => setCreateNew(false)}
            onOk={() => {
                form.submit();
            }}
            visible={visible}
        >
            <Typography.Title>New category</Typography.Title>
            <Form onFinish={onSave} form={form}>
                <Form.Item label="Category name" name="name">
                    <Input disabled={isCreating} required />
                </Form.Item>
                {isCreating && <Spin />}
            </Form>
        </Modal>
    );
};

const IngredientGroupPage = () => {
    const [refetching, setRefetching] = useState(false);
    const pageSize = 20;
    const [query, setQuery] = useQueryParam<string>('q', StringParam as any); // FIXME: actual type
    const [id, setSelectedId] = useQueryParam('selected', StringParam);
    const [creatingNew, setCreateNew] = useQueryParam<boolean>('creatingNew', BooleanParam as any); // FIXME: actual type
    const [page, setPage] = useQueryParam<number>('page', NumberParam as any); // FIXME: actual type
    const { data, loading, fetchMore, refetch } = useGetIngredientsCategory({
        variables: { limit: pageSize, offset: pageSize * Math.max(0, page - 1), q: query, childrenOnly: false },
    });
    const [create, { loading: isCreating }] = useCreateIngredientsGroup();

    const columns: ColumnsType<IngredientCategory> = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Parent category',
            dataIndex: 'category',
            key: 'category',
            render(i, p) {
                return <p>{p.parentGroup?.name}</p>;
            },
        },
    ];

    const renderTitle = useCallback(() => {
        return (
            <>
                <Row gutter={[20, 0]} justify="start">
                    <Col span={18}>
                        <Form
                            onFinish={(form) => {
                                setPage(1);
                                setQuery(form.query);
                            }}
                            initialValues={{ query }}
                        >
                            <Row gutter={[10, 0]}>
                                <Col>
                                    <Form.Item name="query">
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Button htmlType="submit">Search</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>

                    <Col span={6}>
                        <Button
                            style={{ float: 'right' }}
                            shape="circle"
                            icon={<PlusOutlined />}
                            onClick={() => setCreateNew(true)}
                            type="primary"
                        />
                    </Col>
                </Row>
            </>
        );
    }, [query, setCreateNew, setPage, setQuery]);

    const loadMore = useCallback(
        (offset: number) => {
            setRefetching(true);
            fetchMore({
                variables: {
                    offset: offset,
                    limit: pageSize,
                },
            }).finally(() => {
                setRefetching(false);
            });
        },
        [fetchMore]
    );

    const onCloseDrawer = useCallback(
        (needToRefetch: boolean) => {
            setSelectedId(undefined);
            if (needToRefetch) {
                refetch();
            }
        },
        [refetch, setSelectedId]
    );

    const handleSaveNew = useCallback(
        (form: IngredientCategory) => {
            create({ variables: { values: form } })
                .then(() => {
                    message.success('Successfully created categeory!');
                    refetch().then(() => {
                        setCreateNew(false);
                    });
                })
                .catch((error) => {
                    message.error(error.message);
                });
        },
        [create, refetch, setCreateNew]
    );
    return (
        <div>
            <AddNewModal
                visible={creatingNew}
                isCreating={isCreating}
                setCreateNew={setCreateNew}
                onSave={handleSaveNew}
            />
            {!!id && <UpdateIngredientGroupDrawer groupId={id} onClose={onCloseDrawer} />}
            <Breadcrumb />
            {data && (
                <Table
                    pagination={{
                        onChange: (page, pageSize) => {
                            loadMore((page - 1) * pageSize!); // FIXME: undefined
                            setPage(page);
                        },
                        defaultCurrent: page,
                        position: ['bottomCenter'],
                        pageSize,
                        showSizeChanger: false,
                        total: data.category.totalAmount,
                    }}
                    title={renderTitle}
                    loading={loading || refetching}
                    sticky
                    dataSource={data?.category.data}
                    columns={columns}
                    onRow={(data) => {
                        return {
                            style: {
                                cursor: 'pointer',
                            },
                            onClick: () => {
                                setSelectedId(String(data.id));
                            },
                        };
                    }}
                />
            )}
        </div>
    );
};

export default IngredientGroupPage;
