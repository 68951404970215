import React, { useCallback, useEffect, useState } from 'react';
import { Button, Col, Popover, Row, Typography } from 'antd';
import { Breadcrumb } from '../../components/Breadcrumb';
import { ProductList } from '../../components/ProductList';
import { SearchBar } from 'src/components/SearchBar/SearchBar';
import { useAdvancedProductSearch, useAdvancedProductSearchCsv, useAdvancedProductSearchTotal } from 'src/api/product';
import { BooleanParam, JsonParam, useQueryParam } from 'use-query-params';
import { OrStatement } from 'src/model/productQuery';
import { CSVSelector } from 'src/components/CSVExport';
import { CheckCircleFilled, CloseCircleFilled, InfoCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { downloadData } from 'src/utils/downloadFile';
import { IconText } from 'src/components/IconText';

const QueryPage = () => {
    const [offset, setOffset] = useState<number>(0);
    const limit = 5;
    const [filter, setFilter] = useQueryParam<OrStatement>('filter', JsonParam);
    const [showCSV] = useQueryParam<boolean | null | undefined>('csv', BooleanParam);

    const { fetchMore: fetchMoreCsv } = useAdvancedProductSearchCsv({
        skip: true,
    });

    const { data, fetchMore, loading } = useAdvancedProductSearch({
        variables: {
            filter,
            offset,
            limit,
            lite: showCSV !== true,
        },
    });

    const { data: totalData, loading: totalLoading } = useAdvancedProductSearchTotal({
        variables: {
            filter,
        },
    });

    // fetch first 5 then 10
    useEffect(() => {}, []);

    const products = data?.response.products;
    const total = totalData?.response.total;
    const hasMore = data?.response.hasMore;

    useEffect(() => {
        fetchMore({ variables: { filter, offset, limit, lite: showCSV !== true } }).catch(console.error);
    }, [offset, limit, filter, fetchMore, showCSV]);
    const handleSubmit = useCallback(
        (filter) => {
            setOffset(0);
            setFilter(filter);
        },
        [setFilter]
    );
    return (
        <div>
            <CSVSelector expanded={!!showCSV} previewProducts={data?.response.products} />
            <Breadcrumb />
            <Row>
                <Col md={24} lg={18}>
                    <div>
                        <Typography.Title>Find products</Typography.Title>
                        <SearchBar
                            extraActions={
                                <>
                                    <Popover
                                        content={<pre>{totalLoading ? 'Still counting...' : 'Total: ' + total}</pre>}
                                        title="Result"
                                        trigger="hover"
                                    >
                                        <Button type="text" icon={<InfoCircleOutlined />}>
                                            Search result
                                        </Button>
                                    </Popover>

                                    {/* // <Button
                                //     onClick={() => setShowCSV(true)}
                                //     icon={<PlusCircleOutlined></PlusCircleOutlined>}
                                // >
                                //     Get CSV
                                // </Button>*/}
                                    <Button
                                        onClick={() => {
                                            if (total && total > 1000) {
                                                const ok = window.confirm(
                                                    'You are about to fetch more than 1000 products.' +
                                                        ' Are you sure you want to continue?'
                                                );
                                                if (!ok) return;
                                            }
                                            fetchMoreCsv({
                                                variables: {
                                                    filter,
                                                    limit: total || Number.MAX_SAFE_INTEGER,
                                                },
                                            })
                                                .then(async (csv) => {
                                                    const csvText = [];
                                                    for (const row of (csv.data as any).response.csv as string[][]) {
                                                        csvText.push(row.join(';'));
                                                    }

                                                    downloadData('query.csv', new Blob([csvText.join('\n')]));
                                                })
                                                .catch(console.error);
                                        }}
                                        icon={<PlusCircleOutlined />}
                                    >
                                        Get CSV
                                    </Button>
                                </>
                            }
                            defaultValues={filter}
                            loading={loading}
                            singleRow
                            onSubmit={handleSubmit}
                        />
                    </div>
                </Col>
            </Row>
            <Row gutter={[20, 20]}>
                <Col md={24} lg={18}>
                    {products && (
                        <ProductList
                            actions={(product) => {
                                const ApprovalIcon = () => {
                                    if (product.interpretedIngredients?.approval?.ok === true)
                                        return <CheckCircleFilled style={{ color: 'green' }} />;
                                    if (product.interpretedIngredients?.approval?.ok === false)
                                        return <CloseCircleFilled style={{ color: 'red' }} />;

                                    return <CloseCircleFilled style={{ color: 'grey' }} />;
                                };
                                return [
                                    <IconText
                                        icon={ApprovalIcon}
                                        text="Product is approved"
                                        key="list-vertical-star-o"
                                    />,
                                ];
                            }}
                            loading={loading}
                            hasMore={hasMore}
                            loadMore={() => {
                                const dataLength = data?.response.products.length;
                                if (dataLength) setOffset(dataLength);
                            }}
                            products={products}
                        />
                    )}
                </Col>
            </Row>
        </div>
    );
};

export default QueryPage;
