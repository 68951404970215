import { gql } from '@apollo/client';
import { CarbonEstimate } from 'src/model/carbonEstimate';
import { m, q } from 'src/utils/utils';

const useCarbonEstimateSchema = gql`
    query ($productId: Int!) {
        product(id: $productId) {
            carbonEstimate {
                total
                agriculture
                manufacturing
                packaging
                transport
            }
        }
    }
`;

export const useCarbonEstimate = q<{ product: { carbonEstimate: CarbonEstimate } }, { productId: number }>(
    useCarbonEstimateSchema,
    { fetchPolicy: 'no-cache' }
);

const useRecalculateCarbonEstimateSchema = gql`
    mutation ($productId: ID!) {
        recalculateCarbonEstimate(id: $productId)
    }
`;

export const useRecalculateCarbonEstimate = m<{ recalculateCarbonEstimate: boolean }, { productId: number }>(
    useRecalculateCarbonEstimateSchema
);
