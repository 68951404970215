import React, { FC, useCallback, useState } from 'react';
import { Button, Tooltip } from 'antd';
import { TagsOutlined } from '@ant-design/icons';
import { InternalModal } from './InternalModal';

interface BulkInterpretButtonProps {
    groupId: number;
}
export const BulkInterpretButton: FC<BulkInterpretButtonProps> = (props) => {
    const { groupId } = props;

    const [visible, setVisible] = useState(false);
    const open = useCallback(() => setVisible(true), []);
    const close = useCallback(() => setVisible(false), []);
    return (
        <>
            <Tooltip title={'Interpret the whole group except approved items'}>
                <Button type="ghost" onClick={open}>
                    Interpret all <TagsOutlined />
                </Button>
            </Tooltip>
            <InternalModal visible={visible} close={close} groupId={groupId} />
        </>
    );
};
