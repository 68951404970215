import React, { FC, useContext, useMemo } from 'react';
import { InterpretedIngredient } from '../../../../model/ingredients';
import { useIngredientsDescriptors } from '../hooks';
import { Alert, Form, Select } from 'antd';
import { IsEditingContext } from '../context';

export interface IOriginCellProps {
    record: InterpretedIngredient;
}

export const OriginCell: FC<IOriginCellProps> = (props) => {
    const { record } = props;
    const isEditing = useContext(IsEditingContext);
    const { data, loading, error } = useIngredientsDescriptors();
    const options = useMemo(
        () =>
            data?.getIngredientDescriptors.data
                .filter((_) => _.isOrigin)
                .map((_) => ({
                    value: _.name,
                    label: _.name,
                })),
        [data?.getIngredientDescriptors.data]
    );
    if (!isEditing) {
        return <>{record.origin}</>;
    }
    if (error) {
        return <Alert type={'error'} message={String(error)} />;
    }
    return (
        <Form.Item name={'origin'} style={{ marginBottom: 0 }}>
            <Select
                loading={loading}
                showSearch={true}
                options={options}
                optionFilterProp={'label'}
                allowClear={true}
                showArrow={false}
            />
        </Form.Item>
    );
};
