import { gql } from '@apollo/client';

export const SITE_USER_ROW_FRAGMENT = gql`
    fragment SiteUserRow on SiteUser {
        userName
        eMail
        userGroup {
            id
            name
        }
    }
`;
