import React, { FC, useCallback, useContext } from 'react';
import { BodyRowProps } from 'rc-table/lib/Body/BodyRow';
import { InterpretedIngredient } from '../../../../model/ingredients';
import { EditItemIdContext, IsEditingContext, IsNotEditableContext, OnEditIngredientRow } from '../context';
import { IExtraRowProps } from '../types';

export interface IRowProps extends BodyRowProps<InterpretedIngredient>, IExtraRowProps {}

export const Row: FC<IRowProps> = (props) => {
    const { children, record, ...restProps } = props;
    const onEdit = useContext(OnEditIngredientRow);
    const editItemId = useContext(EditItemIdContext);

    const handleClickRow = useCallback(() => {
        if (record && editItemId !== record.id) {
            onEdit(record.id);
        }
    }, [editItemId, onEdit, record]);

    if (!record) {
        // special case - no data.
        return <tr {...restProps}>{children}</tr>;
    }
    const isEditing = editItemId === record.id;

    return (
        <IsEditingContext.Provider value={isEditing}>
            <IsNotEditableContext.Provider value={!!editItemId && !isEditing}>
                <tr {...restProps} onClick={handleClickRow}>
                    {children}
                </tr>
            </IsNotEditableContext.Provider>
        </IsEditingContext.Provider>
    );
};
