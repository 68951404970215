import React, { FC } from 'react';
import { ProductLite } from '../model/product';
import { List, Row, Col, Spin, Typography, Button } from 'antd';
import { ROUTER_PAGES } from '../routes';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Image } from './Image';

interface IProps {
    products: ProductLite[];
    hasMore?: boolean;
    manualLoad?: boolean;
    loading?: boolean;

    loadMore?: () => void;
    content?: (product: ProductLite) => React.ReactElement;
    tags?: (product: ProductLite) => React.ReactElement;
    actions?: (product: ProductLite) => React.ReactElement[];
}

export const ProductList: FC<IProps> = (props) => {
    const { hasMore, manualLoad, products } = props;

    const history = useHistory();

    return (
        <div>
            <InfiniteScroll
                loader={
                    <div key={1} style={{ width: '100%', display: 'flex' }}>
                        <Spin style={{ margin: 'auto' }} />
                    </div>
                }
                scrollThreshold={0.5}
                dataLength={hasMore ? products.length + 1 : products.length}
                next={
                    () => {
                        if (props.loadMore) props.loadMore();
                    } /* FIXME: undefined */
                }
                hasMore={manualLoad ? false : props.hasMore !== false}
            >
                <List
                    itemLayout="vertical"
                    loading={props.loading && props.hasMore}
                    dataSource={props.products}
                    renderItem={(item) => (
                        <List.Item
                            extra={
                                <>
                                    <Row justify="center" align="middle">
                                        <Col>{props?.content?.(item)}</Col>
                                        <Col>
                                            <div style={{ width: '60px', height: '60px', display: 'flex' }}>
                                                <Image
                                                    style={{ maxWidth: '60px', maxHeight: '60px', margin: 'auto' }}
                                                    src={item.productImage?.url}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            }
                            actions={props?.actions?.(item)}
                            style={{ cursor: 'pointer' }}
                            key={item.id}
                            onClick={() => history.push(ROUTER_PAGES.products.path.replace(':id', item.id.toString()))}
                        >
                            <List.Item.Meta
                                title={item.name}
                                description={
                                    <>
                                        <Typography>
                                            {item.brandName}, {item.productIdentifier.externalId}
                                        </Typography>
                                    </>
                                }
                            />
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
            {manualLoad && (
                <div style={{ display: 'flex' }}>
                    <Button
                        loading={props.loading}
                        disabled={!hasMore}
                        style={{ margin: 'auto' }}
                        onClick={props.loadMore}
                    >
                        Load more
                    </Button>
                </div>
            )}
        </div>
    );
};
