import { gql, QueryResult, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ID } from '../../../../../utils/type';
import { notNullGuard } from '../../../../../utils/typescript';

const getIngredientGroupRelationshipsSchema = gql`
    query getIngredientGroupRelationships {
        getIngredientCategories(offset: 0, limit: 99999999, q: "") {
            data {
                id
                name
                parentGroup {
                    id
                }
            }
            totalAmount
        }
    }
`;

interface IngredientGroupRef {
    id: ID;
}
type ResponseIngredientGroup = {
    id: ID;
    name: string;
    parentGroup: null | {
        id: ID;
    };
};
type Response = {
    getIngredientCategories: null | {
        data: Array<null | ResponseIngredientGroup>;
    };
};
type ArrayLikeData = (IngredientGroupRef & {
    name: string;
    parentGroup: IngredientGroupRef | null;
})[];
type MapEntry = IngredientGroupRef & {
    name: string;
    children: (IngredientGroupRef & { name: string })[];
};
type ReadonlyMapEntry = IngredientGroupRef & {
    name: string;
    children: readonly (IngredientGroupRef & { name: string })[];
};
type ReadonlyIngredientGroupsMap = ReadonlyMap<string, ReadonlyMapEntry>;

function toMap(source: ArrayLikeData): Map<string, MapEntry> {
    const groupsMap = new Map<string, MapEntry>(
        source.map((group) => [
            group.id.toString(),
            {
                id: group.id,
                name: group.name,
                children: [],
            },
        ])
    );
    for (const group of source) {
        const groupsTreeNode = groupsMap.get(group.id.toString());
        if (groupsTreeNode) {
            const { parentGroup } = group;
            if (parentGroup) {
                const parentTreeNode = groupsMap.get(parentGroup.id.toString());
                if (parentTreeNode) {
                    if (!parentTreeNode.children.includes(groupsTreeNode)) {
                        parentTreeNode.children.push(groupsTreeNode);
                    }
                }
            }
        }
    }
    return groupsMap;
}
export function useIngredientGroupRelationshipsMap(): [QueryResult<Response>, ReadonlyIngredientGroupsMap] {
    // FIXME: //  `api/client`, has a field policy for the request and it is not good enough, mess the data and honestly I do not know if something is broken after the request
    const queryResult = useQuery<Response>(getIngredientGroupRelationshipsSchema);
    const map = useMemo(() => {
        if (!queryResult.data) {
            return new Map();
        }
        const ingredientGroupsData = queryResult.data.getIngredientCategories;
        if (!ingredientGroupsData) {
            return new Map();
        }
        const ingredients = ingredientGroupsData.data.filter(notNullGuard);
        return toMap(ingredients);
    }, [queryResult.data]);

    return useMemo(() => [queryResult, map], [queryResult, map]);
}
