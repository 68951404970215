import { gql } from '@apollo/client';
import { Segment } from '../model/segment';
import { q } from '../utils/utils';

const useSegmentSchema = gql`
    query segment($segmentCode: String!) {
        segment(segmentCode: $segmentCode) {
            code
            families {
                code
                text
                classes {
                    code
                    text
                    bricks {
                        code
                        text
                    }
                }
            }
        }
    }
`;
export const useSegment = q<{ segment: Segment }, { segmentCode: string }>(useSegmentSchema);
