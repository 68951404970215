import { Button, Form, FormProps, Input, Select } from 'antd';
import React, { FC, useCallback } from 'react';
import { useAddOrigin } from './hooks';
import { notification } from 'antd';

export interface Values {
    name: string;
    synonyms: string[];
}
export interface AddNewOriginFormProps {}
const AddNewOriginForm: FC<AddNewOriginFormProps> = () => {
    const [addOrigin, { loading }] = useAddOrigin();
    const onSave: FormProps<Values>['onFinish'] = useCallback(
        (values) => {
            addOrigin({
                variables: {
                    values: {
                        name: values.name,
                        synonyms: values.synonyms,
                    },
                },
            })
                .then(() => {
                    notification.success({
                        message: 'The origin is created',
                    });
                })
                .catch((err) => {
                    notification.error({
                        message: err instanceof Error ? err.message : String(err),
                    });
                });
        },
        [addOrigin]
    );
    return (
        <Form
            onFinish={onSave}
            initialValues={{
                name: '',
                synonyms: [],
            }}
        >
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
                <Input />
            </Form.Item>
            <Form.Item label={'Synonyms'} name={'synonyms'}>
                <Select mode="tags" style={{ width: '100%' }} />
            </Form.Item>
            <Button loading={loading} htmlType={'submit'} type={'primary'}>
                Create
            </Button>
        </Form>
    );
};

export default AddNewOriginForm;
