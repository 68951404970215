import { gql, QueryHookOptions, QueryResult, useMutation, useQuery } from '@apollo/client';
import { IngredientOrigin } from './types';
import { MutationHookOptions, MutationTuple } from '@apollo/client/react/types/types';
import { ID } from '../../utils/type';

export interface IngredientsOriginsResponse {
    getIngredientOrigins: {
        totalAmount: number;
        data: Array<IngredientOrigin | null> | null;
    };
}
export function useFetchOrigins(
    options: QueryHookOptions<IngredientsOriginsResponse, { limit: number; offset: number }>
): QueryResult<IngredientsOriginsResponse, { limit: number; offset: number }> {
    return useQuery(
        gql`
            query OriginsPage_Origins($limit: Int!, $offset: Int!) {
                getIngredientOrigins(limit: $limit, offset: $offset) {
                    totalAmount
                    data {
                        id
                        name
                        synonyms
                    }
                }
            }
        `,
        options
    );
}

export function useDeleteOrigin(options: MutationHookOptions<unknown, { id: ID }>): MutationTuple<unknown, { id: ID }> {
    return useMutation(
        gql`
            mutation OriginsPage_DeleteOrigin($id: ID!) {
                deleteIngredientOrigin(id: $id)
            }
        `,
        options
    );
}
