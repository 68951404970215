import { FC, useCallback, useMemo } from 'react';
import { Form, notification, Switch } from 'antd';
import { PERMISSION_KEYS, PermissionsType, UserGroup } from '../types';
import { useUpdateGroup } from '../hooks';

type FormValues = PermissionsType;
export interface GroupPermissionsProps {
    group: UserGroup;
}

const GroupPermissions: FC<GroupPermissionsProps> = ({ group }) => {
    const [form] = Form.useForm();

    const [updateGroupPermissions, { loading }] = useUpdateGroup();

    const handleChange = useCallback(
        (changed: unknown, values: FormValues) => {
            updateGroupPermissions({
                variables: {
                    id: group.id,
                    values: {
                        admin: group.admin,
                        name: group.name,
                        permissions: values,
                    },
                },
            }).catch((err) => {
                notification.error({
                    message: err instanceof Error ? err.message : String(err),
                });
                form.resetFields();
            });
        },
        [form, group, updateGroupPermissions]
    );
    const initialValues: FormValues = useMemo(() => {
        const result = {} as FormValues;
        for (const key of PERMISSION_KEYS) {
            const value = group.permissions[key] || {
                read: false,
                write: false,
            };
            result[key] = {
                read: value.read,
                write: value.write,
            };
        }

        return result;
    }, [group.permissions]);

    return (
        <Form layout={'inline'} onValuesChange={handleChange} initialValues={initialValues} form={form}>
            {PERMISSION_KEYS.map((key) => {
                return (
                    <Form.Item key={key} label={key}>
                        <Form.Item name={[key, 'read']} label={'read'} valuePropName={'checked'}>
                            <Switch loading={loading} />
                        </Form.Item>
                        <Form.Item name={[key, 'write']} label={'write'} valuePropName={'checked'}>
                            <Switch loading={loading} />
                        </Form.Item>
                    </Form.Item>
                );
            })}
        </Form>
    );
};

export default GroupPermissions;
