import { createContext } from 'react';
import { InterpretedIngredient } from '../../../model/ingredients';
import { FormInstance } from 'antd';

export const EditItemIdContext = createContext<InterpretedIngredient['id'] | null>(null);
export const IsEditingContext = createContext(false);
export const IsNotEditableContext = createContext(false);
export const ItemUpdateInProgressContext = createContext(false);
export const ItemsOnDeleteContext = createContext<ReadonlySet<number>>(new Set());
export const OnEditIngredientRow = createContext((ingredientId: number): void => {});
export const OnMoveIngredientUp = createContext((ingredient: InterpretedIngredient) => {});
export const OnMoveIngredientUpper = createContext((ingredient: InterpretedIngredient) => {});
export const OnMoveIngredientDown = createContext((ingredient: InterpretedIngredient) => {});
export const EditRowForm = createContext<FormInstance | null>(null);
