import { gql, QueryResult, useMutation, useQuery } from '@apollo/client';
import { MutationTuple } from '@apollo/client/react/types/types';
import { LIFE_TAGS } from '../../../api/product';
import { YesNoMaybe } from '../../../model/ingredients';
import { ING_HIT_CHILDREN } from '../../../api/product';

const UPDATE_HIT = gql`
    mutation update($hitId: ID!, $values: UpdateIntepretedIngredientInput!) {
        updateInterpretedIngredient(id: $hitId, values: $values) {
            id
            interpretedIngredients {
                id
                data {
                    ...ingHit
                    ...ingHitChildren
                }
            }
        }
    }

    ${ING_HIT_CHILDREN}
`;

export interface IUpdateIngredientHitVariables {
    hitId: number;
    values: {
        amount: string | undefined;
        descriptions: string[] | undefined;
        eco: string | undefined;
        foundName: string | undefined;
        ingredientId: number | undefined;
        origin: string | undefined;
        parentId: number | undefined;
    };
}
export function useUpdateIngredientHit(): MutationTuple<unknown, IUpdateIngredientHitVariables> {
    return useMutation(UPDATE_HIT);
}

export interface IDeleteIngredientHitVariables {
    hitId: number;
}
const DELETE_INGREDIENT_HIT = gql`
    mutation delete($hitId: ID!) {
        deleteIngredientProductHit(id: $hitId) {
            id
            interpretedIngredients {
                id
                average
                message
                approval {
                    ok
                    id
                }
                changes {
                    changes
                }
                data {
                    ...ingHit
                    ...ingHitChildren
                }
            }
        }
    }

    ${ING_HIT_CHILDREN}
`;

export function useDeleteIngredientHit(): MutationTuple<unknown, IDeleteIngredientHitVariables> {
    return useMutation(DELETE_INGREDIENT_HIT);
}

const FETCH_INGREDIENTS = gql`
    query {
        getIngredients(offset: 0, limit: 99999999, q: "") {
            data {
                id
                name
                synonyms
                eNumber
            }
        }
    }
`;
export interface IFetchIngredientsResponse {
    getIngredients: {
        data: {
            id: string;
            name: string;
            synonyms: (string | null)[] | null;
            eNumber: string | null;
        }[];
    };
}
export function useIngredients(): QueryResult<IFetchIngredientsResponse, unknown> {
    return useQuery(FETCH_INGREDIENTS, {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network',
    });
}

const ADD_MUTATION = gql`
    mutation addIngredientProductHit($productId: ID!, $values: UpdateIntepretedIngredientInput!) {
        addInterpretedIngredient(productId: $productId, values: $values) {
            id
            interpretedIngredients {
                id
                average
                message
                approval {
                    ok
                    id
                }
                changes {
                    changes
                }
                data {
                    ...ingHit
                    ...ingHitChildren
                }
            }
        }
    }

    ${ING_HIT_CHILDREN}
`;

export interface IAddIngredientHitVariables {
    productId: number;
    values: {
        amount?: string;
        descriptions?: string[];
        eco?: string;
        foundName?: string;
        ingredientId: number;
        origin?: string;
        parentId?: number;
    };
}
export function useAddIngredientHit(): MutationTuple<unknown, IAddIngredientHitVariables> {
    return useMutation(ADD_MUTATION);
}

const MOVE_HIT = gql`
    fragment Product on Product {
        id
        interpretedIngredients {
            id
            data {
                ...ingHit
                ...ingHitChildren
            }
        }
    }
    mutation move($hitId: ID!, $target: MoveInterpretedIngredientInputTarget!) {
        moveInterpretedIngredient(id: $hitId, target: $target) {
            ...Product
        }
    }

    ${ING_HIT_CHILDREN}
`;
export interface IMoveIngredientHitVariables {
    hitId: number;
    target: {
        afterId: number | null;
        parentId: number | null;
    };
}
export function useMoveIngredientHit(): MutationTuple<unknown, IMoveIngredientHitVariables> {
    return useMutation(MOVE_HIT);
}

const GET_INGREDIENTS_DESCRIPTORS = gql`
    query getIngredientDescriptors($offset: Int!, $limit: Int!) {
        getIngredientDescriptors(offset: $offset, limit: $limit) {
            data {
                id
                name
                isOrigin
            }
        }
    }
`;

export interface IFetchIngredientDescriptorsResponse {
    getIngredientDescriptors: {
        data: { id: number | string; name: string; isOrigin: boolean }[];
    };
}
export function useIngredientsDescriptors(): QueryResult<
    IFetchIngredientDescriptorsResponse,
    { offset: number; limit: number }
> {
    return useQuery(GET_INGREDIENTS_DESCRIPTORS, {
        variables: {
            offset: 0,
            limit: 999999999,
        },
    });
}

const UPDATE_PRODUCT_MESSAGE = gql`
    mutation ($productId: ID!, $message: String!) {
        updateProductMessage(id: $productId, message: $message) {
            id
            interpretedIngredients {
                id
                message
            }
        }
    }
`;
export interface IUpdateProductMessageVariables {
    productId: number;
    message: string;
}
export function useUpdateProductMessage(): MutationTuple<unknown, IUpdateProductMessageVariables> {
    return useMutation(UPDATE_PRODUCT_MESSAGE);
}

const GET_PRODUCT_MESSAGE = gql`
    query ($productId: Int!) {
        product(id: $productId) {
            id
            interpretedIngredients {
                id
                message
            }
        }
    }
`;
export interface IProductMessageResult {
    product: {
        interpretedIngredients: null | {
            message: null | string;
        };
    };
}
export function useProductMessage(productId: number): QueryResult<IProductMessageResult, { productId: number }> {
    return useQuery(GET_PRODUCT_MESSAGE, {
        variables: {
            productId,
        },
    });
}

const GET_PRODUCT_LIFE_TAGS = gql`
    query ($productId: Int!) {
        product(id: $productId) {
            id
            interpretedLifestyles {
                ...LifeTagFields
            }
        }
    }

    ${LIFE_TAGS}
`;
export interface IProductLifeTagsResult {
    product: {
        interpretedLifestyles: {
            id: string | number;
            isLactoVegetarian: YesNoMaybe;
            isVegan: YesNoMaybe;
            isVegetarian: YesNoMaybe;
            isOvoVegetarian: YesNoMaybe;
            isPescetarian: YesNoMaybe;
        } | null;
    };
}
export function useProductLifeTags(productId: number): QueryResult<IProductLifeTagsResult, { productId: number }> {
    return useQuery(GET_PRODUCT_LIFE_TAGS, {
        variables: {
            productId,
        },
    });
}

const UPDATE_PRODUCT_LIFE_TAGS = gql`
    mutation updateProductLifeTags($productId: ID!, $values: UpdateLifestylesInput!) {
        updateProductLifestyle(productId: $productId, values: $values) {
            id
            interpretedLifestyles {
                id
                isVegetarian
                isLactoVegetarian
                isOvoVegetarian
                isPescetarian
                isVegan
            }
        }
    }
`;
export interface IUpdateProductLifeTagsVariables {
    productId: string | number;
    values: {
        isLactoVegetarian: YesNoMaybe;
        isOvoVegetarian: YesNoMaybe;
        isPescetarian: YesNoMaybe;
        isVegan: YesNoMaybe;
        isVegetarian: YesNoMaybe;
    };
}
export function useUpdateProductLifeTags(): MutationTuple<unknown, IUpdateProductLifeTagsVariables> {
    return useMutation(UPDATE_PRODUCT_LIFE_TAGS);
}
